export const constantValues = {
	businessOrAppName:'GoPaani',
	frontendDateFormat:'DD MMM YYYY',	// To show the date in app
	backendDteFormat:'DD-MM-YYYY',		// To save the date in datebase
	loadingViewStyle:{
		backgroundColor:'#ffffff',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	gradientCommonColorsArray:['#175491','#175491','#5154b5','#5154b5'],
	crossPlatformToastStyle:{
		backgroundColor:'#ffffff',
		textColor:'black',
	},
	crossPlatformToastSomeValue:{
		position: -70,
		delay: 0,
		opacity:0.8,
		shadow: true,
		animation: false,
		hideOnPress: false,
	},
	crossPlatformToastDuration:1500,
};