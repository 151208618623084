import i18n from 'i18n-js';
import {constantValues} from './helpers';
import alert from './commonAlert'
	
export const showTechnicalError = ()=> {
	alert(
		constantValues.businessOrAppName,
		i18n.t('defaultTechnicalError'),
		[
			{text: i18n.t('ok'), onPress: () =>{
				null
			}},
		],
		{ cancelable: false }
	);
}
