import React, { Component } from 'react';
import getEnvVars from '../environment';
const { apiUrl } = getEnvVars();
import { Text, View, StyleSheet, ScrollView,ActivityIndicator, Image, TouchableOpacity, Clipboard, Platform, Alert, Share,BackHandler } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import i18n from 'i18n-js';
import translationValues from '../components/translation';

export default class PaymentSuccessScreen extends Component{

	constructor(props){
		super(props)
		this.state = {
			isLoading:true
		}
	}
	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('willFocus', ()=>{
 			i18n.fallbacks = true;
 			i18n.translations = translationValues;
 			i18n.locale = global.isLanguage
 			this.setState({isLoading:false})
 		});
		this.setState({isLoading:false})
	}

	onCloseButtonPress = () => {
		this.props.navigation.goBack();
	}
	
	render(){
		const { navigate, state: { params } } = this.props.navigation;
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<LinearGradient colors={['#BFECF4','#BFECF4', '#BFECF4', '#FFFFFF']} start={{ x: 1, y: 1 }} end={{ x: 1, y: 0 }} style={styles.linearGradientStyle}>
					<TouchableOpacity style={styles.closeButtonViewStyle} onPress={()=> this.onCloseButtonPress()}>
						<MaterialCommunityIcons name="close-circle" size={28} color="#ffffff" style={styles.closeCircleIconStyle} />
					</TouchableOpacity>
					<View style={styles.mainViewStyle}>
						<MaterialCommunityIcons name="checkbox-multiple-marked-circle" size={65} color="#59D400" />
						<View style={styles.paidAmountViewStyle}>
							<MaterialCommunityIcons name="currency-inr" size={32} color="black" />
							<Text style={styles.paidAmountTextStyle}>{params.paidAmount}</Text>
						</View>
						<Text style={styles.successPaymentTextStyle}>{i18n.t('transactionSuccessMessage')}</Text>
						<Text style={styles.amountTransferToBusinessTextStyle}>{i18n.t('transactionInfoText',{businessName:params.businessName})}</Text>
						<Text style={styles.orderDetailsTextStyle}>{i18n.t('transactionDetails')}{' '}{params.orderId}</Text>
						<Image
							style={styles.paidAmountIconStyle} 
							source={require('../assets/images/payment-paid-image.png')}
						/>
					</View>
				</LinearGradient>
			);
		}
	}
}

const styles = StyleSheet.create({
 	loadingViewStyle:{
 		position: 'absolute',
 		left: 0,
 		right: 0,
 		top: 0,
 		bottom: 0,
 		alignItems: 'center',
 		justifyContent: 'center'
 	},
 	mainViewStyle:{
 		paddingTop:15,
 		justifyContent:'center',
 		alignItems:'center'
 	},
 	paidAmountViewStyle:{
 		paddingTop:15,
 		flexDirection:'row',
 		alignItems:'center'
 	},
 	paidAmountTextStyle:{
 		paddingLeft:5,
 		fontWeight:'bold',
 		color:'#000000',
 		fontSize:28
 	},
 	successPaymentTextStyle:{
 		paddingTop:15,
 		textAlign:'center',
 		fontWeight:'bold',
 		color:'#000000',
 		fontSize:18
 	},
 	amountTransferToBusinessTextStyle:{
 		paddingTop:20,
 		textAlign:'center',
 		color:'#000000',
 		fontSize:14
 	},
 	orderDetailsTextStyle:{
		paddingTop:10,
 		textAlign:'center',
 		color:'#000000',
 		fontSize:14
 	},
 	paidAmountIconStyle:{
 		width:wp('100%'),
 		height:250,
 		resizeMode:'contain'
 	},
 	closeButtonViewStyle:{
 		flexDirection:'row',
 		justifyContent:'flex-end',
 		elevation:5,
 		paddingRight:15
 	},
 	closeCircleIconStyle:{
 		backgroundColor:'#b3b3b3',
 		borderRadius:28/2
 	},
 	linearGradientStyle:{
 		flex:1,
 		marginTop:25
 	}
})