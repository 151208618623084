import AsyncStorage from '@react-native-async-storage/async-storage'
import i18n from 'i18n-js';
/*

	used function to return the aysnc data of jar type and  return the userfacing jar type 
*/
export const getJarTypeValue = async () => {

 	let userFacingDataOfCool =  await AsyncStorage.getItem('userFacingDataOfCool');
	let userFacingDataOfPet =  await AsyncStorage.getItem('userFacingDataOfPet');
	let jsonParseUserFacingDataOfCool = JSON.parse(userFacingDataOfCool)
	let jsonParseUserFacingDataOfPet = JSON.parse(userFacingDataOfPet)
	let userFacingNameCool = ''
	let userFacingNamePet = ''
	let productImageOfCool = null
	let productImageOfPet = null
	if(jsonParseUserFacingDataOfCool){
		userFacingNameCool = jsonParseUserFacingDataOfCool.userFacingNameOfCool
		productImageOfCool = jsonParseUserFacingDataOfCool.productImageOfCool
	}
	if(jsonParseUserFacingDataOfPet){
		userFacingNamePet = jsonParseUserFacingDataOfPet.userFacingNameOfPet
		productImageOfPet = jsonParseUserFacingDataOfPet.productImageOfPet
	}
	return {userFacingNameCool:userFacingNameCool ?userFacingNameCool :i18n.t('chilled_jar'), userFacingNamePet:userFacingNamePet ?userFacingNamePet :i18n.t('capsule_jar'),productImageOfCool:productImageOfCool,productImageOfPet:productImageOfPet}

}