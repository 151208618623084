import { StyleSheet } from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';

export default StyleSheet.create({
	errorTextStyle:{
		fontSize:12,
		textAlign:'left',
		color:'red',
		paddingTop:10
	},
	discountTextInputViewStyle:{
		padding:5,
		alignItems:'center',
		marginBottom:10
	},
	discountHeadingTextStyle:{
		fontSize:14,
		width: wp('85%'),
		lineHeight:30,
		color:'#999999',
		textAlign:'left'
	},
	discountViewStyle:{
		backgroundColor:'#F2FCFC',
		padding:10,
		paddingTop:0,
		alignItems:'center',
		justifyContent:'center',
		borderColor: '#fff',
		width:wp('93%'),
		borderWidth: 1,
		borderRadius: 5,
		elevation: 5,//android
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10
		
	},
	discountSuccessTextStyle:{
		color:'#289400',
		fontWeight:'bold',
		fontSize:14,
		textAlign:'center',
		paddingTop:10,
	},
	alreadySubmitReferrralStyle:{
		padding:10,
		justifyContent:'center',
		alignItems:'center'
	},
	discountInputViewStyle:{
		width: wp('85%'),
		height:50,
		paddingHorizontal:8,
		borderColor:'#175491',
		borderWidth:1,
		borderRadius:5,
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between'
	},
	discountInputStyle: {
		width:wp('55%'),
		color: '#175491',
		fontWeight:'bold',
		fontSize: 14,
		minHeight:35
	},
	disableButtonStyle:{
		borderWidth:1,
		borderColor:'#38c6f4',
		backgroundColor:'transparent',
		padding:4,
		width:80
	},
	disabledTitleStyle:{
		fontSize:16,
		color:'#b3b3b3',
		paddingTop:0,
		paddingBottom:0,
		textAlign:'center',
		fontWeight:'bold'
	},
	discountTitleStyle:{
		fontSize:16,
		color:'#38c6f4',
		paddingTop:0,
		paddingBottom:0,
		textAlign:'center',
		fontWeight:'bold'
	},
	discountButtonStyle:{
		borderWidth:1,
		borderColor:'#38c6f4',
		backgroundColor:'transparent',
		padding:4,
		width:80
	},
	input: {
		width: wp('85%'),
		fontSize: 14,
		color:'#808080',
	},
	ImageIconStyle: {
		height: 65,
		width: 65,
		resizeMode : 'contain',
	},
	IconStyle: {
		height: hp('4%'),
		width: wp('4%'),
		resizeMode : 'contain',
		marginLeft:10,
	},
	ButtonStyle: {
		flexDirection: 'row',
		justifyContent:'center',
		alignItems:'center',
		backgroundColor: '#fff',
		borderColor: '#fff',
		width:wp('42%'),
		height: hp('25%'),
		borderWidth: 1,
		borderRadius: 11,
		elevation: 5,//android
		margin:wp('2.2%'),
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10
	},
	TextStyle :{
		color: "#175491",
		marginBottom : 4,
		fontWeight:'bold',
		fontSize:16,
		marginTop:10,
		textAlign: 'center',
	},
	dashboardIcon:{
		flexDirection:'column',
		justifyContent:'center',
		alignItems:'center'
	},
	deliveryJarViewStyle:{
		padding:20,
		alignItems:'center',
		justifyContent:'center',
		backgroundColor: '#fff',
		borderColor: '#fff',
		width:wp('93%'),
		borderWidth: 1,
		borderRadius: 5,
		elevation: 5,//android
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10
	},
	mainDeliveryViewStyle:{
		marginTop:10,
		justifyContent:'center',
		alignItems:'center'
	},
	todayDeliveryIconStyle:{
		height: 65,
		width: 65,
		resizeMode : 'contain',
	},
	todaysDeliveryDetailViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		borderBottomWidth:3,
		borderBottomColor:'#f2f5f7',
		paddingBottom:10,
	},
	pastTodaysDeliveryViewStyle:{
		alignItems:'flex-start',
		paddingLeft: wp('4%'), 
		justifyContent:'space-around',
		width:wp('45%')
	},
	deliveryDetailViewStyle:{
		padding:10,
		alignItems:'flex-end',
		justifyContent:'flex-end',
		width:wp('25%')
	},
	todaysDeliveryTextStyle:{
		fontSize:16,
		fontWeight:'bold',
		color:'#175491',
		marginBottom:10
	},
	pastEntriesButtonStyle:{
		borderColor:'#38c6f4',
		borderRadius:5,
		borderWidth:1,
		padding:5,
		paddingLeft:15,
		paddingRight:15
	},
	pastEntriesTextStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#38c6f4'
	},
	jarCountTextStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#808080'
	},
	deliveryStatusViewStyle:{
		flexDirection:'row',
		alignItems:'flex-end'
	},
	deliveryTimeTextStyle:{
		textAlign:'left',
		fontSize:12,
		color:'#175491',
		marginTop:5,
		marginBottom:5,
		marginRight:5
	},
	balanceJarViewStyle:{
		paddingTop:10,
		paddingBottom:10,
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
	},
	balanceJarTextStyle:{
		fontSize:16,
		fontWeight:'bold',
		color:'#38c6f4',
		width:wp('40%')
	},
	balanceJarValueViewStyle:{
		flexDirection:'row',
		alignItems:'flex-end',
		justifyContent:'space-around',
		width:wp('48%')
	},
	balanceJarValueTextStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#808080',
	},
	jarIconStyle:{
		width:20,
		height:20,
		resizeMode:'contain'
	},
	deliveryChangesJarIconStyle:{
		width:20,
		height:20,
		resizeMode:'contain'
	},
	ratingTodaysDeliveryViewStyle:{
		paddingTop:10,
		paddingBottom:10,
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
	},
	rateHeadingTextStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#808080',
		width:wp('40%')
	},
	ratingViewStyle:{
		flexDirection:'row',
		alignItems:'flex-end',
		justifyContent:'space-around',
		width:wp('45%')
	},
	noDeliveryDayIconStyle:{
		width:45,
		height:45,
		resizeMode:'contain'
	},
	crossIconStyle:{
		backgroundColor:'#f2f5f7',
		elevation:5,
		alignItems:'center',
		width:28,
		height:28,
		borderRadius:28/2,
		justifyContent:'center',
		textAlign:'center',
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10
	},
	crossButtonViewStyle:{
		justifyContent:'flex-end',
		alignItems:'flex-end',
		marginRight:-15,
		marginTop:-20
	},
	reviewPaymentCrossButtonViewStyle:{
		justifyContent:'flex-end',
		alignItems:'flex-end',
		marginRight:-25,
		marginTop:-20
	},
	overlayRatingHeadingTextStyle:{
		fontSize:16,
		fontWeight:'bold',
		color:'#38c6f4',
		textAlign:'center',
		paddingBottom:10
	},
	ratingCommentTextStyle:{
		fontSize:14,
		color:'#808080',
		textAlign:'center',
		paddingTop:10,
		paddingBottom:10
	},
	overlayRatingViewStyle:{
		alignItems:'center',
		justifyContent:'center',
	},
	fieldInnerViewStyle:{
		marginTop:10,
		marginBottom:10,
		padding:10,
		alignItems:'center',
		justifyContent:'center',
		borderWidth:1,
		borderColor:'#b3b3b3',
		borderRadius:5
	},
	overlaySubmitButton:{
		flexDirection:'row',
		justifyContent:'center',
		alignItems:'center',
		marginTop:10,
		marginBottom:10
	},
	overlaySubmitButtonStyle:{
		width:wp('80%'),
		padding:10,
		marginBottom:10,
		borderRadius:5
	},
	overlayViewStyle:{
		justifyContent:'center',
		alignItems:'center',
	},
	titleStyle:{
		fontWeight:'bold',
		color:'#ffffff',
		fontSize:20,
		textAlign:'center'
	},
	AdvanceTitleStyle:{
		letterSpacing:2.5,
		color:'#ffffff',
		fontSize:20,
		textAlign:'center'
	},
	buttonBarViewStyle:{
		marginTop:10,
		flexDirection: 'row',
		flexWrap: 'wrap',
		width:wp('100%'),
		justifyContent:'center',
		alignItems:'center'
	},
	buttonBarStyle:{
		paddingTop:10,
		paddingBottom:10,
		flexDirection:'column',
		justifyContent:'space-between',
		alignItems:'center',
		backgroundColor: '#fff',
		borderColor: '#fff',
		width:wp('43%'),
		height:196,
		borderWidth: 1,
		borderRadius: 5,
		elevation: 5,//android
		margin:wp('2.5%'),
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10
	},
	deliveryChangesButtonBarStyle:{
		flexDirection:'column',
		justifyContent:'space-between',
		backgroundColor: '#fff',
		borderColor: '#fff',
		width:wp('43%'),
		height:196,
		borderWidth: 1,
		borderRadius: 5,
		elevation: 5,//android
		margin:wp('2.5%'),
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10,
		overflow:'hidden'
	},
	deliveryChangesButtonBarBackGroundStyle:{
		flexDirection:'column',
		justifyContent:'space-between',
		backgroundColor: '#E4F9F8',
		borderColor: '#fff',
		width:wp('43%'),
		height:196,
		borderWidth: 1,
		borderRadius: 5,
		elevation: 5,//android
		margin:wp('2.5%'),
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10,
		overflow:'hidden'
	},
	dueAmountViewStyle:{
		flexDirection:'row',
		alignItems:'center'
	},
	dueAmountTextStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#175491',
		textAlign:'center',
		paddingHorizontal:5
	},
	dueAmountValueStyle:{
		fontSize:18,
		fontWeight:'bold',
		color:'#175491',
		textAlign:'center'
	},
	creditAmountValueStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'green',
		textAlign:'center'
	},
	jarDeliveryFrequencyStyle:{
		paddingTop:20,
		fontSize:14,
		fontWeight:'bold',
		color:'#175491',
		textAlign:'center',
	},
	payNowButtonStyle:{
		backgroundColor:'#38c6f4',
		borderRadius:5,
		width:wp('35%'),
		justifyContent:'center',
		alignItems:'center',
		padding:5,
		marginBottom:10
	},
	payNowDisableButtonStyle:{
		backgroundColor:'#bcbec0',
		borderRadius:5,
		width:wp('35%'),
		justifyContent:'center',
		alignItems:'center',
		padding:5
	},
	payNowTextStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#ffffff',
		textAlign:'center',
	},
	payNowDisableTextStyle:{
		fontSize:14,
		color:'#ffffff',
		fontWeight:'bold',
		opacity:0.7,
		textAlign:'center',
	},
	eachJarDeliveryFrequencyStyle:{
		marginTop:10,
		flexDirection:'row',
		borderWidth:0.5,
		borderColor:'#175491',
		borderRadius:5,
		width:wp('25%'),
		justifyContent:'space-evenly',
		alignItems:'center',
		backgroundColor:'#ffffff',
		paddingTop:5,
		paddingBottom:5
	},
	deliveryFrequencyValueStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#175491',
		textAlign:'center',
	},
	deliveryJarChangesStyle:{
		flexDirection:'column',
		backgroundColor: '#fff',
		borderColor: '#fff',
		width:wp('43%'),
		height:196,
		borderWidth: 1,
		borderRadius: 5,
		elevation: 5,
		margin:wp('2.5%'),
		justifyContent:'center',
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10
	},
	bottomButtonBarStyle:{
		flexDirection:'column',
		justifyContent:'center',
		alignItems:'center',
		backgroundColor: '#fff',
		borderColor: '#fff',
		width:wp('43%'),
		height:196,
		borderWidth: 1,
		borderRadius: 5,
		elevation: 5,//android
		margin:wp('2.5%'),
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10
	},
	bottomBarButtonHeadingStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#175491',
		marginTop:10,
		textAlign:'center',
	},
	overlaySetOffDayTextStyle:{
		fontSize:16,
		fontWeight:'bold',
		color:'#38c6f4',
		textAlign:'center',
		paddingBottom:10
	},
	headingOverlayAdvanceAmountTextStyle:{
		fontSize:16,
		fontWeight:'bold',
		color:'#38c6f4',
		textAlign:'center',
		paddingBottom:10
	},
	subheadingAdvanceAmountTextStyle:{
		fontSize:14,
		color:'#808080',
		textAlign:'center',
		paddingTop:5,
		paddingBottom:15,
		marginBottom:20,
		borderBottomWidth:5,
		borderBottomColor:'#f2f5f7'
	},
	ItemViewStyle:{
		borderWidth:1,
		borderColor:'#b3b3b3',
		borderRadius:5,
		width:wp('80%'),
		marginBottom:20,
		marginTop:10,
		alignItems:'flex-start'
	},
	labelTextStyle:{
		backgroundColor:'#ffffff',
		fontSize: 16,
		color: "#808080",
		fontWeight:'bold',
		marginTop:-12,
		marginLeft:20,
		paddingLeft:5,
		paddingRight:5
	},
	textInputViewStyle:{
		alignItems:'center',
		flexDirection:'row',
		paddingTop:5,
		paddingBottom:5,
		paddingLeft:10
	},
	textInputStyle:{
		width: wp('70%'),
		fontSize: 14,
		marginLeft:10,
		color:'#808080',
		fontWeight:'bold',
		minHeight : 35
	},
	contentSetOffDayStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#808080',
		textAlign:'center',
		paddingBottom:5
	},
	subContentSetOffDayStyle:{
		fontSize:12,
		color:'#808080',
		textAlign:'center',
		paddingBottom:15
	},
	offDayDateSelectionViewStyle:{
		justifyContent:'center',
		alignItems:'center',
		flexDirection:'row',
		paddingTop:10,
		paddingBottom:20
	},
	dateSelectionTouchableStyle:{
		alignItems:'flex-start',
		justifyContent:'center',
		borderWidth:1,
		borderColor:'#38c6f4',
		borderRadius:5,
		width:wp('42%'),
		padding:5
	},
	selectedDateTouchableStyle:{
		flexDirection:'row',
		width:wp('45%'),
		alignItems:'center',
		justifyContent:'flex-start'
	},
	selectedDateTextStyle:{
		fontSize: 14,
		color: "#38c6f4"
	},
	datePickerStyle:{
		justifyContent:'center',
		alignItems:'center',
		width:wp('42%')
	},
	arrowViewStyle:{
		marginLeft:-12,
		marginRight:-12,
		zIndex:2
	},
	arrowBarStyle:{
		alignItems:'flex-end',
		justifyContent:'flex-end'
	},
	arrowIconStyle:{
		width:25, 
		height:25,
		resizeMode:'contain'
	},
	deliveryJarDetailViewStyle:{
		alignItems:'center'
	},
	deliveryChangesBoxViewStyle:{
		flexDirection:'column',
		justifyContent:'center',
		alignItems:'center',
		backgroundColor: '#E4F9F8',
		borderColor: 'transparent',
		width:wp('90%'),
		borderWidth: 1,
		borderRadius: 5,
		margin:wp('2.5%'),
		position: "relative",
		elevation:5,
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10
	},
	triangle: {
		width:10,
		height:10,
		position:"absolute",
		top:-10,
		right:35,
		borderLeftWidth:10,
		borderLeftColor:"transparent",
		borderRightWidth:10,
		borderRightColor:"transparent",
		borderBottomWidth:10,
		borderBottomColor:"#E4F9F8"
	},
	deliveryBoxViewStyle:{
		margin:10,
		padding:10,
		borderWidth:1,
		borderRadius:5,
		borderColor:'#38c6f4',
		width:wp('87%'),
		maxHeight:320,
	},
	deliveryItemScrollView:{
		marginBottom:85,
		borderBottomColor:'#38C6F4',
		borderBottomWidth:0.5,
		paddingBottom:15,
	},
	deliveryItemChangeRequestScrollView:{
		borderBottomColor:'#38C6F4',
		borderBottomWidth:0.5,
	},
	headingDeliveryChangesStyle:{
		color:'#175491',
		fontWeight:'bold',
		fontSize:16,
		lineHeight:20,
	},
	alreadySetOffDayStyle:{
		paddingTop:5,
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between'
	},
	setOffDayValueStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#808080',
		marginLeft:10
	},
	deliveryPerDayViewStyle:{
		marginTop:20,
		marginBottom:10,
		flexDirection:'row',
		justifyContent:'space-between',
		alignItems:'center',
		borderBottomWidth:0.5,
		borderBottomColor:'#38C6F4',
		paddingBottom:15
	},
	perDayDeliveryCheckboxContainerStyle:{
		borderWidth:0,
		backgroundColor:'transparent',
		padding:0,
		margin:0,
		marginLeft:0,
		marginRight:0
	},
	perDayDeliverycheckBoxTextStyle:{
		fontSize:11,
		fontWeight:'bold',
		color:'#b3b3b3',
		marginLeft:5,
		marginRight:5
	},
	submitButtonContainer:{
		position:'absolute',
		left:0,
		right:0,
		bottom:0,
		alignItems:'center',
	},
	perDeliverySubmitButtonStyle:{
		backgroundColor:'#175491',
		width:150,
		paddingTop:5,
		paddingBottom:5,
		justifyContent:'center',
		alignItems:'center',
		borderRadius:2
	},
	perDeliverySubmitContainerStyle:{
		alignItems:'center',
		justifyContent:'center',
		paddingTop:20,
		marginVertical:10,
	},
	perDeliverySubmitTitleStyle:{
		fontSize:14,
		color:'#ffffff',
		fontWeight:'bold'
	},
	perDeliveryJarIconStyle:{
		width:30,
		height:30,
		resizeMode:'contain'
	},
	perDeliveryJarViewStyle:{
		paddingTop:15,
		paddingBottom:15,
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
	},
	perDeliveryIconTextViewStyle:{
		flexDirection:'row',
		alignItems:'center'
	},
	perDeliveryTextStyle:{
		fontSize:12,
		color:'#b3b3b3',
		width:wp('40%'),
	},
	numericIconStyle:{
		color: 'white',
		fontWeight:'bold'
	},
	numericInputStyle:{
		backgroundColor:'#ffffff'
	},
	ratingStartIconStyle:{
		marginLeft:5,
		marginRight:5,
	},
	buttonBarIconStyle:{
		height: 45,
		width: 45,
		resizeMode : 'contain',
	},
	arrowRightCornerStyle:{
		justifyContent:'flex-end',
		marginRight:-13,
		transform: [{ rotate: "-45deg" }],
	},
	deliveryChangesInnerViewStyle:{
		alignItems:'center',
		justifyContent:'center',
		height:170,
		paddingTop:10
	},
	arrorwRightCornerViewStyle:{
		alignItems:'flex-end',
		justifyContent:'flex-end'
	},
	mainProductViewStyle:{
		marginTop:10,
		flexDirection: 'row',
		flexWrap: 'wrap',
		width:wp('100%'),
		justifyContent:'center',
		alignItems:'center'
	},
	loadingViewStyle:{
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	alreadyRequestedMessageTextStyle:{
		fontWeight:'bold',
		color:'#175491',
		fontSize:16,
		paddingTop:15,
		paddingBottom:15,
		paddingLeft:5,
		paddingRight:5,
		lineHeight:30
	},
	bottomJarDeliveryChangesTextStyle:{
		fontWeight:'bold',
		color:'#808080',
		fontSize:14,
	},
	noDeliveryToolTipTextStyle:{
		color:'#808080',
		fontSize:16,
		fontWeight:'bold'
	},
	noDeliveryTooltipContainerStyle:{
		elevation:5,
		backgroundColor:'#f2f5f7',
		padding:10,
	},
	headingReviewPaymentTextStyle:{
		color:'#175491',
		fontSize:16,
		fontWeight:'bold',
		textAlign:'center'
	},
	headingReviewPaymentViewStyle:{
		alignItems:'center',
		paddingBottom:15,
		borderBottomColor:'#175491',
		borderBottomWidth:2
	},
	detailPaymentViewStyle:{
		justifyContent:'space-between',
		flexDirection:'row',
		alignItems:'flex-start',
		paddingTop:5,
		paddingBottom:5
	},
	detailTextStyle:{
		color:'#808080',
		fontSize:14,
		width:wp('60%')
	},
	refundableTextStyle:{
		color:'#808080',
		fontSize:12,
		width:wp('60%')
	},
	detailAmountViewStyle:{
		justifyContent:'space-evenly',
		flexDirection:'row',
		alignItems:'center',
		width:wp('20%')
	},
	detailAmountTextStyle:{
		color:'#808080',
		fontSize:14,
		textAlign:'right',
		fontWeight:'bold',
	},
	totalPaymentPayableViewStyle:{
		justifyContent:'space-between',
		flexDirection:'row',
		alignItems:'center',
		borderBottomWidth:2,
		borderBottomColor:'#175491',
		borderTopColor:'#175491',
		borderTopWidth:2,
		marginTop:5,
		marginBottom:25,
		paddingTop:10,
		paddingBottom:10
	},
	payableAmountTextStyle:{
		color:'#175491',
		fontSize:14,
		width:wp('60%')
	},
	payableAMountValueTextStyle:{
		color:'#175491',
		fontSize:14,
		textAlign:'right',
		fontWeight:'bold',
	},
	submitButtonContainerStyle:{
		alignItems:'center',
		justifyContent:'center'
	},
	submitButtonStyle:{
		width:wp('80%'),
		paddingTop:15,
		paddingBottom:5,
		marginBottom:20,
	},
	noteHeadingTextStyle:{
		color:'#175491',
		fontSize:14,
		fontWeight:'bold',
	},
	noteDetailViewStyle:{
		alignItems:'flex-start',
		flexDirection:'row',
		paddingTop:5,
		paddingBottom:5,
		paddingRight:5,
	},
	headingNoteDetailTextStyle:{
		color:'#808080',
		fontSize:14,
		fontWeight:'bold',
		lineHeight:20
	},
	contentNoteDetailTextStyle:{
		color:'#808080',
		fontSize:14,
		lineHeight:20
	},
	reviewPaymentOverlayViewStyle:{
		paddingLeft:10,
		paddingRight:10
	},
	bulletPointViewStyle:{
		width:5,
		height:5,
		backgroundColor:'#175491',
		borderRadius:5/2,
		marginRight:7,
		marginTop:7
	},
	todaysDeliveryDetailStyle:{
		flexDirection:'row',
		alignItems:'center',
		paddingVertical:10
	},
	todayDeliveryImageIconStyle:{
		height: 65,
		width: 65,
		resizeMode : 'contain',
	},
	moreBusinessDeliveryJarViewStyle:{
		alignItems:'center',
		justifyContent:'center',
		backgroundColor: '#fff',
		borderColor: '#fff',
		width:wp('93%'),
		borderWidth: 1,
		borderRadius: 5,
		elevation: 5,//android
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10
	},
	moreBusinessCustomerBillButtonStyle: {
		flexDirection: 'row',
		justifyContent:'center',
		alignItems:'center',
		backgroundColor: '#fff',
		borderColor: '#fff',
		width:wp('42%'),
		height:196,
		borderWidth: 1,
		borderRadius: 11,
		elevation: 5,//android
		margin:wp('2.2%'),
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10
	},
	balanceValueTextStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#808080',
		marginRight:10
	},
	balanceViewStyle:{
		marginTop:10,
		padding:10,
		width:wp('88%'),
		borderRadius:5,
		borderWidth:1,
		borderColor:'#38c6f4'
	},
	eachItemBalanceViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		paddingTop:2,
		paddingBottom:2,
		justifyContent:'space-between',
		marginVertical: 5
	},
	balanceViewContainer:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
	},
	selectedBalanceContainer:{
		width:wp('88%'),
		borderBottomWidth: 1,
		borderBottomColor:'#38C6F4', 
		alignSelf:'center',
		paddingVertical: 5
	},
	itemIconStyle:{
		height: 15,
		width: 15,
		resizeMode:'contain',
		marginRight:5
	},
	noBalanceItem:{
		textAlign:'center',
		fontWeight:'bold',
		fontSize:14,
		color:'#808080',
		padding:5
	},
	nextButtonTouchableStyle:{
		alignItems:"center",
		marginTop:10,
		backgroundColor:"#175491",
		width:wp('50%'),
		padding:10,
		borderRadius:5
	},
	crossIconStyle:{
		backgroundColor:'#ffffff',
		elevation:5,
		alignItems:'center',
		width:28,
		height:28,
		borderRadius:28/2,
		justifyContent:'center',
		textAlign:'center',
		shadowOffset : {width : 2, height: 2},
		shadowColor : '#808080',
		shadowOpacity : 0.5,
		shadowRadius : 10
	},

	crossButtonViewStyle:{
		justifyContent:'flex-end',
		alignItems:'flex-end',
		marginRight:-15,
		marginTop:-20
	},

	overlayInsideViewStyle:{
		justifyContent:'center',
		alignItems:'center'
	},

	customerSupportIconStyle:{
		width:45,
		height:45,
		resizeMode:'contain'
	},

	supportAddContactTextStyle:{
		textAlign:'center',
		fontSize:14,
		fontWeight:'bold',
		color:'#175491',
		padding:20,
		paddingTop:10
	},

	nextButtonTextStyle:{
		fontWeight:'bold',
		color:'#ffffff',
		fontSize:16,
		textAlign:'center'
	},

});