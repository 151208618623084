import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'

const logout = async (navigation) => {
	await AsyncStorage.removeItem('storeOpenAppByCustomer');
	await AsyncStorage.removeItem('loginCustomerData');
	await AsyncStorage.removeItem('userFacingDataOfCool');
	await AsyncStorage.removeItem('userFacingDataOfPet');
	global.userTokenData = null
	navigation.replace('LoginStackScreen',{
		screen:'CustomerLoginScreen',
		params:{},
	})
	if(Platform.OS==='web') window.location.href = window.location.origin
};

export default logout;