import { View, StyleSheet, TouchableOpacity, Text, FlatList, TouchableHighlight, Dimensions, Modal, Platform } from 'react-native'
import { Ionicons } from '@expo/vector-icons'; 
import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const phoneHeight = Dimensions.get('window').height
const phoneWidth = Dimensions.get('window').width

const Item = ({onPress, item, highlightColor, selected, themeColor, index}) => {
	const {label, value, itemProps, itemStyle, itemTextStyle , unPressable} = item
	const extraProps = itemProps ? itemProps : {}
	return (
		<TouchableHighlight underlayColor={unPressable ? itemStyle ? itemStyle.backgroundColor : null : highlightColor} style={[styles.pickerItem, selected===value || selected===label ? {backgroundColor:highlightColor} : {} , itemStyle ? itemStyle : {}]} onPress={()=> unPressable ? null : onPress(value,index)} {...extraProps} >
			<Text style={[styles.pickerItemText, selected===value || selected===label ? {color:themeColor,fontWeight:'bold'} : null , itemTextStyle ? itemTextStyle : {}]} numberOfLines={1}>
				{label}
			</Text>
		</TouchableHighlight>
	)
}

export default function CustomPicker(props){
	//Destructuring Props
	const { value, onChangeValue, items, width, numberOfOptions, themeColor, highlightColor, rightIcon, leftIcon, borderColor, containerStyle, disabled, onOpen, onClose, placeholder } = props
	const buttonRef = useRef()

	const [show,setShow] = useState(false) //For Dropdown
	const [scrollerPosition,setScrollerPosition] = useState({
		top:0,
		left:0
	})

	//To Close Picker on Scrolling Screen
	const addScrollListener = () => { window.onscroll = e => { setShow(false) } }

	//To Stop Picker-Close on Scrolling after Navigation to another Screen
	const removeScrollListener = () => { window.onscroll = null }

	useEffect(()=>{
		if(Platform.OS==='web') addScrollListener() //ComponentDidMount
		return () => { if(Platform.OS==='web') removeScrollListener() } //ComponentWillUnmount
	},[])

	const maxListHeight = (numberOfOptions*40)+2

	const handleListLayout = e => {
		for(let option=0;option<items.length;option++){
			const { label, value : optionValue } = items[option]
			if(value===optionValue){
				e.target?.scrollTo({x:0,y:40*option,animated:true})
				break
			}
		}
	}

	const handlePress = () => {
		if(buttonRef.current){
			buttonRef.current.measure((...data)=>{
				setScrollerPosition({
					top : phoneHeight-data[5] > maxListHeight ? data[5] : phoneHeight-maxListHeight-20, //Margin of 20 from bottom
					left : data[4]
				})
				setShow(true) //Show the List
				onOpen()
			})
		}
	}

	const handleChange = (newValue,index) => {
		onChangeValue(newValue,index,items)
		setShow(false) //Hide the Dropdown
		onClose()
	}

	//Extracting the Label to show on the Dropdown Button
	let extractedLabel = null
	extractedLabel = items.find(item=>item.value===value)
	if(!extractedLabel) extractedLabel = items.find(item=>item.label===value)
	if(!extractedLabel && value) extractedLabel={label:value}
	const selected = extractedLabel ? extractedLabel.label : placeholder		

	return (
		<View style={{width}}>
			<TouchableOpacity ref={buttonRef} activeOpacity={0.3} disabled={!items.length || disabled===true}
				onPress={handlePress} style={[styles.button,{borderColor:borderColor || themeColor},containerStyle]}>
				<View style={styles.leftGroupContainer}>
					{leftIcon}
					<Text numberOfLines={1} style={[styles.buttonText,{marginLeft : leftIcon ? 6 : 0, color:themeColor, width : leftIcon ? '80%' : '100%'}]}>{selected}</Text>
				</View>
				{rightIcon ?
					<View style={styles.rightIconContainer}>
						{rightIcon}
					</View>
					:null
				}
			</TouchableOpacity>
			<Modal onRequestClose={()=>{
				setShow(false)
				onClose()}} visible={show} transparent={true}>
				<View onStartShouldSetResponder={()=>true} onResponderRelease={()=>{
					setShow(false)
					onClose()}} style={{width:phoneWidth,height:phoneHeight,backgroundColor:null}}>
					<FlatList keyExtractor={(_,index)=>index} initialNumToRender={items.length} onLayout={handleListLayout} nestedScrollEnabled={true} style={[
						styles.list,{width,maxHeight:maxListHeight,marginLeft:scrollerPosition.left,marginTop:scrollerPosition.top}]} 
						data={items}
						renderItem={ ({item,index}) => <Item themeColor={themeColor} index={index} selected={value} highlightColor={highlightColor} item={item} onPress={handleChange}/> }
					/>
				</View>
			</Modal>
		</View>
		)
}

const styles = StyleSheet.create({
	button : {
		padding:10,
		borderRadius:3,
		borderWidth:1,
		flexDirection:"row",
		justifyContent:"space-between",
		alignItems:"center",
	},
	buttonText : {
		fontWeight : "600",
		fontSize:14,
		alignItems:'center',
		width : '80%'
	},
	pickerItem : {
		padding:10,
		borderWidth:1,
		borderColor:"#f3f3f3",
		backgroundColor:'#fff',
		borderRadius:3
	},
	pickerItemText : {
		fontSize:15
	},
	list : {
		position:'absolute',
		backgroundColor:'white',
		borderColor:'#175491',
		borderWidth:1,
		elevation:3,
		borderRadius:3,
	},
	rightIconContainer : {
		flex:1,
		alignItems : 'flex-end',
		justifyContent : 'center'
	},
	leftGroupContainer : {
		flex:5,
		flexDirection : 'row',
		overflow : 'hidden',
		alignItems : 'center'
	}
})

//Default Props
//Default Props are used in case the Props are not provided by the Parent
CustomPicker.defaultProps = {
	themeColor : "#175491",
	width : 200,
	highlightColor : "#f3f3f3",
	rightIcon : <Ionicons name="caret-down-sharp" size={16} color="#175491"/>,
	items : [], //Initializing with blank array to avoid errors like undefined is not an object evalutating items.length etc..,
	onChangeValue : () => {},
	containerStyle : {},
	numberOfOptions : 5,
	disabled : false,
	onOpen : ()=>{},
	onClose : ()=>{},
	placeholder : null
}

//PropTypes are used to define the Prop's Data Types
//These are not required but Recommended
CustomPicker.propTypes = {
	themeColor : PropTypes.string,
	//Width and Height can either be Number or String
	width : PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
	items : PropTypes.arrayOf(PropTypes.object),
	value : PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
	onChangeValue : PropTypes.func,
	highlightColor : PropTypes.string,
	containerStyle : PropTypes.object,
	numberOfOptions : PropTypes.number,
	disabled : PropTypes.bool,
	onOpen : PropTypes.func,
	onClose : PropTypes.func,
	placeholder : PropTypes.string
}