import i18n from 'i18n-js';
/*
	This file is used to declare common functions
*/
export const isValidPhoneNumber= (val)=>{
	/*
		this function will return true to only digits
	*/
	const onlyDegitRegex = /^[0-9]*$/	//Regular expression
	if( onlyDegitRegex.test(val) ){
		return  true
	}
	else {
		return false
	}
}