import * as MediaLibrary from 'expo-media-library';
import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import * as WebBrowser from 'expo-web-browser';
import {format} from 'date-fns';
/* created the function to share the pdf over different option like print, share and download */
export const BillPdfGenerateWithOption = async (userToken, generateUrl, shareOption) => {
	if(shareOption === 'download'){
		//we are opening the web browser to download the pdf that user requested from the app
		WebBrowser.openBrowserAsync(generateUrl);
	}else if(shareOption === 'print'){
		Print.printAsync({
			uri:generateUrl,
		}
		).then((filePath) => {
		})
		.catch(err => {
		});
	}else{
		let creationDate = format(new Date(), 'DDMMYYYYHHmmssSSS')
		FileSystem.downloadAsync(
			generateUrl,
			FileSystem.documentDirectory +creationDate+'.pdf',
			{headers:{Authorization:'Token ' +userToken}},
		)
		.then( async ({ uri }) => {
			Sharing.shareAsync(uri)
		}).catch(error => {
		});
	}
}