import axios from 'axios';
import Constants from 'expo-constants';
import getEnvVars from './environment';
const { apiUrl, businessApiUrl } = getEnvVars();
import { Platform } from 'react-native'

const webHeaders = {
	'Accept' : '*/*'
}

const mobileHeaders = {
	'Access-Control-Allow-Origin': '*',
	'Accept': '*/*',
};

const v1 = axios.create({
	baseURL: apiUrl,
	headers : Platform.OS==='web' ? webHeaders : mobileHeaders
});

v1.interceptors.request.use((req) => {
	const authToken = global.userTokenData.token;
	if (authToken) {
		req.headers['Authorization'] = 'Token ' +authToken;
	}
	// Important: request interceptors **must** return the request.
	return req;
	},
	(error) => {
		return Promise.reject(error);
	}
);

v1.interceptors.response.use(res => {
	// Important: response interceptors **must** return the response.
	return res;
	},
	(error) => {
		if (error.response && (error.response.status === 401 || error.response.status === 403)) {
			return error.response;//if error.response exist
		}
		else {
			return {
				data:{
					statusCode:400,
					responseDetail:'badRequest'
				}
			}//To create error data
		}
	}
);

const client = {
	v1,
	fetchCustomerDetail: (businessId, customerBusinessId, params) => {
		return v1.get(`/business/${businessId}/customer/${customerBusinessId}/`,{params:params}).then((res) => {
			return res.data
		});
	},
	createCustomerDetail: (businessId, customerBusinessId, requestBody) => {
		return v1.post(`/business/${businessId}/customer/${customerBusinessId}/`,requestBody).then((res) => {
			return res.data
		});
	},
	resetCustomerPhoneNumber:(customerOtpVerifyId, requestBody) => {
		return v1.post(`/reset-customer-phone-number/${customerOtpVerifyId}/`,requestBody).then((res) => {
			return res.data
		});
	},
	verifyOtpToresetCustomerPhoneNumber:(customerOtpVerifyId, requestBody) => {
		return v1.put(`/reset-customer-phone-number/${customerOtpVerifyId}/`,requestBody).then((res) => {
			return res.data
		});
	},
	customerAppMandatoryUpdate: (params) => {
		return v1.get(`api/v1/mandatory-app-update-version/${Constants.expoConfig.version}/`,{params:params}).then((res) => {
			return res.data
		});
	},
	getCustomerAppLanguage:(userId) => {
		return v1.get(`customer-app-language/${userId}/`).then((res) => {
			return res.data
		});
	},
	storeCustomerAppLanguage:(userId, requestBody) => {
		return v1.post(`customer-app-language/${userId}/`, requestBody).then((res) => {
			return res.data
		});
	},
	getPhoneContactAdded:(customerOtpVerifyId, params) => {
		return v1.get(`customer-contacts/${customerOtpVerifyId}/`, {params:params}).then((res) => {
			return res.data
		});
	},
	storePhoneContactAPI:(customerOtpVerifyId, requestBody) => {
		return v1.post(`/customer-contacts/${customerOtpVerifyId}/`,requestBody).then((res) => {
			return res.data
		});
	},
	fetchCustomerJarEntry:(businessId, customerBusinessId, params) => {
		return v1.get(`show-customer-jar-entry/${businessId}/${customerBusinessId}/`, {params:params}).then((res) => {
			return res;
		});
	},
};

export default client;