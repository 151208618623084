import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import React, { Component } from 'react';
import getEnvVars from '../environment';
const { googleApiKey } = getEnvVars();
import {View, StyleSheet} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import i18n from 'i18n-js';
export default class AutoCompleteAddress extends Component{
	constructor(props){
		super(props)
		
	}
	render(){
		const {inputRef} = this.props;
		return(

			<GooglePlacesAutocomplete
				placeholder={this.props.shoPlaceHolderText === false ?null :i18n.t('enterLocation')}
				minLength={2} 
				value={this.props.location}
				autoFocus={false}
				returnKeyType={'default'}
				//this property is used to display the suggestion list type:boolean
				placeholderTextColor='#808080'
				listViewDisplayed={this.props.showPlaceList}
				fetchDetails={true}
				enablePoweredByContainer={false}
				onSubmitEditing={() =>this.props.handleAddressSubmitEdit}
				onPress={(data, details = null)=>{this.props.handleAddressOnPress(details)}}
				getDefaultValue={() => {return this.props.location;}}
				textInputProps={{
					onChangeText:value => this.props.handleAddressChangeText(value,true),
					value:this.props.location,
					ref:(r) => { inputRef && inputRef(r) },
				}}
				query={{
					key: googleApiKey,
					language: 'en', 
					components: 'country:in',
					types:['address'],
				}}
				styles={{
					textInput:{
						fontSize: 20,
						borderWidth:0,
						paddingLeft:0,
						paddingTop:0,
						paddingBottom:0,
						padding:0,
						color:'#808080',
					},
					textInputContainer:{
						borderTopWidth: 0,
						borderBottomWidth:0,
						width:wp('75%'),
						backgroundColor:'none',
						paddingBottom:0,
						paddingLeft:0,
						borderLeft:'none',
					},
					container:{
						paddingLeft:0,
						paddingBottom:0,
						width: wp('90%'),
						paddingRight:0,
					},
					listView:this.props.addressListStyle,
					description: this.props.listItemStyle,
				}}
				//this property is used to choose which api is used to search
				nearbyPlacesAPI="GooglePlacesSearch" 
				currentLocation={this.props.getCurrentLocation == false ? this.props.getCurrentLocation :true}
				currentLocationLabel={this.props.getCurrentLocation == false ?null :i18n.t('getCurrentLocation')}
				GooglePlacesSearchQuery={{
					rankby: 'distance',
					type: 'address',
					fields: 'formatted_address',
				}}
			/> 
		)
	}
}
const styles = StyleSheet.create({
	listView:{ 
		// to show list 
		marginLeft:-40,
		width: wp('80%'),
		backgroundColor:'white',
	},
	listAddressStyle: {	
		height: wp('20%'),
		fontSize:20,
		fontWeight: 'bold',
		backgroundColor:'white',		
	}
});

AutoCompleteAddress.defaultProps = {
	addressListStyle:styles.listView,
	listItemStyle:styles.listAddressStyle,
}