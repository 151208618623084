import { View, StyleSheet, Text, TouchableHighlight, ScrollView, Platform, TextInput, ActivityIndicator } from 'react-native'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import getEnvVars from '../environment'
import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'
import alert from '../utils/commonAlert'

const { googleApiKey, apiUrl } = getEnvVars()

export default function GooglePlacesAutocomplete(props){

	const { value, onSelect, containerStyle, listStyle, minimumCharacters, itemStyle, onChangeText, inputStyle, editable, textInputProps } = props
	const [results, setResults] = useState([])
	const [loading, setLoading] = useState(false)
	const [inputHeight,setInputHeight] = useState(0)

	const getDetails = async address => {
		const placeId = address.place_id
		const customerData = JSON.parse(await AsyncStorage.getItem('loginCustomerData'))
		try{
			const response = await axios.get(`${apiUrl}google-places-details/${placeId}`,{
				headers : {
					Authorization:'Token '+customerData.token
				}
			})
			onSelect({...response.data.result,title:address.description})
			setLoading(false)
		}
		catch(e){
			alert(e.response?.data?.message)
			setLoading(false)
		}
	}

	const fetchResults = async() => {
		const customerData = JSON.parse(await AsyncStorage.getItem('loginCustomerData'))
		try{
			const response = await axios.get(`${apiUrl}google-places-suggestions/${value}`,{
				headers : {
					Authorization:'Token '+customerData.token
				}
			})
			setResults(response.data?.predictions)
			setLoading(false)
		}
		catch(e){
			alert(e.response?.data?.message)
			onSelect("")
			setLoading(false)
		}
	}

	useEffect(()=>{
		value.length>=minimumCharacters && !results.find(item=>value===item.description) && setLoading(true)
		let wait = setTimeout(()=> value.length>=minimumCharacters && !results.find(item=>value===item.description) && fetchResults(),1000)
		return ()=>{
			clearTimeout(wait)
		}
	},[value])

	const listItem = (item,index) => <TouchableHighlight key={index} onPress={()=>getDetails(item)} underlayColor='#B3B3B3' style={[styles.itemStyle,itemStyle,{ borderBottomWidth : index===results.length-1 ? 0 : 0.5 }]} ><Text>{item.description}</Text></TouchableHighlight>	

	return (
		<View style={[styles.containerStyle,containerStyle]} >
			<TextInput onContentSizeChange={e=>{setInputHeight(e.nativeEvent.contentSize.height)}} editable={editable} multiline={true} value={value} style={[styles.inputStyle,{height:inputHeight},inputStyle]} onChangeText={onChangeText} {...textInputProps} />
			{
				(!value || !editable || value.length<minimumCharacters || results.find(item=>value===item.description) || (!results.length && !loading))
				? <View/>
				: <ScrollView contentContainerStyle={{justifyContent:'center',flex:1}} nestedScrollEnabled={true} style={[styles.listStyle,listStyle]}>
					{
						loading ? <ActivityIndicator color='skyblue'/>
						: results.map(listItem)
					}
				  </ScrollView>
			}
		</View>
	)
}

const styles = StyleSheet.create({
	container : {
		width : '100%',
		padding : 5
	},
	itemStyle : {
		padding:5,
		borderBottomColor : '#808080',
	},
	listStyle : {
		borderBottomRightRadius:5,
		borderBottomLeftRadius:5,
		minHeight : 80,
	},
	inputStyle : {
		padding:6,
	}
})

GooglePlacesAutocomplete.propTypes = {
	value : PropTypes.string,
	onSelect : PropTypes.func,
	containerStyle : PropTypes.oneOfType([PropTypes.object,PropTypes.array]),
	listStyle : PropTypes.oneOfType([PropTypes.object,PropTypes.array]),
	minimumCharacters : PropTypes.number,
	itemStyle : PropTypes.oneOfType([PropTypes.object,PropTypes.array]),
	onChangeText : PropTypes.func,
	inputStyle : PropTypes.oneOfType([PropTypes.object,PropTypes.array]),
	editable : PropTypes.bool,
	textInputProps : PropTypes.object
}

GooglePlacesAutocomplete.defaultProps = {
	value : "",
	onSelect : ()=>{},
	containerStyle : {},
	listStyle : {},
	minimumCharacters : 2,
	itemStyle : {},
	onChangeText : ()=>{},
	inputStyle : {},
	editable : true,
	textInputProps : {}
}