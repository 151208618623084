import React, { Component } from 'react';
import { Text, View, StyleSheet,ActivityIndicator, TouchableOpacity,Platform,BackHandler,Image,} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { CheckBox,Button, Header} from "react-native-elements";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { Ionicons } from '@expo/vector-icons';
import i18n from 'i18n-js';
import translationValues from '../components/translation';
import client from '../client';
import AsyncStorage from '@react-native-async-storage/async-storage'
import alert from '../utils/commonAlert'
/*
	usedFor:-this screen is show when user press on choose language  from drawer menu it is used to change user  language 
*/
export default class ChooseLanguage extends Component{
	constructor(props){
		super(props)
		this.state={
			isLanguage:global.isLanguage,
			isLoading:true,
		}
		this.willFocusSubscription = ''
	}
	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			Platform.OS==='web' && window.history.replaceState(null,null,"/ChooseLanguage")
			this.reloadTranslation()
			this.getAppLanguage();
		})
	}
	componentWillUnmount(){
		this.willFocusSubscription()
	}

	getAppLanguage = async () => {
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		if(loginCustomerData){
			const loginCustomerDataJson = JSON.parse(loginCustomerData)
			const responseJsonData = await client.getCustomerAppLanguage(loginCustomerDataJson.user_id)
			if(responseJsonData.success){
				this.setState({isLanguage:responseJsonData.app_language, isLoading:false})
				//  here we set user language globally
				global.isLanguage = responseJsonData.app_language
				await AsyncStorage.setItem('isLanguage',responseJsonData.app_language);
			}
		}
	}

	/* componentDidUpdate is invoked immediately after updating occurs so here we call reloadtranslation function, which is used to reload translation
	*/
	componentDidUpdate(){
		this.reloadTranslation()
	}
	/* this function is used to change user language it
	return respose app_language which is users selected language 
	*/
	 changeLanguage = async () => {
	 	const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
	 	if(loginCustomerData){
	 		const loginCustomerDataJson = JSON.parse(loginCustomerData)
	 		this.setState({isLoading:true})
	 		const {isLanguage} = this.state;
	 		let requestBody = {app_language:isLanguage}
		 	await AsyncStorage.setItem('isLanguage',isLanguage);
			//  here we set user language globally
			global.isLanguage = isLanguage
		 	const responseJsonData = await client.storeCustomerAppLanguage(loginCustomerDataJson.user_id, requestBody)
		 	if(responseJsonData.success){
		 		this.setState({isLoading:false})
				if(Platform.OS==='android'){
				}
				else{
					alert(
						i18n.t('choose_language'),
						i18n.t('language_changed_succesfully'),
						[
							{text: i18n.t('ok')},
						],
						{ cancelable: false }
					)
				}
			}
		}
		this.props.navigation.navigate('Home')
	}

	reloadTranslation=() =>{
		i18n.fallbacks = true;
		i18n.translations = translationValues;
		i18n.locale = global.isLanguage;
	}

	render() {
		if(this.state.isLoading){
			return(
				<View style={styles.loading}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<View style={{flex:1, backgroundColor:'#ffffff'}}>	 
					<Header
			 			placement="left"
			 			statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						leftContainerStyle={{justifyContent:'center'}}
						leftComponent={<TouchableOpacity
								style={styles.header}
								activeOpacity={0.5}
								onPress={()=>this.props.navigation.openDrawer()}
							>
								<Ionicons name='md-menu' color={'#38c6f4'} size={34} />
								<Text style={styles.headerTitleTextStyle}>{i18n.t('choose_language')}</Text>
							</TouchableOpacity>
						}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
						containerStyle={{borderBottomWidth:null,backgroundColor:null, alignItems:'center'}}
					/>

					
						{/* this is english language container*/}
						<TouchableOpacity 
							style={styles.englishContainer}
							onPress={() => this.setState({isLanguage:'en'})}
						>
							<View style={styles.textView}>
							<Text style={[styles.textStyle,{paddingTop:5}]}>English</Text>
							</View>
							<CheckBox
								checked={this.state.isLanguage==='en'?true:false}
								iconRight={true}
								containerStyle={styles.CheckBoxContainer}
								checkedIcon='check-circle'
		  						uncheckedIcon='circle-o'
		  						disabled={true}
							/>
						</TouchableOpacity>
						{/* this is Hindi  language container*/}
						<TouchableOpacity 
							style={styles.hindiContainer}
							onPress={() => this.setState({isLanguage:'hi'})}
						>
							<View style={styles.textView}>
								<Text style={styles.textStyle}>हिंदी</Text>
								<Text style={styles.languageText}>Hindi</Text>
							</View>
							<CheckBox
								checked={this.state.isLanguage==='hi'?true:false}
								iconRight={true}
								containerStyle={styles.CheckBoxContainer}
								checkedIcon='check-circle'
		  						uncheckedIcon='circle-o'
		  						disabled={true}
							/>		
						</TouchableOpacity>
						<TouchableOpacity 
							style={styles.hindiContainer}
							onPress={() => this.setState({isLanguage:'mr'})}
						>
							<View style={styles.textView}>
								<Text style={styles.textStyle}>मराठी</Text>
								<Text style={styles.languageText}>(Marathi)</Text>
							</View>
							<CheckBox
								checked={this.state.isLanguage==='mr'?true:false}
								iconRight={true}
								containerStyle={styles.CheckBoxContainer}
								checkedIcon='check-circle'
		  						uncheckedIcon='circle-o'
		  						disabled={true}
							/>		
						</TouchableOpacity>
						<TouchableOpacity 
							style={styles.hindiContainer}
							onPress={() => this.setState({isLanguage:'gu'})}
						>	
							<View style={styles.textView}>
								<Text style={styles.textStyle}>ગુજરાતી</Text>
								<Text style={styles.languageText}>(Gujarati)</Text>
							</View>
							<CheckBox
								checked={this.state.isLanguage==='gu'?true:false}
								iconRight={true}
								containerStyle={styles.CheckBoxContainer}
								checkedIcon='check-circle'
		  						uncheckedIcon='circle-o'
		  						disabled={true}
							/>		
						</TouchableOpacity>
				
					<Button 
						disabled={this.state.isLanguage?false:true }
						disabledTitleStyle={{color:'hsl(208, 8%, 63%)'}}
						title={i18n.t('submit')}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0},end:{x: 1, y: 0},}}
						buttonStyle={styles.buttonStyle}
						containerStyle={{alignItems:"center"}}
						titleStyle={styles.buttonTittle}
						onPress={this.changeLanguage}	
					 />
				</View>
			)
		}
	}

}
const styles = StyleSheet.create({
englishContainer:{
	flexDirection:'row',
	width:wp('100%'),
	paddingTop:5,
	height:hp('10%'),
	borderBottomWidth:1,
	borderBottomColor:'#F3F5F7',
	justifyContent:'space-between'
	},
	hindiContainer:{
	flexDirection:'row',
	width:wp('100%'),
	height:hp('10%'),
	borderBottomWidth:1,
	borderBottomColor:'#F3F5F7',
	paddingTop:5

	},
	textView:{
		width:wp('80%'),
		paddingTop:10,
		paddingLeft:16,
	},
	textStyle:{
		fontWeight:'bold',
		fontSize:15,
	},
	CheckBoxContainer:{
		backgroundColor:'white',
		borderWidth:0,
		width:wp('20'),
		alignItems:'flex-end',
		paddingRight : 30
	},
	buttonStyle:{
		width:wp('90%'),
		padding:10,
		borderRadius:5,
		height:hp('10%'),
		marginTop:10,
	},
	buttonTittle:{
		fontWeight:'bold',
		color:'#ffffff',
		fontSize:20,
		textAlign:'center',
	},
	loading:{
		backgroundColor:'#ffffff',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	header:{
		flexDirection:'row',
		alignItems:'center',
	},
	headerTitleTextStyle:{
		color: '#fff',
		fontSize:16,
		fontWeight:'bold',
		marginLeft:5
	},
	languageText:{
		color:'#B6B6B6',
		fontWeight:'bold',
	}

})