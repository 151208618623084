import React from "react";
import getEnvVars from '../environment';
const { businessApiUrl } = getEnvVars();
import { LinearGradient } from 'expo-linear-gradient';
import { View, Text, StyleSheet, TouchableOpacity,ScrollView,FlatList,Image, ActivityIndicator, Platform } from "react-native";
import {format} from 'date-fns';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import i18n from 'i18n-js';
import { Ionicons, FontAwesome, MaterialCommunityIcons, Feather } from '@expo/vector-icons';
import {Header, Overlay, CheckBox} from 'react-native-elements';
import FixedMonthlyBillingInfo from '../components/fixedMonthlyBillingInfo';
import RegularCustomerBillInfo from '../components/regularCustomerBillInfo';
import * as WebBrowser from 'expo-web-browser';
import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import translationValues from '../components/translation';
import {getJarTypeValue} from '../components/getJarTypeValue';
import CustomerBillInfo from '../components/customerBillInfo';
import ShareOptionComponent from '../components/shareOptionComponent';
import {BillPdfGenerateWithOption} from '../components/billPdfGenerateWithOption';
import logout from '../components/logout';
import BalanceAndDepositView from '../components/BalanceAndDepositView';
import AsyncStorage from '@react-native-async-storage/async-storage'
import alert from '../utils/commonAlert'

export default class CustomerBills extends React.PureComponent{
	constructor(props){
		super(props);
		this.state = {
			billingPeriod:'',
			isLoading:true,
			last_three_months_customer_bill_details:[],
			visible:false,
			a4Print:'a4',
			rollSize:'roll_size',
			bill4A4 :'a4_4_bill',
			a5Bill:'a5',
			languageCode:'en',
			showBillOption:false,
			isForSharing:false,
			isForDownload:false,
			isForPrint:false,
			isProductDelivered:false,
			isProductBillVisible:false,
			billingPeriod:'',
			forDownload:'download',
			forSharing:'share',
			forPrint:'print',
			fixedMonthlyCustomer:false,
			downloadOption:'bill',
			sizeOption:'a4',
			depositAmount:'',
			balanceAmount:'',
		}
		this.willFocusSubscription = ''
	}
	

	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			this.fetchPastMonthsBill();
		 	i18n.fallbacks = true;
			Platform.OS==='web' && window.history.replaceState(null,null,"/CustomerBills")
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
		});
		
		this.getUserFacingJarTypeValues();
	}

	componentWillUnmount() {
		this.willFocusSubscription();
	}

	getUserFacingJarTypeValues = async () => {
		const {userFacingNameCool, userFacingNamePet, productImageOfCool, productImageOfPet} = await getJarTypeValue();
		this.setState({userFacingNameCool:userFacingNameCool,userFacingNamePet:userFacingNamePet,productImageOfCool:productImageOfCool, productImageOfPet:productImageOfPet})
	}

	generateCustomerPaymentBills = async (sizeOption) => {
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		let loginCustomerDataJson = JSON.parse(loginCustomerData);
		const generatePdfUrl = businessApiUrl+'customer-month-bill-generate/'+loginCustomerDataJson.business_id+'/'+loginCustomerDataJson.customer_business_id+'/'+loginCustomerDataJson.token+'?date_string='+this.state.billingPeriod+'&format_type='+sizeOption+'&language_code='+this.state.languageCode


		let creationDate = format(new Date(), 'DDMMYYYYHHmmssSSS')
		if(this.state.isForSharing===true){
			FileSystem.downloadAsync(
			  generatePdfUrl,
			  FileSystem.documentDirectory +creationDate+'.pdf'
			)
			  .then(({ uri }) => {
			    Sharing.shareAsync(uri)
			  })
			  .catch(error => {
			    console.error(error);
			  })
		}
		else if (this.state.isForDownload===true){
			WebBrowser.openBrowserAsync(generatePdfUrl);
		}
		else{
			let filePath = await Print.printAsync({
		      uri:generatePdfUrl,
		      base64 : false
		    });
		}
		this.handleCancel();
	
	}

	fetchPastMonthsBill = async () => {
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		let loginCustomerDataJson = JSON.parse(loginCustomerData);
		const response = await fetch(
			businessApiUrl+'last-three-months-billing-details/'+loginCustomerDataJson.business_id+'/'+loginCustomerDataJson.customer_business_id+'/?new_generic_version_request_v2='+true,{
				headers:new Headers({
					Authorization:'Token '+loginCustomerDataJson.token 
				})
			}
		);
		const payment_data = await response.json();
		if (response.status === 401) {
			alert(
				i18n.t('customer'),
				payment_data.detail,
				[
					{text: i18n.t('ok'), onPress: () => logout(this.props.navigation)},
				],
				{ cancelable: false }
			)
		}
		else{
			if(payment_data.success){
				this.setState({ last_three_months_customer_bill_details: payment_data.last_three_months_customer_bill_details,isLoading:false,isProductDelivered:payment_data.is_product_delivered,fixedMonthlyCustomer:payment_data.is_fixed_monthly,depositAmount:payment_data.deposit_amount,balanceAmount:payment_data.current_payment_due});
			}
		}
	}

	renderHeader = () => {
		return(
			<View>
				<BalanceAndDepositView
					balanceAmount = {this.state.balanceAmount}
					depositAmount = {this.state.depositAmount}
					showReceivedAmount={false}
				/>
				<View style={styles.headerContainer}>
					<View style={styles.headerImagetextView}>
						<Image 
							source={require('../assets/images/bills.png')}
							style={styles.downloadImage}
						/>
						<Text style={styles.downloadbilltext}>{i18n.t('download_bills')}</Text>
					</View>
				</View>
			</View>
		)
	}


	renderItem = ({item}) => {
		return(
			<View style={{flex:1}}>
				<View style={{flexDirection:"column",marginTop:10,marginBottom:10}}>
					<View style={{width: wp('100%'), flexDirection:'row',justifyContent:'flex-end',backgroundColor:'#ffffff'}}>
						<View style={{width: wp('60%'), flexDirection:'row',justifyContent:'flex-end'}}>
							<Text style={{fontSize: 16,color:'#38c6f4',}}>{i18n.t(format(item.billing_period, 'MMMM'))}{' '}{format(item.billing_period, 'YYYY')}</Text>
						</View>
						<View style={{width: wp('35%'), flexDirection:'row',justifyContent:'center'}}>
							<TouchableOpacity
								onPress={()=>this.setState({billingPeriod:format(item.billing_period,'DD-MM-YYYY'), visible:true})}
								style={{marginLeft:20}}>
								<FontAwesome name='share-square' size={25} color='#175491' />
							</TouchableOpacity>
						</View>
					</View>
					<View style={styles.showForm}>
						<View style={{width:wp('100%'),flexDirection:'column', marginTop:10,marginBottom:10}}>
							<View style={{width:wp('90%'),flexDirection:'row', marginBottom:10}}>
								<View style={{width:wp('45%')}}>
									<Text style={{fontSize: 16,}}>{i18n.t('billing_period')}</Text>
								</View>
								<View style={{width:wp('45%')}}>
									<Text style={{fontSize: 16,}}>{i18n.t(format(item.billing_period, 'MMMM'))}{' '}{format(item.billing_period, 'YYYY')}</Text>
								</View>
							</View>
							<View style={{width:wp('90%'),flexDirection:'row'}}>
								<View style={{width:wp('45%')}}>
									<Text style={{fontSize: 16}}>{i18n.t('billing_date')}</Text>
								</View>
								<View style={{width:wp('45%')}}>
									<Text style={{fontSize: 16}}>{i18n.t(format(item.billing_date, 'MMMM'))}{' '}{format(item.billing_date, 'YYYY')}</Text>
								</View>
							</View>
						</View>
						{item.is_current_month && 
							<View style={{justifyContent:'center', alignItems:'center'}}>
								<View style={{flexDirection: 'column',justifyContent:'center',borderRadius:5,height:hp('15%'),	borderWidth: 1, marginBottom: 10, width:wp('90%'),backgroundColor:'#ffffff',elevation:5, borderColor:'#ffffff', }}>
									<View style={{width: wp('90%'),justifyContent:'center', alignItems:'center',flexDirection:'row'}}>
										<Image 
											source={require('../assets/jar_icon/balance_jar.png')} 
											style={styles.balanceJarsIconStyle}
										/>
										<Text style={{fontSize: 14,color: '#38c6f4',textAlign:'center',fontWeight:'bold',marginLeft:5}}>{i18n.t('balance_jar')}</Text>
									</View>
									<View style={{width: wp('90%'),justifyContent:'center', alignItems:'center', marginTop:10}}>
										<TouchableOpacity style={{flexDirection: 'row',marginBottom:10}} disabled={true}>
											<Text style={{textAlign:'left',fontSize:15, fontWeight:'bold',marginBottom:10, paddingLeft:10, paddingRight:10, marginTop:10}}>{item.cool_balance_jar}  {'X'} </Text>
											<Image 
												source={this.state.productImageOfCool ?{uri:this.state.productImageOfCool} :require('../assets/jar_icon/cool.png')} 
												style={styles.balanceJarIconStyle}
											/>
											<Text style={{textAlign:'left',fontSize:20, fontWeight:'bold',marginTop:5}}> + </Text>
											<Text style={{textAlign:'left',fontSize:15, fontWeight:'bold',paddingLeft:10, paddingRight:10, marginTop:10}}>{item.pet_balance_jar}  {'X'} </Text>
											<Image 
												source={this.state.productImageOfPet ?{uri:this.state.productImageOfPet} :require('../assets/jar_icon/pet.png')} 
												style={styles.balanceJarIconStyle}
											/>
											<Text style={{textAlign:'left',fontSize:20, fontWeight:'bold',marginTop:5}}> = </Text>
											<Text style={{textAlign:'left',fontSize:16, fontWeight:'bold',paddingLeft:10, paddingRight:10, marginTop:10}}>{item.balance_jar}</Text>
												
										</TouchableOpacity>
									</View>
								</View>
							</View>
						}
					</View>
				{/*render component for the type of customer if customer is fixed monthly or regular customer*/}
					{item.is_fixed_monthly_customer
						?  	<FixedMonthlyBillingInfo 
								item={item}
								productImageOfCool={this.state.productImageOfCool}
								productImageOfPet={this.state.productImageOfPet}
								userFacingNamePet={this.state.userFacingNamePet}
								userFacingNameCool={this.state.userFacingNameCool}
							/>
						: 	<RegularCustomerBillInfo 
								item={item}
								productImageOfCool={this.state.productImageOfCool}
								productImageOfPet={this.state.productImageOfPet}
								userFacingNamePet={this.state.userFacingNamePet}
								userFacingNameCool={this.state.userFacingNameCool}
							/>
					}
				</View>
			</View>
		)
	}
	handleCancel = () => {
		this.setState({ visible: false , billingPeriod:'',showBillOption:false,isForSharing:false, isForDownload:false, isForPrint:false });
	};

	OnBillOptionPress = (sizeOption) => {
		this.generateCustomerPaymentBills(sizeOption)
	}

	renderProductItem = ({item, index}) => {
		return(
			<View style={styles.currentPreviouseMonthlyBillStyle}>
				<View
					key={index}
					style={styles.currentMonthTouchableStyle}>
					<View style={styles.touchableDateArrowStyle}>
						<Text style={styles.dateStyle}>{i18n.t(format(item.billing_period, 'MMMM'))}{' '}{format(item.billing_period, 'YYYY')}
						</Text>
						<View style={styles.upAndDownArrowStyle}>
							<TouchableOpacity style={{marginRight:15}} onPress={()=>this.setState({isProductBillVisible:true, billingPeriod:format(item.billing_period,'DD-MM-YYYY')})}>
								<FontAwesome name='share-square' size={25} color='#ffffff' />
							</TouchableOpacity>
						</View>
					</View>
				</View>
				<View style={styles.billingInfoViewStyle}>
					<View style={styles.billingDateViewStyle}>
						<Text style={styles.billingDateValueTextStyle}>{i18n.t('billing_date')}</Text>
						<Text style={styles.billingDateValueTextStyle}>{':'}</Text>
						<Text style={styles.billingDateValueTextStyle}>{format(item.billing_date, 'DD ')}{i18n.t(format(item.billing_date, 'MMMM'))}{', '}{format(item.billing_date, 'YYYY ')}{'at '}{format(item.billing_date, 'hh:mm A')}</Text>
					</View>
				</View>
				{item.is_fixed_monthly_customer  && Object.keys(item.fixed_item_data).length !== 0 &&
					<View style={styles.mainView}>
						<View style={styles.fixedAmuntMainView}>
							<View style={styles.headingView}>
								<View style={{width:wp('50%'), flexDirection:'column',paddingBottom:10}}>
									<Text style={styles.headingText}>{i18n.t('fixed_amount')}</Text>
									<Text style={{fontSize:12}}>
										({i18n.t('fixedJarText',{jarType:item.fixed_item_data.item_user_facing_name ?item.fixed_item_data.item_user_facing_name :item.fixed_item_data.product_name})} = {item.fixed_item_data.fixed_product})</Text>
								</View>
								<View style={{width:wp('20%'),flexDirection:'row',marginLeft:5,alignItems:'center'}}>
									<FontAwesome name="rupee" size={16}  />
									<Text style={styles.valueText}>{item.fixed_item_data.fixed_amount}</Text>
								</View>
								
								<View style={[styles.valueTextView]}>
									<FontAwesome name="rupee" size={16}  />
									<Text style={styles.valueText}>{
										item.fixed_item_data.total_product_delivered === 0
										? '-'
										: item.fixed_item_data.total_product_delivered  <= item.fixed_item_data.fixed_product
										?item.fixed_item_data.fixed_amount 
										: '-' }
									</Text>
								</View>
							</View>
						</View>
						<View style={styles.fixedDeliveredView}>
							<View style={styles.headingView}>
								<View style={{width:wp('50%'), flexDirection:'column'}}>
									<Text style={styles.headingText}>{i18n.t('totalDeliveredJarText',{jarType:item.fixed_item_data.item_user_facing_name ?item.fixed_item_data.item_user_facing_name :item.fixed_item_data.product_name})}</Text>
								</View>
								<View style={styles.symbolView}>
									<Image 
										source={item.fixed_item_data.item_image ?{uri:item.fixed_item_data.item_image} :require('../assets/jar_icon/default-item-icon.png')} 
										style={{height:20,width:20,resizeMode:'contain',}}
									/>
								</View>
								<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5,}}>
									<Text style={styles.valueText}>{item.fixed_item_data.total_product_delivered}</Text>
								</View>
							</View>
						</View>	
					</View>
				}
				{item.customer_product_list_of_given_billing_month.length > 0
					?<View>
						{item.customer_product_list_of_given_billing_month.map((productItem, i)=> {
							return(
								productItem.is_fixed_monthly === true && productItem.total_product_delivered <= productItem.fixed_product
								? null
								: <View key={i} style={styles.productDeliveredViewStyle}>
									<View style={styles.productItemViewStyle}>
										<Text numberOfLines={3} ellipsizeMode='tail' style={styles.productItemNameTextStyle}>{i18n.t('total')}{' '}{productItem.item_user_facing_name ? productItem.item_user_facing_name :productItem.product_name}</Text>
										<Text style={styles.multiplyTextStyle}>{'X'}</Text>
										<Text style={styles.priceTextStyle}>{i18n.t('price')}</Text>
									</View>
									<View style={styles.productItemDeliveredValueStyle}>
										<Text style={styles.productDeliveredValueTextStyle}>{'( '}{productItem.total_product_delivered}</Text>
										<Text style={styles.multiplyProductValueTextStyle}>{'X'}</Text>
										<Text style={styles.productPriceValueTextStyle}>{productItem.product_price}{' )'}</Text>
									</View>
									<View style={styles.productAmountViewStyle}>
										<FontAwesome name="rupee" size={15} color="#000000" style={{paddingLeft:25 }} />
										<Text style={styles.priceValueTextStyle}>{productItem.total_product_delivered_amount}</Text>
									</View>
								</View>
							)
						})}
					</View>
					: <View>
						<Text style={styles.noProductDeliveredTextStyle}>{'No Product Delivered for this month'}</Text>
					</View> 
				}
				<View style={{paddingLeft:10,paddingRight:10}}>
					<CustomerBillInfo item={item} />
				</View>
			</View>
		)
	}

	handleProductBillCancel = (e) => {
		e.preventDefault();
		this.setState({ isProductBillVisible: false, billingPeriod:''});
	};
	generateProductBilling = async (shareOption) => {
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		let loginCustomerDataJson = JSON.parse(loginCustomerData);
		const generateUrl = businessApiUrl+'customer-month-bill-generate/'+loginCustomerDataJson.business_id+'/'+loginCustomerDataJson.customer_business_id+'/'+loginCustomerDataJson.token+'?date_string='+this.state.billingPeriod+'&format_type='+this.state.sizeOption+'&language_code='+this.state.languageCode+'&download_option='+this.state.downloadOption+'&new_generic_version_request_v2='+true
		BillPdfGenerateWithOption(loginCustomerDataJson.token, generateUrl, shareOption)
		this.setState({isProductBillVisible:false, billingPeriod:''})
	}

	render(){
		const { navigation, route, route: { params } } = this.props;
		if(this.state.isLoading){
			return(
				<View style={{position: 'absolute',left: 0,right: 0,top: 0,bottom: 0,alignItems: 'center',justifyContent: 'center', backgroundColor:'#ffffff'}}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			);
		}
		else{
			return(
				<View style={{flex:1, backgroundColor:'#ffffff'}}>
					{params.showHeader === false 
						? null
						:<Header
							statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
							centerComponent={<Image resizeMode='cover' style={{height:35.85,width:31.05,resizeMode:'contain'}}  source={require('../assets/images/small_logo.png')}/>}
							leftComponent={
								<TouchableOpacity
									style={{alignItems:'center', flexDirection:'row'}}
									activeOpacity={0.5}
									onPress={()=>this.props.navigation.goBack()}>
									<FontAwesome name='chevron-left' color='#38c6f4' size={24} />
									<Text style={{color: '#fff', fontSize:16, fontWeight:'bold', marginLeft:5}}>{i18n.t('home')}</Text>
								</TouchableOpacity>
							}
							containerStyle={{borderBottomWidth:null,backgroundColor:null,alignItems:'center',elevation:5}}
							centerContainerStyle={{justifyContent:'center'}}
							leftContainerStyle={{justifyContent:'center'}}
							ViewComponent={LinearGradient}
							linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
						/>
					}
					<FlatList
						data={this.state.last_three_months_customer_bill_details}
						renderItem={this.renderProductItem}
						keyExtractor={(item,index)=> index.toString()}
						ListHeaderComponent={this.renderHeader}
					/>
					<Overlay
						isVisible={this.state.visible}
						onBackdropPress={this.handleCancel}
						overlayStyle={styles.chooseLanguageOverlay}
					>

						<View style={styles.overlayView}>
							<View style={styles.viewHeading}>
								<Text style={styles.OptionHeadingTextStyle}>{i18n.t('choose_language_to_generate_bill')}</Text>
							</View>
							<View style={{flexDirection:'row',justifyContent:'center',width:wp('100%')}}>
								<View style={styles.checkLanguageStyle}>
									<CheckBox
										size={28}
										containerStyle={{borderWidth:0,backgroundColor:'transparent'}}
										center
										title='English'
										checkedIcon='check-circle'
										uncheckedIcon='circle-o'
										checked={this.state.languageCode==='en'? true :false}
										onPress={()=>this.setState({languageCode:'en'})}
										checkedColor='#38C6F4'
									/>
									<CheckBox
										size={28}
										containerStyle={{borderWidth:0,backgroundColor:'transparent'}}
										center
										title='हिंदी'
										checkedIcon='check-circle'
										uncheckedIcon='circle-o'
										checked={this.state.languageCode==='hi'? true :false}
										onPress={()=>this.setState({languageCode:'hi'})}
										checkedColor='#38C6F4'
									/>
								</View>
							</View>
							<View style={styles.singleOptionBillViewStyle}>
								<View style={styles.mainShareOptionStyle}>
									{
										/**
										here showing the option to download , sharing and print the bill.. with icon and the label
										*/
									}
									<View style={styles.shareOptionViewStyle}>
									{
										Platform.OS!=='web'
										?	<TouchableOpacity style={this.state.isForDownload===true || this.state.isForPrint===true ?styles.disaOptionTouchableStyle :styles.optionTouchableStyle}
												disabled={this.state.isForDownload===true || this.state.isForPrint===true ?true :false}
												onPress={()=> this.setState({showBillOption:!this.state.showBillOption,isForSharing:!this.state.isForSharing})}>
												<Ionicons name='md-share' size={32} color='#175491' style={this.state.isForDownload===true || this.state.isForPrint===true ?{opacity:0.62} :{opacity:1}} />											
												<Text style={this.state.isForDownload===true || this.state.isForPrint===true ?styles.disableOptionLabelText :styles.optionLabelText}>{i18n.t('share')}</Text>
											</TouchableOpacity>
										: null
									}

										<TouchableOpacity style={this.state.isForSharing===true || this.state.isForPrint===true ?styles.disaOptionTouchableStyle :styles.optionTouchableStyle}
											disabled={this.state.isForSharing===true || this.state.isForPrint === true ?true :false}
											onPress={()=> this.setState({showBillOption:!this.state.showBillOption,isForDownload:!this.state.isForDownload})}>
											<Feather name="download" size={32} color="#175491" style={this.state.isForSharing===true || this.state.isForPrint===true ?{opacity:0.62} :{opacity:1}}/>
											<Text style={this.state.isForSharing===true || this.state.isForPrint===true ?styles.disableOptionLabelText :styles.optionLabelText}>{i18n.t('download')}</Text>
										</TouchableOpacity>

										<TouchableOpacity style={this.state.isForSharing===true || this.state.isForDownload===true ?styles.disaOptionTouchableStyle :styles.optionTouchableStyle}
											disabled={this.state.isForSharing===true || this.state.isForDownload===true ?true :false}
											onPress={()=> Platform.OS!=='web' ? this.setState({showBillOption:!this.state.showBillOption,isForPrint:!this.state.isForPrint}) : this.setState({showBillOption:!this.state.showBillOption,isForDownload:!this.state.isForDownload})}>
											<Ionicons name='md-print' size={32} color='#175491' style={this.state.isForDownload===true || this.state.isForSharing===true ?{opacity:0.62} :{opacity:1}}/>
											<Text style={this.state.isForSharing===true || this.state.isForDownload===true ?styles.disableOptionLabelText :styles.optionLabelText}>{i18n.t('print')}</Text>
										</TouchableOpacity>
									</View>
									{
										/**
										here showing the size option message and the size option a4, a5, rollsize for single print download bill...where we are using space-between property which divide component with equal width from flex start
										*/
									}
									{this.state.showBillOption &&
										<View style={styles.mainSizeOptionViewStyle}>
											<Text style={styles.billingContentTextStyle}>{i18n.t('size_option_message')}</Text>
											<View style={styles.sizeOptionViewStyle}>
												<TouchableOpacity style={styles.a4SizeTouchableStyle} 
													activeOpacity={0.5}
													onPress={()=>this.OnBillOptionPress(this.state.a4Print)}>
													<Text style={styles.sizeTextStyle}>{i18n.t('a4_size')}</Text>
												</TouchableOpacity>
												<TouchableOpacity style={styles.a5SizeTouchableStyle} 
													activeOpacity={0.5}
													onPress={()=>this.OnBillOptionPress(this.state.a5Bill)}>
													<Text style={styles.sizeTextStyle}>{i18n.t('a5_size')}</Text>
												</TouchableOpacity>
												<TouchableOpacity style={styles.rollSizeTouchableStyle}
													activeOpacity={0.5}
													onPress={()=>this.OnBillOptionPress(this.state.rollSize)}>
													<Text style={styles.sizeTextStyle}>{i18n.t('roll_size')}</Text>
												</TouchableOpacity>
											</View>
										</View>
									}
								</View>
								{
									/**
									showing cancel option, if user wawnt to cancel or invisible the overlay.
									*/
								}
								<View style={styles.cancelViewStyle}>
									<TouchableOpacity style={styles.cancelTouchableStyle} 
										activeOpacity={0.5}
										onPress={this.handleCancel}>
										<Text style={styles.cancelTextStyle}>{i18n.t('cancelOption')}</Text>
									</TouchableOpacity>
								</View>
							</View>
						</View>
					</Overlay>
					<Overlay
						isVisible={this.state.isProductBillVisible}
						onBackdropPress={this.handleProductBillCancel}
						overlayStyle={styles.productBillOverlay}
					>
						<View>
							{this.state.billingPeriod
								?<ShareOptionComponent
									isForSharing={this.state.forSharing}
									isForDownload={this.state.forDownload}
									isForPrint={this.state.forPrint}
									shareDownloadPDF={this.generateProductBilling}
								/>
								:null
							}
							{
								/**
								showing cancel option, if user wawnt to cancel or invisible the overlay.
								*/
							}
							<View style={styles.cancelViewStyle}>
								<TouchableOpacity style={styles.cancelTouchableStyle} 
									activeOpacity={0.5}
									onPress={this.handleProductBillCancel}>
									<Text style={styles.cancelTextStyle}>{i18n.t('cancelOption')}</Text>
								</TouchableOpacity>
							</View>
						</View>
					</Overlay>
				</View>
				
			);
		}
	}
}

const styles = StyleSheet.create({
	balanceJarIconStyle:{
		height: 35,
		width: 35,
		resizeMode : 'contain',
	},
	productBillOverlay:{
		width:wp('95%'),
		height:'auto'
	},
	chooseLanguageOverlay:{
		width:wp('90%'),
		height:'auto'
	},
	balanceJarsIconStyle:{
		height: 30,
		width: 30,
		resizeMode : 'contain',
	},
	showForm:{
		borderColor: '#fff',
		width:wp('100%'),
		borderColor: '#ddd',
		backgroundColor:'#f5fcfc',
		padding:10
	},
	OptionButtonStyle: {
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: '#fff',
		borderColor: '#fff',
		width:wp('80%'),
		height: hp('10%'),
		borderWidth: 1,
		elevation: 5,//android
		marginBottom: 10,
		marginTop: 10,
		borderRadius:5,
		paddingLeft:20
	},
	OptionTextStyle :{
		color: "#175491",
		marginBottom : 4,
		fontWeight:'bold',
		fontSize:16,
		textAlign: 'center',
		marginLeft:10
	},
	OptionHeadingTextStyle :{
		color: "#175491",
		marginBottom : 4,
		fontWeight:'bold',
		fontSize:16,
		textAlign: 'center',
	},
	overlayOptionIconStyle:{
		height: hp('10%'),
		width: wp('10%'),
		resizeMode : 'contain',
	},
	viewStyle:{
		justifyContent:'center',
		alignItems:'center'
	},
	viewHeading:{
		justifyContent:'center',
		alignItems:'center',
		marginTop:10,
		marginBottom:10
	},
	overlayView:{
		flexDirection:'column',
		alignItems:'center'
	},
	buttonViewStyle:{
		flexDirection:'row',
		marginTop:10,
		borderBottomWidth:2,
		borderBottomColor:'#175491',
		paddingBottom:20
	},
	beforePreviousMonthSelectStyle:{
		borderColor:'#175491',
		borderRadius:5,
		alignItems:'center',
		borderWidth:1,
		backgroundColor:'#ffffff',
		width:wp('30%'),
		marginRight:40
	},
	afterPreviousMonthSelectStyle:{
		borderColor:'#175491',
		borderRadius:5,
		alignItems:'center',
		borderWidth:1,
		backgroundColor:'#b0e8e8',
		width:wp('30%'),
		marginRight:40
	},
	beforeCurrentMonthSelectStyle:{
		borderColor:'#175491',
		borderRadius:5,
		alignItems:'center',
		borderWidth:1,
		backgroundColor:'#ffffff',
		width:wp('30%')
	},
	afterCurrentMonthSelectStyle:{
		borderColor:'#175491',
		borderRadius:5,
		alignItems:'center',
		borderWidth:1,
		backgroundColor:'#b0e8e8',
		width:wp('30%')
	},
	beforeNextMonthSelectStyle:{
		borderColor:'#175491',
		borderRadius:5,
		alignItems:'center',
		borderWidth:1,
		backgroundColor:'#ffffff',
		width:wp('30%'),
		marginLeft:40
	},
	afterNextMonthSelectStyle:{
		borderColor:'#175491',
		borderRadius:5,
		alignItems:'center',
		borderWidth:1,
		backgroundColor:'#b0e8e8',
		width:wp('30%'),
		marginLeft:40
	},
	checkLanguageStyle:{
		flexDirection:'row',
		borderBottomWidth:2,
		borderBottomColor:'#175491',
		paddingBottom:10,
		width:wp('71%'),
	},
	OptionButtonStyle: {
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: '#fff',
		borderColor: '#fff',
		width:wp('80%'),
		height: hp('10%'),
		borderWidth: 1,
		elevation: 5,//android
		marginBottom: 10,
		marginTop: 10,
		borderRadius:5,
		paddingLeft:20
	},
	OptionTextStyle :{
		color: "#175491",
		marginBottom : 4,
		fontWeight:'bold',
		fontSize:16,
		textAlign: 'center',
		marginLeft:10
	},
	optionTouchableStyle:{
		flexDirection:'column',
		alignItems:'center',
		paddingHorizontal : 8
	},
	sizeTextStyle:{
		color: "#175491",
		fontWeight:'bold',
		fontSize:12,
		textAlign: 'center',
	},
	optionLabelText:{
		color: "#808080",
		fontWeight:'bold',
		fontSize:12,
		textAlign: 'center',
	},
	disableOptionLabelText:{
		opacity:0.62,
		color: "#808080",
		fontWeight:'bold',
		fontSize:12,
		textAlign: 'center',
	},
	disaOptionTouchableStyle:{
		flexDirection:'column',
		alignItems:'center',
		opacity:0.62
	},
	headingTextStyle:{
		color: "#175491",
		fontWeight:'bold',
		fontSize:16,
	},
	cancelTextStyle:{
		color: "#175491",
		fontWeight:'bold',
		fontSize:18,
		textAlign: 'center',
	},
	OptionHeadingTextStyle :{
		color: "#175491",
		marginBottom : 4,
		fontWeight:'bold',
		fontSize:16,
		textAlign: 'center',
	},
	overlayOptionIconStyle:{
		height: hp('10%'),
		width: wp('10%'),
		resizeMode : 'contain',
	},
	viewStyle:{
		justifyContent:'center',
		alignItems:'center'
	},
	viewHeading:{
		justifyContent:'center',
		alignItems:'center',
		marginTop:10,
		marginBottom:10
	},
	billingContentTextStyle:{
		color: "#808080",
		fontWeight:'bold',
		fontSize:12,
		paddingBottom:5
	},
	buttonViewStyle:{
		flexDirection:'row',
		marginTop:10,
		borderBottomWidth:2,
		borderBottomColor:'#175491',
		paddingBottom:20
	},
	beforePreviousMonthSelectStyle:{
		borderColor:'#175491',
		borderRadius:5,
		alignItems:'center',
		borderWidth:1,
		backgroundColor:'#ffffff',
		width:wp('30%'),
		marginRight:40
	},
	afterPreviousMonthSelectStyle:{
		borderColor:'#175491',
		borderRadius:5,
		alignItems:'center',
		borderWidth:1,
		backgroundColor:'#b0e8e8',
		width:wp('30%'),
		marginRight:40
	},
	beforeCurrentMonthSelectStyle:{
		borderColor:'#175491',
		borderRadius:5,
		alignItems:'center',
		borderWidth:1,
		backgroundColor:'#ffffff',
		width:wp('30%')
	},
	afterCurrentMonthSelectStyle:{
		borderColor:'#175491',
		borderRadius:5,
		alignItems:'center',
		borderWidth:1,
		backgroundColor:'#b0e8e8',
		width:wp('30%')
	},
	beforeNextMonthSelectStyle:{
		borderColor:'#175491',
		borderRadius:5,
		alignItems:'center',
		borderWidth:1,
		backgroundColor:'#ffffff',
		width:wp('30%'),
		marginLeft:40
	},
	afterNextMonthSelectStyle:{
		borderColor:'#175491',
		borderRadius:5,
		alignItems:'center',
		borderWidth:1,
		backgroundColor:'#b0e8e8',
		width:wp('30%'),
		marginLeft:40
	},
	checkLanguageStyle:{
		flexDirection:'row',
		borderBottomWidth:1,
		borderBottomColor:'#175491',
		paddingBottom:10,
		width:wp('80%'),
	},
	cancelTouchableStyle:{
		flexDirection:'row',
		justifyContent:'center',
		alignItems:'center',
		borderWidth:1,
		borderColor:'#175491',
		width:wp('26%'),
		height:hp('7%'),
		padding:10,
		borderRadius:5
	},
	cancelViewStyle:{
		justifyContent:'center',
		alignItems:'center',
		marginTop:10
	},
	rollSizeTouchableStyle:{
		flexDirection:'row',
		borderWidth:1, 
		borderColor:'#175491',
		margin:5,
		padding:5,
		borderRadius:5,
		width:wp('25%'),
		justifyContent:'center',
		alignItems:'center'
	},
	a5SizeTouchableStyle:{
		flexDirection:'row',
		borderWidth:1,
		borderColor:'#175491',
		margin:5,
		padding:5,
		borderRadius:5,
		width:wp('25%'),
		justifyContent:'center',
		alignItems:'center'
	},
	a4SizeTouchableStyle:{
		flexDirection:'row',
		borderWidth:1, 
		borderColor:'#175491',
		padding:5, 
		borderRadius:5, 
		width:wp('25%'), 
		justifyContent:'center', 
		alignItems:'center',
		marginRight:5, 
		marginTop:5, 
		marginBottom:5
	},
	sizeOptionViewStyle:{
		flexDirection:'row',
		justifyContent:'flex-start',
		alignItems:'flex-start'
	},
	mainSizeOptionViewStyle:{
		marginTop:15,
		borderBottomWidth:1,
		borderBottomColor:'#175491',
		paddingBottom:20
	},
	shareOptionViewStyle:{
		alignItems:'center',
		flexDirection:'row',
		justifyContent:'space-between',
		marginTop:5,
		marginBottom:5
	},
	mainShareOptionStyle:{
		paddingBottom:10,
		width:wp('80%'),
	},
	singleOptionBillViewStyle:{
		padding:10
	},
	dropdownContainerStyle:{
		alignItems:'center',
		width:wp('50%'),
		marginTop:15,
		paddingTop:10,
		marginBottom:5,
		borderColor:'#175491',
		borderRadius:5,
		borderWidth:2,
	},
	dropdownStyle:{
		justifyContent:'center',
		alignItems:'center'
	},
	inputContainerStyle:{
		borderBottomWidth:0,
		justifyContent:'center',
		alignItems:'center',
		width:wp('45%')
	},
	OptionHeadingTextStyle :{
		color: "#175491",
		marginBottom : 4,
		fontWeight:'bold',
		fontSize:16,
		textAlign: 'center',
	},
	viewHeading:{
		justifyContent:'center',
		alignItems:'center',
		marginTop:10,
		marginBottom:10
	},
	previousDownloadBillViewStyle:{
		alignItems:'center',
		justifyContent:'center',
		flexDirection:'row',
		padding:10,
		paddingBottom:20
	},
	touchableDownloadBillStyle:{
		alignItems:'center',
		flexDirection:'row',
		padding:10,
		borderColor:'#38c6f4',
		borderWidth:2,
		borderRadius:5,
		width:wp('80%')
	},
	previouseBillTextStyle:{
		textAlign:'left',
		fontSize:16,
		fontWeight:'bold',
		color:'#38c6f4',
		marginLeft:10
	},
	cancelButtonViewStyle:{
		justifyContent:'center',
		alignItems:'center',
		marginTop:10,
		marginBottom:10
	},
	cancelTouchableStyle:{
		flexDirection:'row',
		justifyContent:'center',
		alignItems:'center',
		borderWidth:1,
		borderColor:'#175491',
		width:wp('26%'),
		height:hp('7%'),
		padding:10,
		borderRadius:5
	},
	cancelTextStyle:{
		color: "#175491",
		fontWeight:'bold',
		fontSize:18,
		textAlign: 'center',
	},
	monthlyBillContainerStyle:{
		flex:1
	},
	currentPreviouseMonthlyBillStyle:{
		flexDirection:"column",
		marginBottom:10
	},
	currentMonthTouchableStyle:{
		width:wp('100%'),
		flexDirection: 'row',
		borderWidth:1,
		backgroundColor:'#175491',
		borderColor:'#175491',
		elevation:3,
	},
	touchableDateArrowStyle:{
		width: wp('100%'),
		flexDirection:'row',
		alignItems:'center',
		padding:6,
		justifyContent:'space-between'
	},
	dateStyle:{
		fontSize: 16,
		color:'#ffffff',
		fontWeight:'bold'
	},
	upAndDownArrowStyle:{
		flexDirection:'row',
		marginRight:5,
		alignItems:'center',
	},
	billingInfoViewStyle:{
		width:wp('100%'),
		backgroundColor:'#f2f5f7',
		padding:10,
		borderBottomColor:'#afdbe2',
		borderBottomWidth:2
	},
	productAmountViewStyle:{
		flexDirection:'row',
		width:wp('30%'),
		alignItems:'center',
		justifyContent:'flex-start'
	},
	productPriceValueTextStyle:{
		color:'#000000',
		fontSize:14,
		fontWeight:'bold'
	},
	multiplyProductValueTextStyle:{
		color:'#000000',
		fontSize:14,
		fontWeight:'bold'
	},
	productDeliveredValueTextStyle:{
		color:'#000000',
		fontSize:14,
		fontWeight:'bold'
	},
	productItemDeliveredValueStyle:{
		flexDirection:'row',
		width:wp('30%'),
		alignItems:'center',
		justifyContent:'space-evenly'
	},
	priceTextStyle:{
		color:'#000000',
		fontSize:14,
		width:wp('10%')
	},
	multiplyTextStyle:{
		color:'#000000',
		fontSize:14,
		fontWeight:'bold',
		width:wp('5%')
	},
	productItemNameTextStyle:{
		color:'#000000',
		fontSize:14,
		width:wp('25%')
	},
	productDeliveredViewStyle:{
		flexDirection:'row',
		paddingTop:20,
		paddingBottom:20,
		borderBottomColor:'#ffffff',
		borderBottomWidth:2,
		alignItems:'center',
		justifyContent:'space-between',
		paddingLeft:10,
		paddingRight:10,
		backgroundColor:'#f2f5f7'
	},
	billingDateViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		marginTop:10,
		marginBottom:10
	},
	billingDateValueTextStyle:{
		fontSize: 16,
		fontWeight:'bold',
		color:'#000000'
	},
	productItemViewStyle:{
		flexDirection:'row',
		width:wp('40%'),
		alignItems:'center'
	},
	noProductDeliveredTextStyle:{
		paddingTop:10,
		paddingBottom:10,
		textAlign:'center',
		fontSize:16,
		fontWeight:'bold',
		color:'#e51b0c'
	},
	mainView:{
		flexDirection:'column',	
	},
	fixedAmuntMainView:{
		flexDirection:'row',
		padding:10,
		borderBottomColor:'#ffffff',
		borderBottomWidth:2,
		backgroundColor:'#f2f5f7'
	},
	headingView:{
		width:wp('70%'),
		flexDirection:'row',
		
	},
	headingText:{
		fontSize:14
	},
	valueText:{
		fontSize:14,
		fontWeight:'bold',
		paddingLeft:10,
		paddingRight:10,
	},
	valueTextView:{
		width:wp('17%'),
		flexDirection:'row',
		justifyContent:'flex-end',
		alignItems:'center'
	},
	fixedDeliveredView:{
		flexDirection:'row',
		padding:10,
		borderBottomColor:'#afdbe2',
		borderBottomWidth:2,
		backgroundColor:'#f2f5f7'
	},
	symbolView:{
		width:wp('3%'),
	},
	priceValueTextStyle:{
		color:'#000000',
		fontSize:14,
		fontWeight:'bold',
		paddingLeft:15
	},
	headerContainer:{
		width:wp('100%'), 
		padding:10,
		backgroundColor:'#f2f5f7',
		alignItems:'center', 
		justifyContent:'center'
	},
	headerImagetextView:{
		flexDirection:'row', 
		alignItems:'center',
	},
	downloadbilltext:{
		color:'#175491', 
		fontSize:16, 
		fontWeight:'bold',
		marginLeft:5
	},
	downloadImage:{
		width:40,
		height:30, 
		resizeMode:'contain'
	}
});