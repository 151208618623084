import { View, Image, Text, StyleSheet, Alert, TouchableOpacity,} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import alert from '../utils/commonAlert'
import i18n from 'i18n-js';

import {constantValues} from '../utils/helpers';

const onPressPlaceOrederUI = ()=>{
	// To show the alert if this component has been used and not pass function
	alert(
		constantValues.businessOrAppName,
		'Technical Error, Please report on app support',
		[
			{text: i18n.t('ok')}
		],
		{ cancelable: false }
	);
}

export const RenderPlaceOrderUI = (props)=>{
	/* To retorn a row according to item detail and functionality */
	return(<TouchableOpacity
		style={props.placeOrderUIContainer}
		onPress={() => props.navigateOnScreen()}
	>
		<View style={props.iconAndTitleContainer}>
			<Image  source={require('../assets/images/customer-orders.png')} style={props.transportIcon}/>
			<Text style={props.titleTextStyle}>{i18n.t('orderProducts')}</Text>
		</View>
	</TouchableOpacity>)
};

const styles = StyleSheet.create({
	transportIcon:{
		width:50,
		height:50,
		resizeMode:'contain',
	},
	iconAndTitleContainer:{
		flexDirection:'row',
		alignItems:'center',
	},
	placeOrderUIContainer:{
 		backgroundColor:'#D9F3F9',
 		paddingHorizontal:10,
 		paddingVertical:5,
 		flexDirection:'row',
 		justifyContent:'space-between',
 		alignSelf:'center',
 		alignItems:'center',
 		width:wp('93%'),
 		borderRadius:5,
 		elevation:5,
 		shadowColor: '#808080',
	 	shadowOpacity: 0.5,
		shadowOffset: {
			height: 2,
			width:2
		},
		shadowRadius : 10,
		marginTop:10,
 	},
 	titleTextStyle:{
 		color:'#175491',
 		fontWeight:'bold',
 		fontSize:18,
 		marginLeft:10,
 	},
});

RenderPlaceOrderUI.defaultProps = {
	placeOrderUIContainer:styles.placeOrderUIContainer,
	titleTextStyle:styles.titleTextStyle,
	transportIcon:styles.transportIcon,
	iconAndTitleContainer:styles.iconAndTitleContainer,
	// onPressPlaceOrederUI:onPressPlaceOrederUI,
}