import { Platform } from 'react-native'
import { initializeApp } from "firebase/app";
import * as firebaseMessaging from 'firebase/messaging'
import getEnvVars from '../environment';
const { apiUrl } = getEnvVars();
import AsyncStorage from '@react-native-async-storage/async-storage'
import axios from 'axios'

const firebaseConfig = {
  apiKey: "AIzaSyCyoLSo3x_C7JbrIa0JCuggiCI2sMoUQu4",
  authDomain: "gopaa-8db13.firebaseapp.com",
  projectId: "gopaa-8db13",
  storageBucket: "gopaa-8db13.appspot.com",
  messagingSenderId: "1013915256586",
  appId: "1:1013915256586:web:7ec0b3bdb77d2cb2689cf3"
};

const sendPushTokenToBackend = async(pushToken) => {
	try{
		const { token, user_id } = JSON.parse(await AsyncStorage.getItem('loginCustomerData'))
		const response = await axios.post(`${apiUrl}customer-push-token/${user_id}/`,
			{ token : pushToken, is_web_token : "true" },
			{ headers : {
				Authorization: `Token ${token}`,
				'Content-Type': 'application/json',
			} })
	}
	catch(e){}
}

const getMessagingToken = async(app) => {
	try{
		const messaging = firebaseMessaging.getMessaging(app)
		messaging.onMessageHandler = payload => {
			const { title, body, route } = payload?.data
			navigator.serviceWorker.getRegistrations().then(registrations => {
				const notification = registrations[0]?.showNotification(title,{
				  body,
			    icon : 'https://web.gopaani.com/static/images/only_logo.png',
			    badge : 'https://web.gopaani.com/static/images/only_logo.png',
			    image : 'https://web.gopaani.com/static/images/only_logo.png',
			    data : { route : route ? route : "" }
				})
			})
		}
		const token = await firebaseMessaging.getToken(messaging,{vapidkey:'BN3XrXeKJP56p1PPlTgmqIlzsH4mxAfK-tu-D42DEHjdbs4Lr_Ik6TNedImSNqXDeoc0zaQyTxi50STr-UG6F1A'})
		sendPushTokenToBackend(token)
	}
	catch(e){}
}

export const enableWebPush = async() => {
	try{
		if(Platform.OS==='web' && window.hasOwnProperty('Notification')){
			await window.Notification.requestPermission()
			if(window.Notification.permission==='granted'){
				const app = initializeApp(firebaseConfig)
				getMessagingToken(app)
			}
		}
	}
	catch(e){}
}
