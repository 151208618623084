import { Platform } from 'react-native'
import { format } from 'date-fns'
import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const webPickerStyle = {
	borderWidth:0,
	marginLeft:10,
	width:0,
	height:0,
	borderWidth:0,
	boxSizing:'border-box',
	margin:0,
	padding:0
}

export default function CommonDatePicker(props){

	const webPicker = useRef()

	const timeToDateString = time => {
		const [hrs,mins] = time.split(':')
		const responseDate = new Date()
		responseDate.setHours(hrs,mins)
		return responseDate
	}
	
	//Checking if current platform is web
	const isWeb = Platform.OS==='web'

	useEffect(()=>{
		if(isWeb){
			webPicker.current?.showPicker()
		}
	},[])

	//Destructure Props
	const { value, onChange, minimumDate, maximumDate, mode } = props

	if(isWeb){
		
		if(mode==="date") 
			return <input 
				ref={webPicker}
				style={webPickerStyle}
				onClick={e => e.target.showPicker()}
				autoFocus={true}
				onBlur={e => onChange({type:'not_set'}, e.target.value)}
				type='date'
				value={format(value,'YYYY-MM-DD')}
				onChange={e => onChange({type:'set'}, e.target.value)}
				min={format(minimumDate,'YYYY-MM-DD')}
				max={format(maximumDate,'YYYY-MM-DD')} />
		
		else return <input
						ref={webPicker}
						style={webPickerStyle}
						value={format(value,'HH:mm')}
						autoFocus={true}
						onBlur={e => onChange({type:'not_set'}, timeToDateString(e.target.value))}
						onChange={e => onChange({type:'set'}, timeToDateString(e.target.value))}
						onClick={e => e.target.showPicker()}
						type="time" />
	}

	else return null
	//else return <DateTimePicker {...props} />
}

CommonDatePicker.propTypes = {
	onChange : PropTypes.func,
	mode : PropTypes.oneOf(["date","time"])
}

CommonDatePicker.defaultProps = {
	mode : "date"
}