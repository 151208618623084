import i18n from 'i18n-js';

import {constantValues} from './helpers';
import {badRequestErrorStatus, logoutRemoveErrorStatus, accessDeniedErrorStatus} from './declaredErrorStatusCode';
import logout from '../components/logout';
import {showTechnicalError} from './showTechnicalError';
import alert from './commonAlert'

export const showErrorAlertStatusCode = (apiResponseStatusCode, navigation = '', loginCustomerData = '', hasToGoBack = false, isNavigateToHomeScreen = false)=>{
	/*	apiResponseStatusCode argument is required but naviagation, loginCustomerData,  hasToGoBack are optional)	*/
	if(logoutRemoveErrorStatus.includes(apiResponseStatusCode) && navigation) {
		// To show alert and logout if login user token not exist(on 401)
		alert(
			constantValues.businessOrAppName,
			i18n.t('invalid_token_message'),
			[
				{text: i18n.t('ok'), onPress: () => logout(navigation)},
			],
			{ cancelable: false }
		)
	}
	else if(accessDeniedErrorStatus.includes(apiResponseStatusCode)) {
		// To show alert if that access has been denied (on 403)
		alert(
			constantValues.businessOrAppName,
			i18n.t('permission_to_perform'),
			[
				{text: i18n.t('ok'), onPress: () =>{
					if(loginCustomerData && navigation && isNavigateToHomeScreen){
						navigation.navigate('Home', {loginCustomerData:loginCustomerData});
					}
					else if(hasToGoBack && navigation && navigation.canGoBack()){
						navigate.goBack();
					}
					else{
						null
					}
				}},
			],
			{ cancelable: false }
		)
	}
	else if(badRequestErrorStatus.includes(apiResponseStatusCode)){
		// To show bad request 
		alert(
			constantValues.businessOrAppName,
			i18n.t('badRequest'),
			[
				{text: i18n.t('ok'), onPress: () =>{
					if(loginCustomerData && navigation && isNavigateToHomeScreen){//loginCustomerData and navigation exist
						navigation.navigate('Home', {loginCustomerData:loginCustomerData});
					}
					else if(hasToGoBack && navigation && navigation.canGoBack()){//hasToGoBack = true and navigation exist
						navigation.goBack();//Because parent params already exist.
					}
					else{
						null
					}
				}},
			],
			{ cancelable: false }
		)
	}
	else{
		showTechnicalError();
	}
}