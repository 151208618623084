import React, { Component } from 'react';
import { Text, View, StyleSheet, ScrollView, TouchableOpacity, ActivityIndicator, Image, Platform, BackHandler} from 'react-native';
import {Button, Header} from '@rneui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import { FontAwesome } from '@expo/vector-icons';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import PasscodeTextInput from './passcodeTextInput';
import CountDown from '../components/countDown';
import i18n from 'i18n-js';
import translationValues from '../components/translation';
import client from '../client';
import logout from '../components/logout';
import AsyncStorage from '@react-native-async-storage/async-storage'
import alert from '../utils/commonAlert'

export default class ResetPhoneNumberOtpView extends Component {

	constructor(props){
		super(props)
		this.state = {
			isLoading:false,
			errorMessage:'',
			showErrorMessage:false,
			totalDuration:30,
			enableResendOTP:false,
			timerRunning:true,
			counterValue:null,
			otpCode:''
		}
		this.willFocusSubscription='';
		this.backhandlerSubscription=''
	}
	componentDidMount(){
		this.backhandlerSubscription = BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
		this.willFocusSubscription = this.props.navigation.addListener('fous', ()=>{
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
		});
 	}
	componentWillUnmount(){
		this.willFocusSubscription();
		this.backhandlerSubscription.remove()
	}
	
	handleBackButtonClick = () => {
		const { navigation, route, route: { params } } = this.props;
		let loginCustomerData = params.loginCustomerData;
		this.props.navigation.navigate('CustomerProfile',{
			loginCustomerData:loginCustomerData,
			showMenuicon:true,
		})
		return true;
	};

	onChangeText = (value) => this.setState({ otpCode:value })

	verifyResetPhoneNumberOtp = async () => {
		this.setState({isLoading:true})
		const {otpCode} = this.state;
		const { navigation, route, route: { params } } = this.props;
		let loginCustomerData = params.loginCustomerData;
		let customerResetPhoneNumber = params.customerResetPhoneNumber;
		let requestBody = {'customer_phone_number':customerResetPhoneNumber,'otp_pin':otpCode}
		const responseData = await client.verifyOtpToresetCustomerPhoneNumber(loginCustomerData.customer_otp_verify_id, requestBody)
		if (responseData.detail) {
			alert(
				'Customer',
				'You do not have permission to perform this action.',
				[
					{text: 'OK', onPress: () => logout(this.props.navigation)},
				],
				{ cancelable: false }
			)
		}else{
			if(responseData.success === true){
				AsyncStorage.getItem('loginCustomerData').then( data => {
				// transform it back to an object
					data = JSON.parse( data );
					data.customer_phone_number = customerResetPhoneNumber;
					//save the value to AsyncStorage again
					AsyncStorage.setItem('loginCustomerData', JSON.stringify(data));
					global.userTokenData = data;
				})
				this.setState({isLoading:false})
				if(Platform.OS==='android'){
				}
				else{
					alert(
						'GoPaani',
						i18n.t('changedMobileNumberSccessMessage'),
						[
							{text: i18n.t('ok')},
						],
						{ cancelable: false }
					)
				}
				this.props.navigation.navigate('CustomerProfile',{
					loginCustomerData:loginCustomerData,
					showMenuicon:true,
				})
				
			}else{
				this.setState({isLoading:false, showErrorMessage:true, errorMessage:i18n.t('correct_otp_number')})
			}
		}
	}

	resendOtpToResetPhoneNumber = async () => {
		this.setState({isLoading:true})
		
		const { navigation, route, route: { params } } = this.props;
		let loginCustomerData = params.loginCustomerData;
		let customerResetPhoneNumber = params.customerResetPhoneNumber;
		let requestBody = {'customer_phone_number':customerResetPhoneNumber.replace('+91','')}
		const responseData = await client.resetCustomerPhoneNumber(loginCustomerData.customer_otp_verify_id, requestBody)
		if (responseData.detail) {
			alert(
				'Customer',
				'You do not have permission to perform this action.',
				[
					{text: 'OK', onPress: () => logout(this.props.navigation)},
				],
				{ cancelable: false }
			)
		}else{
			this.setState({isLoading:false,enableResendOTP:false,showErrorMessage:false,totalDuration:30,})
			alert(
				i18n.t('otp'),
				i18n.t('again_sent_otp_message'),
				[
					{text:i18n.t('ok')},
				],
				{ cancelable: false }
			)
		}
	}

	render(){
		const { navigation, route, route: { params } } = this.props;
		let loginCustomerData = params.loginCustomerData;
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<View style={styles.mainViewStyle}>
					<Header
						statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						placement={"left"}
						leftComponent={
							<TouchableOpacity
								style={styles.headerLeftComponentStyle}
								activeOpacity={0.5}
								onPress={()=>this.props.navigation.navigate('CustomerProfile',{loginCustomerData:params.loginCustomerData,showMenuicon:true})}>
								<FontAwesome name='chevron-left' color='#38c6f4' size={28} />
							</TouchableOpacity>
						}
						leftContainerStyle={{justifyContent:'center'}}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
					/>
					<ScrollView contentContainerStyle={styles.contentContainerStyle} keyboardShouldPersistTaps={'always'}>
						<View style={styles.headingViewStyle}>
							<Text style={styles.headingTextStyle}>{i18n.t('verify_text')}</Text>
							<Text style={styles.headingContentTextStyle}>{i18n.t('sub_heading')}</Text>
						</View>
						<View style={styles.innerView}>
							<View style={styles.innerViewStyle}>
								<PasscodeTextInput
									otpCode={this.state.otpCode}
									onChangeText={this.onChangeText}
								/>
								{this.state.showErrorMessage &&
									<Text style={styles.errorText}>{this.state.errorMessage}</Text>
								}
								<Text style={styles.timeRemainingTextStyle}>{i18n.t('time_remaining')}</Text>
								<CountDown
									until={this.state.totalDuration}
									onFinish={() => this.setState({enableResendOTP:true})}
									size={14}
									digitStyle={{backgroundColor: '#FFF'}}
									digitTxtStyle={styles.timerTextStyle}
									timeToShow={['M', 'S']}
									separatorStyle={{color: '#175491'}}
									timeLabels={{m: null, s: null}}
									showSeparator={true}
									running={this.state.timerRunning}
									onChange={until => this.setState({counterValue:until})}
								/>
								<TouchableOpacity
									onPress={()=> this.resendOtpToResetPhoneNumber()}
									activeOpacity={0.5}
									disabled={this.state.enableResendOTP ?false:true}>
									<Text style={this.state.enableResendOTP ?{fontWeight:'bold', fontSize:18,textAlign:'left',marginTop:10,color:'#38c6f4'} :{fontWeight:'bold', fontSize:18,textAlign:'left',marginTop:10,color:'#b3b3b3'}}>{i18n.t('resend_otp')}</Text>
								</TouchableOpacity>
							</View>
							<Button
								ViewComponent={LinearGradient}
								linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0},end:{x: 1, y: 0},}}
								title={i18n.t('verify_title')}
								buttonStyle={styles.buttonStyle}
								containerStyle={styles.containerStyle}
								titleStyle={styles.titleStyle}
								onPress={this.verifyResetPhoneNumberOtp}
								disabled={this.state.otpCode === '' ?true:false}
								disabledTitleStyle={{color:'hsl(208, 8%, 63%)'}}
							/>
						</View>
					</ScrollView>
				</View>
			
			)
		}
	}
}


const styles = StyleSheet.create({
	errorText:{
		fontSize:12,
		textAlign:'center',
		color:'red',
		width:wp('85%'),
		marginBottom:5,
	},
	innerView:{
		alignItems: 'center',
		width:wp('95%'),
		backgroundColor: '#fff',
		padding:10,
		borderRadius:10,
	},
	innerViewStyle:{
		width:wp('95%'),
		backgroundColor: '#fff',
		borderRadius:5,
		borderWidth:1,
		borderColor:'#175491',
		alignItems:'center',
		paddingBottom:60
	},
 	headerLeftComponentStyle:{
		flexDirection:'row',
		alignItems:'center'
	},
	headerContainerStyle:{
		borderBottomWidth:null,
		backgroundColor:null,
		alignItems:'center',
		elevation:5
	},
 	contentContainerStyle:{
		justifyContent:'center',
		alignItems:'center',
	},
	loadingViewStyle:{
		backgroundColor:'#ffffff',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	mainViewStyle:{
		flex:1,
		backgroundColor:'#ffffff'
	},
	headingTextStyle:{
		fontWeight:'bold',
		fontSize:20,
		color:'#175491',
		marginTop:10,
		marginBottom:10,
	},
	headingViewStyle:{
		flexDirection:'column',
		padding:10
	},
	headingContentTextStyle:{
		fontWeight:'bold',
		fontSize:16,
		marginTop:10,
		marginBottom:10,
		color:'#b3b3b3'
	},
	timeRemainingTextStyle:{
		fontSize:12,
		textAlign:'left',
		color:'#175491'
	},
	timerTextStyle:{
		color: '#175491',
		fontSize:18,
		fontWeight:'bold'
	},
	titleStyle:{
		fontWeight:'bold',
		color:'#ffffff',
		fontSize:20,
		textAlign:'center'
	},
	containerStyle:{
		flexDirection:'row',
		justifyContent:'center',
		alignItems:'center',
		marginTop:-35
	},
	buttonStyle:{
		width:wp('75%'),
		padding:15,
		borderRadius:5
	},
})