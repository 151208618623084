import React, { Component } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import ChatConversation from './screens/chatConversation';
import Home from './screens/home';
import DrawerContentComponents from './drawerContentComponents';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import ChooseLanguage from './screens/ChooseLanguage';
import ShowCustomerBusiness from './screens/showCustomerBusiness';
import JarDeliveryFrequency from './screens/jarDeliveryFrequency';
import CustomerProfile from './screens/customerProfile';
import ResetCustomerPhoneNumber from './screens/resetCustomerPhoneNumber';
import ResetPhoneNumberOtpView from './screens/resetPhoneNumberOtpView';

const CustomerProfileStack = createStackNavigator()
function CustomerProfileScren(){
	return(
		<CustomerProfileStack.Navigator initialRouteName="CustomerProfile" screenOptions={{headerShown: false}}>
			<CustomerProfileStack.Screen name="CustomerProfile" component={CustomerProfile} />
			<CustomerProfileStack.Screen name="ResetCustomerPhoneNumber" component={ResetCustomerPhoneNumber} />
			<CustomerProfileStack.Screen name="ResetPhoneNumberOtpView" component={ResetPhoneNumberOtpView} />
		</CustomerProfileStack.Navigator>
	)
}

const Drawer = createDrawerNavigator();
export default function CustomerDrawerNavigator(){
	return(
		<Drawer.Navigator 
			drawerStyle={{
				backgroundColor: '#ffffff',
				width: wp('75%'),
			}}
			drawerContent={(props) => <DrawerContentComponents {...props} /> }
			initialRouteName="Home" 
			screenOptions={{headerShown: false}}>
			<Drawer.Screen name="Home" component={Home}/>
			<Drawer.Screen name="CustomerProfile" component={CustomerProfileScren}/>
			<Drawer.Screen name="ChatConversation" component={ChatConversation}/>
			<Drawer.Screen name="JarDeliveryFrequency" component={JarDeliveryFrequency}/>
			<Drawer.Screen name="ChooseLanguage" component={ChooseLanguage}/>
			<Drawer.Screen name="SwitchAccount" component={ShowCustomerBusiness}/>
		</Drawer.Navigator>
	)
}