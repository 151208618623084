import { StyleSheet } from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';

export default StyleSheet.create({
	optionLabelText:{
		color: "#808080",
		fontWeight:'bold',
		fontSize:12,
		textAlign: 'center',
	},
	optionTouchableStyle:{
		flexDirection:'column',
		alignItems:'center',
		paddingHorizontal : 8
	},
	shareOptionViewStyle:{
		alignItems:'center',
		flexDirection:'row',
		justifyContent:'space-between',
		marginTop:5,
		marginBottom:5
	},
	cancelButtonViewStyle:{
		justifyContent:'center',
		alignItems:'center',
		marginTop:10
	},
	cancelTouchableStyle:{
		flexDirection:'row',
		justifyContent:'center',
		alignItems:'center',
		borderWidth:1,
		borderColor:'#175491',
		width:wp('26%'),
		height:hp('7%'),
		padding:10,
		borderRadius:5
	},
	cancelTextStyle:{
		color: "#175491",
		fontWeight:'bold',
		fontSize:18,
		textAlign: 'center',
	},
	totalAmountTextStyle:{
		color:'#000000',
		fontSize:16,
		width:wp('80%'),
		fontWeight:'bold'
	},
	productTotalAmountViewStyle:{
		flexDirection:'row',
		paddingTop:10,
		paddingBottom:10,
		borderBottomColor:'#6fdde2',
		borderBottomWidth:2,
		alignItems:'center',
		justifyContent:'space-between',
		paddingLeft:5,
		paddingRight:5,
		backgroundColor:'#f2f5f7',
		borderTopColor:'#6fdde2',
		borderTopWidth:2,
	},
	productAmountViewStyle:{
		flexDirection:'row',
		width:wp('20%'),
		alignItems:'center',
		justifyContent:'space-evenly'
	},
	productPriceValueTextStyle:{
		color:'#000000',
		fontSize:14,
		fontWeight:'bold'
	},
	multiplyProductValueTextStyle:{
		color:'#000000',
		fontSize:14,
		fontWeight:'bold'
	},
	productDeliveredValueTextStyle:{
		color:'#000000',
		fontSize:14,
		fontWeight:'bold'
	},
	productItemDeliveredValueStyle:{
		flexDirection:'row',
		width:wp('25%'),
		alignItems:'center',
		justifyContent:'space-evenly'
	},
	priceTextStyle:{
		color:'#000000',
		fontSize:12,
		width:wp('10%')
	},
	multiplyTextStyle:{
		color:'#000000',
		fontSize:12,
		fontWeight:'bold',
		width:wp('5%')
	},
	productItemNameTextStyle:{
		color:'#000000',
		fontSize:12,
		width:wp('40%')
	},
	productItemViewStyle:{
		flexDirection:'row',
		width:wp('50%'),
		alignItems:'center',
		justifyContent:'space-evenly'
	},
	billProductItemViewStyle:{
		flexDirection:'row',
		paddingTop:10,
		paddingBottom:10,
		borderBottomColor:'#ffffff',
		borderBottomWidth:2,
		alignItems:'center',
		justifyContent:'space-between',
		paddingLeft:5,
		paddingRight:5,
		backgroundColor:'#f2f5f7'
	},
	shareTextStyle:{
		fontWeight:'bold',
		color:'#175491',
		fontSize:14
	},
	billShareIconStyle:{
		alignItems:'center',
		backgroundColor:'#ffffff',
		borderRadius:2,
		flexDirection:'row',
		paddingLeft:5,
		paddingRight:5
	},
	billDateTextStyle:{
		fontWeight:'bold',
		color:'#ffffff',
		fontSize:16,
		width:wp('70%')
	},
	billTopHeadingViewStyle:{
		backgroundColor:'#175491',
		paddingTop:15,
		paddingBottom:15,
		paddingLeft:10,
		paddingRight:10,
		alignItems:'center',
		justifyContent:'space-between',
		flexDirection:'row'
	},
	billoverlayMainViewStyle:{
		marginTop:20,
		marginBottom:20,
		width:wp('95%')
	},
	productQuantityViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-evenly',
		width:wp('20%')
	},
	productQuantityTextStyle:{
		color: '#4d4d4d',
		fontSize:14,
		fontWeight:'bold'
	},
	productIconStyle:{
		width:30,
		height:30,
		resizeMode:'contain'
	},
	productNameTextStyle:{
		color: '#808080',
		fontSize:14,
		fontWeight:'bold',
		marginLeft:10
	},
	productNameViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'flex-start',
		width:wp('55%'),
	},
	eachProductItemViewStyle:{
		flexDirection:'row',
		paddingTop:10,
		paddingBottom:10
	},
	addedProductViewStyle:{
		width:wp('95%')
	},
	productsViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		padding:10,
		justifyContent:'space-between'
	},
	billItemViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'center',
		width:wp('20%'),
		backgroundColor:'#175491',
		borderRadius:5,
		padding:5,
		elevation:5
	},
	businessUserItemViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		width:wp('40%')
	},
	billTextStyle:{
		fontSize:14,
		color:'#ffffff',
		fontWeight:'bold',
		paddingLeft:5
	},
	billIconStyle:{
		width:25,
		height:25,
		resizeMode:'contain',
		tintColor:'#ffffff'
	},
	employeeNameTextStyle:{
		color:'#4d4d4d',
		fontSize:12,
		paddingLeft:5,
		fontWeight:'bold',
		width:wp('30%')
	},
	employeeIconStyle:{
		width:25,
		height:25,
		resizeMode:'contain'
	},
	itemTopHeadingViewStyle:{
		flexDirection:'row',
		width:wp('60%'),
		alignItems:'center'
	},
	dateTimeTextStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#4d4d4d',
		paddingLeft:5
	},
	datetimeIconeStyle:{
		height: 20,
		width: 20,
		resizeMode:'contain',
		tintColor:'#38c6f4'
	},
	itemTopViewStyle:{
		flexDirection:'row',
		padding:10,
		borderBottomWidth:0.5,
		borderBottomColor:'#6fdde2',
		alignItems:'center',
		justifyContent:'space-between'
	},
	dateItemViewStyle:{
		width:wp('25%'),
		flexDirection:'row',
		alignItems:'center'
	},
	productMainItemViewStyle:{
		backgroundColor:'#f2f5f7',
		elevation:1,
		marginTop:5,
		marginBottom:5
	},
	renderNotFoundViewStyle:{
		flex:1,
		alignItems:'center',
		justifyContent:'center'
	},
	mainHeaderContainerViewStyle:{
		flex:1
	},
	mainViewStyle:{
		flex:1,
		backgroundColor:'#ffffff',
	},
	mainHeadingViewStyle:{
		flexDirection:'row',
		width:wp('100%'),
		padding:10,
		backgroundColor:'#f2f5f7',
		alignItems:'center',
		justifyContent:'center'
	},
	jarEntryIconStyle:{
		width:40,
		height:30,
		resizeMode:'contain'
	},
	headingTextStyle:{
		color:'#175491',
		fontSize:16,
		fontWeight:'bold',
		marginLeft:5
	},
	innerDropdownViewStyle:{
		width:wp('52%'),
		borderBottomWidth:1,
		borderBottomColor:'#b3b3b3'
	},
	dropdownViewStyle:{
		width:wp('100%'),
		paddingLeft:10,
		paddingRight:10,
		paddingTop:5,
		paddingBottom:5,
		alignItems:'center',
		justifyContent:'center'
	},
	toggleViewStyle:{
		width:wp('100%'),
		flexDirection:'row',
		alignItems:'center',
		padding:10,
		justifyContent:'space-between'
	},
	optionViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		width:wp('40%')
	},
	balanceJarIconStyle:{
		width:40,
		height:30,
		resizeMode:'contain'
	},
	showCurrentBalanceJarTextStyle:{
		color:'#38c6f4',
		fontSize:14,
		marginLeft:5,
		marginTop:5
	},
	switchTextStyle:{
		color:'#38c6f4',
		fontSize:14,
		marginLeft:5,
	},
	mainItemHeadingViewStyle:{
		width:wp('100%'),
		flexDirection:'row',
		backgroundColor:'#f5fcfc',
		padding:10,
		alignItems:'center',
		justifyContent:'space-between'
	},
	itemHeadingViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'center',
	},
	dateIconStyle:{
		height:30,
		width:40,
		resizeMode:'contain'
	},
	jarIconStyle:{
		height:25,
		width:25,
		resizeMode:'contain',
	},
	itemHeadingTextStyle:{
		textAlign:'center',
		fontSize:14,
		color:'#38c6f4',
		fontWeight:'bold',
		marginLeft:5
	},
	itemViewStyle:{
		width:wp('100%'),
		flexDirection:'row',
		padding:10, 
	},
	itemTextStyle:{
		textAlign:'center',
		fontSize:14,
		color:'#175491',
		width:wp('25%')
	},
	loadingViewStyle:{
		position: 'absolute',
		backgroundColor:'#ffffff',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	headerIconStyle:{
		height: 45,
		width: 45,
		resizeMode:'contain',
	},
	headerLeftComponentStyle:{
		flexDirection:'row',
		paddingTop:10
	},
	headerTitleTextStyle:{
		color: '#fff',
		fontSize:16,
		fontWeight:'bold',
		marginLeft:5
	},
	renderSeparatorViewStyle:{
		height: 1,
		width: '100%',
		backgroundColor: '#ffffff',
	},
	notFoundDataTextStyle:{
		marginTop:10,
		marginBottom:10,
		fontWeight:'bold',
		fontSize:18,
		textAlign:'center',
		color:'#808080',
	},
	containerStyle:{
		borderBottomWidth:null,
		backgroundColor:null,
		alignItems:'center',
		elevation:5
	},
	inTextStyle:{
		color: '#299B2E',
		fontSize:14,
		fontWeight:'bold'
	},
	outTextStyle:{
		color: '#ED2424',
		fontSize:14,
		fontWeight:'bold'
	},
	billoverlayStyle:{
		width:wp('95%'),
		padding:0,
		alignItems:'center',
		justifyContent:'center'
	},
	shareOptionOverlay:{
		width:wp('90%'),
		height:'auto',
	}
});