import React, { Component } from 'react';
import { NavigationActions } from "react-navigation";
import getEnvVars from './environment';
const { apiUrl } = getEnvVars();
import { Text, View, StyleSheet,ActivityIndicator, Image, TouchableOpacity, AsyncStorage, StatusBar, Platform} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import i18n from 'i18n-js';
import translationValues from './components/translation';
import { Ionicons, MaterialCommunityIcons,FontAwesome, MaterialIcons } from '@expo/vector-icons';
import {Linking} from 'react-native'
import logout from './components/logout';
import { CommonActions, DrawerActions } from '@react-navigation/native';
import alert from './utils/commonAlert'
import Constants from 'expo-constants';

export default class DrawerContentComponents extends Component {

	constructor(props){
		super(props)
		this.state={
			isLoading: true,
		}
		this.willFocusSubscription = ''
	}

	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			this.loadTranslation();
		});
	}
	componentWillUnmount() {
		this.willFocusSubscription();
	}

	loadTranslation =()=>{
		i18n.fallbacks = true;
		i18n.translations = translationValues;
		i18n.locale = global.isLanguage;
		this.setState({isLoading:false})
	}

	navigateToScreen = ( route ) =>(
		() => {
		this.props.navigation.dispatch(
			CommonActions.navigate({
				name:route,
				params:{
					loginCustomerData:global.userTokenData,
					business_id:null,
					showMenuicon:true
				},
			})
		)
		this.props.navigation.dispatch(DrawerActions.closeDrawer())
	})

	logoutPermission = () => {
		alert(
			i18n.t('logout'),
			i18n.t('logout_message'),
			[
				{text:i18n.t('cancel'),style:'cancel'},
				{text: i18n.t('yes'), onPress: () => logout(this.props.navigation)},
			],
			{ cancelable: false }
		)
	};

	render() {
		const { state } = this.props;
		const { routes, index } = state; 
		const focusedRoute = routes[index].name; 
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return (
				<View style={{flex:1,backgroundColor:'#ffffff'}}>
					<TouchableOpacity 
						style={styles.customerInfoViewStyle}
						onPress={this.navigateToScreen('CustomerProfile')}>
						<View style={styles.customerViewStyle}>
							<FontAwesome name="user-circle" size={45} color="#38c6f4" />
							<View>
								<Text style={styles.customerNameTextStyle}>{global.userTokenData.customer_name}</Text>
								<Text style={styles.customerPhoneNumberDetail}>{global.userTokenData.customer_phone_number}</Text>
							</View>
						</View>
						<View style={styles.editIconViewStyle}>
							<MaterialCommunityIcons name="square-edit-outline" size={40} color="#38c6f4" />
						</View>

					</TouchableOpacity>
					<View
						style={styles.businessInfoViewStyle}>
						<Image
							source={
								global.userTokenData.business_logo
								? {uri:global.userTokenData.business_logo}
								: require('./assets/images/business_logo.png')
							}
							style={styles.businessIconStyle}
						/>
						<View>
							<Text style={styles.supplierDetailTextStyle}>{i18n.t('WaterSupplierHeading')}</Text>
							<Text style={styles.businessDetailsTextStyle}>{global.userTokenData.business_name}</Text>
							<View style={styles.phoneContactStyle}>
								<MaterialIcons name="phone-in-talk" size={14} color="#38c6f4" />
								<Text  onPress={()=>Linking.openURL(`tel:${global.userTokenData.phone_number}`)} style={styles.businessPhoneDetails}>{global.userTokenData.phone_number}</Text>
							</View>
						</View>

					</View>
					<TouchableOpacity 
						style={[styles.screenStyle, (focusedRoute=='Home') ? styles.activeBackgroundColor : null]}
						onPress={this.navigateToScreen('Home')}>
						<FontAwesome name="home" style={focusedRoute=='Home' ?styles.selectedIconStyle : styles.screenIcon} size={24}/>
						<Text style={focusedRoute=='Home' ?styles.selectedTextStyle : styles.screenTextStyle}>{i18n.t('home')}</Text>
					</TouchableOpacity>
					<TouchableOpacity 
						style={[styles.screenStyle, (focusedRoute=='ChatConversation') ? styles.activeBackgroundColor : null]}
						onPress={this.navigateToScreen('ChatConversation')}>
						<Ionicons name="ios-chatbox"  style={focusedRoute=='ChatConversation' ?styles.selectedIconStyle : styles.screenIcon} size={24} />
						<Text style={focusedRoute=='ChatConversation' ?styles.selectedTextStyle : styles.screenTextStyle}>{i18n.t('messages')}</Text>
					</TouchableOpacity>
					<TouchableOpacity 
						style={[styles.screenStyle, (focusedRoute=='JarDeliveryFrequency') ? styles.activeBackgroundColor : null]}
						onPress={this.navigateToScreen('JarDeliveryFrequency')}>
						<Image
							source={require('./assets/jar_icon/delivery_frequency.png')}
							style={{width:30, height:30, resizeMode:'contain',}}
						/>
						<Text style={focusedRoute=='JarDeliveryFrequency' ?styles.selectedTextStyle : styles.screenTextStyle}>{i18n.t('jarDeliveryFrequency')}</Text>
					</TouchableOpacity>
					<TouchableOpacity 
						style={[styles.screenStyle, (focusedRoute=='ChooseLanguage') ? styles.activeBackgroundColor : null]}
						onPress={this.navigateToScreen('ChooseLanguage')}>
						<FontAwesome name="language" style={focusedRoute=='ChooseLanguage' ?styles.selectedIconStyle : styles.screenIcon} size={24}/>
						<Text style={focusedRoute=='ChooseLanguage' ?styles.selectedTextStyle : styles.screenTextStyle}>{i18n.t('choose_language')}</Text>
					</TouchableOpacity>
					{ global.userTokenData.hasOwnProperty("list_of_business_customers") && global.userTokenData.list_of_business_customers.length>1 &&
						<TouchableOpacity 
							style={[styles.screenStyle, (focusedRoute=='SwitchAccount') ? styles.activeBackgroundColor : null]}
							onPress={this.navigateToScreen('SwitchAccount')}>
							<MaterialCommunityIcons name="account-switch" size={21} style={focusedRoute=='SwitchAccount' ?styles.selectedIconStyle : styles.screenIcon} />
							<Text style={focusedRoute=='SwitchAccount' ?styles.selectedTextStyle : styles.screenTextStyle}>{i18n.t('switch_account')}</Text>
						</TouchableOpacity>
					}
					<TouchableOpacity 
						style={styles.screenStyle} 
						onPress={this.logoutPermission}>
							<Ionicons name="md-log-out" style={styles.screenIcon} size={24} />
							<Text style={styles.screenTextStyle}>{i18n.t('logout')}</Text>
					</TouchableOpacity>
				</View>
			)
		}
 	}
}

const styles = StyleSheet.create({
	screenStyle: {
		flexDirection:'row',
		padding:10,
		paddingTop:20,
		paddingBottom:20,
		borderBottomColor:'#b3b3b3',
		borderBottomWidth:0.5,
		alignItems:'center'
	},
	screenIcon:{
		color: '#175491',
		marginLeft:10
	},
	selectedIconStyle:{
		color: '#175491',
		marginLeft:10
	},
	screenTextStyle:{
		color:'#175491',
		fontSize:16,
		marginLeft:15
	},
	businessDetailsTextStyle:{
		color:'#38c6f4',
		fontWeight:'bold',
		width:wp('60%'),
		fontSize:16,
		marginLeft:10
	},
	businessPhoneDetails:{
		color:'#38c6f4',
		fontSize:14,
		marginLeft:5
	},
	customerNameTextStyle:{
		width:wp('60%'),
		color:'#175491',
		fontWeight:'bold',
		fontSize:18,
		paddingLeft:10,
	},
	customerPhoneNumberDetail:{
		color:'#175491',
		fontSize:14,
		paddingLeft:10,
	},
	selectedTextStyle: {
		color:'#175491',
		fontSize:16,
		marginLeft:15
	},
	activeBackgroundColor: {
		backgroundColor: '#f2fcfc'
	},
	customerInfoViewStyle:{
		backgroundColor:'#DBF3F6',
		paddingTop:StatusBar.currentHeight ? StatusBar.currentHeight+20 : 20,
		paddingLeft:10,
		paddingRight:5,
		marginTop: Constants.statusBarHeight
	},
	customerInfoIconStyle:{
		width:40,
		height:40,
		resizeMode:'contain',
		tintColor:'#38c6f4'
	},
	businessInfoViewStyle:{
		backgroundColor:'#f2f5f7',
		alignItems:'flex-start',
		flexDirection:'row',
		paddingTop:10,
		paddingBottom:10,
		paddingLeft:10,
		paddingRight:10
	},
	businessIconStyle:{
		width:45,
		height:45,
		resizeMode:'contain',
	},
	loadingViewStyle:{
		backgroundColor:'#ffffff',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	supplierDetailTextStyle:{
		paddingLeft:10,
		color:'#175491',
		fontSize:12
	},
	phoneContactStyle:{
		flexDirection:'row',
		alignItems:'center',
		paddingLeft:10
	},
	customerViewStyle:{
		flexDirection:'row',
		alignItems:'flex-start'
	},
	editIconViewStyle:{
		flexDirection:'row',
		justifyContent:'flex-end',
	}

});