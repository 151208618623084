import React, { Component } from 'react';
import { View, FlatList, Image, ActivityIndicator, Text, AppState,StyleSheet, TouchableOpacity, Share, Clipboard, Linking, Platform} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import i18n from 'i18n-js';
import { Header, Overlay, Avatar} from 'react-native-elements';
import ListItem from '../components/listItem'
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import getEnvVars from '../environment';
const { apiUrl } = getEnvVars();
import { Ionicons, FontAwesome, MaterialCommunityIcons} from '@expo/vector-icons';
const axios = require('axios');
import translationValues from '../components/translation';
import logout from '../components/logout';
import client from '../client';
import * as Contacts from 'expo-contacts';
import {getDeviceId} from '../getDeviceID';
import AsyncStorage from '@react-native-async-storage/async-storage'
import alert from '../utils/commonAlert'

export default class ShowAllBusiness extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allAssignedBusiness: [],
			isLoading:true,
			appState: AppState.currentState,
			showOverlay:false,
			showContactOverlay:false,
			isCameFromReferral:false,
			isAllowContactPermission:false
		};
		this.willFocusSubscription = ''
		this.appStateSubscription = ''

	}
	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
		});
		this.fetchAssignAllBusiness();
		this.appStateSubscription = AppState.addEventListener('change', this.handleAppStateChange);
	}

	componentWillUnmount() {
		this.willFocusSubscription();
		this.appStateSubscription.remove()
	}

	handleAppStateChange = async (nextAppState) => {
		if (this.state.appState.match(/inactive|background/) && nextAppState === 'active') {
			this.fetchAssignAllBusiness();
		}

		this.setState({appState:nextAppState})
	}

	fetchAssignAllBusiness = async () => {
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		const JsonLoginCustomerData = JSON.parse(loginCustomerData)
		const response = await fetch(apiUrl+'customer-request-info/'+JsonLoginCustomerData.customer_otp_verify_id+'/',{
			headers:new Headers({
				Authorization:'Token '+JsonLoginCustomerData.token
			})
		})

		const responseJsonData = await response.json();
		if(responseJsonData.success){
			AsyncStorage.getItem('loginCustomerData')
				.then( data => {
				// transform it back to an object
				data = JSON.parse( data );
				data.showing_assign_business_list = responseJsonData.showing_assign_business_list
				//save the value to AsyncStorage again
				AsyncStorage.setItem('loginCustomerData', JSON.stringify(data));
			})
			this.setState({allAssignedBusiness:responseJsonData.showing_assign_business_list,isCameFromReferral:responseJsonData.is_came_from_referral,isLoading:false})
			if(Platform.OS!=='web') this.checkImportContactAlready();
		}

	}
	checkImportContactAlready = async () =>{
		const { navigation, route, route: { params } } = this.props;
		let loginCustomerData = params.loginCustomerData;
		let deviceId = await getDeviceId();
		let urlParams = {'device_id':deviceId}
		let isDeniedImportContact = await AsyncStorage.getItem('isDeniedImportContact')
		if(isDeniedImportContact === null){
			const responseJson = await client.getPhoneContactAdded(loginCustomerData.customer_otp_verify_id, urlParams)
			if(responseJson.already_imported_contacts !== true){
				this.setState({showContactOverlay:true})
			}
		}
	}
	onShare = async () => {
		this.setState({showOverlay:false})
		try {
			const result = await Share.share({
				title:'',
				message: i18n.t('inviteContent'),
			});
		} catch (error) {
			alert(error.message);
		}
	};

	copyToClipboard = async () => {
		await Clipboard.setString(i18n.t('inviteContent'));
		this.setState({showOverlay:false})
		if(Platform.OS==='android'){
		}
		else{
			alert(
				i18n.t('copy'),
				i18n.t('copy_to_clipboard'),
				[
					{text: i18n.t('ok')},
				],
				{ cancelable: false }
			)
		}
	};
	ConvertLeadToCustomerRole = async () => {
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		const JsonLoginCustomerData = JSON.parse(loginCustomerData)
		const response = await fetch(apiUrl+'customer-request-info/'+JsonLoginCustomerData.customer_otp_verify_id+'/?is_role_customer='+true,{
			headers:new Headers({
				Authorization:'Token '+JsonLoginCustomerData.token
			})
		})
		const responseJsonData = await response.json();
		if(responseJsonData.success){
			if(responseJsonData.user_loggedin_data.role==='customer'){
				alert(
					i18n.t('customer'),
					i18n.t('becomeBusinessCustomerSuccessMessage'),
					[
						{text: i18n.t('ok')},
					],
					{ cancelable: false }
				)
				if(responseJsonData.user_loggedin_data.list_of_business_customers.length>1){
					this.props.navigation.replace('ShowCustomerBusiness',{
						loginCustomerData:responseJsonData.user_loggedin_data,
					})
				}
				else{
					AsyncStorage.getItem('loginCustomerData')
						.then( data => {
						// transform it back to an object
						data = JSON.parse( data );
						data.business_id = responseJsonData.user_loggedin_data.list_of_business_customers[0].business_id
						data.customer_business_id = responseJsonData.user_loggedin_data.list_of_business_customers[0].customer_business_id
						data.business_name = responseJsonData.user_loggedin_data.list_of_business_customers[0].business_name
						data.business_address = responseJsonData.user_loggedin_data.list_of_business_customers[0].business_address
						data.phone_number = responseJsonData.user_loggedin_data.list_of_business_customers[0].phone_number
						data.customer_name = responseJsonData.user_loggedin_data.list_of_business_customers[0].customer_name
						data.role=responseJsonData.user_loggedin_data.role
						data.customer_phone_number=responseJsonData.user_loggedin_data.customer_phone_number
						//save the value to AsyncStorage again
						AsyncStorage.setItem('loginCustomerData', JSON.stringify(data));
						global.userTokenData = data
						this.props.navigation.replace('CustomerStackScreen',{
							screen:'CustomerScreen',
							params:{
								screen: 'Home', 
								params:{loginCustomerData:data},
							}
						});
					})
					
				}
			}
		}
	}

	approveCustomerRequest = (business_id, business_name) => {
		alert(
			'GoPaani',
			i18n.t('approveBusinessMessage', {businessName:business_name}),
			[	
				{text:i18n.t('cancel'),style:'cancel'},
				{text:i18n.t('yes'),onPress:()=>this.sendApproveRequest(business_id)}
			],
			{ cancelable: false }
		)
	};
	addGoPaaniSupportContact =  async () => {
		const contact = {
			[Contacts.Fields.FirstName]: 'GoPaani',
			[Contacts.Fields.LastName]: 'Support',
			[Contacts.Fields.PhoneNumbers]: [{ label : "mobile",
			number: "9009467264"},{ label : "mobile",number: "9174965746"}]
		}
		const { status } = await Contacts.requestPermissionsAsync();
		if (status === 'granted') {
			this.setState({isAllowContactPermission:true}, ()=> this.toStoreDataInContact())
			await Contacts.addContactAsync(contact)
			.then((contactId) => {
			})
			.catch((err) => {
				alert(err);
			});
		}else{
			await AsyncStorage.setItem('isDeniedImportContact', JSON.stringify(true))
			this.setState({isDeniedImportContact:true})
		}
		this.setState({showContactOverlay:false})
	}
	toStoreDataInContact = async () => {
		const { navigation, route, route: { params } } = this.props;
		let loginCustomerData = params.loginCustomerData;
		if(this.state.isAllowContactPermission === true){
			this.storePhoneContactIndb();
		}
	}
	/* storing the  phone contact to our database */
	storePhoneContactIndb = () => {
		const { navigation, route, route: { params } } = this.props;
		let loginCustomerData = params.loginCustomerData;
		Contacts.getContactsAsync().then(({data}) => {
			let contactDetails = [];
			for (var i=0; i<data.length; i++){
				try{
					let phone_number = data[i].phoneNumbers[0].number.replace(/\s/g,'').replace(/-/g,'')
					let without91 = phone_number.replace('+91','')
					if(without91.length === 10){
						contactDetails.push({
							name: data[i].name,
							phone_number:phone_number,
							
						});
					}
				}
				catch{
					continue;
				}
			}
			getDeviceId().then((deviceId) => {
				let requestBody = {device_id:deviceId,phone_contact:contactDetails}
				const responseJson = client.storePhoneContactAPI(loginCustomerData.customer_otp_verify_id, requestBody)
			}).catch((err) => {
				alert(err);
			});
			
		}).catch((err) => {
			alert(err)
		});
	}

	sendApproveRequest = async (business_id) => {
		this.setState({isLoading:true})
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		const JsonLoginCustomerData = JSON.parse(loginCustomerData)
		try{
			const response = await axios.patch(apiUrl+'customer-request-info/'+JsonLoginCustomerData.customer_otp_verify_id+'/',{
					business_id:business_id
				},
				{
					headers:{
						Authorization:'Token '+JsonLoginCustomerData.token
					}
				}
			);
			if(response.data.success){
				this.ConvertLeadToCustomerRole();
			}
			else{
				this.setState({isLoading:false})
				console.log(response.data.errors)
			}
		}
		catch(err){
			console.log(err);
		}
	}

	sendRequestMore = async () => {
		this.setState({isLoading:true})
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		const JsonLoginCustomerData = JSON.parse(loginCustomerData)
		try{
			const response = await axios.post(apiUrl+'customer-request-info/'+JsonLoginCustomerData.customer_otp_verify_id+'/',{
					is_request_more:true
				},
				{
					headers:{
						Authorization:'Token '+JsonLoginCustomerData.token
					}
				}
			);
			if(response.data.success){
				this.setState({isLoading:false});
				alert(
					i18n.t('customer'),
					i18n.t('sendRequestMoreSuccessMessage'),
					[
						{text: i18n.t('ok')},
					],
					{ cancelable: false }
				)
			}
			else{
				console.log(response.data.errors)
			}
		}
		catch(err){
			console.log(err);
		}
	}

	renderSeparator = () => {
		return (
			<View style={styles.renderSeparatorViewStyle}/>
		);
	};

	renderNotFound = () => {
		const { navigation, route, route: { params } } = this.props;
		return(
			<View style={styles.renderNotFoundViewStyle}>
				{params.isNotServeArea === true
					?	<Text style={styles.ShowMessageTextStyle}>{i18n.t('notserveAreaMessage')}</Text>
					:	<Text style={styles.ShowMessageTextStyle}>{i18n.t('sendRequestToWaterSupplierMessage')}</Text>
				}
				<Image style={{width:320, height:200, resizeMode:'contain'}}  source={require('../assets/images/sent-request-graphics.png')}/>
				<View style={styles.inviteViewStyle}>
					<TouchableOpacity
						style={styles.inviteButtonStyle}
						activeOpacity={0.5}
						onPress={()=> this.setState({showOverlay:true})}>
						<Text style={styles.inviteTextStyle}>{i18n.t('inviteWaterSupllierMessage')}</Text>
					</TouchableOpacity>
				</View>

			</View>
		);
	}

	renderHeader = () => {
		return(
			<Text style={styles.headingTextStyle}>{i18n.t('whatSupplierHeading')}</Text>
		)
	}

	renderFooter = () => {
		if(!this.state.isCameFromReferral && this.state.allAssignedBusiness.length > 0){
			return (
				//Footer View with request More button
				<View style={styles.footer}>
					<TouchableOpacity style={styles.btnSend} onPress={()=>this.sendRequestMore()}>
					  <Image style={{width:20, height:20, resizeMode:'contain'}}  source={require('../assets/images/request-more.png')}/>
					  <Text style={styles.requestMoreTextStyle}>{i18n.t('requestMore')}</Text>
					</TouchableOpacity>
				</View>
			);
		}
		else{
			return null
		}
	};

	logoutPermission = () => {
		alert(
			i18n.t('logout'),
			i18n.t('logout_message'),
			[
				{text:i18n.t('cancel'),style:'cancel'},
				{text: i18n.t('yes'), onPress: () => logout(this.props.navigation)},
			],
			{ cancelable: false }
		)
	};

	render() {
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return (
				<View style={{flex:1, backgroundColor:'#ffffff'}}>
					<Header
						statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						centerComponent={<Image resizeMode='cover' style={styles.headerIconStyle}  source={require('../assets/images/small_logo.png')}/>}
						ViewComponent={LinearGradient}
						centerContainerStyle={{justifyContent:'center'}}
						rightContainerStyle={{justifyContent:'center'}}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
						rightComponent={<View style={{flexDirection:'row', alignItems:'center'}}> 
								<TouchableOpacity
									style={styles.logoutButtonStyle}
									activeOpacity={0.5}
									onPress={this.logoutPermission}
									>
									<Ionicons name={'md-log-out'} color={'#ffffff'} size={32} />
								</TouchableOpacity>
							</View>
						}
					/>
					<FlatList 
						data={this.state.allAssignedBusiness} 
						renderItem={({item}) => {
							return(
								<ListItem
									leftAvatar={ 
										<Avatar 
											rounded={true} size={40} 
											source={require('../assets/images/business_logo.png')}
										/> 
									}
									titleElement={<Text>{item.business_name}</Text>}
									subtitleElement={<Text style={styles.subtitleTextStyle}  onPress={()=> Linking.openURL(`tel:${item.phone_number}`)}>{item.phone_number}</Text>}
									titleStyle={styles.businessNameTextStyle}
									rightElement={
										<TouchableOpacity
											onPress={()=>this.props.navigation.navigate('ChatConversation',{
											business_id:item.business_id,
										})}
											activeOpacity={0.5}>
											<MaterialCommunityIcons name={"chat-processing"} size={32} color="#408efc" />
										</TouchableOpacity>
									}
									rightTitleElement={
										item.has_requested===true
										?	<TouchableOpacity
												style={styles.ApproveButtonStyle}
												activeOpacity={0.5}
												onPress={()=>this.approveCustomerRequest(item.business_id, item.business_name)}
												>
												<Text style={styles.approveTextStyle}>{i18n.t('approveButton')}</Text>
											</TouchableOpacity>
										: 	null
									}
								/>
							)}
						}
						contentContainerStyle={{ flexGrow: 1 }}
						ListEmptyComponent={this.renderNotFound}
						ListHeaderComponent={this.renderHeader}
						keyExtractor={(item, index) => index.toString()}
						extraData={this.state}
						ListFooterComponent={this.renderFooter}
						ItemSeparatorComponent={this.renderSeparator}
					/>
					<Overlay
						isVisible={this.state.showOverlay}
						overlayStyle={styles.overlayStyle}
						onBackdropPress={() =>this.setState({showOverlay:false})}
					>
						<View style={styles.overlayViewStyle}>
							<TouchableOpacity style={styles.crossButtonViewStyle}
								activeOpacity={0.5}
								onPress={()=> this.setState({showOverlay:false})}>
								<FontAwesome name="close" size={28} color="#175491" style={styles.crossIconStyle} />
							</TouchableOpacity>
							<View style={styles.overlayInsideViewStyle}>
								<Image
									source={require('../assets/images/popup-graphics.png')}
									style={styles.popupGraphicIconStyle}
								/>
								<Text style={styles.shareTextStyle}>{i18n.t('inviteSupllierHeading')}</Text>
								<View style={styles.shareTextViewStyle}>
									<Text style={styles.messageTextStyle}>{i18n.t('inviteContent')}</Text>
									<View style={styles.shareIconViewStyle}>
										<TouchableOpacity onPress={()=>this.onShare()}>
											<Ionicons name='md-share' size={28} color='#175491'/>
										</TouchableOpacity>
										<TouchableOpacity style={styles.clipboardIconStyle} onPress={()=>this.copyToClipboard()}>
											<FontAwesome name='clone' size={28} color='#175491'/>
										</TouchableOpacity>
									</View>
								</View>
							</View>
						</View>
					</Overlay>
					<Overlay
						isVisible={this.state.showContactOverlay}
						onBackdropPress={() => this.setState({showContactOverlay:false})}
						width={wp('90%')}
						height={hp('35%')}>
						<View>
							<TouchableOpacity style={styles.crossButtonStyle}
								activeOpacity={0.5}
								onPress={()=> this.setState({showContactOverlay:false})}>
								<FontAwesome name="close" size={24} color="#b3b3b3" style={styles.crossStyleIcon} />
							</TouchableOpacity>
							<View style={styles.overlayInsideViewStyle}>
								<Image
									source={require('../assets/images/customer-support.png')}
									style={styles.customerSupportIconStyle}
								/>
								<Text style={styles.supportAddContactTextStyle}>{i18n.t('addSupportContactText')}</Text>
								<TouchableOpacity
									style={styles.nextButtonTouchableStyle}
									onPress={()=>this.addGoPaaniSupportContact()}>
									<Text style={styles.nextButtonTextStyle}>{i18n.t('next')}</Text>
								</TouchableOpacity>
							</View>
						</View>
					</Overlay>
					
				</View>
			);
		}
	};
}

const styles = StyleSheet.create({
	subtitleTextStyle:{
		lineHeight:30,
		textDecorationLine: 'underline'
	},
	overlayViewStyle:{
		backgroundColor:'#F4F8F9'
	},
	overlayStyle:{
		padding:0,
		margin:0,
		width:wp('80%'),
		height:'auto'
	},
	crossIconStyle:{
		backgroundColor:'#ffffff',
		elevation:5,
		alignItems:'center',
		width:30,
		height:30,
		borderRadius:30/2,
		justifyContent:'center',
		paddingLeft:5
	},
	crossButtonViewStyle:{
		justifyContent:'flex-end',
		alignItems:'flex-end',
		marginLeft:-5,
		marginRight:-5,
		marginTop:-10
	},
	shareTextStyle:{
		color:'#38c6f4',
		fontSize:16,
		textAlign:'center',
		fontWeight:'bold',
	},
	overlayInsideViewStyle:{
		justifyContent:'center',
		alignItems:'center'
	},
	popupGraphicIconStyle:{
		width:157.5,
		height:140,
		resizeMode:'contain'
	},
	clipboardIconStyle:{
		marginLeft:20
	},
	shareIconViewStyle:{
		flexDirection:'row',
		justifyContent:'flex-end',
		marginTop:10,
		marginBottom:10
	},
	messageTextStyle:{
		color:'#808080',
		fontSize:14,
		marginBottom:10
	},
	shareTextViewStyle:{
		padding:10,
		borderRadius:10
	},
	inviteTextStyle:{
		color:'#175491',
		fontSize:14,
		fontWeight:'bold'
	},
	inviteViewStyle:{
		marginTop:10,
		marginBottom:10,
		alignItems:'center',
		justifyContent:'center'
	},
	logoutButtonStyle:{
		marginRight:10
	},
	approveTextStyle:{
		color:'#38c6f4',
		fontSize:12,
		fontWeight:'bold',
		textAlign:'center'
	},
	inviteButtonStyle:{
		flexDirection:'row',
		borderWidth:1,
		borderColor:'#175491',
		padding:5,
		borderRadius:5,
		alignItems:'center',
		justifyContent:'center',
		marginTop:15
	},
	ApproveButtonStyle:{
		flexDirection:'row',
		borderWidth:1,
		borderColor:'#38c6f4',
		padding:5,
		width:65,
		borderRadius:5,
		alignItems:'center',
		justifyContent:'center'
	},
	headerIconStyle:{
		height: 45,
		width: 45,
		resizeMode:'contain',
	},
	containerStyle:{
		borderBottomWidth:null,
		backgroundColor:null,
		alignItems:'center'
	},
	loadingViewStyle:{
		position: 'absolute',
		backgroundColor:'#ffffff',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	headingTextStyle:{
		padding:15,
		color:'#808080',
		fontSize:16,
		fontWeight:'bold'
	},
	ShowMessageTextStyle:{
		color:'#175491',
		fontSize:16,
	},
	renderNotFoundViewStyle:{
		paddingBottom:10,
		paddingLeft:15,
		paddingRight:15,
		alignItems:'center',
		justifyContent:'center'
	},
	renderSeparatorViewStyle:{
		height: 1,
		width: '100%',
		backgroundColor: '#ced0ce',
	},
	footer:{
		padding: 10,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
	},
	btnSend:{
		backgroundColor:"#175491",
		height:45,
		borderRadius:10,
		padding:10,
		alignItems:'center',
		justifyContent:'center',
		borderWidth:1,
		flexDirection:'row',
		borderColor:'#175491'
	},
	requestMoreTextStyle:{
		fontSize:16,
		color:'#ffffff',
		fontWeight:'bold',
		textAlign:'center',
		marginLeft:5
	},
	businessNameTextStyle:{
		fontSize:16,
		color:'#175491',
		fontWeight:'bold'
	},
	crossButtonStyle:{
		justifyContent:'flex-end',
		alignItems:'flex-end',
		marginRight:-15,
		marginTop:-20
	},
	crossStyleIcon:{
		backgroundColor:'#ffffff',
		elevation:5,
		alignItems:'center',
		width:28,
		height:28,
		borderRadius:28/2,
		justifyContent:'center',
		textAlign:'center'
	},
	customerSupportIconStyle:{
		width:45,
		height:45,
		resizeMode:'contain'
	},
	supportAddContactTextStyle:{
		textAlign:'center',
		fontSize:14,
		fontWeight:'bold',
		color:'#175491',
		padding:20,
		paddingTop:10
	},
	nextButtonTouchableStyle:{
		alignItems:"center",
		marginTop:10,
		backgroundColor:"#175491",
		width:wp('50%'),
		padding:10,
		borderRadius:5
	},
	nextButtonTextStyle:{
		fontWeight:'bold',
		color:'#ffffff',
		fontSize:16,
		textAlign:'center'
	}
  
});  
