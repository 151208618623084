import { Component } from 'react';
import { View, Image, Text, StyleSheet, TouchableOpacity, ActivityIndicator, FlatList, Platform} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import {Header, SearchBar} from "react-native-elements";

import {FontAwesome, Entypo} from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';

import i18n from 'i18n-js';
import {format} from 'date-fns';

import translationValues from '../components/translation';
import {constantValues} from '../utils/helpers';
import {clientBusinessApi} from '../clientBusinessApi';
import {showErrorAlertStatusCode} from '../utils/showErrorAlertStatusCode';
import {showTechnicalError} from '../utils/showTechnicalError';


export default class AllPurchaseOrderScreen extends Component {
	/* This screen is to show all/select item for order and place purchase order */

	constructor(props) {
		super(props);
		this.state = {
			isLoading:true,
			customerAllPurchaseOrderArray:[],
			showLoading:false,
		};
		this.willFocusSubscription = ''
	}

	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			Platform.OS==='web' && window.history.replaceState(null,null,'AllPurchaseOrderScreen')
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
			// this.fetchAllBusinessItem();
		});
		this.fetchCustomerAllPurchaseOrders();
	}

	componentWillUnmount() {
		this.willFocusSubscription();
	}

	fetchCustomerAllPurchaseOrders = async ()=>{
		const { navigation, route:{params:{loginCustomerData}}} = this.props;
		const apiParams = {customer_business_id:loginCustomerData.customer_business_id};
		const response = await clientBusinessApi.getAllCreatedOrders(loginCustomerData.business_id, apiParams);
		if(response.data && response.data.success === true){
			const responseData = response.data;
			this.setState({isLoading:false, customerAllPurchaseOrderArray:responseData.customer_orders_list});
		}
		else if(response.status){
			showErrorAlertStatusCode(response.status, navigation);
		}
		else{
			showTechnicalError();
		}
	}

	renderPurchaseOders = ({item, index})=>{
		return(<View>
			<View style={{flexDirection:'row', paddingLeft:10, paddingRight:8, paddingVertical:10, justifyContent:'space-between', paddingBottom:8, backgroundColor:'#D5F7F0', backgroundColor:'#175491'}}>
				<View>
					<Text style={{ color:'#ffffff', fontWeight:'bold', marginBottom:5}}>{format(item.delivery_datetime, "DD MMM'YY")}</Text>
					<Text style={{color:'#ffffff'}}>{format(item.delivery_datetime, "hh:mm A")}</Text>
				</View>
				<View>
					<View style={{flexDirection:'row', alignItems:'center'}}>
						<Image
							source={item.image_url
								?{uri:item.image_url}
								:item.role === 'staff'
								?require('../assets/images/employeeWithCircle.png')
								:item.role === 'owner'
								?require('../assets/images/business_logo.png')
								:require('../assets/images/customer-profile.png')
							}
							style={styles.purchaseOrderCreatedByIcon}
						/>
						<Text style={{marginLeft:5, marginBottom:5, color:'#ffffff', fontWeight:'bold'}}>{item.order_by}</Text>
					</View>
					<Text style={{color:'#ffffff', textAlign:'right'}}>{item.order_status}</Text>
				</View>
			</View>
			<View style={styles.tableColumnHeadingContainer}>
				<Text style={styles.tableColumnHeading}>{i18n.t('productName')}</Text>
				<Text style={styles.tableColumnHeading}>{i18n.t('quantity')}</Text>
			</View>
			{item.product_info.map((purchaseItem, index)=>{
				return(<View style={styles.perDeliveryJarViewStyle} key={index}>
					<View style={styles.perDeliveryIconTextViewStyle}>
						<View style={styles.productIconContainer}>
							<Image source={purchaseItem.image_url ?{uri:purchaseItem.image_url} :require('../assets/jar_icon/default-item-icon.png')} style={styles.productIconStyle}/>
						</View>
						<Text style={styles.productNameStyle}>{purchaseItem. user_facing_name ? purchaseItem.user_facing_name : purchaseItem.item_name}</Text>
					</View>
					<Text style={styles.productQuantity}>{purchaseItem.product_quantity} </Text>
				</View>)
			})}
			<View style={styles.totalProductsRow}>
				<Text style={styles.totalProductsStyle}>{i18n.t('totalProduct')}</Text>
				<Text style={styles.totalProductsStyle}>{item.total_products}</Text>
			</View>
			{item.advance_amount > 0
				? <View style={styles.advancePaymentView}>
					<Text style={styles.advancetext}>{i18n.t('advancePayment')}</Text>
					<Text style={styles.advanceAmount}>{'₹ '+ item.advance_amount}</Text>
				</View>
				: null
			}
			<View style={styles.horizontalBorder}/>
			{item.comment
				? <View style={styles.commentView}>
					<Text style={styles.commentText}>{i18n.t('commentText')}</Text>
					<Text style={styles.commentData}>{item.comment}</Text>
				</View>
				: null
			}
		</View>)
	}

	renderFooter = ()=>{
		return(<View
				style={{paddingVertical:10, backgroundColor:'#175491', alignItems:'center'}}
			>
			<TouchableOpacity style={{flexDirection:'row', padding:8, borderRadius:5, borderWidth:1, borderColor:'#ffffff', justifyContent:'center', alignItems:'center'}} onPress={()=>this.setState({isPurchaseOrderItem:true, width:wp('50%')})}>
				<Text style={{color:'#ffffff', marginRight:5, fontWeight:'bold', fontSize:16}}>{i18n.t('showMore')}</Text>
				<Entypo name="chevron-down" size={28} color="#ffffff" />
			</TouchableOpacity>
		</View>)
	}

	notexistPurchaseOrder = ()=>{
		return(<View style={{alignItems:'center', padding:20}}>
			<Image 
				source={require('../assets/images/not-found.png')}
				style={styles.notFoundIcon}
			/>
			<Text style={{fontSize:20,color:'#175491',fontWeight:'bold', textAlign:'center', paddingTop:10}}>{i18n.t('noOrderFound')}</Text>
		</View>)
	}


	render() {
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else {
			return (
				<View style={styles.screenMainContainer}>
					<Header
						statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						leftContainerStyle={{justifyContent:'center'}}
						leftComponent={<TouchableOpacity
							style={styles.leftHeaderContainer}
							onPress={() => this.props.navigation.goBack()}
							activeOpacity={0.5}>
							<FontAwesome name='chevron-left' color='#38c6f4' size={28} />
						</TouchableOpacity>}
						centerComponent={<Image resizeMode='cover' style={styles.headerIconStyle}  source={require('../assets/images/small_logo.png')}/>}
						centerContainerStyle={{justifyContent:'center'}}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:constantValues.gradientCommonColorsArray, start:{x: 0, y: 0}, end:{x: 1, y: 0} }}
					/>
					<Text style={{marginLeft:10, backgroundColor:'#ffffff', color:'#175491', fontWeight:'bold', fontSize:18, paddingVertical:10}}>{i18n.t('allOrder')}</Text>
					<FlatList
						data={this.state.customerAllPurchaseOrderArray}
						keyExtractor={(item,index)=> index.toString()}
						renderItem={({item, index})=>{
							return(<FlatList
								data={item.customer_order}
								renderItem={this.renderPurchaseOders}
								keyExtractor={(item,index)=> index.toString()}
							/>);
						}}
						ListEmptyComponent={this.state.customerAllPurchaseOrderArray.length < 1 ?this.notexistPurchaseOrder :null}
					/>
				</View>
			)
		}
	}
};

const styles = StyleSheet.create({
	leftHeaderContainer:{
		paddingLeft:5,
		paddingVertical:10,
		justifyContent:'flex-start',
		width:50,
	},
	headerTitleTextStyle:{
		color: '#fff',
		fontSize:16,
		fontWeight:'bold',
		marginLeft:5
	},
	headerIconStyle:{
		height: 45,
		width: 45,
		resizeMode:'contain',
	},
	containerStyle:{
		borderBottomWidth:null,
		backgroundColor:null,
		alignItems:'center',
		elevation:5
	},
	headerRightContainer:{
		borderRadius:5,
		borderWidth:1,
		borderColor:'#ffffff',
		paddingVertical:8,
		paddingHorizontal:5,
		marginTop:-22,
		width:110,
	},
	rightHeaderTitle:{
		color: '#fff',
		fontSize:16,
		fontWeight:'bold',
	},
	loadingViewStyle:constantValues.loadingViewStyle,
	purchaseOrderCreatedByIcon:{
		width:20,
		height:20,
		resizeMode:'contain',
		backgroundColor:'#ffffff',
		borderRadius:20,
	},
	tableColumnHeadingContainer:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		borderBottomWidth:0.5,
		borderBottomColor:'#38C6F4',
		backgroundColor:"#E4F2F4",
		paddingHorizontal:10,
		paddingVertical:15,
	},
	tableColumnHeading:{
		fontSize:18,
		fontWeight:'bold',
		color:'#38C6F4',
		paddingLeft:5,
	},
	productQuantity:{
		fontSize:16,
		fontWeight:'bold',
		color:'#175491',
		width:wp('10%'),
		textAlign:'right'
	},
	totalProductsStyle:{
		marginLeft:10,
		paddingVertical:8,
		color:'#175491',
		fontWeight:'bold',
		fontSize:18,
	},
	totalProductsRow:{
		flexDirection:'row',
		paddingRight:10,
		paddingLeft:3,
		justifyContent:'space-between',
		paddingVertical:10,
		backgroundColor:'#F2FCFC',
	},
	perDeliveryJarViewStyle:{
		paddingTop:15,
		paddingBottom:15,
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		borderBottomWidth:0.5,
		borderBottomColor:'#38C6F4',
		paddingHorizontal:10,
	},
	perDeliveryIconTextViewStyle:{
		flexDirection:'row',
		alignItems:'center'
	},
	productIconContainer:{
		borderRadius:15,
		padding:5,
		borderWidth:1,
		borderColor:"#38c6f4",
	},
	productIconStyle:{
		width:20,
		height:20,
		resizeMode:'contain'
	},
	productNameStyle:{
		fontSize:16,
		fontWeight:'bold',
		color:'#175491',
		width:wp('40%'),
		marginLeft:5,
	},
	iconAndTitleContainer:{
		flexDirection:'row',
		alignItems:'center',
	},
	placeOrderUIContainer:{
 		backgroundColor:'#ffffff',
 		paddingHorizontal:10,
 		paddingVertical:5,
 		flexDirection:'row',
 		justifyContent:'space-between',
 		alignSelf:'center',
 		alignItems:'center',
 		width:wp('93%'),
 		borderRadius:5,
 		borderColor:'#ffffff',
 		elevation:5,
 		shadowColor: '#3d3d3d',
	 	shadowRadius: 2,
	 	shadowOpacity: 0.5,
		shadowOffset: {
			height: 1,
		},
		marginTop:10,
 	},
 	titleTextStyle:{
 		color:'#175491',
 		fontSize:13,
 	},



	screenMainContainer:{
		flex:1,
		backgroundColor:'#ffffff'	
	},
	perDeliveryIconTextViewStyle:{
		flexDirection:'row',
		alignItems:'center'
	},
	datePickerStyle:{
		justifyContent:'center',
		alignItems:'center',
	},
	datePickerViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-evenly',
		marginBottom:10,
		marginTop:10,
	},
	datePickerTouchableStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'center',
		paddingTop:10
	},
	jarEntryDateTextStyle:{
		fontSize: 18,
		paddingBottom:5,
		color: "#38c6f4",
		marginLeft:5,
		borderBottomWidth:1,
		borderBottomColor:'#38c6f4',
	},
	notFoundIcon:{
		width:120, 
		height:120, 
		resizeMode:'contain'
	},
	advancePaymentView:{
		flexDirection:'row',
		justifyContent:'space-between',
		alignItems:'center',
		paddingVertical:20,
		paddingHorizontal: 10 ,
		borderTopWidth:1, 
		borderColor:'#38c6f4',
	},
	advancetext:{
		width:wp('70%'), 
		fontWeight:'bold', 
		fontSize:16, 
		color:'#175491'
	},
	advanceAmount:{
		width:wp('27%'), 
		textAlign:'right', 
		fontWeight:'bold', 
		fontSize:16, 
		color:'#175491',
		paddingRight:10,
	},
	horizontalBorder:{
		borderBottomColor:'#38c6f4', 
		borderBottomWidth:1,
	},
	commentText:{
		fontWeight:'bold',
		color:'#175491',
		fontSize:16
	},
	commentData:{
		color:'#175491',
		fontSize:16,
		paddingTop:5
	},
	commentView:{
		padding:10,
		borderBottomColor:'#38c6f4', 
		borderTopColor:'#38c6f4', 
		borderBottomWidth:1,
	},
});

AllPurchaseOrderScreen.defaultProps = {
	placeOrderUIContainer:styles.placeOrderUIContainer,
	titleTextStyle:styles.titleTextStyle,
	transportIcon:styles.transportIcon,
	iconAndTitleContainer:styles.iconAndTitleContainer,
}