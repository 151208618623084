import React from "react";
import getEnvVars from '../environment';
const { apiUrl,businessApiUrl } = getEnvVars();
import { View, Text, ActivityIndicator, FlatList, TouchableOpacity,Alert, Image, TextInput, Platform } from "react-native";
import {Overlay, Header} from 'react-native-elements';
import {format} from 'date-fns';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { FontAwesome ,MaterialCommunityIcons, Entypo, Ionicons, Feather} from '@expo/vector-icons';
import i18n from 'i18n-js';
import * as MediaLibrary from 'expo-media-library';
import * as Permissions from 'expo-permissions';
import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import { LinearGradient } from 'expo-linear-gradient';
import styles from '../style/showpaymentBillLedger.styles';
import translationValues from '../components/translation';
import logout from '../components/logout';
import {BillPdfGenerateWithOption} from '../components/billPdfGenerateWithOption';
import Tooltip from 'react-native-walkthrough-tooltip';
import BalanceAndDepositView from '../components/BalanceAndDepositView';
import AsyncStorage from '@react-native-async-storage/async-storage'
import alert from '../utils/commonAlert'

export default class ShowPaymentBillLedger extends React.Component{
	constructor(props){
		super(props);
		this.state={
			isLoading:true,
			pastPaymentsAndBillList:[],
			isProductDelivered:false,
			showShareOptionOverlay:false,
			isForDownload:'download',
			isForSharing:'share',
			isForPrint:'print',
			billDate:'',
			productItemActiveIndex:null,
			commentToolTipVisible:false,
			activeIndex:null,
			depositAmount:'',
			balanceAmount:'',
			billAmount:'',
			runningAmountDate:'',
		}
		this.willFocusSubscription='';
	}

	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			Platform.OS==='web' && window.history.replaceState(null,null,"/ShowPaymentBillLedger")
			i18n.locale = global.isLanguage;
			this.fetchPastPaymentAmount().catch(error => {
				console.log(error)
			});
		});
	}
	componentWillUnmount(){
		this.willFocusSubscription();
	}

	fetchPastPaymentAmount = async () => {
		this.setState({isLoading:true})
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		let loginCustomerDataJson = JSON.parse(loginCustomerData);
		const response = await fetch(
			businessApiUrl+'show-past-customer-payment/'+loginCustomerDataJson.business_id+'/'+loginCustomerDataJson.customer_business_id+'/'+'?new_generic_version_request_v2='+true+'&show_invoice_amount_in_ledger='+true+'&date_wise_discount_extra_charges_flag='+true ,{
				headers:new Headers({
					Authorization:'Token '+loginCustomerDataJson.token 
				})
			}
		);

		const past_payment_data = await response.json();
		if (response.status === 401) {
			alert(
				i18n.t('customer'),
				i18n.t('permission_to_perform'),
				[
					{text: i18n.t('ok'), onPress: () => logout(this.props.navigation)},
				],
				{ cancelable: false }
			)
		}
		else{
			if(past_payment_data.success){
				this.setState({pastPaymentsAndBillList:[{}, ...past_payment_data.sorted_customer_payment_bill_ledger_history_list],minDate:format(past_payment_data.past_payment_entry_limit_date,'D-MMM-YYYY'), isLoading:false,removePreviousButton:past_payment_data.remove_previous_button,removeNextButton: past_payment_data.remove_next_button, customerName:past_payment_data.customer_name, minDeletePaymentDate:past_payment_data.past_payment_delete_entry_limit_date,isCustomerActive:past_payment_data.is_customer_active,isDailyPaymentFrequency:past_payment_data.is_daily_payment_frequency,isProductDelivered:past_payment_data.is_product_delivered,depositAmount:past_payment_data.total_paid_amount,balanceAmount:past_payment_data.current_payment_due, billAmount:past_payment_data.total_invoice_amount, runningAmountDate:past_payment_data.running_amount_date});

			}
			else{
				this.setState({isLoading:false})
				alert(
					i18n.t('payment'),
					past_payment_data.message,
					[
						{text: i18n.t('ok'), onPress: () => logout(this.props.navigation)},
					],
					{ cancelable: false }
				)
			}
		}
	}
	shareDownloadPDF = async (shareOption) => {
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		let loginCustomerDataJson = JSON.parse(loginCustomerData);
		let generateUrl = `${businessApiUrl}products/${loginCustomerDataJson.business_id}/bill/${loginCustomerDataJson.customer_business_id}/?date_string=${this.state.billDate}&format_type=a4&bill_option=single_day&token=${loginCustomerDataJson.token}&new_generic_version_request_v2=${true}`
		BillPdfGenerateWithOption(loginCustomerDataJson.token, generateUrl, shareOption)
		this.setState({showShareOptionOverlay:false})
	}
	numberWithCommas = (number) => {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	renderSeparator = () => {
		return (<View style={styles.renderSeparatorViewStyle}/> );
	};
	renderNotFound = () => {
		return(
			<View style={styles.notFoundViewStyle}>
				<Text style={styles.noPastPaymentTextStyle}>{i18n.t('no_past_payment')}</Text>
			</View>
		)
	};
	renderHeader = () => {
		let maxDate = format(new Date,'D-MMM-YYYY')
		return(
			<View>
				<BalanceAndDepositView
					billAmount = {this.state.billAmount}
					balanceAmount = {this.state.balanceAmount}
					depositAmount = {this.state.depositAmount}
					showTotalInvoiceAmount={true}
				/>
				<View style={styles.mainHeadingViewStyle}>
					<Image 
						source={require('../assets/images/payment-add.png')} 
						style={styles.jarEntryIconStyle}
					/>
					<Text style={styles.headingTextStyle}>{i18n.t('paymentBillLedger')}</Text>
				</View>
			</View>
		)
	};
	
	renderProductDeliveredCustomersItem = ({item, index}) => {
		return(
			<View>
				{this.state.productItemActiveIndex === index && this.state.isDailyPaymentFrequency && item.is_payment_data === false
					? null
					: <View style={styles.line} />
				}
				{index === 0 
				? <View style={styles.ledgerHeadingViewStyle}>
					<Text style={styles.dateHeadingStyle}>{i18n.t('date')}</Text>
					<Text style={styles.invoiceText}>{`${i18n.t('invoiceAmount')}(₹)`}<Text style={styles.subHeadingTextStyle}>{`(${i18n.t('debit')})`}</Text></Text>
					<Text style={styles.productItemHeadingStyle}>{`${i18n.t('paymentGivenHeading')}(₹)\n`}<Text style={styles.subHeadingTextStyle}>{`(${i18n.t('creditText')})`}</Text></Text>
					<Text style={styles.runningBalanceStyle}>{`${i18n.t('runningBalance')}(₹) \n`}<Text style={styles.subHeadingTextStyle}>{` (${i18n.t('runningBalanceDate',{date:`${format(this.state.runningAmountDate, "MMM'YY")}`})})`}</Text></Text>
					<View style={styles.arrowItemViewStyle} />
				</View>
				: <View style={[styles.itemsContainerView,{backgroundColor: item.is_payment_data ? "#ffffff" : "#f2f5f7" ,}]}>
					<TouchableOpacity 
						style={styles.eachItemViewStyle} 
						activeOpacity={0.5} 
						onPress={()=> this.setState({productItemActiveIndex:(this.state.productItemActiveIndex === index) ? null : index})} 
						disabled={item.is_online_payment=== true || (this.state.isDailyPaymentFrequency === false && item.is_payment_data === false && !item.is_payment_due_at_start_amount)}
					>
						<View style={styles.dateitemView}>
							{item.ledger_entry_date 
								? <View>
									<View style={styles.showInRow}>
										{item.is_discount_or_extra_charge === true
											?  <Text style={styles.textColor}>{format(item.ledger_entry_date,"MMM'YY")}</Text>
											: <Text style={styles.textColor}>{format(item.ledger_entry_date,"DD MMM'YY")}</Text>
										}
										
										{item.is_discount_or_extra_charge === false && item.is_payment_data === true  && this.state.productItemActiveIndex === index
											? <Text style={styles.timeText}>{format(item.ledger_entry_date,'hh:mm A')}</Text>
											: null
										}
									</View>
									{item.is_payment_data === true  
										? item.is_online_payment === true
											? <Text style={styles.paymentDetailStyle}>{i18n.t('onlinePayment')}</Text>
											: item.is_discount_or_extra_charge === true
											?<Text style={styles.paymentDetailStyle}>{item.is_extra_charge ?i18n.t('showing_extra_charges_value') :i18n.t('showing_discount_value')}</Text>
											:null
										: null
									}
									{item.is_payment_data === false 
										? item.is_payment_due_at_start_amount === true 
											? <Text style={styles.paymentDetailStyle}>{i18n.t('productLedgerStartBalance')}</Text>
											: this.state.isDailyPaymentFrequency === true
											? <Text style={styles.paymentDetailStyle}>{format(item.ledger_entry_date, "DD MMM'YY")} {i18n.t('invoice')}</Text>
											:<Text style={styles.paymentDetailStyle}>{format(item.billing_period, "MMM'YY")} {i18n.t('invoice')}</Text>
										: null
									}
								</View>
								: null
							}
						</View>
						{/*Invoice amount (debit) row */}
						{item.is_payment_data === false || (item.is_discount_or_extra_charge === true && item.is_extra_charge === true)
							? <View style={styles.paymentView}>
								{item.is_discount_or_extra_charge === true && item.is_extra_charge === true 
									? <Text style={item.paid_amount === 0 ? [styles.productInvoicePaymentText,{color:'#808080'} ] : styles.productInvoicePaymentText}>{this.numberWithCommas(item.paid_amount*-1)}</Text>
									: <Text style={item.total_due_amount === 0 ? [styles.productInvoicePaymentText,{color:'#808080'} ] :styles.productInvoicePaymentText}>{item.total_due_amount < 0 ?this.numberWithCommas(item.total_due_amount*(-1)) :this.numberWithCommas(item.total_due_amount)}</Text>
								}
							</View>
							: null
						}
						<View style={styles.paymentView}/>
						{/*Payment Collected (Credit) row*/}
						{item.is_payment_data === true && !(item.is_discount_or_extra_charge === true && item.is_extra_charge === true)
							? <View style={styles.paymentView}>
								<Text style={styles.productBillPaymentTextStyle}>{item.is_extra_charge === true ? this.numberWithCommas(item.paid_amount*-1) : this.numberWithCommas(item.paid_amount)}</Text>
							</View>
							: null
						}
						{/*running balance*/}
						<View style={styles.paymentView}>
							<Text 
								style={item.running_total === 0 
								? [styles.runningBillPaymentTextStyle, {color:'#808080'}]
								: item.running_total > 0 
								? styles.runningBillPaymentTextStyle
								: [styles.runningBillPaymentTextStyle, {color:'#ED1C24'}]
								}>{item.running_total < 0 ? this.numberWithCommas(item.running_total *-1) : this.numberWithCommas(item.running_total)}{item.running_total > 0 && <Text style={styles.creditText}>{`\n(${i18n.t('creditText')})`}</Text>}
							</Text>
						</View>
						<View style={styles.arrowItemViewStyle}>
							{(this.state.isDailyPaymentFrequency === false && item.is_payment_data === false && !item.is_payment_due_at_start_amount) || item.is_online_payment
								?null
								: <MaterialCommunityIcons name={ this.state.productItemActiveIndex===index ? "chevron-up" : "chevron-down"} size={32} color="#808080"  />
							}
						</View>
					</TouchableOpacity>
					{this.state.productItemActiveIndex === index && (item.is_payment_data === false || item.is_discount_or_extra_charge === true ||item.is_online_payment === false) &&
						<View>
							{item.is_payment_data === true
								?<View style={styles.paymentContentViewStyle}>
									<View style={styles.paymentHeadingViewStyle}>
										{item.is_discount_or_extra_charge === true
											?<View>
												{item.date_of_discount_extra_charge && 
													<Text style={styles.perticularTextStyle}>{i18n.t('appliedOn',{date:`${format(item.date_of_discount_extra_charge, 'DD MMM YY')}`})} {format(item.date_of_discount_extra_charge,'hh:mm A')}</Text>
												}
											</View>
											: <View style={styles.employeeNameViewStyle}>
												<Image 
													source={require('../assets/images/employee-avatar-without-circle.png')} 
													style={styles.employeeIconStyle} />
												{item.employee_name &&
													<Text numberOfLines={2} ellipsizeMode='tail' style={styles.employeeNameTextStyle}>{item.employee_name}</Text>
												}
											</View>
										}
									</View>
									{item.comment
										?<View style={styles.paymentCommentViewStyle}>
											<Text style={styles.productCommentTextStyle}>{i18n.t('commentText')}{' - '}{item.comment}</Text>
										</View>
										: null
									}
								</View>
								:<View style={styles.billSectionViewStyle}>
									<View style={styles.billContentViewStyle}>
										{item.is_payment_due_at_start_amount === true
										?<View style={styles.paymentDueBeforeGoPaaniViewStyle}>
											<Text style={styles.totalAmountTextStyle}>{i18n.t('at_starting_amount')}</Text>
											<View style={styles.beforeGopaaniView}>
												<Text style={styles.rupeeTextStyle}>{'₹'}</Text>
												<Text style={styles.productAmountTextStyle}>{item.total_due_amount}</Text>
											</View>
										</View>
										:<View>
											<View style={styles.billHeadingViewStyle}>
												<View style={styles.invoiceEmployeeNameStyle}>
													<Image 
														source={require('../assets/images/employee-avatar-without-circle.png')} 
														style={styles.employeeIconStyle} />
													{item.employee_name && 
														<Text numberOfLines={2} ellipsizeMode='tail'  style={styles.employeeNameTextStyle}>{item.employee_name}</Text>
													}
												</View>
												<TouchableOpacity style={styles.billButtonStyle} onPress={()=> this.setState({showShareOptionOverlay:true, billDate:format(item.billing_payment_date,'YYYY-MM-DD')})}>
													<Image 
														source={require('../assets/images/billing_icon.png')} 
														style={styles.billIconStyle}
													/>
													<Text style={styles.billTextStyle}>{i18n.t('deliveryInvoiceText')}</Text>
												</TouchableOpacity>
											</View>
											{item.product_data.map((productItem, i) => {
												return(
													<View key={i} style={styles.productItemViewStyle}>
														<View style={styles.productNameIconViewStyle}>
															<Image 
																source={productItem.image_url ?{uri:productItem.image_url} :require('../assets/jar_icon/default-item-icon.png')} 
																style={styles.productIconStyle}
															/>
															<View>
																<Text style={styles.productNameTextStyle}>{productItem.user_facing_name ? productItem.user_facing_name :productItem.product_name}</Text>
																{productItem.unit_name
																	? <Text style={styles.productUnitStyle}>({productItem.unit_name})</Text>
																	: null
																}
															</View>
														</View>
														<Text style={styles.productQuantityPriceTextStyle}>{'( '}{productItem.total_product_delivered}{' X '}{' ₹ '}{productItem.product_price}{' )'}</Text>
														<View style={styles.productPriceViewStyle}>
															<Text style={styles.rupeeTextStyle}>{'₹'}</Text>
															<Text style={styles.productAmountTextStyle}>{this.numberWithCommas(productItem.product_amount)}</Text>
														</View>
													</View>
												)	
											})}
											<View style={styles.totalAmountViewStyle}>
												<Text style={styles.totalAmountTextStyle}>{i18n.t('totalInvoiceAmount')}</Text>
												<View style={styles.productPriceViewStyle}>
													<Text style={styles.rupeeTextStyle}>{'₹'}</Text>
													<Text style={styles.productAmountTextStyle}>{this.numberWithCommas(item.total_due_amount)}</Text>
												</View>
											</View>
										</View>
									}
									</View>
								</View>
							}
						</View>
					}
				</View>
				}
			</View>
		)
	}

	render(){
		const { navigation, route, route: { params } } = this.props;
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<View style={{flex:1, backgroundColor:'#ffffff'}}>
					{params.showHeader === false 
						?null
						:<Header
							centerComponent={<Image resizeMode='cover' style={styles.headerIconStyle}  source={require('../assets/images/small_logo.png')}/>}
							leftComponent={
								<TouchableOpacity
									style={styles.headerLeftComponentStyle}
									activeOpacity={0.5}
									onPress={()=>this.props.navigation.goBack()}>
									<FontAwesome name='chevron-left' color='#38c6f4' size={24} />
									<Text style={styles.headerTitleTextStyle}>{i18n.t('home')}</Text>
								</TouchableOpacity>
							}
							containerStyle={styles.containerStyle}
							ViewComponent={LinearGradient}
							linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
						/>
					}
					<FlatList
						data={this.state.pastPaymentsAndBillList}
						renderItem={this.renderProductDeliveredCustomersItem}
						keyExtractor={(item,index) => index.toString()}
						ItemSeparatorComponent={this.renderSeparator}
						ListFooterComponent={this.state.pastPaymentsAndBillList.length < 2 ? this.renderNotFound : null}
						ListHeaderComponent={this.renderHeader}
						keyboardShouldPersistTaps='always'
						extraData={this.state}
						stickyHeaderIndices={[1]} // add the stickyHeaderIndices prop
					/>
					<Overlay
						isVisible={this.state.showShareOptionOverlay}
						onBackdropPress={() => this.setState({ showShareOptionOverlay: false,})}
						overlayStyle={{width:wp('95%'), height:'auto'}}
					>
						<View>
							<View style={styles.shareOptionViewStyle}>
								<TouchableOpacity style={styles.optionTouchableStyle}
									onPress={()=> this.shareDownloadPDF(this.state.isForSharing)}>
									<Ionicons name='md-share' size={32} color='#175491' />
									<Text style={styles.optionLabelText}>{i18n.t('share')}</Text>
								</TouchableOpacity>
								<TouchableOpacity style={styles.optionTouchableStyle}
									onPress={()=> this.shareDownloadPDF(this.state.isForDownload)}>
									<Feather name="download" size={32} color="#175491"/>
									<Text style={styles.optionLabelText}>{i18n.t('download')}</Text>
								</TouchableOpacity>
								<TouchableOpacity style={styles.optionTouchableStyle}
									onPress={()=> this.shareDownloadPDF(this.state.isForPrint)}>
									<Ionicons name='md-print' size={32} color='#175491'/>
									<Text style={styles.optionLabelText}>{i18n.t('print')}</Text>
								</TouchableOpacity>
							</View>
							<View style={styles.cancelButtonViewStyle}>
								<TouchableOpacity style={styles.cancelTouchableStyle} 
									activeOpacity={0.5}
									onPress={()=> this.setState({showShareOptionOverlay:false})}>
									<Text style={styles.cancelTextStyle}>{i18n.t('cancelOption')}</Text>
								</TouchableOpacity>
							</View>
						</View>
					</Overlay>
				</View>
			)
		}
	}
}