import React from "react";
import { View, Text, StyleSheet, TouchableOpacity} from "react-native";
import {format} from 'date-fns';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import i18n from 'i18n-js';
import {FontAwesome } from '@expo/vector-icons';
import CustomerBillInfo from './customerBillInfo';
export default class regularCustomerBillingInfo extends React.PureComponent{
	constructor(props){
		super(props);
	}

	render(){
		const item = this.props.item;
		return(
			<View style={styles.showForm}>
				<View style={styles.mainView}>
					<View style={styles.fixedAmuntMainView}>
						<View style={styles.headingView}>
							<View style={{width:wp('18%')}}>
								<Text style={{fontSize:14}}>{i18n.t('jarPricingText',{jarType:this.props.userFacingNameCool})}</Text>
							</View>
							<View style={{width:wp('3%')}}>
								<Text style={{fontSize:14}}>{'x'}</Text>
							</View>
							<View style={{width:wp('18%')}}>
								<Text style={{fontSize:14}}>{i18n.t('totalJarText',{jarType:this.props.userFacingNameCool})}</Text>
							</View>
						
							<View style={{width:wp('20%'),flexDirection:'row',marginLeft:20}}>
								<View style={{width:wp('8%')}}>
									<Text style={{fontSize:14}}>({item.per_cool_jar_price}</Text>
								</View>
								<View style={{width:wp('3%')}}>
									<Text style={{fontSize:14}}>{'x'}</Text>
								</View>
								<View style={{width:wp('9%')}}>
									<Text style={{fontSize:14}}>{item.total_cool_jar_in})</Text>
								</View>
							</View>
						</View>
						<View style={{width:wp('30%'), flexDirection:'row',justifyContent:'space-evenly', alignItems:'center'}}>
							<FontAwesome name="rupee" size={20} style={{ color: '#38c6f4'}} />
							<Text style={{fontSize:14, fontWeight:'bold'}}>{item.total_cool_amount}</Text>
						</View>
					</View>
					<View style={styles.fixedAmuntMainView}>
						<View style={styles.headingView}>
							<View style={{width:wp('18%')}}>
								<Text style={{fontSize:14}}>{i18n.t('jarPricingText',{jarType:this.props.userFacingNamePet})}</Text>
							</View>
							<View style={{width:wp('3%')}}>
								<Text style={{fontSize:14}}>{'x'}</Text>
							</View>
							<View style={{width:wp('18%')}}>
								<Text style={{fontSize:14}}>{i18n.t('totalJarText',{jarType:this.props.userFacingNamePet})}</Text>
							</View>
						
							<View style={{width:wp('20%'),flexDirection:'row',marginLeft:20}}>
								<View style={{width:wp('8%')}}>
									<Text style={{fontSize:14}}>({item.per_pet_jar_price}</Text>
								</View>
								<View style={{width:wp('3%')}}>
									<Text style={{fontSize:14}}>{'x'}</Text>
								</View>
								<View style={{width:wp('9%')}}>
									<Text style={{fontSize:14}}>{item.total_pet_jar_in})</Text>
								</View>
							</View>
						</View>
						<View style={{width:wp('30%'), flexDirection:'row',justifyContent:'space-evenly', alignItems:'center'}}>
							<FontAwesome name="rupee" size={20} style={{ color: '#38c6f4' }} />
							<Text style={{fontSize:14, fontWeight:'bold'}}>{item.total_pet_amount}</Text>
						</View>
					</View>
				</View>
				<CustomerBillInfo item={item} />
			</View>
		)
	}
}

const styles = StyleSheet.create({
	mainView:{
		flexDirection:'column'
	},
	fixedAmuntMainView:{
		borderBottomWidth:1,
		borderBottomColor:'#afdbe2',
		flexDirection:'row',
		marginTop:10,
		marginBottom:10
	},
	headingView:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'flex-start',
		width:wp('70%'),
	},
	headingText:{
		fontSize:14
	},
	symbolView:{
		width:wp('3%')
	},
	valueTextView:{
		width:wp('17%'),
		flexDirection:'row',
		justifyContent:'flex-end'
	},
	valueText:{
		fontSize:14,
		fontWeight:'bold',
		paddingLeft:10,
		paddingRight:10,
		marginTop:5
	},
	rupeeSymbolStyle:{
		color: '#38c6f4',
		marginTop:5 
	},
	totalExtraChargeView:{
		width:wp('90%'),
		borderBottomWidth:3, 
		borderBottomColor:'#afdbe2', 
		flexDirection:'row',
		marginTop:10,
		marginBottom:10
	},
	customView:{
		width:wp('18%')
	},
	JarPricingView:{
		width:wp('8%')
	},
	jarPricingTextView:{
		width:wp('9%')
	},
	mainJarPricingView:{
		width:wp('20%'),
		flexDirection:'row',
		marginLeft:20
	},
	showForm:{
		borderColor: '#fff',
		width:wp('100%'),
		borderColor: '#ddd',
		backgroundColor:'#f5fcfc',
		padding:10
	},
	
});