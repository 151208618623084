import React, { Component } from 'react';
import { Text, View, StyleSheet, ActivityIndicator, TouchableOpacity, Image, ScrollView } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Header} from "react-native-elements";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { FontAwesome } from '@expo/vector-icons';
import i18n from 'i18n-js';
import translationValues from '../components/translation';
import client from '../client';
import logout from '../components/logout';
import alert from '../utils/commonAlert'
/*
	usedFor:-this screen is show when user press on payment detail screen
*/
export default class PayNowScreen extends Component{
	constructor(props){
		super(props)
		this.state={
			isLoading:true,
			bankAccountDetails:null,
			paymentMethod:'',
			paymentMethodNumber:null,
			totalDueAmount:null,
			isDailyPaymentFrequency:false,
			paytmPhoneNumber:null,
			phonepePhoneNumber:null,
			googlepayPhoneNumber:null,

		}
		this.willFocusSubscription = ''
		
	}
	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			this.fetchPaymentDetails();
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
		})
	}
	componentWillUnmount(){
		this.willFocusSubscription()
	}

	fetchPaymentDetails = async () => {
		const { navigation, route, route: { params } } = this.props;
		const loginCustomerData = params.loginCustomerData;
		const isDailyPaymentFrequency = params.isDailyPaymentFrequency;
		const totalDueAmount = params.totalDueAmount;
		let urlParams = {request_for:'business_payment_details'}
		const responseData = await client.fetchCustomerDetail(loginCustomerData.business_id, loginCustomerData.customer_business_id, urlParams)
		if (responseData.detail) {
			alert(
				'Customer',
				'You do not have permission to perform this action.',
				[
					{text: 'OK', onPress:()=> logout(this.props.navigation)},
				],
				{ cancelable: false }
			)
		}
		else{
			this.setState({bankAccountDetails:responseData.business_bank_detail_dict,totalDueAmount:totalDueAmount,isDailyPaymentFrequency:isDailyPaymentFrequency, paytmPhoneNumber:responseData.paytm_phone_number, phonepePhoneNumber:responseData.phone_pe_phone_number, googlepayPhoneNumber:responseData.google_pay_phone_number, paymentMethod:responseData.paytm_phone_number ?'Paytm' :responseData.phone_pe_phone_number ?'PhonePe' :'GooglePay',paymentMethodNumber:responseData.paytm_phone_number ?responseData.paytm_phone_number :responseData.phone_pe_phone_number ?responseData.phone_pe_phone_number :responseData.google_pay_phone_number, isLoading:false})
		}
	}
	render() {
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<View style={{flex:1, backgroundColor:'#ffffff'}}>
					<Header
						centerComponent={<Image resizeMode='cover' style={styles.headerIconStyle}  source={require('../assets/images/small_logo.png')}/>}
						leftComponent={
							<TouchableOpacity
								style={styles.headerLeftComponentStyle}
								activeOpacity={0.5}
								onPress={()=>this.props.navigation.goBack()}>
								<FontAwesome name='chevron-left' color='#38c6f4' size={24} />
								<Text style={styles.headerTitleTextStyle}>{i18n.t('home')}</Text>
							</TouchableOpacity>
						}
						centerContainerStyle={{justifyContent:'center'}}
						leftContainerStyle={{justifyContent:'center'}}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
					/>
					<ScrollView showsVerticalScrollIndicator={false}>
						<Text style={styles.payNowHeadingTextStyle}>{i18n.t('headingPaymentDetailsScreen')}</Text>
						{ this.state.paytmPhoneNumber || this.state.phonepePhoneNumber || this.state.googlepayPhoneNumber || this.state.bankAccountDetails?null:<Text style={styles.noPaymentInfoFound}>{i18n.t('noPaymentInfoFound')}</Text>

						}
						{this.state.paytmPhoneNumber || this.state.phonepePhoneNumber || this.state.googlepayPhoneNumber
							?<View style={styles.paymentModeViewStyle}>
								<View style={styles.paymentMethodViewStyle}>
									{this.state.paytmPhoneNumber
										?<TouchableOpacity 
											style={this.state.paymentMethod==='Paytm' ?styles.selectedPaymentMethodStyle :styles.eachPaymentMethodStyle}
											onPress={()=>this.setState({paymentMethod:'Paytm', paymentMethodNumber:this.state.paytmPhoneNumber})}>
											<Image 
												source={require('../assets/images/paytm.png')}
												style={styles.paymentModeIconStyle}
											/>
											<Text style={this.state.paymentMethod ==='Paytm' ?styles.selectedPaymentMethodTextStyle :styles.paymentMethodTextStyle}>{'Paytm'}</Text>
										</TouchableOpacity>
										:null
									}
									{this.state.phonepePhoneNumber
										?<TouchableOpacity 
											style={this.state.paymentMethod==='PhonePe' ?styles.selectedPaymentMethodStyle :styles.eachPaymentMethodStyle}
											onPress={()=>this.setState({paymentMethod:'PhonePe', paymentMethodNumber:this.state.phonepePhoneNumber})}>
											<Image 
												source={require('../assets/images/phone-pay.png')}
												style={styles.paymentModeIconStyle}
											/>
											<Text style={this.state.paymentMethod ==='PhonePe' ?styles.selectedPaymentMethodTextStyle :styles.paymentMethodTextStyle}>{'PhonePe'}</Text>
										</TouchableOpacity>
										:null
									}
									{this.state.googlepayPhoneNumber
										?<TouchableOpacity 
											style={this.state.paymentMethod==='GooglePay' ?styles.selectedPaymentMethodStyle :styles.eachPaymentMethodStyle}
											onPress={()=>this.setState({paymentMethod:'GooglePay', paymentMethodNumber:this.state.googlepayPhoneNumber})}>
											<Image 
												source={require('../assets/images/google-pay.png')}
												style={styles.paymentModeIconStyle}
											/>
											<Text style={this.state.paymentMethod ==='GooglePay' ?styles.selectedPaymentMethodTextStyle :styles.paymentMethodTextStyle}>{'GooglePay'}</Text>
										</TouchableOpacity>
										:null
									}
								</View>
								{this.state.paymentMethodNumber 
									?<View style={styles.paymentMethodDetailViewStyle}>
										<Text style={styles.paymentMethodDetailTextStyle}>{i18n.t('youCanPaViaPaymentModeOurPhoneNumberMobile',{paymentMode:this.state.paymentMethod,paymentMethodNumber:this.state.paymentMethodNumber})}</Text>
										
									</View>
									:null
								}
							</View>
							:null
						}
						{this.state.bankAccountDetails
							?<View style={styles.bankDetailViewStyle}>
								<View style={styles.bankIconNameViewStyle}>
									<FontAwesome name="bank" size={24} color="#38c6f4" />
									<Text style={styles.bankHeadingTextStyle}>{i18n.t('bankAccount')}</Text>
								</View>
								<View style={styles.bankInfoViewStyle}>
									<Text style={styles.bankInfoTextStyle}>{i18n.t('accountName')}{' - '}{this.state.bankAccountDetails.account_holder_name}</Text>
									<Text style={styles.bankInfoTextStyle}>{i18n.t('accountNo')}{' - '}{this.state.bankAccountDetails.account_no}</Text>
									<Text style={styles.bankInfoTextStyle}>{i18n.t('bankName')}{' - '}{this.state.bankAccountDetails.bank_name}</Text>
									<Text style={styles.bankInfoTextStyle}>{i18n.t('branch')}{' - '}{this.state.bankAccountDetails.branch}</Text>
									<Text style={styles.bankInfoTextStyle}>{i18n.t('ifscCode')}{' - '}{this.state.bankAccountDetails.ifsc_code}</Text>
								</View>
							</View>
							:null
						}
						
						<View style={styles.dueAmountShadowViewStyle}>
							<Text style={styles.dueAmountTextStle}>{this.state.isDailyPaymentFrequency===true ?i18n.t('todayDueAmount') :i18n.t('lastMonthDueAmount')}</Text>
							<Text style={styles.dueAmountTextStle}>{"₹ "}{this.state.totalDueAmount}</Text>
						</View>
						
					</ScrollView>
				</View>
			)
		}
	}

}
const styles = StyleSheet.create({
	loadingViewStyle:{
		backgroundColor:'#ffffff',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	headerIconStyle:{
		height:35.85,
		width:31.05,
		resizeMode:'contain'
	},
	headerLeftComponentStyle:{
		flexDirection:'row',
		alignItems:'center'
	},
	headerTitleTextStyle:{
		color: '#fff',
		fontSize:16,
		fontWeight:'bold',
		marginLeft:5
	},
	containerStyle:{
		height: 60,
		borderBottomWidth:null,
		backgroundColor:null,
		alignItems:'center',
		elevation:5
	},
	payNowHeadingTextStyle:{
		padding:10,
		color: '#175491',
		fontSize:18,
		fontWeight:'bold',
	},
	paymentModeViewStyle:{
		paddingTop:10,
		paddingBottom:20,
		backgroundColor:'#f2fcfc',
	},
	bankDetailViewStyle:{
		marginTop:10,
		marginBottom:10,
		backgroundColor:'#f2fcfc',
		paddingTop:20,
	},
	paymentMethodViewStyle:{
		paddingTop:10,
		paddingLeft:10,
		paddingRight:10,
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'flex-start'
	},
	eachPaymentMethodStyle:{
		paddingTop:5,
		paddingBottom:5,
		paddingLeft:10,
		paddingRight:10,
		flexDirection:'row',
		alignItems:'center',
		backgroundColor:'transparent',
		borderWidth:1,
		borderColor:'#38c6f4',
		backgroundColor:'#ffffff'
	},
	selectedPaymentMethodStyle:{
		paddingTop:5,
		paddingBottom:5,
		paddingLeft:10,
		paddingRight:10,
		flexDirection:'row',
		alignItems:'center',
		borderWidth:1,
		borderColor:'#38c6f4',
		backgroundColor:'#38c6f4'
	},
	paymentModeIconStyle:{
		width:30,
		height:25,
		resizeMode:'contain'
	},
	paymentMethodTextStyle:{
		color:'#808080',
		fontSize:14,
		fontWeight:'bold',
		marginLeft:5
	},
	selectedPaymentMethodTextStyle:{
		color:'#ffffff',
		fontSize:14,
		fontWeight:'bold',
		marginLeft:5
	},
	bankHeadingTextStyle:{
		color:'#808080',
		fontSize:16,
		fontWeight:'bold',
		marginLeft:10
	},
	bankInfoViewStyle:{
		padding:10,
		marginTop:10,
		marginBottom:20,
		marginLeft:20,
		marginRight:20,
		alignItems:'flex-start',
		backgroundColor:'#ffffff',
		borderRadius:2
	},
	bankInfoTextStyle:{
		paddingTop:5,
		paddingBottom:5,
		color:'#175491',
		fontSize:14,
		fontWeight:'bold',
	},

	noPaymentInfoFound:{
		paddingTop:25,
		paddingBottom:25,		
		paddingLeft:10,
		paddingRight:10,
		textAlign: 'center',
		color:'#FF0000',
		fontSize:14,
		fontWeight:'bold',
	},
	bankIconNameViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		paddingLeft:30,
	},
	paymentMethodDetailViewStyle:{
		padding:10,
		marginLeft:10,
		marginRight:10,
		alignItems:'flex-start',
		backgroundColor:'#ffffff',
		borderRadius:2
	},
	
	dueAmountShadowViewStyle:{
		marginTop:10,
		marginBottom:20,
		height:70,
		padding:10,
		elevation:5,
		borderWidth:1,
		borderColor:'#f2f5f7',
		backgroundColor:'#ffffff',
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between'
	},
	dueAmountTextStle:{
		color:'#175491',
		fontSize:16,
		fontWeight:'bold',
	},
	paymentMethodDetailTextStyle:{
		paddingBottom:5,
		color:'#175491',
		fontSize:14,
		lineHeight:30,
		fontWeight:'bold',
	}
})