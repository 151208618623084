import React, { Component } from 'react';
import getEnvVars from '../environment';
const { apiUrl } = getEnvVars();
import { Text, View, StyleSheet, ScrollView, TextInput, TouchableOpacity, ActivityIndicator, BackHandler, Image} from 'react-native';
import {Button, Header} from 'react-native-elements';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons, FontAwesome, MaterialCommunityIcons, Feather} from '@expo/vector-icons';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
const axios = require('axios');
import PasscodeTextInput from './passcodeTextInput';
import CountDown from '../components/countDown';
import i18n from 'i18n-js';
import translationValues from '../components/translation';
import client from '../client';
import * as Application from 'expo-application';
import {showCrossPlatformToast} from '../utils/showCrossPlatformToast';
import AsyncStorage from '@react-native-async-storage/async-storage'
import alert from '../utils/commonAlert'

export default class OtpVerifyView extends Component {

	constructor(props){
		super(props);
		const { navigation, route, route: { params } } = this.props;
		let registedPhoneNumber = params.registed_phone_number;
		let otpDuration = params.otp_timeout;
		this.state = {
			isLoading:false,
			errorMessage:'',
			showErrorMessage:false,
			registedPhoneNumber:registedPhoneNumber,
			totalDuration:otpDuration,
			enableResendOTP:false,
			timerRunning:true,
			counterValue:null,
			submitDisable:false,
			otpCode:''
		}
		this.CustomerOtpVerify = this.CustomerOtpVerify.bind(this);
		this.ResendOTP = this.ResendOTP.bind(this);
		this.willFocusSubscription='';
		this.backhandlerSubscription='';
	}
	componentDidMount(){
		this.backhandlerSubscription = BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
			this.setState({isLoading:false})

		});
 	}

	componentWillUnmount(){
		this.willFocusSubscription()
		this.backhandlerSubscription.remove()
	}

	handleBackButtonClick = () => {
		this.props.navigation.replace('LoginStackScreen',{
			screen:'CustomerLoginScreen',
			params:{}
		});
		return true;
	};

	onChangeText = (value) => this.setState({ otpCode:value })

	ResendOTP = async () => {
		const {registedPhoneNumber, totalDuration} = this.state;
		try{
			this.setState({isLoading:true})
			const response = await axios.post(apiUrl+'customer-resend-otp-verify/',{
				phone_number:registedPhoneNumber,
				is_new_otp_changes:true,
				is_resend_otp_request:true
			});
			if(response.data.success){
				this.setState({isLoading:false,enableResendOTP:false,showErrorMessage:false,submitDisable:false,totalDuration:totalDuration,})
				alert(
					i18n.t('otp'),
					i18n.t('again_sent_otp_message_via_call'),
					[
						{text:i18n.t('ok')},
					],
					{ cancelable: false }
				)
			}
			else{
				this.setState({isLoading:false,enableResendOTP:false,showErrorMessage:false,submitDisable:false,totalDuration:totalDuration})
				alert(
					i18n.t('otp'),
					i18n.t('try_again_later_message'),
					[
						{text: i18n.t('ok')},
					],
					{ cancelable: false }
				)
				this.props.navigation.replace('LoginStackScreen',{
					screen:'CustomerLoginScreen',
					params:{}
				});
			}
		}
		catch(err){
			console.log(err);
		}
	}
	/* store the language into db */
	saveLanguage = async (user_id) => {
 		let requestBody = {app_language:global.isLanguage}
	 	await AsyncStorage.setItem('isLanguage',global.isLanguage);
	 	await client.storeCustomerAppLanguage(user_id, requestBody)
	}

	CustomerOtpVerify = async() => {
		const {registedPhoneNumber, otpCode} = this.state;
		let formData = {
			otp_pin:otpCode,
			phone_number:registedPhoneNumber
		}
		try {
			const referral_value = await Application.getInstallReferrerAsync();
			const store_install_time = await Application.getInstallationTimeAsync();
			const store_last_update = await Application.getLastUpdateTimeAsync();
			formData['referral_value'] = referral_value
			formData['store_install_time'] = store_install_time
			formData['store_last_update'] = store_last_update
		}catch(err){
			
		}
		try{
			this.setState({isLoading:true,timerRunning:false})
			const response = await axios.post(apiUrl+'customer-otp-verify/',formData);
			if(response.data.success){
				this.setState({enableResendOTP:false,showErrorMessage:false,submitDisable:false,totalDuration:0, otpCode:''})
				if(response.data.is_business_customer === true){
					await AsyncStorage.setItem('loginCustomerData', JSON.stringify(response.data.user_loggedin_data));
					global.userTokenData = response.data.user_loggedin_data
					await this.saveLanguage(response.data.user_loggedin_data.user_id)
					if(response.data.user_loggedin_data.role==='master'){
						this.props.navigation.replace('AdminDashboard',{
							loginCustomerData:response.data.user_loggedin_data,
						})
					}
					else if(response.data.user_loggedin_data.list_of_business_customers.length>1){
						this.props.navigation.replace('ShowCustomerBusiness',{
							loginCustomerData:response.data.user_loggedin_data,
						})
					}
					else if(response.data.user_loggedin_data &&  response.data.user_loggedin_data.list_of_business_customers && response.data.user_loggedin_data.list_of_business_customers.length>0){
						AsyncStorage.getItem('loginCustomerData').then( data => {
						// transform it back to an object
							data = JSON.parse( data );
							data.business_id = response.data.user_loggedin_data.list_of_business_customers[0].business_id
							data.customer_business_id = response.data.user_loggedin_data.list_of_business_customers[0].customer_business_id
							data.business_name = response.data.user_loggedin_data.list_of_business_customers[0].business_name
							data.business_logo = response.data.user_loggedin_data.list_of_business_customers[0].business_logo
							data.business_address = response.data.user_loggedin_data.list_of_business_customers[0].business_address
							data.phone_number = response.data.user_loggedin_data.list_of_business_customers[0].phone_number
							data.customer_name = response.data.user_loggedin_data.list_of_business_customers[0].customer_name
							//save the value to AsyncStorage again
							AsyncStorage.setItem('loginCustomerData', JSON.stringify(data));
							this.props.navigation.replace('CustomerStackScreen',{
								screen:'CustomerScreen',
								params:{
									screen: 'Home', 
									params:{loginCustomerData:data},
								}
							});
						})
					}else{
						showCrossPlatformToast(i18n.t('defaultTechnicalError'));
						this.setState({isLoading:false,})
					}
				}
				else if(response.data.is_came_from_referral === true){
					await AsyncStorage.setItem('loginCustomerData', JSON.stringify(response.data.user_loggedin_data));
					global.userTokenData = response.data.user_loggedin_data
					this.props.navigation.replace('LeadStackScreen',{
						screen:'CustomerRequestInfo',
						params:{
							isCameFromReferral:response.data.is_came_from_referral
						}
					});
				}
				else{
					await AsyncStorage.setItem('loginCustomerData', JSON.stringify(response.data.user_loggedin_data));
					global.userTokenData = response.data.user_loggedin_data
					if(response.data.user_loggedin_data.is_already_submitted_info===true){
						this.props.navigation.replace('LeadChatScreen',{
							screen:'ShowAllBusiness',
							params:{
								loginCustomerData:response.data.user_loggedin_data,
								is_not_serve_area:response.data.user_loggedin_data.is_not_serve_area
							}
						});
					}
					else{
						this.props.navigation.replace('LeadStackScreen',{
							screen:'CustomerBegin',
							params:{loginCustomerData:response.data.user_loggedin_data,}
						});
					}
				}
			}
			else{
				this.setState({isLoading:false,showErrorMessage:true,errorMessage:i18n.t('correct_otp_number'),timerRunning:true,totalDuration:this.state.counterValue})
			}
		
		}
		catch(err){
			console.log(err);
		}
	}

	render(){
		if(this.state.isLoading){
			return(
				<View style={{position: 'absolute',left: 0,right: 0,top: 0,bottom: 0,alignItems: 'center',justifyContent: 'center', backgroundColor:'#ffffff'}}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<View style={{flex:1, backgroundColor:'#ffffff'}}>
					<Header
						statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						leftComponent={<TouchableOpacity
							style={{marginLeft:10,paddingTop:10, flexDirection:'row'}}
							onPress={() => this.props.navigation.replace('LoginStackScreen',{screen:'CustomerLoginScreen',params:{}})}
							activeOpacity={0.5}>
							<FontAwesome name='chevron-left' color='#38c6f4' size={28} />
							</TouchableOpacity>}
						centerComponent={<Image resizeMode='cover' style={{height: 50,width: 50,resizeMode:'contain'}}  source={require('../assets/images/small_logo.png')}/>}
						containerStyle={{borderBottomWidth:null,backgroundColor:null,alignItems:'center'}}
						centerContainerStyle={{justifyContent:'center'}}
						leftContainerStyle={{justifyContent:'center'}}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
					/>
					<ScrollView style={{flex:1}} keyboardShouldPersistTaps={'always'}>
						<View style={styles.mainViewStyle}>
							<Image style={styles.headingGraphicStyle}  source={require('../assets/images/opt-screen-graphic.png')}/>
							<Text style={styles.headingTextStyle}>{i18n.t('verify_text')}</Text>
							<Text style={styles.TextSubHeadingStyle}>
								<Text style={styles.subHeadingTextStyle}>{i18n.t('sub_heading_verify')}</Text>
								<Text style={styles.enteredMobileNumberTextStyle}>{this.state.registedPhoneNumber.replace('+91','')}</Text>
							</Text>
							<View style={styles.innerView}>
								<View style={styles.innerViewStyle}>
									<PasscodeTextInput
										otpCode={this.state.otpCode}
										onChangeText={this.onChangeText}
									/>
									{this.state.showErrorMessage &&
										<Text style={{fontSize:12,textAlign:'left',color:'red'}}>{this.state.errorMessage}</Text>
									}
									<Text style={{fontSize:12,textAlign:'left',marginTop:10,color:'#175491'}}>{i18n.t('time_remaining')}</Text>
									<CountDown
										until={this.state.totalDuration}
										onFinish={() => this.setState({enableResendOTP:true})}
										size={14}
										digitStyle={{backgroundColor: '#FFF'}}
										digitTxtStyle={{color: '#175491',fontSize:14,fontWeight:'bold'}}
										timeToShow={['M', 'S']}
										separatorStyle={{color: '#175491'}}
										timeLabels={{m: null, s: null}}
										showSeparator={true}
										running={this.state.timerRunning}
										onChange={until => this.setState({counterValue:until})}
									/>
									<TouchableOpacity
										style={this.state.enableResendOTP ?styles.resendOTPContainer :styles.resendOTPDisableContainer}
										onPress={() => this.ResendOTP()}
										activeOpacity={0.5}
										disabled={this.state.enableResendOTP ?false:true}>
										<Feather name="phone-call" size={16} color="#ffffff" />
										<Text style={styles.activeResendOtpTextStyle}>{i18n.t('resend_otp_via_call')}</Text>
									</TouchableOpacity>	
								</View>
								<View style={{flexDirection:'row', justifyContent:'center',alignItems:'center',marginTop:-35}}>
									<Button
										ViewComponent={LinearGradient}
										linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0},end:{x: 1, y: 0},}}
										title={i18n.t('verify_title')}
										buttonStyle={{width:wp('70%'),padding:20,borderRadius:5}}
										containerStyle={{alignItems:"center"}}
										titleStyle={{fontWeight:'bold',color:'#ffffff',fontSize:20,textAlign:'center'}}
										onPress={this.CustomerOtpVerify}
										disabled={this.state.otpCode === '' || this.state.submitDisable ?true:false}
										disabledTitleStyle={{color:'hsl(208, 8%, 63%)'}}
									/>
								</View>
							</View>
						</View>
					</ScrollView>
				</View>
			
			)
		}
	}
}


const styles = StyleSheet.create({
  input: {
	width: wp('8%'),
	color:'#38c6f4',
	fontSize: 18,
	marginLeft:10,
	borderBottomWidth:1, 
	borderBottomColor:'#38c6f4',
	padding:10
  },
  inputPassword: {
	width: wp('43%'),
	color:'#38c6f4',
	fontSize: 14,
	marginLeft:15
  },
  innerView:{
	alignItems: 'center',
	width:wp('90%'),
	backgroundColor: '#fff',
	padding:10,
	borderRadius:10,
  },
  innerViewStyle:{
	alignItems: 'center',
	width:wp('90%'),
	backgroundColor: '#fff',
	borderRadius:5,
	borderWidth:1,
	borderColor:'#175491',
	paddingBottom:55,
  },
  headingGraphicStyle:{
		width:wp('100%'),
		height:hp('15%'),
		resizeMode:'contain'
	},
	headingTextStyle:{
		fontWeight:'bold',
		fontSize:20,
		color:'#175491',
		paddingTop:10,
		textAlign:'center',
		paddingLeft:15,
		paddingRight:10,
	},
	TextSubHeadingStyle:{
		marginTop:10,
		paddingLeft:15,
		paddingRight:10,
		marginBottom:10,
		textAlign:'center',
	},
	subHeadingTextStyle:{
		fontWeight:'bold',
		lineHeight:30,
		fontSize:16,
		color:'#38c6f4',
	},
	enteredMobileNumberTextStyle:{
		fontWeight:'bold',
		fontSize:16,
		color:'#175491',
		lineHeight:30,
		textAlign:'center',
		textDecorationLine:'underline'
	},
	mainViewStyle:{
		alignItems:'center',
		marginTop:10,
	},
	resendOTPContainer:{
		padding:10,
		backgroundColor:'#38c6f4',
		borderRadius:5,
		flexDirection:'row',
		alignItems:'center',
		maxWidth:wp('86%'),
	},
	resendOTPDisableContainer:{
		padding:10,
		backgroundColor:'#d2d2d2',
		borderRadius:5,
		flexDirection:'row',
		alignItems:'center',
		maxWidth:wp('86%'),
	},
	activeResendOtpTextStyle:{
		fontWeight:'bold',
		paddingLeft:5,
		fontSize:18,
		color:'#ffffff'
	},
})