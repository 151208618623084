/*
	We have mentioned here token remove error and when we don't have do any thing on error
*/
export const logoutRemoveErrorStatus = [//Token remove error code
	401,
];
export const accessDeniedErrorStatus = [//Token remove error code
	403,
];
export const badRequestErrorStatus = [//Don't do any thing on error code
	400,
	404,
	0,
	500,
	502,
	503,
	504,
];