import React, { Component } from 'react';
import { View,ImageBackground, FlatList, Image, TouchableOpacity, Platform, Text} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import i18n from 'i18n-js';
import { Header, Avatar } from 'react-native-elements';
import ListItem from '../components/listItem';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import getEnvVars from '../environment';
const { apiUrl } = getEnvVars();
import { Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage'

export default class ShowCustomerBusiness extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount = () => {
		if(Platform.OS==='web'){
			window.history.replaceState(null,null,"/SwitchAccount")
		}
	}

	renderSeparator = () => {
		return (
			<View
				style={{
					height: 1,
					width: '100%',
					backgroundColor: '#CED0CE',
				}}
			/>
		);
	};

	onItemPress = async (item, role) => {
		AsyncStorage.getItem('loginCustomerData')
			.then( data => {
			// transform it back to an object
			data = JSON.parse( data );
			data.business_id = item.business_id
			data.customer_business_id = item.customer_business_id
			data.business_name = item.business_name
			data.business_logo = item.business_logo
			data.business_address = item.business_address
			data.phone_number = item.phone_number
			data.customer_name = item.customer_name
			//save the value to AsyncStorage again
			AsyncStorage.setItem('loginCustomerData', JSON.stringify(data));
			global.userTokenData = data
			this.props.navigation.replace('CustomerStackScreen',{
				screen:'CustomerScreen',
				params:{
					screen: 'Home', 
					params:{loginCustomerData:data},
				}
			});
		})
	}

	render() {
		const { navigation, route, route: { params } } = this.props;
		const loginCustomerData = params.loginCustomerData;
		const showMenuicon = params.showMenuicon;
		return (
			<View style={{flex:1, backgroundColor:"#ffffff"}}>
				<Header
					centerComponent={<Image resizeMode='cover' style={{height: 45,width: 45,resizeMode:'contain'}}  source={require('../assets/images/small_logo.png')}/>}
					containerStyle={{borderBottomWidth:null,backgroundColor:null,alignItems:'center'}}
					leftComponent={
						showMenuicon===true 
						?	<TouchableOpacity style={{marginLeft:10, flexDirection:'row', alignItems:'center'}} activeOpacity={0.5} onPress={()=>this.props.navigation.openDrawer()}>
								<Ionicons name='md-menu' color={'#38c6f4'} size={28} />
							</TouchableOpacity>
						: 	null
					}
					ViewComponent={LinearGradient}
					linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
				/>
				<FlatList 
					data={loginCustomerData.list_of_business_customers} 
					renderItem={({item}) => {
						return(
							<ListItem
								leftAvatar={ 
									<Avatar 
										rounded={true} size={40} 
										source={item.business_logo ? {uri:item.business_logo} :require('../assets/images/business_logo.png')}
									/> 
								}
								titleElement={<Text>{item.business_name}</Text>}
								rightTitleElement={<Text style={{color:'#808080'}}>{item.customer_name}</Text>}
								onPress={() => this.onItemPress(item, loginCustomerData.role)}
							/>
						)}
					}
					keyExtractor={(item, index) => index.toString()}
					extraData={this.state}
					ItemSeparatorComponent={this.renderSeparator}
				/>
			</View>
		);
	};
}
