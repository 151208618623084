import React, { Component } from 'react';
import { View, ImageBackground, Platform } from 'react-native';
import client from '../client';
import AsyncStorage from '@react-native-async-storage/async-storage'
import { handleNavigation } from '../utils/URLIntegration'

export default class AuthLoadingScreen extends Component {
	constructor(props) {
		super(props);
	}

	checkWebLocalStorage = () => {
		if(Platform.OS==='web'){
			try{
				window.localStorage
				return true
			}
			catch(e){
				return false
			}
		}
	}

	componentDidMount(){
		const cookieAlert = () => {
			const response = window.confirm("COOKIES BLOCKED\nPlease allow cookies for GoRecordz from Browser Settings")
			if(response) window.open("https://support.google.com/accounts/answer/61416?hl=en","_blank")
		}
		if(!this.checkWebLocalStorage()) setTimeout(cookieAlert,500)
		else this.bootstrapAsync();
	}

	CallSaveLanguageAPIOnce = async (user_id) => {
		let iscallPushTokenAPIOnce = await AsyncStorage.getItem('iscallPushTokenAPIOnce');
		if(iscallPushTokenAPIOnce === null){
			await AsyncStorage.setItem('iscallPushTokenAPIOnce',JSON.stringify(true))
			let requestBody = {app_language:global.isLanguage}
			await AsyncStorage.setItem('isLanguage',global.isLanguage);
			await client.storeCustomerAppLanguage(user_id, requestBody)
		}
	}

	bootstrapAsync = async () => {
		const userTokenData = await AsyncStorage.getItem('loginCustomerData');
		const isLanguage = await AsyncStorage.getItem('isLanguage');
		const JsonUserTokenData = JSON.parse(userTokenData)
		global.isLanguage = isLanguage
		global.userTokenData = JsonUserTokenData
		let firstTimeArrive = await AsyncStorage.getItem('firstTimeArrive');
		if(JsonUserTokenData){
			this.CallSaveLanguageAPIOnce(JsonUserTokenData.user_id);
			if(JsonUserTokenData.role==='master'){
				this.props.navigation.replace('AdminDashboard',{loginCustomerData:JsonUserTokenData,})
			}
			else if(JsonUserTokenData.business_id || JsonUserTokenData.customer_business_id){
				this.props.navigation.replace('CustomerStackScreen',{
					screen:'CustomerScreen',
					params:{
						screen: 'Home', 
						params:{loginCustomerData:JsonUserTokenData},
					}
				});
				global.isLanguage = isLanguage
			}
			else if(JsonUserTokenData.list_of_business_customers.length>1){
				this.props.navigation.replace('ShowCustomerBusiness',{loginCustomerData:JsonUserTokenData});
				global.isLanguage = isLanguage
			}
			else{
				if(JsonUserTokenData.is_already_submitted_info===true){
					this.props.navigation.replace('LeadChatScreen',{
						screen:'ShowAllBusiness',
						params:{loginCustomerData:JsonUserTokenData,is_not_serve_area:JsonUserTokenData.is_not_serve_area,},
					});
				}
				else{
					this.props.navigation.replace('LeadStackScreen',{
						screen:'CustomerBegin',
						params:{loginCustomerData:JsonUserTokenData},
					});
				}
			}
		}
		else{
			if(firstTimeArrive == null){
				await AsyncStorage.setItem('firstTimeArrive','true')
				this.props.navigation.replace('WelcomeGoPaaniScreen');
			
			}else{
				this.props.navigation.replace('LoginStackScreen',{
					screen:'CustomerLoginScreen',
					params:{}
				});
			}
		}
		if(Platform.OS==='web'){
			const pathArray = window.location.pathname.split('/')
			const lastRoute = pathArray[pathArray.length-1]		
			if(JsonUserTokenData && JsonUserTokenData.token && JsonUserTokenData.business_id) handleNavigation(lastRoute,this.props.navigation)
			else {
				lastRoute!=='' && alert('NOT_FOUND')
				window.history.replaceState(null,null,'/')
			}
		}	
	};
	render() {
		return (
			<ImageBackground source={require('../assets/images/splash_screen.png')} style={{width: '100%', height: '100%'}}>
				<View/>
			</ImageBackground>
		);
	};
}