import React from "react";
import { View, Text, StyleSheet, Image} from "react-native";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import i18n from 'i18n-js';
import {FontAwesome } from '@expo/vector-icons';
import CustomerBillInfo from './customerBillInfo';

export default class FixedMonthlyBillingInfo extends React.PureComponent{
	constructor(props){
		super(props);
	}

	render(){
		const item = this.props.item;
		return(
			<View style={styles.showForm}>
				{item.total_cool_jar_in > item.fixed_cool_jar && item.total_pet_jar_in >  item.fixed_pet_jar
				? 	<View style={styles.mainView}>
						<View style={styles.fixedAmuntMainView}>
							<View style={styles.headingView}>
								<View style={{width:wp('50%'), flexDirection:'column'}}>
									<Text style={styles.headingText}>{i18n.t('fixed_amount')}</Text>
									<Text style={{fontSize:12}}>({i18n.t('fixedJarText',{jarType:this.props.userFacingNameCool})} = {item.fixed_cool_jar} & {i18n.t('fixedJarText',{jarType:this.props.userFacingNamePet})} = {item.fixed_pet_jar})</Text>
								</View>
								<View style={styles.symbolView}>
									<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
								</View>
								<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5}}>
									<Text style={styles.valueText}>{item.fixed_month_amount}</Text>
								</View>
							</View>
							<View style={styles.symbolView}>
								<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
							</View>
							<View style={styles.valueTextView}>
								<Text style={styles.valueText}>-</Text>
							</View>
						</View>
						<View style={styles.fixedAmuntMainView}>
							<View style={styles.headingView}>
								<View style={{width:wp('50%'), flexDirection:'column'}}>
									<Text style={styles.headingText}>{i18n.t('totalDeliveredJarText',{jarType:this.props.userFacingNameCool})}</Text>
								</View>
								<View style={styles.symbolView}>
									<Image 
										source={this.props.productImageOfCool ?{uri:this.props.productImageOfCool} :require('../assets/jar_icon/cool.png')} 
										style={{height: hp('3%'),width: wp('3%'),resizeMode : 'contain',}}
									/>
								</View>
								<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5,marginTop:-5}}>
									<Text style={styles.valueText}>{item.total_cool_jar_in}</Text>
								</View>
							</View>
						</View>
						<View style={styles.fixedAmuntMainView}>
							<View style={styles.headingView}>
								<View style={{width:wp('50%'), flexDirection:'column'}}>
									<Text style={styles.headingText}>{i18n.t('totalDeliveredJarText',{jarType:this.props.userFacingNamePet})}</Text>
								</View>
								<View style={styles.symbolView}>
									<Image 
										source={this.props.productImageOfPet ?{uri:this.props.productImageOfPet} :require('../assets/jar_icon/pet.png')}
										style={{height: hp('3%'),width: wp('3%'),resizeMode : 'contain',}}
									/>
								</View>
								<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5,marginTop:-5}}>
									<Text style={styles.valueText}>{item.total_pet_jar_in}</Text>
								</View>
							</View>
						</View>
						<View style={styles.fixedAmuntMainView}>
							<View style={styles.headingView}>
								<View style={styles.customView}>
									<Text style={styles.headingText}>{i18n.t('jarPricingText',{jarType:this.props.userFacingNameCool})}</Text>
								</View>
								<View style={styles.symbolView}>
									<Text style={styles.headingText}>{'x'}</Text>
								</View>
								<View style={styles.customView}>
									<Text style={styles.headingText}>{i18n.t('totalJarText',{jarType:this.props.userFacingNameCool})}</Text>
								</View>
							
								<View style={styles.mainJarPricingView}>
									<View style={styles.JarPricingView}>
										<Text style={styles.headingText}>({item.per_cool_jar_price}</Text>
									</View>
									<View style={styles.symbolView}>
										<Text style={styles.headingText}>{'x'}</Text>
									</View>
									<View style={styles.jarPricingTextView}>
										<Text style={styles.headingText}>{item.total_cool_jar_in})</Text>
									</View>
								</View>
							</View>
							<View style={styles.symbolView}>
								<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
							</View>
							<View style={styles.valueTextView}>
								<Text style={styles.valueText}>{item.extra_amount_for_cool_jar}</Text>
							</View>
						</View>
						<View style={styles.fixedAmuntMainView}>
							<View style={styles.headingView}>
								<View style={styles.customView}>
									<Text style={styles.headingText}>{i18n.t('jarPricingText',{jarType:this.props.userFacingNamePet})}</Text>
								</View>
								<View style={styles.symbolView}>
									<Text style={styles.headingText}>{'x'}</Text>
								</View>
								<View style={styles.customView}>
									<Text style={styles.headingText}>{i18n.t('totalJarText',{jarType:this.props.userFacingNamePet})}</Text>
								</View>
							
								<View style={styles.mainJarPricingView}>
									<View style={styles.JarPricingView}>
										<Text style={styles.headingText}>({item.per_pet_jar_price}</Text>
									</View>
									<View style={styles.symbolView}>
										<Text style={styles.headingText}>{'x'}</Text>
									</View>
									<View style={styles.jarPricingTextView}>
										<Text style={styles.headingText}>{item.total_pet_jar_in})</Text>
									</View>
								</View>
							</View>
							<View style={styles.symbolView}>
								<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
							</View>
							<View style={styles.valueTextView}>
								<Text style={styles.valueText}>{item.extra_amount_for_pet_jar}</Text>
							</View>
						</View>
					</View>
				: 	<View>
						{item.total_cool_jar_in > item.fixed_cool_jar && (item.total_pet_jar_in== 0  && item.fixed_pet_jar == 0)
							?<View style={styles.mainView}>
								<View style={styles.fixedAmuntMainView}>
									<View style={styles.headingView}>
										<View style={{width:wp('50%'), flexDirection:'column'}}>
											<Text style={styles.headingText}>{i18n.t('fixed_amount')}</Text>
											<Text style={{fontSize:12}}>({i18n.t('fixedJarText',{jarType:this.props.userFacingNameCool})} = {item.fixed_cool_jar} & {i18n.t('fixedJarText',{jarType:this.props.userFacingNamePet})} = {item.fixed_pet_jar})</Text>
										</View>
										<View style={styles.symbolView}>
											<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
										</View>
										<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5}}>
											<Text style={styles.valueText}>{item.fixed_month_amount}</Text>
										</View>
									</View>
									<View style={styles.symbolView}>
										<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
									</View>
									<View style={styles.valueTextView}>
										<Text style={styles.valueText}>-</Text>
									</View>
								</View>
								<View style={styles.fixedAmuntMainView}>
									<View style={styles.headingView}>
										<View style={{width:wp('50%'), flexDirection:'column'}}>
											<Text style={styles.headingText}>{i18n.t('totalDeliveredJarText',{jarType:this.props.userFacingNameCool})}</Text>
										</View>
										<View style={styles.symbolView}>
											<Image 
												source={this.props.productImageOfCool ?{uri:this.props.productImageOfCool} :require('../assets/jar_icon/cool.png')} 
												style={{height: hp('3%'),width: wp('3%'),resizeMode : 'contain',}}
											/>
										</View>
										<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5,marginTop:-5}}>
											<Text style={styles.valueText}>{item.total_cool_jar_in}</Text>
										</View>
									</View>
								</View>
								<View style={styles.fixedAmuntMainView}>
									<View style={styles.headingView}>
										<View style={styles.customView}>
											<Text style={styles.headingText}>{i18n.t('jarPricingText',{jarType:this.props.userFacingNameCool})}</Text>
										</View>
										<View style={styles.symbolView}>
											<Text style={styles.headingText}>{'x'}</Text>
										</View>
										<View style={styles.customView}>
											<Text style={styles.headingText}>{i18n.t('totalJarText',{jarType:this.props.userFacingNameCool})}</Text>
										</View>
									
										<View style={styles.mainJarPricingView}>
											<View style={styles.JarPricingView}>
												<Text style={styles.headingText}>({item.per_cool_jar_price}</Text>
											</View>
											<View style={styles.symbolView}>
												<Text style={styles.headingText}>{'x'}</Text>
											</View>
											<View style={styles.jarPricingTextView}>
												<Text style={styles.headingText}>{item.total_cool_jar_in})</Text>
											</View>
										</View>
									</View>
									<View style={styles.symbolView}>
										<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
									</View>
									<View style={styles.valueTextView}>
										<Text style={styles.valueText}>{item.extra_amount_for_cool_jar}</Text>
									</View>
								</View>
							</View>

							: <View>
								{item.total_pet_jar_in > item.fixed_pet_jar && (item.total_cool_jar_in== 0  && item.fixed_cool_jar == 0)
									?<View style={styles.mainView}>
										<View style={styles.fixedAmuntMainView}>
											<View style={styles.headingView}>
												<View style={{width:wp('50%'), flexDirection:'column'}}>
													<Text style={styles.headingText}>{i18n.t('fixed_amount')}</Text>
													<Text style={{fontSize:12}}>({i18n.t('fixedJarText',{jarType:this.props.userFacingNameCool})} = {item.fixed_cool_jar} & {i18n.t('fixedJarText',{jarType:this.props.userFacingNamePet})} = {item.fixed_pet_jar})</Text>
												</View>
												<View style={styles.symbolView}>
													<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
												</View>
												<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5}}>
													<Text style={styles.valueText}>{item.fixed_month_amount}</Text>
												</View>
											</View>
											<View style={styles.symbolView}>
												<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
											</View>
											<View style={styles.valueTextView}>
												<Text style={styles.valueText}>-</Text>
											</View>
										</View>
										<View style={styles.fixedAmuntMainView}>
											<View style={styles.headingView}>
												<View style={{width:wp('50%'), flexDirection:'column'}}>
													<Text style={styles.headingText}>{i18n.t('totalDeliveredJarText',{jarType:this.props.userFacingNamePet})}</Text>
												</View>
												<View style={styles.symbolView}>
													<Image 
														source={this.props.productImageOfPet ?{uri:this.props.productImageOfPet} :require('../assets/jar_icon/pet.png')}
														style={{height: hp('3%'),width: wp('3%'),resizeMode : 'contain',}}
													/>
												</View>
												<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5,marginTop:-5}}>
													<Text style={styles.valueText}>{item.total_pet_jar_in}</Text>
												</View>
											</View>
										</View>
										<View style={styles.fixedAmuntMainView}>
											<View style={styles.headingView}>
												<View style={styles.customView}>
													<Text style={styles.headingText}>{i18n.t('jarPricingText',{jarType:this.props.userFacingNamePet})}</Text>
												</View>
												<View style={styles.symbolView}>
													<Text style={styles.headingText}>{'x'}</Text>
												</View>
												<View style={styles.customView}>
													<Text style={styles.headingText}>{i18n.t('totalJarText',{jarType:this.props.userFacingNamePet})}</Text>
												</View>
											
												<View style={styles.mainJarPricingView}>
													<View style={styles.JarPricingView}>
														<Text style={styles.headingText}>({item.per_pet_jar_price}</Text>
													</View>
													<View style={styles.symbolView}>
														<Text style={styles.headingText}>{'x'}</Text>
													</View>
													<View style={styles.jarPricingTextView}>
														<Text style={styles.headingText}>{item.total_pet_jar_in})</Text>
													</View>
												</View>
											</View>
											<View style={styles.symbolView}>
												<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
											</View>
											<View style={styles.valueTextView}>
												<Text style={styles.valueText}>{item.extra_amount_for_pet_jar}</Text>
											</View>
										</View>
									</View>
									: 	<View>
											{item.total_cool_jar_in > item.fixed_cool_jar || item.total_pet_jar_in >  item.fixed_pet_jar
											?<View style={styles.mainView}>
												<View style={styles.fixedAmuntMainView}>
													<View style={styles.headingView}>
														<View style={{width:wp('50%'), flexDirection:'column'}}>
															<Text style={styles.headingText}>{i18n.t('fixed_amount')}</Text>
															<Text style={{fontSize:12}}>({i18n.t('fixedJarText',{jarType:this.props.userFacingNameCool})} = {item.fixed_cool_jar} & {i18n.t('fixedJarText',{jarType:this.props.userFacingNamePet})} = {item.fixed_pet_jar})</Text>
														</View>
														<View style={styles.symbolView}>
															<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
														</View>
														<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5}}>
															<Text style={styles.valueText}>{item.fixed_month_amount}</Text>
														</View>
													</View>
													<View style={styles.symbolView}>
														<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
													</View>
													<View style={styles.valueTextView}>
														<Text style={styles.valueText}>{item.fixed_month_amount}</Text>
													</View>
												</View>
												<View style={styles.fixedAmuntMainView}>
													<View style={styles.headingView}>
														<View style={{width:wp('50%'), flexDirection:'column'}}>
															<Text style={styles.headingText}>{i18n.t('totalDeliveredJarText',{jarType:this.props.userFacingNameCool})}</Text>
														</View>
														<View style={styles.symbolView}>
															<Image 
																source={this.props.productImageOfCool ?{uri:this.props.productImageOfCool} :require('../assets/jar_icon/cool.png')} 
																style={{height: hp('3%'),width: wp('3%'),resizeMode : 'contain',}}
															/>
														</View>
														<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5,marginTop:-5}}>
															<Text style={styles.valueText}>{item.total_cool_jar_in}</Text>
														</View>
													</View>
												</View>
												<View style={styles.fixedAmuntMainView}>
													<View style={styles.headingView}>
														<View style={{width:wp('50%'), flexDirection:'column'}}>
															<Text style={styles.headingText}>{i18n.t('totalDeliveredJarText',{jarType:this.props.userFacingNamePet})}</Text>
														</View>
														<View style={styles.symbolView}>
															<Image 
																source={this.props.productImageOfPet ?{uri:this.props.productImageOfPet} :require('../assets/jar_icon/pet.png')}
																style={{height: hp('3%'),width: wp('3%'),resizeMode : 'contain',}}
															/>
														</View>
														<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5,marginTop:-5}}>
															<Text style={styles.valueText}>{item.total_pet_jar_in}</Text>
														</View>
													</View>
												</View>
												<View style={styles.fixedAmuntMainView}>
													<View style={styles.headingView}>
														<View style={styles.customView}>
															<Text style={styles.headingText}>{i18n.t('jarPricingText',{jarType:this.props.userFacingNameCool})}</Text>
														</View>
														<View style={styles.symbolView}>
															<Text style={styles.headingText}>{'x'}</Text>
														</View>
														<View style={styles.customView}>
															<Text style={styles.headingText}>{i18n.t('extraJarText',{jarType:this.props.userFacingNameCool})}</Text>
														</View>
													
														<View style={styles.mainJarPricingView}>
															<View style={styles.JarPricingView}>
																<Text style={styles.headingText}>({item.per_cool_jar_price}</Text>
															</View>
															<View style={styles.symbolView}>
																<Text style={styles.headingText}>{'x'}</Text>
															</View>
															<View style={styles.jarPricingTextView}>
																<Text style={styles.headingText}>{item.extra_cool_jar})</Text>
															</View>
														</View>
													</View>
													<View style={styles.symbolView}>
														<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
													</View>
													<View style={styles.valueTextView}>
														<Text style={styles.valueText}>{item.extra_amount_for_cool_jar}</Text>
													</View>
												</View>
												<View style={styles.fixedAmuntMainView}>
													<View style={styles.headingView}>
														<View style={styles.customView}>
															<Text style={styles.headingText}>{i18n.t('jarPricingText',{jarType:this.props.userFacingNamePet})}</Text>
														</View>
														<View style={styles.symbolView}>
															<Text style={styles.headingText}>{'x'}</Text>
														</View>
														<View style={styles.customView}>
															<Text style={styles.headingText}>{i18n.t('extraJarText',{jarType:this.props.userFacingNamePet})}</Text>
														</View>
													
														<View style={styles.mainJarPricingView}>
															<View style={styles.JarPricingView}>
																<Text style={styles.headingText}>({item.per_pet_jar_price}</Text>
															</View>
															<View style={styles.symbolView}>
																<Text style={styles.headingText}>{'x'}</Text>
															</View>
															<View style={styles.jarPricingTextView}>
																<Text style={styles.headingText}>{item.extra_pet_jar})</Text>
															</View>
														</View>
													</View>
													<View style={styles.symbolView}>
														<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
													</View>
													<View style={styles.valueTextView}>
														<Text style={styles.valueText}>{item.extra_amount_for_pet_jar}</Text>
													</View>
												</View>
											</View>
										: 	null
										}
									</View>
								}
							</View>
						}
					</View>
				}
				{item.total_cool_jar_in <= item.fixed_cool_jar && item.total_pet_jar_in <= item.fixed_pet_jar &&
					<View style={styles.mainView}>
						<View style={styles.fixedAmuntMainView}>
							<View style={styles.headingView}>
								<View style={{width:wp('50%'), flexDirection:'column'}}>
									<Text style={styles.headingText}>{i18n.t('fixed_amount')}</Text>
									<Text style={{fontSize:12}}>({i18n.t('fixedJarText',{jarType:this.props.userFacingNameCool})} = {item.fixed_cool_jar} & {i18n.t('fixedJarText',{jarType:this.props.userFacingNamePet})} = {item.fixed_pet_jar})</Text>
								</View>
								<View style={styles.symbolView}>
									<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
								</View>
								<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5}}>
									<Text style={styles.valueText}>{item.fixed_month_amount}</Text>
								</View>
							</View>
							<View style={styles.symbolView}>
								<FontAwesome name="rupee" size={20} style={styles.rupeeSymbolStyle} />
							</View>
							<View style={styles.valueTextView}>
								<Text style={styles.valueText}>{item.fixed_month_amount}</Text>
							</View>
						</View>
						<View style={styles.fixedAmuntMainView}>
							<View style={styles.headingView}>
								<View style={{width:wp('50%'), flexDirection:'column'}}>
									<Text style={styles.headingText}>{i18n.t('totalDeliveredJarText',{jarType:this.props.userFacingNameCool})}</Text>
								</View>
								<View style={styles.symbolView}>
									<Image 
										source={this.props.productImageOfCool ?{uri:this.props.productImageOfCool} :require('../assets/jar_icon/cool.png')} 
										style={{height: hp('3%'),width: wp('3%'),resizeMode : 'contain',}}
									/>
								</View>
								<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5,marginTop:-5}}>
									<Text style={styles.valueText}>{item.total_cool_jar_in}</Text>
								</View>
							</View>
						</View>
						<View style={styles.fixedAmuntMainView}>
							<View style={styles.headingView}>
								<View style={{width:wp('50%'), flexDirection:'column'}}>
									<Text style={styles.headingText}>{i18n.t('totalDeliveredJarText',{jarType:this.props.userFacingNamePet})}</Text>
								</View>
								<View style={styles.symbolView}>
									<Image 
										source={this.props.productImageOfPet ?{uri:this.props.productImageOfPet} :require('../assets/jar_icon/pet.png')}
										style={{height: hp('3%'),width: wp('3%'),resizeMode : 'contain',}}
									/>
								</View>
								<View style={{width:wp('17%'),flexDirection:'row',marginLeft:5,marginTop:-5}}>
									<Text style={styles.valueText}>{item.total_pet_jar_in}</Text>
								</View>
							</View>
						</View>
					</View>
				}
				<CustomerBillInfo item={item} />
			</View>
		)
	}
}

const styles = StyleSheet.create({
	mainView:{
		width:wp('90%'),
		flexDirection:'column'
	},
	fixedAmuntMainView:{
		width:wp('90%'),
		borderBottomWidth:1,
		borderBottomColor:'#afdbe2',
		flexDirection:'row',
		marginTop:10,
		marginBottom:10
	},
	headingView:{
		width:wp('70%'),
		flexDirection:'row',
	},
	headingText:{
		fontSize:14
	},
	symbolView:{
		width:wp('3%')
	},
	valueTextView:{
		width:wp('17%'),
		flexDirection:'row',
		justifyContent:'flex-end'
	},
	valueText:{
		fontSize:14,
		fontWeight:'bold',
		paddingLeft:10,
		paddingRight:10,
		marginTop:5
	},
	rupeeSymbolStyle:{
		color: '#38c6f4',
		marginTop:5 
	},
	totalExtraChargeView:{
		width:wp('90%'),
		borderBottomWidth:3, 
		borderBottomColor:'#afdbe2', 
		flexDirection:'row',
		marginTop:10,
		marginBottom:10
	},
	customView:{
		width:wp('18%')
	},
	JarPricingView:{
		width:wp('8%')
	},
	jarPricingTextView:{
		width:wp('9%')
	},
	mainJarPricingView:{
		width:wp('20%'),
		flexDirection:'row',
		marginLeft:20
	},
	showForm:{
		borderColor: '#fff',
		width:wp('100%'),
		borderColor: '#ddd',
		backgroundColor:'#f5fcfc',
		padding:10
	},
	
});