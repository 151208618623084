import React, { Component } from 'react';
import { Text, View, StyleSheet, ScrollView,ActivityIndicator,Linking, Image, Platform } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { Button } from 'react-native-elements';
import AsyncStorage from '@react-native-async-storage/async-storage'
/*
	usedFor:- this component is used to show welcome screen where showing customer app link and business login link because A lot of business customers accidentally download business app and signup on it

*/
export default class WelcomeGoPaaniScreen extends Component{

	constructor(props){
		super(props)
		this.state = {
			isLoading:true,
			queryNumber:9826966287,
			queryAnotherNumber:9009467264,
			businessAppLink:'https://play.google.com/store/apps/details?id=com.gopaani.gopaaniapp&hl=en_IN',
			isBusinessCustomerUser:true,
		}
	}

	componentDidMount(){
		this.setState({isLoading:false})
	}

	onPressCustomerLoginScreen =  async (val) => {
		await AsyncStorage.setItem('isBusinessCustomerUser',JSON.stringify(val))
		this.props.navigation.replace('LoginStackScreen',{
			screen:'CustomerLoginScreen',
			params:{}
		});
	}

	render(){
		if(this.state.isLoading){
			return(
				<View style={styles.loadingView}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<ScrollView style={{flex:1, backgroundColor:'#ffffff'}} keyboardShouldPersistTaps={'always'}>
					<LinearGradient
						colors={['#175491','#175491','#5154b5','#5154b5']}
						style={{flex:1}}
						start={{x: 0, y: 0}}
						end={{x: 1, y: 0}}>
						<View style={styles.logoVIew}>
							<Image
								source={require('../assets/images/small_logo.png')}
								style={styles.logoImage}

							/>
						</View>
					</LinearGradient>
					<View style={styles.mainView}>
						<View style={styles.innerView}>
							<View style={styles.topView}>
								<Text style={styles.welcomeGoPaaniTextStyle}>Welcome to GoPaani</Text>
							</View>
							<View style={styles.innerViewStyle}>
							{
								Platform.OS==='web' ? null
								: <>
									<View style={styles.businessAppViewStyle}>
										<Text style={styles.questionTextStyle}>क्या आप पानी के जार ऑर्डर करना चाहते हैं?{'\n'}Are you looking to order water jars?</Text>
									</View>
									<View style={styles.buttonViewStyle}>
										<Button
											ViewComponent={LinearGradient}
											linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0},end:{x: 1, y: 0},}}
											title={'हाँ, मुझे पानी जार सप्लायर्स से कनेक्ट करें\nYes, connect me with Water Jar Suppliers'}
											buttonStyle={styles.buttonStyle}
											containerStyle={styles.containerStyle}
											titleStyle={styles.supplierBottonTitleStyle}
											icon={<Image 
												
												source={require('../assets/images/customer_app_logo.png')}
												style={styles.iconStyle}
											/>}
											onPress={()=>this.onPressCustomerLoginScreen(!this.state.isBusinessCustomerUser)}
										/>
									</View>
								</>
							}
								<View style={[styles.customerAppViewStyle,{borderWidth:0}]}>
									<Text style={styles.questionTextStyle}>आप एक ग्राहक हैं जिन्हे पानी जार दिया जाता है?{'\n'}Are you a customer who receives water jars?</Text>
								</View>
								<View style={styles.buttonViewStyle}>
									<Button
										ViewComponent={LinearGradient}
										linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0},end:{x: 1, y: 0},}}
										title={'हाँ, अपने फ़ोन नंबर से लॉगिन करें\nYes, Login with your Phone Number'}
										containerStyle={styles.containerStyle}
										buttonStyle={styles.buttonStyle}
										titleStyle={styles.titleStyle}
										icon={<Image 
											source={require('../assets/images/customer_app_logo.png')} 
											style={styles.iconStyle}
										/>}
										onPress={()=>this.onPressCustomerLoginScreen(this.state.isBusinessCustomerUser)}
									/>
								</View>
								<View style={styles.customerAppViewStyle}>
									<Text style={styles.questionTextStyle}> क्या आप पानी का व्यवसाय करते हैं?{'\n'}Are you in Water Delivery Business?</Text>
								</View>
								<View style={styles.buttonViewStyle}>
									<Button
										ViewComponent={LinearGradient}
										linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0},end:{x: 1, y: 0},}}
										title={'बिज़नेस ऐप डाउनलोड करें\nDownload Business App'}
										containerStyle={styles.containerStyle}
										buttonStyle={styles.buttonStyle}
										titleStyle={styles.titleStyle}
										icon={<Image 
											source={require('../assets/images/business_app_logo.png')}
											style={styles.iconStyle}
										/>}
										onPress={()=>Linking.openURL(this.state.businessAppLink)}
									/>
								</View>
							</View>
						</View>
						<View style={styles.queryNumberView}>
							<Text style={styles.learnMoreText}>To learn more about GoPaani call</Text>
							<View style={{flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
								<Text onPress={()=>Linking.openURL(`tel:${this.state.queryNumber}`)} style={styles.queryNumberText}>{this.state.queryNumber}</Text>
								<Text style={styles.pipeTextStyle}>{'|'}</Text>
								<Text onPress={()=>Linking.openURL(`tel:${this.state.queryAnotherNumber}`)} style={styles.queryNumberText}>{this.state.queryAnotherNumber}</Text>
							</View>
						</View>
					</View>
				</ScrollView>
			);
		}
	}
}

const styles = StyleSheet.create({
 	mainView:{
		alignItems:'center',
  		marginTop:5
 	},
  	innerView:{
		alignItems: 'center',
		width:wp('90%'),
		backgroundColor: '#fff',
		padding:10,
		marginTop:-30,
		borderRadius:10,
		marginBottom:10
	},
  	innerViewStyle:{
		width:wp('100%'),
		alignItems:'center',
		justifyContent:'center'
	},
 	topView:{
		flexDirection:'row',
		justifyContent:'center',
		alignItems:'center',
		marginBottom:10
	},
	welcomeGoPaaniTextStyle:{
		color:'#175491', 
		fontSize:20,
		textAlign:'center',
		fontWeight:'bold'
	},
	queryNumberView:{
		marginBottom:20
	},
	learnMoreText:{
		color:'#808080',
		fontSize:14,
		fontWeight:'bold', 
		textAlign:'left'
	},
	queryNumberText:{
		color:'#38c6f4',
		fontSize:14,
		fontWeight:'bold', 
		textAlign:'center',
		textDecorationLine:'underline',
		marginLeft:5,
		marginRight:5
	},
	pipeTextStyle:{
		color:'#38c6f4',
		fontSize:14,
		fontWeight:'bold', 
		textAlign:'center',
	},
	logoVIew:{
		height:hp('22%'),
		alignItems:'center',
		justifyContent:'center'
	},
	logoImage:{
		height: hp('15%'),
		width: wp('15%'),
		resizeMode : 'contain'
	},
	loadingView:{
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	iconStyle:{
		height: 50,
		width: 50,
		resizeMode : 'contain',
	},
	buttonStyle:{
		width:wp('90%'),
		padding:10,
		paddingVertical:12,
		justifyContent:'flex-start'
	},
	supplierBottonTitleStyle:{
		color:'#ffffff',
		fontSize:16,
		textAlign:'left',
		padding:5,
		width:wp('74%')
	},
	titleStyle:{
		color:'#ffffff',
		fontSize:16,
		textAlign:'left',
		padding:5
	},
	containerStyle:{
		marginTop:10,
		marginBottom:20
	},
	businessAppViewStyle:{
		flexDirection:'row',
		padding:5,
		paddingTop:15,
		alignItems:'center',
		justifyContent:'center'
	},
	questionTextStyle:{
		fontSize:16,
		color:'#999999',
		marginLeft:10,
		textAlign:'center'
	},
	buttonViewStyle:{
		flexDirection:'row',
		padding:5,
		justifyContent:'flex-start',
		paddingTop:10,
	},
	customerAppViewStyle:{
		flexDirection:'row',
		padding:5,
		justifyContent:'center',
		paddingTop:15,
		borderTopWidth:1,
		borderTopColor:'#b3b3b3',
		alignItems:'center',
		width:wp('90%')
	}
})