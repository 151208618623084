import React,{ Component } from 'react';
import { TouchableOpacity,Text, Image, Linking } from 'react-native';
import { withNavigation } from 'react-navigation';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import i18n from 'i18n-js';
/*
	this component is used to show the details on header in right side

*/
export default class RightHeaderComponent extends Component {
	render() {
		const {videoLink} = this.props
		return (
			<TouchableOpacity onPress={()=>Linking.openURL(videoLink)}>
				<Image
					source={require('../assets/images/video.png')}
					style={{width:25, height:25, resizeMode:'contain'}}
				/>
				<Text style={{color:'#ffffff', fontSize:14}}>{i18n.t('help')}</Text>
			</TouchableOpacity>
		);
	}
}