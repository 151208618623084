import React, { Component } from 'react';
import getEnvVars from '../environment';
const { apiUrl } = getEnvVars();
import { Text, View, StyleSheet, ScrollView, TextInput,ActivityIndicator, Image, Alert, TouchableOpacity, Platform} from 'react-native';
import {Button, CheckBox, Header} from 'react-native-elements';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons, AntDesign, MaterialCommunityIcons} from '@expo/vector-icons';
import DateTimePicker from '../components/commonDatePicker';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
const axios = require('axios');
import {format} from 'date-fns';
import i18n from 'i18n-js';
import translationValues from '../components/translation';
import AsyncStorage from '@react-native-async-storage/async-storage'

export default class AdditionalCustomerRequestInfo extends Component{

	constructor(props){
		super(props)
		this.state = {
			isLoading:true,
			chilledJar:'',
			capsuleJar:'',
			locationType:'',
			deliveryTime:new Date(),
			eventFromDate:new Date(),
			eventToDate:new Date(),
			eventComment:'',
			showTime:false,
			showEventDetails:false,
			show:false,
			showAnother:false
		}
		this.willFocusSubscription = ''
	}

	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
		});
		this.setState({isLoading:false})
	}

	componentWillUnmount(){
		this.willFocusSubscription();
	}

	submitAdditionalCustomerRequest = async () => {
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		const JsonLoginCustomerData = JSON.parse(loginCustomerData)
		const { navigation, route, route: { params } } = this.props;
		const {fullName, location, zipcode} = params;
		const{locationType, eventComment, eventFromDate, eventToDate, deliveryTime, chilledJar, capsuleJar} = this.state;
		this.setState({isLoading:true})
		try{
			const response = await axios.post(apiUrl+'customer-request-info/'+JsonLoginCustomerData.customer_otp_verify_id+'/',{
					full_name:fullName,
					location:location,
					location_zip_code:zipcode,
					location_type:locationType,
					delivery_time:format(deliveryTime, 'HH:mm'),
					event_from_date:format(eventFromDate,'DD-MM-YYYY'),
					event_to_date:format(eventToDate, 'DD-MM-YYYY'),
					event_comment:eventComment,
					number_of_chilled_jar:chilledJar,
					number_of_capsule_jar:capsuleJar,
					is_request_more:false

				},
				{
					headers:{
						Authorization:'Token '+JsonLoginCustomerData.token
					}
				}
			);
			if(response.data && response.data.success){
				this.setState({
					fullName: '',
					location:'',
					zipcode:'',
					chilledJar:'',
					capsuleJar:'',
					locationType:'',
					deliveryTime:'',
					eventFromDate:'',
					eventToDate:'',
					eventComment:'',
					showTime:false,
					showTimePicker:false,
					showEventDetails:false,
					isLoading:false
				});
				AsyncStorage.getItem('loginCustomerData')
					.then( data => {
					// transform it back to an object
					data = JSON.parse( data );
					data.fullName = fullName
					data.location = location
					data.is_not_serve_area = response.data.is_not_serve_area
					data.is_already_submitted_info = true
					//save the value to AsyncStorage again
					AsyncStorage.setItem('loginCustomerData', JSON.stringify(data));
					global.userTokenData = data
					this.props.navigation.replace('LeadChatScreen',{
						screen:'ShowAllBusiness',
						params:{loginCustomerData:data,is_not_serve_area:response.data.is_not_serve_area,},
					});
				})
			}
			else{
				this.setState({isLoading:false})
			}
		}
		catch(err){
			this.setState({isLoading:false})
		}

	};

	onChange = (key,val) => {
		const re = /^[0-9\b]+$/;
		if (val === '' || re.test(val)) {
			this.setState({[key]: val})
		}
	}

	render(){
		let minDate = new Date()
		if(this.state.isLoading){
			return(
				<View style={{position: 'absolute',left: 0,right: 0,top: 0,bottom: 0,alignItems: 'center',justifyContent: 'center', backgroundColor:'#ffffff'}}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<View style={{flex:1, backgroundColor:'#ffffff'}}>
					<Header
						centerComponent={<Image resizeMode='cover' style={{height: 50,width: 50,resizeMode:'contain'}}  source={require('../assets/images/small_logo.png')}/>}
						containerStyle={{borderBottomWidth:null,backgroundColor:null,alignItems:'center',elevation:5}}
						statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
					/>
					<ScrollView  keyboardShouldPersistTaps={'always'}>
						<View style={{padding:10}}>
							<Text style={{color:'#175491',fontSize:14, fontWeight:'bold'}}>{i18n.t('selectLocationType')}</Text>
							<View style={{borderBottomWidth:5, borderBottomColor:'#f2f5f7', width:wp('90%')}}>
								<View style={{flexDirection:'row', justifyContent:'space-between'}}>
									<CheckBox
										size={28}
										containerStyle={{borderWidth:0,backgroundColor:'transparent'}}
										center
										title={i18n.t('shop')}
										checkedIcon='check-square-o'
										uncheckedIcon='square-o'
										checked={this.state.locationType==='shop'? true :false}
										onPress={()=>this.setState({locationType:'shop', showTime:true, showEventDetails:false, chilledJar:'',capsuleJar:'', eventFromDate:new Date(),eventToDate:new Date(), eventComment:''})}
										checkedColor='#38C6F4'
									/>
									<CheckBox
										size={28}
										containerStyle={{borderWidth:0,backgroundColor:'transparent'}}
										center
										title={i18n.t('office')}
										checkedIcon='check-square-o'
										uncheckedIcon='square-o'
										checked={this.state.locationType==='office'? true :false}
										onPress={()=>this.setState({locationType:'office', showTime:true, showEventDetails:false, chilledJar:'',capsuleJar:'', eventFromDate:new Date(),eventToDate:new Date(), eventComment:''})}
										checkedColor='#38C6F4'
									/>

								</View>
								<View style={{flexDirection:'row', justifyContent:'space-between'}}>
									<CheckBox
										size={28}
										containerStyle={{borderWidth:0,backgroundColor:'transparent'}}
										center
										title={i18n.t('residence')}
										checkedIcon='check-square-o'
										uncheckedIcon='square-o'
										checked={this.state.locationType==='residence'? true :false}
										onPress={()=>this.setState({locationType:'residence', showTime:true, showEventDetails:false, chilledJar:'',capsuleJar:'', eventFromDate:new Date(),eventToDate:new Date(), eventComment:''})}
										checkedColor='#38C6F4'
									/>
									<CheckBox
										size={28}
										containerStyle={{borderWidth:0,backgroundColor:'transparent'}}
										center
										title={i18n.t('event')}
										checkedIcon='check-square-o'
										uncheckedIcon='square-o'
										checked={this.state.locationType==='event'? true :false}
										onPress={()=>this.setState({locationType:'event',showEventDetails:true, showTime:false})}
										checkedColor='#38C6F4'
									/>
								</View>
							</View>
							{this.state.showEventDetails &&
								<View style={{padding:10, borderBottomWidth:5, borderBottomColor:'#f2f5f7'}}>
									<Text style={{color:'#175491',fontSize:14, fontWeight:'bold'}}>{i18n.t('selectDateTime')}</Text>
									<View style={{justifyContent:'center', alignItems:'center', flexDirection:'row', paddingTop:10,paddingBottom:10}}>
										<View style={{borderWidth:1, borderColor:'#38c6f4', borderRadius:5,width:wp('45%'), height:hp('6%') }}>	
											<View>
												<TouchableOpacity
													onPress={()=>this.setState({show:true})}
													style={{flexDirection:'row', marginTop:5}}>
													<MaterialCommunityIcons name={'calendar-range'} color={'#38c6f4'} size={26} style={{marginLeft:10 }} />
													<Text style={{fontSize: 14,color: "#38c6f4", marginLeft:5, marginTop:2}}>{format(this.state.eventFromDate, 'DD-MMM-YYYY')}</Text>
												</TouchableOpacity>
											</View>
											{this.state.show &&
												<DateTimePicker
													style={{justifyContent:'center',alignItems:'center', width:wp('45%')}}
													value={this.state.eventFromDate}
													mode="date"
													onChange={(event, val)=> event.type==='set' ? this.setState({eventFromDate:new Date(val), show:false}) :this.setState({show:false})}
												/>
											}
										</View>
										<View style={{elevation:5, marginLeft:-12, marginRight:-12}}>
											<AntDesign name={'rightcircleo'} color={'#38c6f4'} size={26} style={{backgroundColor:'#ffffff' }} />
										</View>
										<View style={{borderWidth:1, borderColor:'#38c6f4', borderRadius:5,width:wp('45%'), height:hp('6%') }}>
											<TouchableOpacity
												onPress={()=>this.setState({showAnother:true})}
												style={{flexDirection:'row', marginTop:5}}>
												<MaterialCommunityIcons name={'calendar-range'} color={'#38c6f4'} size={26} style={{marginLeft:15 }} />
												<Text style={{fontSize: 14,color: "#38c6f4", marginLeft:5, marginTop:2}}>{format(this.state.eventToDate, 'DD-MMM-YYYY')}</Text>
											</TouchableOpacity>
											{this.state.showAnother &&
												<DateTimePicker
													style={{justifyContent:'center',alignItems:'center', width:wp('45%')}}
													value={this.state.eventToDate}
													mode="date"
													onChange={(event, selectedDate)=> event.type==='set' ? this.setState({eventToDate:new Date(selectedDate), showAnother:false}) :this.setState({showAnother:false})}													
												/>
											}
										</View>
									</View>
									<View style={{borderBottomWidth:1, borderBottomColor:'#38c6f4', borderRadius:5,width:wp('45%'), height:hp('6%') }}>
										<TouchableOpacity
											onPress={()=>this.setState({showTimePicker:true})}
											style={{flexDirection:'row', marginTop:5}}>
											<Ionicons name={'md-time'} color={'#38c6f4'} size={26} style={{marginLeft:15 }} />
											<Text style={{fontSize: 14,color: "#38c6f4", marginLeft:5, marginTop:2}}>{format(this.state.deliveryTime, 'HH:mm')}</Text>
										</TouchableOpacity>
										{this.state.showTimePicker &&
											<DateTimePicker
												style={{justifyContent:'center',alignItems:'center', width:wp('45%')}}
												value={this.state.deliveryTime}
												mode="time"
												is24Hour={true}
												onChange={(event, val)=> event.type==='set' ? this.setState({deliveryTime:new Date(val), showTimePicker:false}) :this.setState({showTimePicker:false})}												
											/>
										}
									</View>
									<View style={{borderWidth:1, borderColor:'#175491', borderRadius:5,width:wp('90%'), padding:10, marginTop:10,marginBottom:10 }}>
										<TextInput
											placeholder={i18n.t('enterComment')}
											multiline={true}
											numberOfLines={3}
											autoCapitalize="none"
											placeholderTextColor='#808080'
											onChangeText={val =>this.setState({eventComment:val})}
											value={this.state.eventComment}
										/>
									</View>
								</View>
							}
							{this.state.showTime &&
								<View style={{padding:10, borderBottomWidth:5, borderBottomColor:'#f2f5f7'}}>
									<Text style={{color:'#175491',fontSize:14, fontWeight:'bold'}}>{i18n.t('selectTime')}</Text>
									<View style={{borderBottomWidth:1, borderBottomColor:'#38c6f4', borderRadius:5,width:wp('45%'), height:hp('6%') , paddingTop:10, paddingBottom:10}}>
										<TouchableOpacity
											onPress={()=>this.setState({showTimePicker:true})}
											style={{flexDirection:'row', marginTop:5}}>
											<Ionicons name={'md-time'} color={'#38c6f4'} size={26} style={{marginLeft:15 }} />
											<Text style={{fontSize: 14,color: "#38c6f4", marginLeft:5, marginTop:2}}>{format(this.state.deliveryTime, 'HH:mm')}</Text>
										</TouchableOpacity>
										{this.state.showTimePicker &&
											<DateTimePicker
												style={{justifyContent:'center',alignItems:'center', width:wp('45%')}}
												value={this.state.deliveryTime}
												mode="time"
												is24Hour={true}
												onChange={(event, val)=> event.type==='set' ? this.setState({deliveryTime:new Date(val), showTimePicker:false}) :this.setState({showTimePicker:false})}												
											/>
										}
									</View>
								</View>
							}
							<View style={{padding:10, width:wp('100%'), borderBottomWidth:5, borderBottomColor:'#f2f5f7', marginBottom:30}}>
								<Text style={{color:'#175491',fontSize:14, fontWeight:'bold'}}>{i18n.t('numberOfJar')}</Text>
								<View style={{flexDirection:'row', justifyContent:'space-between', padding:10, alignItems:'center'}}>
									<Image resizeMode='cover' style={{height: 35,width: 35,resizeMode:'contain'}}  source={require('../assets/jar_icon/cool.png')}/>
									<Text style={{color:'#175491',fontSize:14, fontWeight:'bold'}}>{i18n.t('chilled_jar')}</Text>
									<TextInput
										style={styles.input}
										placeholder=''
										autoCapitalize="none"
										placeholderTextColor='#808080'
										keyboardType='number-pad'
										maxLength={4}
										onChangeText={val => this.onChange('chilledJar', val)}
										value={this.state.chilledJar}
									/>
								</View>
								<View style={{flexDirection:'row', justifyContent:'space-between', padding:10, alignItems:'center'}}>
									<Image resizeMode='cover' style={{height: 35,width: 35,resizeMode:'contain'}}  source={require('../assets/jar_icon/pet.png')}/>
									<Text style={{color:'#175491',fontSize:14, fontWeight:'bold'}}>{i18n.t('capsule_jar')}</Text>
									<TextInput
										style={styles.input}
										placeholder=''
										autoCapitalize="none"
										placeholderTextColor='#808080'
										keyboardType='number-pad'
										maxLength={4}
										onChangeText={val => this.onChange('capsuleJar', val)}
										value={this.state.capsuleJar}
									/>
								</View>
									
							</View>
							<View style={{flexDirection:'row', justifyContent:'center',alignItems:'center'}}>
								<Button
									ViewComponent={LinearGradient}
									linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0},end:{x: 1, y: 0},}}
									title={i18n.t('submit')}
									buttonStyle={{width:wp('70%'),padding:20,borderRadius:5}}
									containerStyle={{alignItems:"center"}}
									titleStyle={{fontWeight:'bold',color:'#ffffff',fontSize:20,textAlign:'center'}}
									onPress={this.submitAdditionalCustomerRequest}
								/>
							</View>
						</View>
					
					</ScrollView>
				</View>
			);
		}
	}
}

const styles = StyleSheet.create({
  input: {
	width: wp('40%'),
	height: hp('6%'),
	color:'#808080',
	fontSize: 14,
	borderWidth:1,
	borderColor:'#175491',
	borderRadius:5,
	textAlign:'center'
  },
  innerView:{
	alignItems: 'center',
	width:wp('85%'),
	height:hp('50%'),
	backgroundColor: '#fff',
	padding:10,
	marginTop:-50,
	borderRadius:10,
  },
  innerViewStyle:{
	width:wp('80%'),
	height:hp('33%'),
	backgroundColor: '#fff',
	borderRadius:5,
	borderWidth:1,
	borderColor:'#175491'
  },
  inputTextBoxViewError:{
  	flexDirection: 'row', 
  	borderBottomWidth:1, 
  	borderBottomColor:'red',
  	margin:10,
  	marginBottom:10
  },
  inputTextBoxView:{
  	flexDirection: 'row',
  	borderBottomWidth:1,
  	borderBottomColor:'#6fdde2',
  	margin:10,
  	marginBottom:10
  },
})