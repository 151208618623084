import React, { Component } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Text, TextInput } from 'react-native';
import AuthLoading from './screens/AuthLoading';
import CustomerJarEntry from './screens/customerJarEntry';
import CustomerLogin from './screens/CustomerLogin';
import OtpVerifyView from './screens/otpVerifyView';
import ShowCustomerBusiness from './screens/showCustomerBusiness';
import CustomerBegin from './screens/customerBegin';
import HowItWork from './screens/howItWork';
import CustomerRequestInfo from './screens/customerRequestInfo';
import AdditionalCustomerRequestInfo from './screens/additionalCustomerRequestInfo';
import ShowAllBusiness from './screens/showAllBusiness';
import ChatConversation from './screens/chatConversation';
import CustomerDrawerNavigator from './customDrawerComponent';
import CustomerBills from './screens/customerBills';
import AdminDashboard from './screens/adminDashboard';
import WelcomeGoPaaniScreen from './screens/welcomeGopaaniScreen';
import ShowPaymentBillLedger from './screens/showPaymentBillLedger';
import BillAndLedgerBarComponent from './billAndLedgerBarComponent';
import PayNowScreen from './screens/payNowScreen';
import PaymentSuccessScreen from './screens/paymentSuccessScreen';
import PlacePurchaseOrder from './screens/placePurchaseOrder';
import AllPurchaseOrderScreen from './screens/allPurchaseOrderScreen';
import { titleFormatter } from './utils/URLIntegration'

import { enableScreens } from 'react-native-screens';
import * as Location from 'expo-location';
import * as Sentry from 'sentry-expo';

Sentry.init({ //To sent error in production mode
	//enableInExpoDevelopment: true,
	dsn: 'https://1b430c78f4754d30a994914bbb420462@sentry.io/1528312',
})
Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.allowFontScaling = false;

TextInput.defaultProps = TextInput.defaultProps || {};
TextInput.defaultProps.allowFontScaling = false;

enableScreens();

Location.installWebGeolocationPolyfill();

const LoginStack = createStackNavigator();
function LoginStackScreen(){
	return(
		<LoginStack.Navigator initialRouteName="CustomerLoginScreen" screenOptions={{headerShown: false}}>
			<LoginStack.Screen name="CustomerLoginScreen" component={CustomerLogin} />
			<LoginStack.Screen name="OtpVerifyView" component={OtpVerifyView} />
		</LoginStack.Navigator>
	)
}

const billAndLedgerStack = createMaterialTopTabNavigator()
function BillAndLedgerScreen(){
	return(
		<billAndLedgerStack.Navigator screenOptions={{headerShown: false}} swipeEnabled={false} initialRouteName="CustomerBills" tabBar={props => <BillAndLedgerBarComponent {...props} />}>
			<billAndLedgerStack.Screen name="CustomerBills" component={CustomerBills} options={{headerShown: false}}/>
			<billAndLedgerStack.Screen name="ShowPaymentBillLedger" component={ShowPaymentBillLedger} options={{headerShown: false}}/>
		</billAndLedgerStack.Navigator>
	)
}

const customerStack = createStackNavigator()
function CustomerStackScreen(){
	return(
		<customerStack.Navigator initialRouteName="CustomerScreen" screenOptions={{headerShown: false}}>
			<customerStack.Screen name="CustomerScreen" component={CustomerDrawerNavigator} />
			<customerStack.Screen name="CustomerJarEntry" component={CustomerJarEntry} />
			<customerStack.Screen name="BillAndLedgerScreen" component={BillAndLedgerScreen} options={{headerShown: false}}/>
			<customerStack.Screen name="CustomerBills" component={CustomerBills} />
			<customerStack.Screen name="ShowPaymentBillLedger" component={ShowPaymentBillLedger} />
			<customerStack.Screen name="PayNowScreen" component={PayNowScreen} />
			<customerStack.Screen name="PaymentSuccessScreen" component={PaymentSuccessScreen} />
			<customerStack.Screen name="PlaceOrderScreen" component={PlacePurchaseOrder} />
			<customerStack.Screen name="AllPurchaseOrderScreen" component={AllPurchaseOrderScreen} />
		</customerStack.Navigator>
	)
}

const LeadStack = createStackNavigator()
function LeadStackScreen(){
	return(
		<LeadStack.Navigator initialRouteName="CustomerBegin" screenOptions={{headerShown: false}}>
			<LeadStack.Screen name="CustomerBegin" component={CustomerBegin} />
			<LeadStack.Screen name="HowItWork" component={HowItWork} />
			<LeadStack.Screen name="CustomerRequestInfo" component={CustomerRequestInfo} />
			<LeadStack.Screen name="AdditionalCustomerRequestInfo" component={AdditionalCustomerRequestInfo} />
		</LeadStack.Navigator>
	)
}

const LeadChatStack = createStackNavigator()
function LeadChatScreen(){
	return(
		<LeadChatStack.Navigator initialRouteName="ShowAllBusiness" screenOptions={{headerShown: false}}>
			<LeadChatStack.Screen name="ShowAllBusiness" component={ShowAllBusiness} />
			<LeadChatStack.Screen name="ChatConversation" component={ChatConversation} />
		</LeadChatStack.Navigator>
	)
}

const RootStack = createStackNavigator();
function RootStackScreen(){
	return(
		<RootStack.Navigator initialRouteName="AuthLoadingScreen" screenOptions={{headerShown: false}}>
			<RootStack.Screen name="AuthLoadingScreen" component={AuthLoading} />
			<RootStack.Screen name="LoginStackScreen" component={LoginStackScreen} />
			<RootStack.Screen name="AdminDashboard" component={AdminDashboard} />
			<RootStack.Screen name="WelcomeGoPaaniScreen" component={WelcomeGoPaaniScreen} />
			<RootStack.Screen name="ShowCustomerBusiness" component={ShowCustomerBusiness} />
			<RootStack.Screen name="CustomerStackScreen" component={CustomerStackScreen} />
			<RootStack.Screen name="LeadStackScreen" component={LeadStackScreen} />
			<RootStack.Screen name="LeadChatScreen" component={LeadChatScreen} />
		</RootStack.Navigator>
	)
}

export default class App extends Component {
  render() {
	return (
		<NavigationContainer documentTitle={{formatter:titleFormatter}}>
			<RootStackScreen/>
		</NavigationContainer>
	)
  }
}