import { Component } from 'react';
import { View, Image, Text, StyleSheet, TouchableOpacity, ActivityIndicator, ScrollView, BackHandler, Platform } from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import {Header, SearchBar} from "react-native-elements";

import {FontAwesome, AntDesign} from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';

import i18n from 'i18n-js';
import {format} from 'date-fns';

import translationValues from '../components/translation';
import {constantValues} from '../utils/helpers';
import RenderPurchaseOrderSummaryUI from './renderPurchaseOrderSummaryUI';
import {clientBusinessApi} from '../clientBusinessApi';
import {showErrorAlertStatusCode} from '../utils/showErrorAlertStatusCode';
import {showTechnicalError} from '../utils/showTechnicalError';
import {showCrossPlatformToast} from '../utils/showCrossPlatformToast';
import NumericInput from '../components/NumericInput'
import alert from '../utils/commonAlert'

export default class PlacePurchaseOrder extends Component {
	/* This screen is to show all/select item for order and place purchase order */

	constructor(props) {
		super(props);
		this.state = {
			isLoading:true,
			businessAllItemsArray:[],
			searchValue:'',
			showLoading:false,
			isPurchaseOrderItem:false,
			purchaseOrderDate:new Date(),
			purchaseOrderTime:new Date(),
			optionalMessage:'',
			showPurchaseOrderSelectionTime:false,
			showPurchaseOrderSelectionDate:false,
			changedQuantityOrderObject:{},
		};
		this.willFocusSubscription = ''
		this.backhandlerSubscription = '';
	}

	componentDidMount(){
		this.backhandlerSubscription = BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			Platform.OS==='web' && window.history.replaceState(null,null,"/PlacePurchaseOrder")
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
			this.fetchAllBusinessItem();
		});
	}

	componentWillUnmount() {
		this.willFocusSubscription();
		this.backhandlerSubscription.remove();
	}

	handleBackButtonClick = () => {
		//To handle hardware back press
		if(!this.state.isPurchaseOrderItem){
			return false;
		}
		else{
			this.setState({isPurchaseOrderItem:false});
			return true;
		}
	};

	fetchAllBusinessItem = async ()=>{
		const { navigation, route:{params:{loginCustomerData}}} = this.props;
		let apiParams = {new_generic_version_request:true};
		const response = await clientBusinessApi.fetchProducts(loginCustomerData.business_id, apiParams);
		if(response.data && response.data.success === true){
			const responseData = response.data;
			responseData.business_products_list.map((item, index)=>{
				//To created product_quantity with default value 0(Zeror)
				item['product_quantity'] = 0;
			});
			this.setState({isLoading:false, businessAllItemsArray:responseData.business_products_list});
		}
		else if(response.status){
			showErrorAlertStatusCode(response.status, navigation);
		}
		else{
			showTechnicalError();
		}
	}

	beforeSumberOrder = ()=>{
		this.setState({isLoading:true});
		let isOrderQuantityExist = this.state.businessAllItemsArray.some(item => item.product_quantity > 0);
		if(isOrderQuantityExist){
			this.onSubmitOrder();
		}
		else{
			this.setState({isLoading:false});
			alert(
				constantValues.businessOrAppName,
				i18n.t('addAtleastQuantity'),
				[
					{text: i18n.t('ok'), onPress: () => this.setState({isPurchaseOrderItem:false})},
				],
				{ cancelable: false }
			);
		}
	}

	onSubmitOrder = async ()=>{
		this.setState({isLoading:true});
		const {businessAllItemsArray, optionalMessage, purchaseOrderDate, purchaseOrderTime} = this.state;
		const deliveryDateTime = format(purchaseOrderDate, 'YYYY-MM-DD') + format(purchaseOrderTime, ' HH:mm');
		const { navigation, route:{params:{loginCustomerData}}} = this.props;
		const requestBody = {ordered_product:businessAllItemsArray, order_by:loginCustomerData.user_id, comment:optionalMessage, delivery_datetime:deliveryDateTime};
		const response = await clientBusinessApi.savePlacedOrder(loginCustomerData.customer_business_id,  requestBody);
		if(response.data && response.data.success === true){
			showCrossPlatformToast(i18n.t('createdOrderSuccess'));
			if(navigation.canGoBack()){
				navigation.goBack();
			}
			else{
				this.setState({isLoading:false, isPurchaseOrderItem:false});
			}
		}
		else if(response.status){
			showErrorAlertStatusCode(response.status, navigation);
			this.setState({isLoading:false});
		}
		else{
			showTechnicalError();
			this.setState({isLoading:false});
		}
	}

	onOpenTimeSelection = ()=>{
		this.setState({showPurchaseOrderSelectionTime:true, showPurchaseOrderSelectionDate:false});
	}

	onOpenDateSelection = ()=>{
		this.setState({showPurchaseOrderSelectionTime:false, showPurchaseOrderSelectionDate:true});
	}

	onChangeDate = (event, val) => {
		if(event.type==='set'){
			this.setState({purchaseOrderDate:new Date(val), showPurchaseOrderSelectionDate:false});
		}else {
			this.setState({showPurchaseOrderSelectionDate:false});
		}
	}

	onChangeTime = (event, val) => {
		if(event.type==='set'){
			this.setState({purchaseOrderTime:new Date(val), showPurchaseOrderSelectionTime:false});
		}else {
			this.setState({showPurchaseOrderSelectionTime:false});
		}
	}

	onChangeOptionalMessage = (val)=>{
		this.setState({optionalMessage:val});
	}

	rightHeaderNavigation = ()=>{
		const {navigation, route:{params}} = this.props;
		navigation.navigate('AllPurchaseOrderScreen',{
			loginCustomerData:params.loginCustomerData,
		});
	}

	onChangeOrderProductValue = (val, item)=>{
		let {changedQuantityOrderObject} = this.state;
		const regularExpression = /^(?:\d*\.\d{0,2}|\d+)$/;
  		if ((val === '' || regularExpression.test(val)) && val.toString().length <= 6) {
  			if(changedQuantityOrderObject.hasOwnProperty(item.id)){
  				item.product_quantity = val;
				changedQuantityOrderObject[item.id] = item;
				if(val <= 0){
					//if user increase and decreased purchase item quantity
					delete changedQuantityOrderObject[item.id];
				}
			}//in case quantity change
			else{
				item.product_quantity = val;
				if(val > 0){
					changedQuantityOrderObject[item.id] = item;
				}
			}
			this.forceUpdate();
		}
		else{
			null
		}
	}

	onChangeSearchText=(text)=>{
		this.setState({searchValue:text});
		if(text === ''){
			this.searchFilterFunction('');
		}
	}

	searchFilterFunction = async (searchText)=>{
		const {changedQuantityOrderObject} = this.state;
		this.setState({showLoading:true});
		if(searchText){
			searchText = searchText.trim().toUpperCase();
		}
		const { navigation, route:{params:{loginCustomerData}}} = this.props;
		let apiParams = {new_generic_version_request:true, search_value:searchText};
		const response = await clientBusinessApi.fetchProducts(loginCustomerData.business_id, apiParams);
		if(response.data && response.data.success === true){
			const responseData = response.data;
			responseData.business_products_list.map((item, index)=>{
				if(changedQuantityOrderObject.hasOwnProperty(item.id)){
					item = changedQuantityOrderObject[item.id];
				}//in case quantity change
				else{
					//To created product_quantity with default value 0(Zeror)
					item['product_quantity'] = 0;
				}
			});
			this.setState({isLoading:false, businessAllItemsArray:responseData.business_products_list, showLoading:false});
		}
		else if(response.status){
			showErrorAlertStatusCode(response.status, navigation);
		}
		else{
			showTechnicalError();
		}
	}

	onCancelInSearch = ()=>{
		this.setState({searchValue:''}, ()=> this.searchFilterFunction(''));
	}

	render() {
		let totalPurchaseOrderProduct = 0;
			this.state.businessAllItemsArray && this.state.businessAllItemsArray.map(item =>{
				totalPurchaseOrderProduct += item.product_quantity;
			})
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else if(!this.state.isPurchaseOrderItem){
			return (
				<View style={{flex:1, backgroundColor:'#ffffff'}}>
					<Header
						statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						leftComponent={<TouchableOpacity
							style={{paddingLeft:5, paddingTop:10, flexDirection:'row', alignItems:'center'}}
							onPress={() => this.props.navigation.goBack()}
							activeOpacity={0.5}>
							<FontAwesome name='chevron-left' color='#38c6f4' size={28} />
							<Text style={styles.headerTitleTextStyle}>{i18n.t('home')}</Text>
						</TouchableOpacity>}
						centerComponent={<Image resizeMode='cover' style={styles.headerIconStyle}  source={require('../assets/images/small_logo.png')}/>}
						centerContainerStyle={{justifyContent:'center'}}
						leftContainerStyle={{justifyContent:'center'}}
						rightContainerStyle={{justifyContent:'center'}}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:constantValues.gradientCommonColorsArray, start:{x: 0, y: 0}, end:{x: 1, y: 0} }}
						rightComponent={<TouchableOpacity
							style={styles.headerRightContainer}
							onPress={this.rightHeaderNavigation}
						>
							<Text style={styles.rightHeaderTitle}>{i18n.t('yourOrders')} </Text>
						</TouchableOpacity>}
					/>
					{/*<View style={{padding:10}}>
						<Text style={{fontWeight:'bold', fontSize:18,textAlign:'left',color:'#175491', paddingTop:5, paddingBottom:8}}>{i18n.t('addPlaceOrderProducts')}</Text>
						<SearchBar
							platform="android"
							placeholder="search" 
							onChangeText={this.onChangeSearchText}
							value={this.state.searchValue}
							loadingProps={{size:'large',color:'skyblue'}}
							showLoading={this.state.showLoading}
							searchIcon={{ size: 24,color:'#808080'}}
							clearIcon={!this.state.searchValue ?false :true}
							containerStyle={{marginTop:0, backgroundColor:'#ffffff',borderColor:'#ffffff',borderTopColor:'#ffffff',borderBottomColor:'#ffffff'}}
							inputContainerStyle={{backgroundColor:'#ffffff',  elevation:5}}
							onClear={this.resetCustomerData}
							returnKeyType='search'
							onSubmitEditing={(event)=>this.searchFilterFunction(event.nativeEvent.text)}
							onCancel={this.onCancelInSearch}
							selectTextOnFocus={true}	
						/>
					</View>*/}
					<ScrollView keyboardShouldPersistTaps='always'>
						{this.state.businessAllItemsArray && this.state.businessAllItemsArray.length > 0
							?this.state.businessAllItemsArray.map((item, index)=>{
								return(<View style={styles.perDeliveryJarViewStyle} key={index}>
									<View style={styles.perDeliveryIconTextViewStyle}>
										<View style={styles.productIconContainer}>
											<Image source={item.product_image_url ?{uri:item.product_image_url} :require('../assets/jar_icon/default-item-icon.png')} style={styles.productIconStyle}/>
										</View>
										<Text style={styles.productNameStyle}>{item.user_facing_name ? item.user_facing_name : item.product_name}</Text>
									</View>
									<NumericInput 
										value={item.product_quantity}
										initValue={item.product_quantity}
										onChange={(val)=> this.onChangeOrderProductValue(val, item)}
										containerStyle={{width:120,height:38}}
										iconSize={15}
										editable={true}
										minValue={0}
										borderColor={'#38c6f4'}
										textInputProps={{selectTextOnFocus:true}}
									/>
								</View>)
							})
							: null
						}
					</ScrollView>
					{this.state.businessAllItemsArray && this.state.businessAllItemsArray.length > 0
						?<View
							style={{flexDirection:'row', justifyContent:'space-between', paddingHorizontal:10, paddingVertical:10, backgroundColor:'#175491', alignItems:'center'}}
						>
							<Text style={{color:"#ffffff", fontWeight:'bold', fontSize:18,}}>{i18n.t('totalProduct')} {Math.round(totalPurchaseOrderProduct*100)/100}</Text>
							<TouchableOpacity
								style={{backgroundColor:'#38c6f4', flexDirection:'row', paddingHorizontal:10, paddingVertical:8, borderRadius:5, alignItems:'center'}}
								onPress={()=>this.setState({isPurchaseOrderItem:true})}
							>
								<Text style={{color:'#ffffff', marginRight:10, marginTop:-4, fontWeight:'bold', fontSize:18}}>{i18n.t('continue')}</Text>
								<AntDesign name="rightcircleo" size={22} color="#ffffff" />
							</TouchableOpacity>
						</View>
						: null
					}
					{this.state.businessAllItemsArray && this.state.businessAllItemsArray.length === 0 && this.state.showLoading === false && this.state.searchValue
						?<Text style={{alignSelf:'center'}}>{i18n.t('noMtchingProductFound')}</Text>
						: null
					}
				</View>
			)
		}
		else{
			return(
				<RenderPurchaseOrderSummaryUI
					businessAllItemsArray={Object.values(this.state.changedQuantityOrderObject)}
					purchaseOrderDate={this.state.purchaseOrderDate}
					purchaseOrderTime={this.state.purchaseOrderTime}
					optionalMessage={this.state.optionalMessage}
					showPurchaseOrderSelectionTime={this.state.showPurchaseOrderSelectionTime}
					showPurchaseOrderSelectionDate={this.state.showPurchaseOrderSelectionDate}
					onOpenDateSelection={this.onOpenDateSelection}
					onOpenTimeSelection={this.onOpenTimeSelection}
					onChangeDate={this.onChangeDate}
					onChangeTime={this.onChangeTime}
					onPressBack={()=>this.setState({isPurchaseOrderItem:false})}
					onChangeOptionalMessage={this.onChangeOptionalMessage}
					totalProducts={totalPurchaseOrderProduct}
					onSubmitOrder={this.beforeSumberOrder}
				/>
			)
		}
	}
};

const styles = StyleSheet.create({
	headerTitleTextStyle:{
		color: '#fff',
		fontSize:16,
		fontWeight:'bold',
		marginLeft:5
	},
	headerIconStyle:{
		height: 45,
		width: 45,
		resizeMode:'contain',
	},
	containerStyle:{
		borderBottomWidth:null,
		backgroundColor:null,
		alignItems:'center',
		elevation:5
	},
	headerRightContainer:{
		borderRadius:5,
		borderWidth:1,
		borderColor:'#ffffff',
		paddingVertical:8,
		paddingHorizontal:5,
		width:110,
		alignItems:'center',
		justifyContent : 'center',
		marginTop : 5
	},
	rightHeaderTitle:{
		color: '#fff',
		fontSize:16,
		fontWeight:'bold',
	},
	loadingViewStyle:constantValues.loadingViewStyle,
	perDeliveryJarViewStyle:{
		paddingTop:15,
		paddingBottom:15,
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		borderTopWidth:0.5,
		borderTopColor:'#38C6F4',
		paddingHorizontal:10,
	},
	perDeliveryIconTextViewStyle:{
		flexDirection:'row',
		alignItems:'center'
	},
	productIconStyle:{
		width:20,
		height:20,
		resizeMode:'contain',
	},
	productIconContainer:{
		borderRadius:15,
		padding:5,
		borderWidth:1,
		borderColor:"#38c6f4",
	},
	productNameStyle:{
		fontSize:16,
		marginLeft:5,
		color:'#175491',
		width:wp('40%'),
	},
	numericIconStyle:{
		color: 'white',
		fontWeight:'bold'
	},
	numericInputStyle:{
		backgroundColor:'#ffffff',
		borderColor:'#38c6f4',
		borderWidth:1
	},
	transportIcon:{
		width:50,
		height:50,
		resizeMode:'contain',
	},
	iconAndTitleContainer:{
		flexDirection:'row',
		alignItems:'center',
	},
	placeOrderUIContainer:{
 		backgroundColor:'#ffffff',
 		paddingHorizontal:10,
 		paddingVertical:5,
 		flexDirection:'row',
 		justifyContent:'space-between',
 		alignSelf:'center',
 		alignItems:'center',
 		width:wp('93%'),
 		borderRadius:5,
 		borderColor:'#ffffff',
 		elevation:5,
 		shadowColor: '#3d3d3d',
	 	shadowRadius: 2,
	 	shadowOpacity: 0.5,
		shadowOffset: {
			height: 1,
		},
		marginTop:10,
 	},
 	titleTextStyle:{
 		color:'#175491',
 		fontSize:13,
 	},



	screenMainContainer:{
		flex:1,
		backgroundColor:'#ffffff'	
	},
	leftHeaderContainer:{
		marginLeft:10,
		marginTop:-20,
		flexDirection:'row',
		alignItems:'center'
	},
	perDeliveryIconTextViewStyle:{
		flexDirection:'row',
		alignItems:'center'
	},
	datePickerStyle:{
		justifyContent:'center',
		alignItems:'center',
	},
	datePickerViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-evenly',
		marginBottom:10,
		marginTop:10,
	},
	datePickerTouchableStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'center',
		paddingTop:10
	},
	jarEntryDateTextStyle:{
		fontSize: 18,
		paddingBottom:5,
		color: "#38c6f4",
		marginLeft:5,
		borderBottomWidth:1,
		borderBottomColor:'#38c6f4',
	},
});

PlacePurchaseOrder.defaultProps = {
	placeOrderUIContainer:styles.placeOrderUIContainer,
	titleTextStyle:styles.titleTextStyle,
	transportIcon:styles.transportIcon,
	iconAndTitleContainer:styles.iconAndTitleContainer,
}