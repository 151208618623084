import { View, StyleSheet, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { FontAwesome } from '@expo/vector-icons'; 

export default function StarRating(props){

	const {starSize, filledColor, emptyColor, onChange, disabled, value } = props

	return (
		<View style={styles.container}>
			<TouchableOpacity style={[styles.button]} onPress={()=>onChange(1)} disabled={disabled}>
				<FontAwesome name={ value>=1 ? "star" : "star-o"} size={starSize} color={value>=1 ? filledColor : emptyColor} />
			</TouchableOpacity>
			<TouchableOpacity style={[styles.button]} onPress={()=>onChange(2)} disabled={disabled}>
				<FontAwesome name={ value>=2 ? "star" : "star-o"} size={starSize} color={value>=2 ? filledColor : emptyColor} />
			</TouchableOpacity>
			<TouchableOpacity style={[styles.button]} onPress={()=>onChange(3)} disabled={disabled}>
				<FontAwesome name={ value>=3 ? "star" : "star-o"} size={starSize} color={value>=3 ? filledColor : emptyColor} />
			</TouchableOpacity>
			<TouchableOpacity style={[styles.button]} onPress={()=>onChange(4)} disabled={disabled}>
				<FontAwesome name={ value>=4 ? "star" : "star-o"} size={starSize} color={value>=4 ? filledColor : emptyColor} />
			</TouchableOpacity>
			<TouchableOpacity style={[styles.button]} onPress={()=>onChange(5)} disabled={disabled}>
				<FontAwesome name={ value>=5 ? "star" : "star-o"} size={starSize} color={value>=5 ? filledColor : emptyColor} />
			</TouchableOpacity>
		</View>
	)
}

const styles = StyleSheet.create({
	container : {
		flexDirection : 'row',
		padding : 10
	},
	button : {
		marginLeft:5
	}
})


StarRating.propTypes = {
	starSize : PropTypes.number,
	filledColor : PropTypes.string,
	emptyColor : PropTypes.string,
	value : PropTypes.number,
	onChange : PropTypes.func,
	disabled : PropTypes.bool
}

StarRating.defaultProps = {
	starSize : 25,
	filledColor : 'royalblue',
	emptyColor : '#58595B',
	value : 1,
	onChange : ()=>{},
	disabled : false
}