/*****************************
* environment.js
* path: '/environment.js' (root of your project)
******************************/

import Constants from 'expo-constants';
import { Platform } from "react-native";


const ENV = {
	// office
	// dev: {
	// 	apiUrl: "http://192.168.43.30:8000/",
	// 	businessApiUrl:'http://192.168.43.30:8000/api/v1/',
	// 	googleApiKey:'',
	// },
	
	//home
	dev: {
		apiUrl: "http://192.168.29.197:8000/",
		businessApiUrl:'http://192.168.29.197:8000/api/v1/',
		googleApiKey:'AIzaSyDRZbfuOlogek__jEplps9Ln9TvtIBNJr8',
	},
	staging: {
		apiUrl: "https://gpstaging.gopaani.com/",
		googleApiKey:'AIzaSyDRZbfuOlogek__jEplps9Ln9TvtIBNJr8',
		businessApiUrl:'https://gpstaging.gopaani.com/api/v1/',
	},
	prod: {
		apiUrl: "https://gpstaging.gopaani.com/",
		googleApiKey:'AIzaSyDRZbfuOlogek__jEplps9Ln9TvtIBNJr8',
		businessApiUrl:'https://gpstaging.gopaani.com/api/v1/',
	},
	testing:{
		apiUrl: "https://gpstaging.gopaani.com/",
		googleApiKey:'AIzaSyDRZbfuOlogek__jEplps9Ln9TvtIBNJr8',
		businessApiUrl:'https://gpstaging.gopaani.com/api/v1/',
	}
};

const getEnvVars = (env = Constants.manifest.releaseChannel) => {
	// What is __DEV__ ?
	// This variable is set to true when react-native is running in Dev mode.
	// __DEV__ is true when run locally, but false when published.
	if (__DEV__) {
		return ENV.dev;
	} 
	else if (env === 'staging') {
		return ENV.staging;
	}
	else if (env === 'prod') {
		return ENV.prod;
	}
	else if (env === 'testing'){
		return ENV.testing;
	}
	else{
		return ENV.prod;
	}
};

export default getEnvVars;