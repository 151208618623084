import React, { Component } from 'react';
import getEnvVars from '../environment';
const { apiUrl } = getEnvVars();
import { Text, View, StyleSheet, ScrollView, TextInput,ActivityIndicator, Image, Switch,TouchableOpacity, Linking, Platform} from 'react-native';
import {Button,Overlay} from 'react-native-elements';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons,AntDesign,FontAwesome} from '@expo/vector-icons';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
const axios = require('axios');
import i18n from 'i18n-js';
import translationValues from '../components/translation';
import AsyncStorage from '@react-native-async-storage/async-storage'
export default class CustomerLoginScreen extends Component{

	constructor(props){
		super(props)
		this.state = {
			isLoading:true,
			phone_number: '',
			showErrorMessage:false,
			errorMessage:null,
			changeLanguageValue:true,
			showLanguageOverlay:false,
			language:'Hindi',
			languageCode:'hi',
			changedLanguage:'Hindi',
			isBusinessCustomerUser:false,
			learnCustomerAppUsingVideo:'https://youtu.be/ambPtUateGQ'
		}
		this.onChangeText = this.onChangeText.bind(this);
		this.willBlurSubscription='';
		this.willFocusSubscription = '';
	}

	componentDidMount(){
		this.willBlurSubscription = this.props.navigation.addListener('blur',() => {this.setState({isLoading:false,showErrorMessage:false,phone_number:'' })
		});
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
 			i18n.fallbacks = true;
 			i18n.translations = translationValues;
 			i18n.locale = global.isLanguage
 			this.getUserSelectedValueFromWelcomeScreen();
 			this.showSelectLanguageOverlayOnFirstLogin();
 			this.fetchLanguage();
 		});
	}

	componentWillUnmount(){
		this.willBlurSubscription();
		this.willFocusSubscription();
	}

	fetchLanguage = async () => {
		let isLanguage = await AsyncStorage.getItem('isLanguage');
		if (isLanguage) {
			global.isLanguage = isLanguage
			if (isLanguage==='en') {
				this.setState({changedLanguage:'English',language:'English'})
			}
			else if (isLanguage==='mr') {
				this.setState({changedLanguage:'Marathi',language:'Marathi'})
			}
			else if (isLanguage==='gu') {
				this.setState({changedLanguage:'Gujarati',language:'Gujarati'})	
			}
		}
		else{
			global.isLanguage='hi';
		}
	}

	getUserSelectedValueFromWelcomeScreen = async () => {
		let isBusinessCustomerUserJson = await AsyncStorage.getItem('isBusinessCustomerUser');
		let isBusinessCustomerUser = JSON.parse(isBusinessCustomerUserJson)
		this.setState({isBusinessCustomerUser:isBusinessCustomerUser,isLoading:false})
	}

	customerLogin = async () => {
		const{phone_number} = this.state;
		this.setState({isLoading:true})
		try{
			const response = await axios.post(apiUrl+'customer-phone-verify/',{
				phone_number:phone_number,
				is_new_otp_changes:true,
				is_new_api_request:true,
				from_web : Platform.OS==='web' ? true : false
			});
			if(response.data.success){
				this.setState({
					phone_number: '',
				});
				this.props.navigation.navigate('OtpVerifyView',{
					registed_phone_number:response.data.registed_phone_number,
					otp_timeout:response.data.otp_timeout
				});
			}
			else{
				if(response.data.errors.hasOwnProperty('phone_number')){
					this.setState({isLoading:false,showErrorMessage:true,errorMessage:response.data.errors.phone_number[0]})
				}
			}
		}
		catch(err){
			console.log(err);
		}

	};

	onChangeText(key, val){
		const re = /^[0-9\b]+$/;
		if (val === '' || re.test(val)) {
			this.setState({[key]: val})
		}
	}

	changeLanguageCode = async(languageCode,language)=>{
		global.isLanguage = languageCode	
		i18n.locale = languageCode
		await AsyncStorage.setItem('isLanguage',languageCode);
		this.setState({showLanguageOverlay:false,changedLanguage:language})
	}
	//here we get AsyncStorage value when customer first time login and if value is null then we show change language overlay and set value of AsyncStorage true. 
	showSelectLanguageOverlayOnFirstLogin = async () =>{
		let isCustomerFirstLogin = await AsyncStorage.getItem('isCustomerFirstLogin')
		if(isCustomerFirstLogin === null){
			this.setState({showLanguageOverlay:true})
		}
		await AsyncStorage.setItem('isCustomerFirstLogin',JSON.stringify(true))
	}

	render(){
		if(this.state.isLoading){
			return(
				<View style={{position: 'absolute',left: 0,right: 0,top: 0,bottom: 0,alignItems: 'center',justifyContent: 'center', backgroundColor:'#ffffff'}}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<ScrollView style={{flex:1, backgroundColor:'#ffffff'}} keyboardShouldPersistTaps={'always'}>
					<LinearGradient
						colors={['#175491','#175491','#5154b5','#5154b5']}
						style={{flex:1}}
						start={{x: 0, y: 0}}
						end={{x: 1, y: 0}}>
						<View style={{height:hp('33%'),alignItems:'center'}}>
							<Image 
								source={require('../assets/images/small_logo.png')} 
								style={{height: hp('30%'),width: wp('25%'),resizeMode : 'contain'}}
							/>
						</View>	
					</LinearGradient>
					<Overlay
						isVisible={this.state.showLanguageOverlay}
						width={wp('90%')}
						height={hp('60%')}
						onBackdropPress={() =>this.setState({showLanguageOverlay:false,language:this.state.changedLanguage})}
						overlayStyle={styles.overlayStyle}
					>
						<View > 
							<View style={styles.chooseLanguageTitleView}>
								<Text style={styles.chooseLanguageTitleText}>{i18n.t('choose_language')}</Text>
							</View>
							<View style={styles.eachLanguageView}>
								<TouchableOpacity 
									style={styles.firstLanguage}
									onPress={()=>this.setState({languageCode:'en',language:'English'})}

								>
									<View style={styles.languageView}>
										<Text style={styles.bigFontLanguage}>English</Text>
										{this.state.language==='English'
											&&
											<FontAwesome name='check-circle' size={25} color='#38c6f4' style={{marginTop:5,}}/>
										}
										
									</View>
									<View style={styles.languageSection}>
										<View style={{paddingTop:10}}><Text style={{color:'#ffffff'}}>(English)</Text></View>
										<Text style={styles.wordStyle}> A</Text>
									</View>
								</TouchableOpacity>
								<TouchableOpacity 
									style={styles.secondLanguage}
									onPress={()=>this.setState({languageCode:'hi',language:'Hindi'})}
								>
									<View style={styles.languageView}>
										<Text style={styles.bigFontLanguage}>Hindi</Text>
										{this.state.language==='Hindi'
											&&
											<FontAwesome name='check-circle' size={25} color='#38c6f4' style={{marginTop:5,}}/>
										}
									</View>
									<View style={styles.languageSection}>
										<View style={{paddingTop:10}}><Text style={{color:'#ffffff'}}>(Hindi)</Text></View>
										<Text style={styles.wordStyle}> अ</Text>
									</View>
								</TouchableOpacity>

							</View>
							<View  style={styles.eachLanguageView}>
								<TouchableOpacity 
									style={styles.firstLanguage}
									onPress={()=>this.setState({languageCode:'mr',language:'Marathi'})}
								>
									<View style={styles.languageView}>
										<Text style={styles.bigFontLanguage}>मराठी</Text>
										{this.state.language==='Marathi'
											&&
											<FontAwesome name='check-circle' size={25} color='#38c6f4' style={{marginTop:5,}}/>
										}
									</View>
									<View style={styles.languageSection}>
										<View style={{paddingTop:10}}><Text style={{color:'#ffffff'}}>(Marathi)</Text></View>
										<Text style={styles.wordStyle}> म</Text>
									</View>
								</TouchableOpacity>
								<TouchableOpacity  
									style={styles.secondLanguage}
									onPress={()=>this.setState({languageCode:'gu',language:'Gujarati'})}
								>
									<View style={styles.languageView}>
										<Text style={styles.bigFontLanguage}>ગુજરાતી</Text>
										{this.state.language==='Gujarati'
											&&
											<FontAwesome name='check-circle' size={25} color='#38c6f4' style={{marginTop:5,}}/>
										}
									</View>
									<View style={styles.languageSection}>
										<View style={{paddingTop:10}}><Text style={{color:'#ffffff'}}>(Gujarati)</Text></View>
										<Text style={styles.wordStyle}> ગ</Text>
									</View>
								</TouchableOpacity>
							</View>
							<View style={styles.overlayButtonView}>
								<Button
									TouchableComponent={TouchableOpacity}
									icon={ <AntDesign name="arrowright" size={30} color="white" style={styles.overlayButton} />}
									iconRight={true}
									titleStyle={{fontSize:20}}
								 	title={i18n.t('ok')}
								 	buttonStyle={{width:wp('30%'),backgroundColor:'#38c6f4'}}
								 	containerStyle={{alignItems:"center",}}
								 	onPress={()=>this.changeLanguageCode(this.state.languageCode,this.state.language)}
								/>
							</View>			
						</View>	
					</Overlay>
					<View style={{alignItems:'center'}}>
						<View style={styles.innerView}>
							<View style={styles.innerViewStyle}>
								<View style={{height:40}}>
									<Text style={{fontWeight:'bold', fontSize:20,textAlign:'center',marginTop:10,color:'#175491'}}>{i18n.t('customer_login_heading')}</Text>
									
								</View>
								{this.state.isBusinessCustomerUser === true &&
									<Text style={{fontSize:14,textAlign:'center',color:'#808080'}}>{i18n.t('headingWaterDeliveryCustomers')}</Text>
								}
								<View style={this.state.showErrorMessage ?styles.inputTextBoxViewError:styles.inputTextBoxView}>
									<Image 
										source={require('../assets/images/mb_icon.png')} 
										style={{height: 25,width: 25,resizeMode : 'contain', tintColor:'#175491'}}
									/>
									<TextInput
										style={styles.input}
										placeholder={i18n.t('registered_mobile_number')}
										maxLength={10}
										autoCapitalize="none"
										placeholderTextColor='#808080'
										keyboardType='phone-pad'
										onChangeText={val => this.onChangeText('phone_number', val)}
										value={this.state.phone_number}
									/>
								</View>
								{this.state.showErrorMessage &&
									<Text style={{fontSize:12,textAlign:'left',color:'red', paddingLeft:10, paddingRight:10}}>{this.state.errorMessage}</Text>
								}
							</View>
							<View style={{flexDirection:'row', justifyContent:'center',alignItems:'center',marginTop:-25}}>
								<Button
									ViewComponent={LinearGradient}
									linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0},end:{x: 1, y: 0},}}
									title={i18n.t('submit')}
									buttonStyle={{width:wp('80%'),padding:10,borderRadius:5}}
									containerStyle={{alignItems:"center"}}
									titleStyle={{fontWeight:'bold',color:'#ffffff',fontSize:20,textAlign:'center'}}
									onPress={this.customerLogin}
									disabled={this.state.phone_number.length <10 ?true:false}
									disabledTitleStyle={{color:'hsl(208, 8%, 63%)'}}
								/>
							</View>
							<View style={styles.chooseLanguageButtonView}>
								<TouchableOpacity
									onPress={()=>this.setState({showLanguageOverlay:true})}
									style={{flexDirection:'row',}}
									>
									<View style={{width:wp('65%')}}>
										<Text style={styles.chooseLanguageText}>{i18n.t('choose_language')}</Text>
									</View>
									<Text style={styles.selectedLanguage}>{this.state.changedLanguage} </Text>
									<AntDesign name='right'  size={20} style={{ marginLeft:5,color:'#175491',}} />	
								</TouchableOpacity>
							</View>

							{this.state.isBusinessCustomerUser === true &&
								<TouchableOpacity
									onPress={()=>Linking.openURL(this.state.learnCustomerAppUsingVideo)}
									style={styles.watchVideoButtonView}
									>
									<View style={{flexDirection:'row', alignItems:'center'}}>
										<Image source={require('../assets/images/video.png')} style={{width:30, height:30, resizeMode:'contain'}}/>
										<Text style={{fontSize:20,color:'#ffffff', fontWeight:'bold', marginLeft:10, letterSpacing:2.5}}>{i18n.t('watchVideo')}</Text>
									</View>
									<Text style={{fontSize:14,color:'#ffffff'}}>{i18n.t('buttonHeadingWatchVideo')}</Text>
								</TouchableOpacity>
							}
							
							
						</View>
					</View>
				</ScrollView>
			);
		}
	}
}

const styles = StyleSheet.create({
  input: {
	width: wp('75%'),
	height:70,
	color:'#808080',
	fontSize: 14,
	marginLeft:10
  },
  innerView:{
	alignItems: 'center',
	width:wp('95%'),
	backgroundColor: '#fff',
	padding:10,
	marginTop:-50,
	borderRadius:10,
  },
  innerViewStyle:{
	width:wp('90%'),
	backgroundColor: '#fff',
	borderRadius:5,
	borderWidth:1,
	borderColor:'#175491',
	alignItems:'center',
	paddingBottom:80
  },
  inputTextBoxViewError:{
  	flexDirection: 'row', 
  	borderBottomWidth:0.5, 
  	borderBottomColor:'red',
  	alignItems:'center',
  	justifyContent:'center',
  	paddingTop:30,
  	marginLeft:20,
  	paddingLeft:5,
  	marginRight:20,
  },
  inputTextBoxView:{
  	flexDirection: 'row',
  	borderBottomWidth:0.5,
  	borderBottomColor:'#175491',
  	alignItems:'center',
  	justifyContent:'center',
  	paddingTop:30,
  	marginLeft:20,
  	paddingLeft:5,
  	marginRight:20,
  },
   switchView:{
  	flexDirection:'row',
  	height:hp('11%'),
  	width:wp('95%'),
  	justifyContent:'flex-end',
  	marginTop:-80,
  	
  },
  switchStyle:{
	 marginTop:-40,
	 marginBottom:15
	},languageView:{
  	height:hp('5%'),
  	flexDirection:'row',
  	justifyContent:'space-between'
  },
  chooseLanguageTitleView:{
  	paddingTop:10,
  	paddingBottom:10
  },
  chooseLanguageTitleText:{
  	color:'#999999',
  	fontSize:20
  },
  overlayButtonView:{
  	justifyContent:'center',
  	marginTop:20
  },
  overlayButton:{
  	marginLeft:10,
  	marginTop:5
  },eachLanguageView:{
  	flexDirection:'row',
  	width:wp('84.5%'),
  	paddingBottom:10,
  	justifyContent:'space-between',

  }, firstLanguage:{
  	backgroundColor:'#175491',
  	height:hp('11%'),
  	width:wp('41%'),
  	justifyContent:'space-between',
  	paddingLeft:5,
  	paddingRight:5,
  	borderRadius:5
  },
  secondLanguage:{
  	backgroundColor:'#175491',
  	height:hp('11%'),
  	width:wp('41%'),
  	justifyContent:'space-between',
  	paddingLeft:5,
  	paddingRight:5,
  	borderRadius:5
  }, bigFontLanguage:{
  	fontSize:20,
  	color:'#ffffff'
  },  languageSection:{
  	flexDirection:'row' ,
  	justifyContent:'space-between'
  },
  chooseLanguageButtonView:{
  	flexDirection:'row',
  	alignItems:'center',
  	justifyContent:'space-around',
  	width:wp('90%'),
  	borderColor:'#175491',
  	borderWidth:1,
  	paddingTop:15,
  	paddingBottom:15,
  	marginTop:20
  },
  watchVideoButtonView:{
  	alignItems:'center',
  	justifyContent:'center',
  	width:wp('90%'),
  	backgroundColor:'#175491',
  	paddingBottom:10,
  	marginTop:20
  },
  chooseLanguageText:{
  	color:'#999999',
  	marginLeft:10
  },
  selectedLanguage:{
  	color:'#175491',
  	fontSize:14
  }, wordStyle:{
  	fontSize:34,
  	color:'#DCEDF9',
  	fontFamily:'serif',
  	fontWeight:'bold'
  },
  overlayStyle:{
    width:wp('90%'),
    top:-65,
    height:hp('60%')
  }
})