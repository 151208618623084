import React, { Component } from 'react';
import getEnvVars from '../environment';
const { apiUrl, businessApiUrl } = getEnvVars();
import { LinearGradient } from 'expo-linear-gradient';
import {Button, Header, Avatar, Overlay} from 'react-native-elements';
import { Ionicons, FontAwesome, MaterialCommunityIcons, Feather} from '@expo/vector-icons';
import { Text, View, StyleSheet, ActivityIndicator, TouchableOpacity, ScrollView, AsyncStorage, Switch, Image, Linking, FlatList, Platform} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
const axios = require('axios');
import i18n from 'i18n-js';
import {format} from 'date-fns';
import * as WebBrowser from 'expo-web-browser';
import * as Print from 'expo-print';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import * as Permissions from 'expo-permissions';
import styles from '../style/customerJarEntry.styles';
import translationValues from '../components/translation';
import {getJarTypeValue} from '../components/getJarTypeValue';
import logout from '../components/logout';
import {BillPdfGenerateWithOption} from '../components/billPdfGenerateWithOption';
import alert from '../utils/commonAlert'
import Picker from '../components/picker'
import client from '../client';
import {showErrorAlertStatusCode} from '../utils/showErrorAlertStatusCode';

export default class CustomerJarEntry extends Component {
	constructor(props){
		super(props);
		var date = new Date();
		date.setDate(1);
		let jarPriod = format(date,'D-M-YYYY')
		this.state={
			isLoading:true,
			jarPriod:jarPriod,
			jarType:'cool',
			listCustomerJarEntry:[],
			isJarTypeCool:true,
			showBillOverlay:false,
			selectedBillData:[],
			productDeliveredTotalAmount:0,
			showShareOptionOverlay:false,
			isForDownload:'download',
			isForSharing:'share',
			isForPrint:'print',
			billDate:null
		}
		this.willFocusSubscription = ''
	}
	componentDidMount(){			
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			i18n.fallbacks = true;
			Platform.OS==='web' && window.history.replaceState(null,null,"/CustomerJarEntry")
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
			this.getCustomerJarEntryDetails();
			this.getUserFacingJarTypeValues();
		});
	}

	componentWillUnmount() {
		this.willFocusSubscription();
	}

	getCustomerJarEntryDetails = () => {
		const { navigation, route, route: { params } } = this.props;
		var date = new Date();
		date.setDate(1);
		let jarPriod = format(date,'D-M-YYYY')
		let deliveredJarTypelist = params.deliveredJarTypelist;
		let jarTypeValue = this.state.jarType
		if(deliveredJarTypelist.length === 1){
			jarTypeValue = deliveredJarTypelist[0]
		}
		this.setState({jarType:jarTypeValue, isJarTypeCool:jarTypeValue === 'cool'}, () => this.fetchCustomerJarEntry(jarPriod, jarTypeValue))
	};


	getUserFacingJarTypeValues = async () => {
		const {userFacingNameCool, userFacingNamePet, productImageOfCool, productImageOfPet} = await getJarTypeValue();
		this.setState({userFacingNameCool:userFacingNameCool,userFacingNamePet:userFacingNamePet,productImageOfCool:productImageOfCool, productImageOfPet:productImageOfPet})
	}

	fetchCustomerJarEntry = async (jarPriod, jarType) => {
		const { navigation, route, route: { params } } = this.props;
		const loginCustomerData = params.loginCustomerData;
		const apiParams = {
			jar_type:jarType,
			date_string:jarPriod,
			new_generic_version_request:true
		};
		const response = await client.fetchCustomerJarEntry(loginCustomerData.business_id, loginCustomerData.customer_business_id, apiParams);
		if(response.data && response.data.success){
			const responseData = await response.data;
			this.setState({listCustomerJarEntry:responseData.customer_data.list_of_jar_entry, isLoading:false});
		}
		else if(response.data && response.data.statusCode){
			showErrorAlertStatusCode(response.data.statusCode, navigation);
		}
		else{
			showErrorAlertStatusCode(response.status, navigation);
		}
	};

	OnselectValue = (itemValue, itemIndex) =>{
		this.setState({isLoading:true, jarPriod:itemValue})
		this.fetchCustomerJarEntry(itemValue, this.state.jarType)
	};
	renderSeparator = () => {
		return (
			<View style={styles.renderSeparatorViewStyle}/>
		);
	}
	renderNotFoundData = () => {
		return (
			<View style={styles.renderNotFoundViewStyle}>
				<Text style={styles.notFoundDataTextStyle}>{i18n.t('noProductDeliveredMessage')}</Text>
			</View>
		);
	};
	renderHeaderForJarCustomers = () => {
		var monthName = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
		var d = new Date();
		d.setDate(1);
		let monthYearList = []
		for (let i=0; i<=5; i++) {
			monthYearList.push({value:format(d,'D-M-YYYY') ,label:i18n.t(monthName[d.getMonth()]) + ' ' + d.getFullYear()})
			d.setMonth(d.getMonth() - 1);
		}
		return(
			<View style={styles.mainHeaderContainerViewStyle}>
				<View style={styles.mainHeadingViewStyle}>
					<Image 
						source={require('../assets/images/product-icon.png')} 
						style={styles.jarEntryIconStyle}
					/>
					<Text style={styles.headingTextStyle}>{i18n.t('productEntry')}</Text>
				</View>
				<View style={styles.dropdownViewStyle}>
					<Picker
						value={this.state.jarPriod}
						onChangeValue={this.OnselectValue}
						items={monthYearList}
						numberOfOptions={10}
					/>
				</View>
			</View>
		)
	}

	renderItemDataProductCustomers = ({item, index}) => {
		return(
			<View style={styles.productMainItemViewStyle}>
				<View style={styles.itemTopViewStyle}>
					<View style={styles.dateItemViewStyle}>
						<Image
							source={require('../assets/images/date_time.png')} 
							style={styles.datetimeIconeStyle}
						/>
						<Text style={styles.dateTimeTextStyle}>{format(item.date_added,'DD')}{' '}{i18n.t(format(item.date_added,'MMMM'))}</Text>
					</View>
					<View style={styles.itemTopHeadingViewStyle}>
						<View style={styles.businessUserItemViewStyle}>
							<Image
								source={require('../assets/images/employee-avatar-without-circle.png')} 
								style={styles.employeeIconStyle}
							/>
							<Text numberOfLines={2} ellipsizeMode='tail'  style={styles.employeeNameTextStyle}>{item.employee_name ?item.employee_name :'---'}</Text>
						</View>
						<TouchableOpacity style={styles.billItemViewStyle}
							onPress={()=>this.setState({showBillOverlay:true, billDate:item.date_added, selectedBillData:JSON.parse(JSON.stringify(item.product_data)),productDeliveredTotalAmount:item.total_amount})}>
							<Image 
								source={require('../assets/images/billing_icon.png')} 
								style={styles.billIconStyle}
							/>
							<Text style={styles.billTextStyle}>{i18n.t('bill')}</Text>
						</TouchableOpacity>
					</View>
				</View>
				<View style={styles.productsViewStyle}>
					<View style={styles.addedProductViewStyle}>
						{item.product_data.map((eachItem, index) => {
							return(
								<View style={styles.eachProductItemViewStyle} key={index}>
									<View style={styles.productNameViewStyle}>
										<Image resizeMode='contain' style={styles.productIconStyle}  source={eachItem.image_url ?{uri:eachItem.image_url} :require('../assets/jar_icon/default-item-icon.png')}/>
										<Text style={styles.productNameTextStyle}>{eachItem.user_facing_name ? eachItem.user_facing_name : eachItem.product_name}</Text>
									</View>
									<View style={styles.productQuantityViewStyle}>
										<Text style={styles.inTextStyle}>{i18n.t('in')}</Text>
										<Text style={styles.inTextStyle}>{eachItem.total_product_delivered}</Text>
									</View>
									{eachItem.is_product === false &&
										<View style={styles.productQuantityViewStyle}>
											<Text style={styles.outTextStyle}>{i18n.t('out')}</Text>
											<Text style={styles.outTextStyle}>{eachItem.total_product_collected}</Text>
										</View>
									}
								</View>
							)}
						)}
					</View>
				</View>
			</View>
		);
	}
	shareDownloadPDF = async (shareOption) => {
		const { navigation, route, route: { params } } = this.props;
		const loginCustomerData = params.loginCustomerData;
		let generateUrl = `${businessApiUrl}products/${loginCustomerData.business_id}/bill/${loginCustomerData.customer_business_id}/?date_string=${this.state.billDate}&format_type=a4&bill_option=single_day&token=${loginCustomerData.token}`
		BillPdfGenerateWithOption(loginCustomerData.token, generateUrl, shareOption)
		this.setState({showBillOverlay:false, showShareOptionOverlay:false})
	}
	render() {
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			);
		}
		else{
			return(
				<View style={styles.mainViewStyle}>
					<Header
						statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						centerComponent={<Image resizeMode='cover' style={styles.headerIconStyle}  source={require('../assets/images/small_logo.png')}/>}
						centerContainerStyle={{justifyContent:'center'}}
						leftContainerStyle={{justifyContent:'center'}}
						leftComponent={
							<TouchableOpacity
								style={styles.headerLeftComponentStyle}
								activeOpacity={0.5}
								onPress={()=>this.props.navigation.goBack()}>
								<FontAwesome name='chevron-left' color='#38c6f4' size={24} />
								<Text style={styles.headerTitleTextStyle}>{i18n.t('home')}</Text>
							</TouchableOpacity>
						}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
					/>
					<FlatList
						data={this.state.listCustomerJarEntry}
						renderItem={this.renderItemDataProductCustomers}
						ListEmptyComponent={this.renderNotFoundData}
						ListHeaderComponent={this.renderHeaderForJarCustomers}
						keyExtractor={(item,index) => index.toString()}
						ItemSeparatorComponent={this.renderSeparator}
						extra={this.state}
						contentContainerStyle={{ flexGrow: 1 }}
					/>
					<Overlay
						isVisible={this.state.showBillOverlay}
						onBackdropPress={() => this.setState({ showBillOverlay: false, billDate:'',selectedBillData:[]})}
						overlayStyle={styles.billoverlayStyle}
					>
						<View style={styles.billoverlayMainViewStyle}>
							<View style={styles.billTopHeadingViewStyle}>
								<Text style={styles.billDateTextStyle}>{format(this.state.billDate, 'DD MMM YYYY')}</Text>
								<TouchableOpacity
									style={styles.billShareIconStyle}
									onPress={()=> this.setState({ showShareOptionOverlay: true,})}>
									<MaterialCommunityIcons name="share" size={24} color="#175491" />
									<Text style={styles.shareTextStyle}>{i18n.t('share')}</Text>
								</TouchableOpacity>
							</View>
							<View>
								<View>
									{this.state.selectedBillData.map((item, index) => {
										return(
											item.total_product_delivered > 0 &&
											<View style={styles.billProductItemViewStyle} key={index}>
												<View style={styles.productItemViewStyle}>
													<Text numberOfLines={1} ellipsizeMode='tail' style={styles.productItemNameTextStyle}>{i18n.t('total')}{' '}{item.user_facing_name ?item.user_facing_name :item.product_name}</Text>
													<Text style={styles.multiplyTextStyle}>{'X'}</Text>
													<Text style={styles.priceTextStyle}>{i18n.t('price')}</Text>
												</View>
												<View style={styles.productItemDeliveredValueStyle}>
													<Text style={styles.productDeliveredValueTextStyle}>{'( '}{item.total_product_delivered}</Text>
													<Text style={styles.multiplyProductValueTextStyle}>{'X'}</Text>
													<Text style={styles.productPriceValueTextStyle}>{item.product_price}{' )'}</Text>
												</View>
												<View style={styles.productAmountViewStyle}>
													<FontAwesome name="rupee" size={24} color="#38c6f4" />
													<Text style={styles.productPriceValueTextStyle}>{item.product_amount}</Text>
												</View>
											</View>
										)}
									)}
								</View>
								<View style={styles.productTotalAmountViewStyle}>
									<Text style={styles.totalAmountTextStyle}>{i18n.t('total_amount')}</Text>
									<View style={styles.productAmountViewStyle}>
										<FontAwesome name="rupee" size={24} color="#38c6f4" />
										<Text style={styles.productPriceValueTextStyle}>{this.state.productDeliveredTotalAmount}</Text>
									</View>
								</View>
								<View style={styles.cancelButtonViewStyle}>
									<TouchableOpacity style={styles.cancelTouchableStyle} 
										activeOpacity={0.5}
										onPress={()=> this.setState({showBillOverlay:false,billDate:'',selectedBillData:[]})}>
										<Text style={styles.cancelTextStyle}>{i18n.t('cancelOption')}</Text>
									</TouchableOpacity>
								</View>
							</View>
						</View>
					</Overlay>
					<Overlay
						isVisible={this.state.showShareOptionOverlay}
						onBackdropPress={() => this.setState({ showShareOptionOverlay: false,})}
						overlayStyle={styles.shareOptionOverlay}
					>
						<View>
							<View style={styles.shareOptionViewStyle}>
							{
								Platform.OS!=='web'
								?	<TouchableOpacity style={styles.optionTouchableStyle}
										onPress={()=> this.shareDownloadPDF(this.state.isForSharing)}>
										<Ionicons name='md-share' size={32} color='#175491' />
										<Text style={styles.optionLabelText}>{i18n.t('share')}</Text>
									</TouchableOpacity>
								: null
							}
								<TouchableOpacity style={styles.optionTouchableStyle}
									onPress={()=> this.shareDownloadPDF(this.state.isForDownload)}>
									<Feather name="download" size={32} color="#175491"/>
									<Text style={styles.optionLabelText}>{i18n.t('download')}</Text>
								</TouchableOpacity>
								<TouchableOpacity style={styles.optionTouchableStyle}
									onPress={()=> Platform.OS!=='web' ? this.shareDownloadPDF(this.state.isForPrint) : this.shareDownloadPDF(this.state.isForDownload)}>
									<Ionicons name='md-print' size={32} color='#175491'/>
									<Text style={styles.optionLabelText}>{i18n.t('print')}</Text>
								</TouchableOpacity>
							</View>
							<View style={styles.cancelButtonViewStyle}>
								<TouchableOpacity style={styles.cancelTouchableStyle} 
									activeOpacity={0.5}
									onPress={()=> this.setState({showShareOptionOverlay:false})}>
									<Text style={styles.cancelTextStyle}>{i18n.t('cancelOption')}</Text>
								</TouchableOpacity>
							</View>
						</View>
					</Overlay>
				</View>
			)
		}
	};
}
