import React, { Component } from 'react';
import {StyleSheet,Text,View,TouchableOpacity,Image,TextInput,FlatList,ActivityIndicator, AppState, Clipboard, Platform } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Header } from 'react-native-elements';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { Ionicons, FontAwesome, MaterialCommunityIcons} from '@expo/vector-icons';
import {format} from 'date-fns';
import getEnvVars from '../environment';
const { apiUrl, paymentUrl } = getEnvVars();
const axios = require('axios');
import i18n from 'i18n-js';
import translationValues from '../components/translation';
import Autolink from 'react-native-autolink';
import logout from '../components/logout';
import AsyncStorage from '@react-native-async-storage/async-storage'
import alert from '../utils/commonAlert'

export default class ChatConversation extends Component {

	constructor(props) {
		super(props);
		this.state = {
			msg: '',
			isLoading:true,
			messages: [],
			inputHeight:0,
			businessName:'',
			appState: AppState.currentState,
		};
		this._isMounted=false
		this.willFocusSubscription = ''
		this.appStateSubscription = ''
	}

	componentDidMount(){
		this._isMounted=true
		this.appStateSubscription = AppState.addEventListener('change', this._handleAppStateChange);
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			Platform.OS==='web' && this.props.route.params.loginCustomerData && window.history.replaceState(null,null,"/ChatConversation")
			this.fetchAllMessages();
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
		});
	}

	componentWillUnmount(){
		this._isMounted=false
		this.willFocusSubscription();
		this.appStateSubscription.remove()
	}

	 _handleAppStateChange = async (nextAppState) => {
	    if (this.state.appState.match(/inactive|background/) && nextAppState === 'active') {
	    	this.fetchAllMessages();
	    }
	    if(this._isMounted===true) this.setState({appState:nextAppState})
	}

	fetchAllMessages = async () => {
		const { navigation, route, route: { params } } = this.props;
		const business_id = params.business_id;
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		const JsonLoginCustomerData = JSON.parse(loginCustomerData)
		let businessId = JsonLoginCustomerData.business_id
		if(business_id){
			businessId = business_id
		}
		const response = await fetch(apiUrl+'business-customer-messages/'+businessId+'/'+JsonLoginCustomerData.customer_otp_verify_id+'/?is_requested_by_customer='+true+"&inverted="+true,{
			headers : new Headers({
				Authorization:'Token '+JsonLoginCustomerData.token,
			})
		})

		const responseData = await response.json()
		if (response.status === 401) {
			alert(
				i18n.t('customer'),
				i18n.t('permission_to_perform'),
				[
					{text: i18n.t('ok'), onPress: () => logout(this.props.navigation)},
				],
				{ cancelable: false }
			)
		}
		else{
			if(responseData.success){
				if(this._isMounted===true) this.setState({messages:responseData.list_of_convsersation_message,businessName:responseData.business_name,isLoading:false, loginCustomerData:JsonLoginCustomerData})
			}
		}
	}

	sendMessage = async () => {
		const { navigation, route, route: { params } } = this.props;
		const business_id = params.business_id;
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		const JsonLoginCustomerData = JSON.parse(loginCustomerData)
		const {msg} = this.state;
		let businessId = JsonLoginCustomerData.business_id
		if(business_id){
			businessId = business_id
		}
		if (msg.length>0){
			try{
				const response = await axios.post(apiUrl+'business-customer-messages/'+businessId+'/'+JsonLoginCustomerData.customer_otp_verify_id+'/',{
						message:msg,
						message_by:JsonLoginCustomerData.user_id,
						is_message_by_customer:true,

					},
					{headers:{
					Authorization:'Token '+JsonLoginCustomerData.token
				}});
				if(response.data.success){
					if(this._isMounted===true) this.setState({msg:''})
					this.fetchAllMessages()
					
				}
				else{
					console.log(responseData.data.errors)
				}
			}
			catch(err){
				console.log(err);
			}
		}
	}

	handleContentSizeChange = ({ nativeEvent }) => {
		const { height } = nativeEvent.contentSize
		if(this._isMounted===true) this.setState({
			inputHeight: height > 120 ? 120 : height
		})
	};

	renderItem = ({item}) => {
		if (item.is_message_sent_by_self === false) {
			return (
				<View style={styles.leftMsg}>
					<Image source={require('../assets/images/business_logo.png')} style={styles.userPic} />
					<View style={styles.msgBlock}>
						<Autolink phone email style={styles.msgTxt} text={item.message} linkStyle={{ color: '#1a0dab' }}  />
						<Text style={styles.time}>{format(item.message_date,'DD MMM YYYY hh:mm A')}</Text>
					</View>
				</View>
			);
		} 
		else{
			return (
				<View style={styles.rightMsg} >
					<View style={styles.rightBlock} >
						<Autolink phone email style={styles.rightTxt} text={item.message} linkStyle={{ color: '#1a0dab' }} />
						<Text style={styles.time}>{format(item.message_date,'DD MMM YYYY hh:mm A')}</Text>
					</View>
					<Image source={require('../assets/images/customer-profile.png')} style={styles.userPic} />
				</View>
			);
		}
	};
	navigationToScreen = () => {
		const { navigation, route, route: { params } } = this.props;
		const business_id = params.business_id;
		if(business_id){
			this.props.navigation.goBack();
		}else{
			this.props.navigation.replace('CustomerStackScreen',{
				screen:'CustomerScreen',
				params:{
					screen: 'Home', 
					params:{loginCustomerData:params.loginCustomerData},
				}
			});
		}
	}
	render() {
		
		
		if(this.state.isLoading){
			return(
				<View style={{position: 'absolute',left: 0,right: 0,top: 0,bottom: 0,alignItems: 'center',justifyContent: 'center',backgroundColor:'#ffffff'}}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			);
		}
		else{
			return (
		 		<View style={{ flex: 1, backgroundColor:'#ffffff'}}>
			 		<Header
			 			statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
			 			placement="left"
			 			leftContainerStyle={{justifyContent:'center'}}
						leftComponent={<TouchableOpacity
							style={{marginLeft:10,alignItems:'center',flexDirection:'row'}}
							activeOpacity={0.5}
							onPress={()=>this.navigationToScreen()}
							>
							<FontAwesome name='chevron-left' color='#38c6f4' size={28} />
							<Image source={require('../assets/images/business_logo.png')} style={{  height: 30,width: 30,borderRadius:20, marginLeft:5}}/>
							<Text numberOfLines={1} ellipsizeMode='tail' style={{color: '#fff', fontSize:18, fontWeight:'bold', marginLeft:5, width:wp('50%'), marginTop:2}}>{this.state.businessName}</Text>
							</TouchableOpacity>
						}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0},style:{padding:15}}}
					/>
					<FlatList
						inverted={true}
						style={styles.list}
						extraData={this.state}
						data={this.state.messages}
						keyExtractor={(item,index)=> index.toString()}
						initialNumToRender={ Platform.OS==='web' ? this.state.messages.length : 20 }
						renderItem={this.renderItem}
					/>
					<View style={styles.footer}>
						<View style={styles.inputContainer}>
							<TextInput style={[styles.inputs,{height: this.state.inputHeight }]}
								multiline={true}
								placeholder={i18n.t('write_a_message')}
								underlineColorAndroid='transparent'
								onContentSizeChange={this.handleContentSizeChange}
								onChangeText={(val) => this.setState({msg:val})}
								value={this.state.msg}
							/>
						</View>
						<TouchableOpacity style={styles.btnSend} onPress={()=>this.sendMessage()}>
							<Image source={require('../assets/images/filled-sent.png')} style={styles.iconSend}  />
						</TouchableOpacity>
					</View>
		 		</View>
			);
		}
	}
}

const styles = StyleSheet.create({
  leftMsg: {
	flexDirection: 'row',
	alignItems: 'flex-end',
	margin: 5,
  },
  rightMsg: {
	flexDirection: 'row',
	alignItems: 'flex-end',
	margin: 5,
	alignSelf: 'flex-end',
  },
  msgBlock: {
	width: 250,
	borderRadius: 5,
	backgroundColor: '#f2f5f7',
	padding: 10,
	shadowColor: '#3d3d3d',
	shadowRadius: 2,
	shadowOpacity: 0.5,
	shadowOffset: {
	  height: 1,
	},
  },
  rightBlock: {
	width: 250,
	borderRadius: 5,
	backgroundColor: '#d7f7f6',
	padding: 10,
	shadowColor: '#3d3d3d',
	shadowRadius: 2,
	shadowOpacity: 0.5,
	shadowOffset: {
	  height: 1,
	},
  },
  msgTxt: {
  	lineHeight:30,
	fontSize: 15,
	color: '#555',
	fontWeight: 'bold',
  },
  rightTxt: {
  	lineHeight:30,
	fontSize: 15,
	color: '#202020',
	fontWeight: 'bold',
  },
  list:{
  },
  footer:{
	flexDirection: 'row',
	backgroundColor: '#eeeeee',
	paddingHorizontal:10,
	padding:5,
  },
  userPic: {
    height: 40,
    width: 40,
    margin: 5,
    borderRadius: 20,
    backgroundColor: '#f8f8f8',
  },
  btnSend:{
	backgroundColor:"#00BFFF",
	width:40,
	height:40,
	borderRadius:360,
	alignItems:'center',
	justifyContent:'center',
  },
  iconSend:{
	width:30,
	height:30,
	alignSelf:'center',
  },
  inputContainer: {
	borderBottomColor: '#F5FCFF',
	backgroundColor: '#FFFFFF',
	borderRadius:30,
	borderBottomWidth: 1,
	flexDirection: 'row',
	alignItems:'center',
	flex:1,
	marginRight:10,
  },
  inputs:{
	marginLeft:16,
	borderBottomColor: '#FFFFFF',
	flex:1,
  },
  time: {
	alignSelf: 'flex-end',
	margin: 5,
	fontSize:12,
	color:"#808080",
	fontWeight:'bold'
  }
});  