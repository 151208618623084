import React, { Component } from 'react';
import getEnvVars from '../environment';
const { apiUrl } = getEnvVars();
import { Text, View, StyleSheet, ScrollView, TextInput,ActivityIndicator, Image,TouchableOpacity} from 'react-native';
import {Header} from 'react-native-elements';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons} from '@expo/vector-icons';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
const axios = require('axios');
import translationValues from '../components/translation';
import i18n from 'i18n-js';
export default class CustomerBegin extends Component{

	constructor(props){
		super(props)
		this.state = {
			isLoading:true
		}
		this.willFocusSubscription = ''
	}

	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
			this.setState({isLoading:false})
		});
		this.setState({isLoading:false})
	}

	componentWillUnmount(){
		this.willFocusSubscription();
	}

	render(){
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<View style={styles.mainContainerStyle}>
					<Header
						statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						centerComponent={<Image resizeMode='cover' style={styles.logoImageStyle}  source={require('../assets/images/small_logo.png')}/>}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
					/>
					<View style={styles.welcomeImageViewStyle}>
						<Image resizeMode='cover' style={styles.welcomeImageStyle}  source={require('../assets/images/customer-app-welcome.jpg')}/>
					</View>
					<View style={styles.welcomeContentViewStyle}>
						<Text style={styles.welcomeTextStyle}>{i18n.t('welcome')}</Text>
						<Text style={styles.welcomeContentTextStyle}>{i18n.t('welcomeContent')}</Text>
					</View>
					<View style={styles.nextButtonViewStyle}>
						<TouchableOpacity style={styles.touchableButtonStyle}
							onPress={()=> this.props.navigation.navigate('HowItWork')}>
							<Text style={styles.nextTextStyle}>{i18n.t('next')}</Text>
						</TouchableOpacity>
					</View>
				</View>
			);
		}
	}
}

const styles = StyleSheet.create({
 	mainContainerStyle:{
 		flex:1,
 		backgroundColor:'#ffffff'
 	},
 	nextTextStyle:{
 		fontSize:18,
 		color:'#175491',
 		fontWeight:'bold',
 	},
 	touchableButtonStyle:{
 		flexDirection:'row',
 		borderWidth:1,
 		borderColor:'#175491',
 		margin:10,
 		padding:10,
 		borderRadius:7,
 		alignItems:'flex-end'
 	},
 	nextButtonViewStyle:{
 		flexDirection: 'row',
 		justifyContent: 'flex-end',
 		marginTop: 10,
 	},
 	welcomeContentTextStyle:{
 		fontSize:14,
 		color:'#175491',
 		textAlign:'center'
 	},
 	welcomeTextStyle:{
 		fontSize:18,
 		color:'#175491',
 		fontWeight:'bold',
 		textAlign:'center',
 		marginBottom:10
 	},
 	welcomeContentViewStyle:{
 		alignItems: 'center',
 		justifyContent: 'center',
 		padding:10,
 		marginTop:10,
 		marginBottom:20
 	},
 	welcomeImageStyle:{
 		height: 300,
 		width: 300
 	},
 	welcomeImageViewStyle:{
 		alignItems: 'center',
 		justifyContent: 'center',
 		padding:10
 	},
 	headerContainerStyle:{
 		borderBottomWidth:null,
 		backgroundColor:null,
 		alignItems:'center',
 		paddingTop:0,
 		elevation:5
 	},
 	logoImageStyle:{
 		height:35.85,
 		width:31.05,
 		resizeMode:'contain',
 	},
 	loadingViewStyle:{
 		position: 'absolute',
 		backgroundColor:'#ffffff',
 		left: 0,
 		right: 0,
 		top: 0,
 		bottom: 0,
 		alignItems: 'center',
 		justifyContent: 'center'
 	}
})