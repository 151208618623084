import React, { Component } from 'react';
import getEnvVars from '../environment';
const { apiUrl } = getEnvVars();
import { Text, View, StyleSheet, ScrollView, TextInput,ActivityIndicator, Image, TouchableOpacity} from 'react-native';
import {Header} from 'react-native-elements';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons} from '@expo/vector-icons';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
const axios = require('axios');
import translationValues from '../components/translation';
import i18n from 'i18n-js';
export default class HowItWork extends Component{

	constructor(props){
		super(props)
		this.state = {
			isLoading:true
		}
		this.willFocusSubscription = ''
	}

	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
		});
		this.setState({isLoading:false})
	}

	componentWillUnmount(){
		this.willFocusSubscription();
	}

	render(){
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<View style={styles.mainContainerStyle}>
					<Header
						centerComponent={<Image style={styles.logoImageStyle}  source={require('../assets/images/small_logo.png')}/>}
						centerContainerStyle={{justifyContent:'center'}}
						statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
					/>
					<ScrollView showsVerticalScrollIndicator={false}>
						<View style={styles.headingViewStyle}>
							<Image resizeMode='cover' style={styles.headingGraphicStyle}  source={require('../assets/images/how-it-work.png')}/>
							<Text style={styles.headingTextStyle}>{i18n.t('howItWork')}</Text>
						</View>
						<View style={styles.eachStepViewStyle}>
							<Image style={styles.stepIconStyle}  source={require('../assets/images/step1.png')}/>
							<View style={styles.contentViewStyle}>
								<Image style={styles.stepWiseIconStyle}  source={require('../assets/images/submit.png')}/>
								<Text style={styles.contentTextStyle}>{i18n.t('submitDetails')}</Text>
							</View>
						</View>
						<View style={styles.eachStepViewStyle}>
							<Image style={styles.stepIconStyle}  source={require('../assets/images/step2.png')}/>
							<View style={styles.contentViewStyle}>
								<Image style={styles.stepWiseIconStyle} source={require('../assets/images/contacted.png')}/>
								<Text style={styles.contentTextStyle}>{i18n.t('step2Content')}</Text>
							</View>
						</View>
						<View style={styles.eachStepViewStyle}>
							<Image style={styles.stepIconStyle}  source={require('../assets/images/step3.png')}/>
							<View style={styles.contentViewStyle}>
								<Image style={styles.stepWiseIconStyle} source={require('../assets/images/confirmation.png')}/>
								<Text style={styles.contentTextStyle}>{i18n.t('step3content')}</Text>
							</View>
						</View>
						<View style={styles.nextButtonViewStyle}>
							<TouchableOpacity style={styles.touchableButtonStyle}
								onPress={()=> this.props.navigation.navigate('CustomerRequestInfo',{isCameFromReferral:false})}>
								<Text style={styles.nextTextStyle}>{i18n.t('next')}</Text>
							</TouchableOpacity>
						</View>
					</ScrollView>
				</View>
			);
		}
	}
}

const styles = StyleSheet.create({
 	contentTextStyle:{
 		fontSize:14,
 		color:'#808080',
 		textAlign:'left'
 	},
 	contentViewStyle:{
 		width:wp('85%')
 	},
 	eachStepViewStyle:{
 		flexDirection:'row',
 		padding:5,
 		alignItems:'center',
 		justifyContent:'space-between'
 	},
 	stepWiseIconStyle:{
 		height: 30,
 		width: 30,
 		resizeMode:'contain'
 	},
 	stepIconStyle:{
 		height: 60,
 		width: 60,
 		resizeMode:'contain'
 	},
 	headingTextStyle:{
 		fontSize:18,
 		color:'#808080',
 		textAlign:'center',
 		fontWeight:'bold'
 	},
 	headingGraphicStyle:{
 		height: 220,
 		width: 350
 	},
 	headingViewStyle:{
 		alignItems: 'center',
 		justifyContent: 'center'
 	},
 	mainContainerStyle:{
 		flex:1,
 		backgroundColor:'#ffffff'
 	},
 	nextTextStyle:{
 		fontSize:18,
 		color:'#175491',
 		fontWeight:'bold',
 	},
 	touchableButtonStyle:{
 		flexDirection:'row',
 		borderWidth:1,
 		borderColor:'#175491',
 		margin:10,
 		padding:10,
 		borderRadius:7,
 		alignItems:'flex-end'
 	},
 	nextButtonViewStyle:{
 		flexDirection: 'row',
 		justifyContent: 'flex-end',
 		marginTop: 10,
 		marginBottom:20
 	},
 	headerContainerStyle:{
 		borderBottomWidth:null,
 		backgroundColor:null,
 		alignItems:'center',
 		paddingTop:0,
 		elevation:5
 	},
 	logoImageStyle:{
 		height:35.85,
 		width:31.05,
 		resizeMode:'contain',
 	},
 	loadingViewStyle:{
 		backgroundColor:'#ffffff',
 		position: 'absolute',
 		left: 0,
 		right: 0,
 		top: 0,
 		bottom: 0,
 		alignItems: 'center',
 		justifyContent: 'center'
 	}
})