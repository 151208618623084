import React, {useState} from 'react';
import {SafeAreaView, Text, View,StyleSheet} from 'react-native';

import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';

const styles = StyleSheet.create({
  root: {margin:'auto'},
  title: {textAlign: 'center', fontSize: 30},
  codeFiledRoot: {
   
    width: 280,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cellRoot: {
    width: 60,
    height: 60,
    lineHeight: 38,
    borderWidth: 2,
    borderColor: '#00000030',
    textAlign:'center',
    marginBottom:10,
    marginTop: 20,
    borderRadius:2,
  },
  cellText: {
    color: '#38c6f4',
    fontSize: 36,
    textAlign: 'center',
  },
  focusCell: {
    borderColor: '#38c6f4',
    borderWidth: 2,
  },
})

const CELL_COUNT = 4;

const PasscodeTextInput = ({otpCode, onChangeText}) => {
  const ref = useBlurOnFulfill({value:otpCode, cellCount: CELL_COUNT});
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value:otpCode,
    setValue:onChangeText,
  });

  return (
    <SafeAreaView style={styles.root}>
      <CodeField
        ref={ref}
        {...props}
        value={otpCode}
        caretHidden={false}
        onChangeText={onChangeText}
        cellCount={CELL_COUNT}
        rootStyle={styles.codeFiledRoot}
        keyboardType="number-pad"
        textContentType="oneTimeCode"
        renderCell={({index, symbol, isFocused}) => (
          <View
            // Make sure that you pass onLayout={getCellOnLayoutHandler(index)} prop to root component of "Cell"
            onLayout={getCellOnLayoutHandler(index)}
            key={index}
            style={[styles.cellRoot, isFocused && styles.focusCell]}>
            <Text style={styles.cellText}>
              {symbol || (isFocused ? <Cursor /> : null)}
            </Text>
          </View>
        )}
      />
    </SafeAreaView>
  );
};

export default PasscodeTextInput;