import React, { Component } from 'react';
import { Text, View, StyleSheet, Image} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import Onboarding from 'react-native-onboarding-swiper';

/**
	* this component is for showing the new update in the app
*/
export default class NewUpdateCustomerAppWalkthrough extends React.PureComponent{
	static navigationOptions = {
		header:  null
	};
	constructor(props){
		super(props)
	}

	onDoneButton = () => {
		this.props.handleOnWalkThroughDone();
	}
	render(){
		let	walkthroughPageList = [{
					backgroundColor: '#fff',
					image: <Image source={global.isLanguage === 'hi' ?require('../assets/new_update_walkthrough/Hindi-cutomer-app-1.jpg') :require('../assets/new_update_walkthrough/customer-app-English-01.jpg')} style={styles.FirstimageContainerStyles} />,
					title: '',
					subtitle: '',
				},
				{
					backgroundColor: '#fff',
					image: <Image source={global.isLanguage === 'hi' ?require('../assets/new_update_walkthrough/Hindi-cutomer-app-2.jpg') :require('../assets/new_update_walkthrough/customer-app-English-02.jpg')} style={styles.otherImageContainerStyles}/>,
					title: '',
					subtitle: '',
				},
				{
					backgroundColor: '#fff',
					image: <Image source={global.isLanguage === 'hi' ?require('../assets/new_update_walkthrough/Hindi-cutomer-app-3.jpg') :require('../assets/new_update_walkthrough/customer-app-English-03.jpg')} style={styles.otherImageContainerStyles} />,
					title: '',
					subtitle: '',
				}
			]
		return(
			<Onboarding
				pages={walkthroughPageList}
				showSkip={false}
				onDone={()=>this.onDoneButton()}
				imageContainerStyles={{paddingBottom:0, height:hp('100%'), width:wp('100%')}}
				containerStyles={{padding:0,paddingTop:5, margin:0,justifyContent:'flex-start'}}
				width={wp('100%')}
				height={hp('100%')}
				bottomBarHeight={40}
				bottomBarColor={'#f2f5f7'}
			/>
		);
	}
}

const styles = StyleSheet.create({
 	FirstimageContainerStyles:{
 		height:hp('80%'),
 		width:wp('100%'),
 		resizeMode:'contain'
 	},
 	StarterFirstimageContainerStyles:{
 		height:hp('75%'),
 		width:wp('100%'),
 		resizeMode:'contain'
 	},
 	StarterOtherImageContainerStyles:{
 		height:hp('75%'),
 		width:wp('100%'),
 		resizeMode:'contain'
 	},
 	otherImageContainerStyles:{
 		height:hp('80%'),
 		width:wp('100%'),
 		resizeMode:'contain'
 	}

})