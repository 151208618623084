import { StyleSheet } from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';

export default StyleSheet.create({
	employeeNameTextStyle:{
		color:'#000000',
		fontSize:14,
		fontWeight:'bold',
		paddingLeft:10
	},
	optionLabelText:{
		color: "#808080",
		fontWeight:'bold',
		fontSize:12,
		textAlign: 'center',
	},
	optionTouchableStyle:{
		flexDirection:'column',
		alignItems:'center',
		paddingHorizontal:8
	},
	shareOptionViewStyle:{
		alignItems:'center',
		flexDirection:'row',
		justifyContent:'space-between',
		marginTop:5,
		marginBottom:5
	},
	mainHeadingViewStyle:{
		flexDirection:'row',
		width:wp('100%'),
		paddingTop:10,
		paddingBottom:10,
		backgroundColor:'#f2f5f7',
		alignItems:'center',
		justifyContent:'center'
	},
	jarEntryIconStyle:{
		width:40,
		height:30,
		resizeMode:'contain'
	},
	headingTextStyle:{
		color:'#175491',
		fontSize:16,
		fontWeight:'bold',
		marginLeft:5
	},
	containerStyle:{
		height: 60,
		borderBottomWidth:null,
		backgroundColor:null,
		alignItems:'center',
		elevation:5
	},
	headerIconStyle:{
		height: 45,
		width: 45,
		resizeMode:'contain',
		marginTop:-25
	},
	headerLeftComponentStyle:{
		marginTop:-20,
		flexDirection:'row'
	},
	headerTitleTextStyle:{
		color: '#fff',
		fontSize:16,
		fontWeight:'bold',
		marginLeft:5
	},
	cancelButtonViewStyle:{
		justifyContent:'center',
		alignItems:'center',
		marginTop:10,
		marginBottom:10
	},
	cancelTouchableStyle:{
		flexDirection:'row',
		justifyContent:'center',
		alignItems:'center',
		borderWidth:1,
		borderColor:'#175491',
		width:wp('26%'),
		height:hp('7%'),
		padding:10,
		borderRadius:5
	},
	cancelTextStyle:{
		color: "#175491",
		fontWeight:'bold',
		fontSize:18,
		textAlign: 'center',
	},
	renderSeparatorViewStyle:{
		width: '100%',
		backgroundColor: '#ffffff',
	},
	paidAmountViewStyle:{
		flexDirection:'row',
		justifyContent:'space-between',
		alignItems:'center'
	},
	paidAmountTextStyle:{
		fontSize:14,
		fontWeight:'bold',
		color:'#175491',
		paddingRight:10
	},
	toolTipContainerStyle:{
		width:wp('90%'),
		alignItems:'center',
		elevation:5,
		backgroundColor:'#f2f5f7',
		padding:10
	},
	perticularTextStyle:{
		fontSize:12,
		color:'#333333',
		paddingLeft:5
	},
	billAmountTextStyle:{
		fontSize:14,
		fontWeight:'bold',
		textAlign:'center',
		color:'#175491'
	},
	singleItemTextStyle:{
		fontSize:14,
		color:'black'
	},
	paymentDateTextStyle:{
		fontSize:12,
		color:'black',
		paddingLeft:5
	},
	singleItemViewStyle:{
		width:wp('25%'),
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'center',
	},
	itemViewStyle:{
		marginLeft:10,
		width:wp('31%'),
		flexDirection:'row',
		alignItems:'flex-start',
	},
	itemBillAmountStyle:{
		backgroundColor:'red',
		marginLeft:10,
		width:wp('31%'),
		flexDirection:'row',
	},
	arrowItemViewStyle:{
		width:wp('8%'),
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'center',
	},
	perticularViewStyle:{
		width:wp('25%'),
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'center'
	},
	eachItemViewStyle:{
		width:wp('100%'),
		flexDirection:'row',
		alignItems:'flex-start',
		paddingVertical:15,
	},
	eachJarDeliveredItemViewStyle:{
		width:wp('100%'),
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		paddingTop:15,
		paddingBottom:15,
		marginTop:5,
		marginBottom:5
	},
	loadingViewStyle:{
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	productItemHeadingStyle:{
		textAlign:'center',
		fontSize:14,
		fontWeight:'bold',
		width:('23%'),
		color:'#808080'
	},
	ledgerHeadingViewStyle:{
		width:wp('100%'),
		flexDirection:'row',
		backgroundColor:'#CBEEF4',
		alignItems:'center',
		paddingVertical:10,
		paddingHorizontal:5,
	},
	ledgerTextStyle:{
		textAlign:'center',
		fontSize:18,
		color:'#38c6f4',
		fontWeight:'bold',
		paddingTop:15,
		paddingBottom:15
	},
	submitTitleStyle:{
		fontWeight:'bold',
		color:'#ffffff',
		fontSize:16,
		textAlign:'center'
	},
	submitButtonContainerStyle:{
		alignItems:"center",
		marginTop:20,
		marginBottom:20
	},
	submitButtonStyle:{
		width:wp('90%'),
		padding:8,
		borderRadius:5,
		height:hp('8%')
	},
	commentTextStyle:{
		color:'#38c6f4',
		fontWeight:'bold',
		fontSize:16,
	},
	datePickerViewStyle:{
		flexDirection:'row',
		borderWidth:2,
		borderColor:'#38c6f4',
		borderRadius:5,
		justifyContent:'center',
		alignItems:'center'
	},
	addPaymentViewStyle:{
		backgroundColor:'#f2f5f7',
		justifyContent:'center',
		alignItems:'center',
		paddingTop:10,
	},
	pastPaymentHeadingTextStyle:{
		fontSize:20,
		fontWeight:'bold',
		color:'#175491',
		textAlign:'center'
	},
	pastPaymentHeadingStyle:{
		marginTop:-25,
		justifyContent:'center',
		alignItems:'center',
	},
	topViewStyle:{
		height:hp('12%'),
		alignItems:'center'
	},
	noPastPaymentTextStyle:{
		textAlign:'center',
		fontSize:16,
		fontWeight:'bold'
	},
	notFoundViewStyle:{
		width:wp('100%'),
		alignItems: 'center',
		justifyContent: 'center',
		padding:20
	},
	paymentDetailStyle:{
		fontSize:10,
		color:'#4D4D4D',
		textAlign:'left',
		paddingTop:5,
		width:wp('23%'),
	},
	commentStyle:{
		fontSize:14,
		color:'#808080'
	},
	TopButtonStyle: {
		flexDirection: 'row',
		justifyContent:'center',
		alignItems:'center',
		backgroundColor: '#ffffff',
		width:wp('90%'),
		height: hp('8%'),
		borderRadius: 5 ,
	},
	customDatePickerStyle:{
		direction:'rtl',

	},
	dateInputPickerTaxt:{
		direction:'ltr',
		borderWidth:0,
		
		padding:0,
	},
	dateText:{
		color:'#000000',
		fontWeight:'bold'
	},
	datePickerStyle:{
		justifyContent:'center',
		alignItems:'center',
		width:wp('27%'),
		height:hp('5%'),
		padding:0
	},
	addPaymentImageStyle:{
		width:35,
		height:35,
		resizeMode : 'contain',
		marginRight:5
	},
	input: {
		width: wp('90%'),
		height: hp('8%'),
		backgroundColor: 'white',
		padding: 8,
		borderRadius:10,
		color: '#000000',
		fontSize: 14,
		elevation:3,
		borderColor:'#ffffff',
		marginTop:20

	},commentButton:{
		width: wp('90%'),
		height: hp('8%'),
		backgroundColor: 'white',
		alignItems:'center',
		padding: 8,
		borderRadius:10,
		elevation:3,
		borderColor:'#ffffff',
		marginTop:20,
		flexDirection:'row',
		justifyContent:'space-between'
	},
	billTextStyle:{
		fontSize:14,
		color:'#ffffff',
		fontWeight:'bold',
		paddingLeft:5,
		maxWidth:wp('32%')
	},
	billIconStyle:{
		width:20,
		height:20,
		resizeMode:'contain',
		tintColor:'#ffffff'
	},
	billSectionViewStyle:{
		backgroundColor:'#f2f5f7'
	},
	paymentContentViewStyle:{
		backgroundColor:'#ffffff',
	},
	billContentViewStyle:{
		backgroundColor:'#f2f5f7'
	},
	billHeadingViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		borderBottomWidth:1,
		borderTopWidth:1,
		borderBottomColor:'#38c6f4',
		borderTopColor:'#38c6f4',
		padding:10,
	},
	paymentHeadingViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		borderTopWidth:1,
		borderTopColor:'#38c6f4',
		padding:10
	},
	employeeNameViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		width:wp('60%'), 
	},
	employeeIconStyle:{
		width:30,
		height:30,
		resizeMode:'contain'
	},
	billButtonStyle:{
		flexDirection:'row',
		alignItems:'center',
		backgroundColor:'#175491',
		borderRadius:5,
		padding:7,
		elevation:5,
	},
	productItemViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		padding:10, 
	},
	productNameIconViewStyle:{
		width:wp('40%'),
		alignItems:'center',
		flexDirection:'row'
	},
	productIconStyle:{
		width:35,
		height:35,
		resizeMode:'contain'
	},
	productNameTextStyle:{
		paddingLeft:5,
		color:'#808080',
		fontWeight:'bold',
		fontSize:14,
		width:wp('34%')
	},
	amountDueValueViewStyle:{
		width:wp('20%'),
		alignItems:'center',
		justifyContent:'space-evenly',
		flexDirection:'row'
	},
	rupeeTextStyle:{
		color:'#4d4d4d',
		fontSize:16,
		paddingLeft:10
	},
	dueRuppeeTextStyle:{
		color:'#38c6f4',
		fontSize:18,
		fontWeight:'bold'
	},
	productAmountTextStyle:{
		color:'#4d4d4d',
		fontWeight:'bold',
		fontSize:14,
		paddingLeft:10
	},
	dueAmountTextStyle:{
		color:'#4d4d4d',
		fontWeight:'bold',
		fontSize:18
	},
	totalAmountViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		borderTopWidth:1,
		borderTopColor:'#38c6f4',
		padding:15,
	},
	paymentDueBeforeGoPaaniViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		padding:15,
		borderTopWidth:1,
		borderTopColor:'#38c6f4',
	},
	totalDueAmountViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		paddingTop:20
	},
	totalDueAmountTextStyle:{
		width:wp('70%'),
		color:'#4d4d4d',
		fontWeight:'bold',
		fontSize:18
	},
	totalAmountTextStyle:{
		width:wp('70%'),
		color:'#4d4d4d',
		fontWeight:'bold',
		fontSize:14
	},
	paymentCommentViewStyle:{
		padding:10,
		width:wp('95%'),
		alignItems:'flex-start'
	},
	labelTextStyle:{
		backgroundColor:'#f2f5f7',
		fontSize: 14,
		color: "#b3b3b3",
		marginTop:-10,
		marginLeft:10,
		paddingLeft:5,
		paddingRight:5
	},
	productCommentTextStyle:{
		fontSize:14, 
		color:'#333333',
		paddingLeft:5
	},
	onlinePaymentText:{
		fontSize:14,
		color:'#175491'
	},
	line: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: '31%',
		width: 0.5,
		backgroundColor: '#175491',
		zIndex:1
	},
	itemsContainerView:{
		marginBottom:5,
		elevation:5,
		width:wp('100%')
	},
	dateitemView:{
		width:wp('23%'),
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'flex-start',
		paddingLeft:15,
	},
	showInRow:{
		flexDirection:'row'
	},
	textColor:{
		color:'#333333',
		fontSize:14,
		fontWeight:'bold'
	},
	timeText:{
		color:'#999999', 
		fontSize:14, 
		paddingLeft:20
	},
	paymentView:{
		width:wp('23%'),
	},
	productInvoicePaymentText:{
		fontSize:18,
		fontWeight:'bold',
		textAlign:'center',
		color:'#ED2424',
	},
	productBillPaymentTextStyle:{
		fontSize:18,
		fontWeight:'bold',
		textAlign:'center',
		color:'#299B2E'
	},
	runningBillPaymentTextStyle:{
		fontSize:18,
		fontWeight:'bold',
		textAlign:'right',
		color:'#299B2E'
	},
	creditText:{
		fontWeight:'300',
		paddingTop:12, 
		fontSize:12,
		color:'#299B2E'
	},
	beforeGopaaniView:{
		width:wp('20%'),
		alignItems:'center',
		flexDirection:'row',
		justifyContent:'flex-end'
	},
	invoiceEmployeeNameStyle:{
		flexDirection:'row',
		alignItems:'center',
		width:wp('50%'), 
	},
	productUnitStyle:{
		paddingLeft:5,
		color:'#808080',
		fontWeight:'bold',
		fontSize:12,
	},
	productQuantityPriceTextStyle:{
		color:'#808080',
		fontWeight:'bold',
		fontSize:14,
		width:wp('30%'),
		textAlign:'left',
		paddingLeft:5
	},
	productPriceViewStyle:{
		width:wp('20%'),
		alignItems:'center',
		justifyContent:'flex-start',
		flexDirection:'row'
	},
	dateHeadingStyle:{
		textAlign:'left',
		fontSize:14,
		fontWeight:'bold',
		width:('23%'),
		paddingLeft:10,
		color:'#808080'
	},
	invoiceText:{
		textAlign:'center',
		width:('23%'),
		fontSize:14,
		fontWeight:'bold',
		color:'#808080'
	},
	runningBalanceStyle:{
		textAlign:'center',
		fontSize:14,
		fontWeight:'bold',
		width:('31%'),
		color:'#808080',
		paddingLeft:5
	},
	subHeadingTextStyle:{
		fontWeight:'300',
		paddingTop:5, 
		fontSize:12
	},
});