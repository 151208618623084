/*
this screen appear inside menu bar third from top when user press on choose language
*/
import React, { Component } from 'react';
import { Text, View, StyleSheet,ActivityIndicator, TouchableOpacity,Platform,Image, TextInput, ScrollView} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { CheckBox,Button, Header} from "react-native-elements";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { Ionicons, FontAwesome, MaterialCommunityIcons, Entypo } from '@expo/vector-icons';
import i18n from 'i18n-js';
import translationValues from '../components/translation';
import {format} from 'date-fns';
import client from '../client';
import logout from '../components/logout';
import {isValidPhoneNumber} from '../commonFunctions';
import AutoCompleteAddress from '../components/autoCompleteAddress';
import AsyncStorage from '@react-native-async-storage/async-storage'
import CommonDatePicker from '../components/commonDatePicker'
import alert from '../utils/commonAlert'
import GooglePlacesAutocomplete from '../components/GooglePlacesAutocomplete'
/*
	usedFor:-this screen is show when user press on customer details, to show customers personal details that business saved in there end, they can update it later also
*/
export default class CustomerProfile extends Component{
	constructor(props){
		super(props)
		this.state={
			isLoading:true,
			customerPhoneNumber:null,
			customerDefineName:null,
			customerAddress:null,
			customerSelfBusinessName:null,
			alternateCustomerPhoneNumber:null,
			customerEmail:null,
			dateOfBirthDate:null,
			dateOfMarriage:null,
			maritalStatus:false,
			alternateCustomerPhoneNumberArray:[],
			showErrorMessage:false,
			alternateNumberError:'',
			emailErrorMessage:'',
			isCustomerAddressExist:false,
			loginCustomerData:null,
			alternativeMobileNumbersList:[],
			maximumAlterNativePhoneNumber:null,//it will be set from backend
			showPlaceList:false,
		}
		this.willFocusSubscription = ''
	}
	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			Platform.OS==='web' && window.history.replaceState(null,null,"/CustomerProfile")
			this.fetchCustomerPersonalInformation();
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
		})
	}
	componentWillUnmount(){
		this.willFocusSubscription()
	}

	fetchCustomerPersonalInformation = async () => {
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		let loginCustomerDataJson = JSON.parse(loginCustomerData);
		let params = {request_for:'customer_personal_information'}
		const responseData = await client.fetchCustomerDetail(loginCustomerDataJson.business_id, loginCustomerDataJson.customer_business_id, params)
		if (responseData.detail) {
			alert(
				'Customer',
				'You do not have permission to perform this action.',
				[
					{text: 'OK', onPress:()=> logout(this.props.navigation)},
				],
				{ cancelable: false }
			)
		}
		else{
			this.setState({customerPhoneNumber:responseData.customer_phone_number,customerDefineName:responseData.customer_define_name, customerAddress:responseData.customer_address, customerSelfBusinessName:responseData.customer_self_business_name, alternativeMobileNumbersList:this.createListOfAlternativePhoneNumber(responseData.alternate_customer_phone_number), customerEmail:responseData.customer_email,dateOfBirthDate:responseData.customer_date_of_birth, dateOfMarriage:responseData.customer_date_of_marriage_anniversary,maritalStatus:responseData.customer_date_of_marriage_anniversary ?true :false, isCustomerAddressExist:responseData.is_customer_address_exist, loginCustomerData:loginCustomerDataJson,maximumAlterNativePhoneNumber:responseData.max_customer_alternate_number_allow,isLoading:false})
		}
	}
	createListOfAlternativePhoneNumber = (phoneNumberString)=>{//to return commaseprated mobile number string to listof mobilenumber
		if( phoneNumberString ){
			let emptyList = []
			let mobileNumbersList = phoneNumberString.split(',')
			for( let i = 0; i < mobileNumbersList.length; i++){//to remove invalid number
				if(mobileNumbersList[i].length === 10){
					emptyList.push(mobileNumbersList[i])//add 1 item
				}
			}
			return emptyList
		}
		else {
			return []
		}
	}

	saveCustomerPersonalDetails = async () => {
		this.setState({isLoading:true})
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		let loginCustomerDataJson = JSON.parse(loginCustomerData);
		const {customerDefineName, customerSelfBusinessName, customerAddress, customerEmail, dateOfBirthDate, dateOfMarriage, maritalStatus} = this.state;
		const alternateCustomerPhoneNumber = this.state.alternativeMobileNumbersList.join(',')//Converting array elements to commaseprated string
		let dateOfMarriageAnniversary = null;
		if(maritalStatus === true){
			dateOfMarriageAnniversary = format(dateOfMarriage, 'YYYY-MM-DD')
		}
		let requestBody = {request_for:'store_customer_personal_information','request_data':{'customer_define_name':customerDefineName, 'customer_self_business_name':customerSelfBusinessName,customer_email:customerEmail,customer_address:customerAddress,customer_date_of_birth:dateOfBirthDate ?format(dateOfBirthDate,'YYYY-MM-DD') :null,customer_date_of_marriage_anniversary:dateOfMarriageAnniversary,alternate_customer_phone_number:alternateCustomerPhoneNumber}}
		const responseData = await client.createCustomerDetail(loginCustomerDataJson.business_id, loginCustomerDataJson.customer_business_id, requestBody)
		if (responseData.detail) {
			alert(
				'Customer',
				'You do not have permission to perform this action.',
				[
					{text: 'OK', onPress: () => logout(this.props.navigation)},
				],
				{ cancelable: false }
			)
		}else{
			if(responseData.success){
				this.setState({isLoading:false, maritalStatus:false, showErrorMessage:false, alternateNumberError:'', emailErrorMessage:''},() => this.fetchCustomerPersonalInformation())
				if(Platform.OS==='android'){
				}
				else{
					alert(
						'GoPaani',
						i18n.t('success_message'),
						[
							{text: i18n.t('ok')},
						],
						{ cancelable: false }
					)
				}
			}else{
				let emailErrorMessage = ''
				if(responseData.errors.hasOwnProperty('customer_email')){
					emailErrorMessage =i18n.t('invalidEmailError');
					if(responseData.errors['customer_email'][0].includes('already')){
						emailErrorMessage =i18n.t('alreadyExistEmailError');
					}
				}
				this.setState({isLoading:false, showErrorMessage:true, emailErrorMessage:emailErrorMessage})
			}
		}

	}
	onSubmitPersonalInformation = () => {
		let alternateCustomerPhoneNumber = this.state.alternateCustomerPhoneNumber ?this.state.alternateCustomerPhoneNumber :''
		const isError = this.checkCustomerPhoneNumber(alternateCustomerPhoneNumber);
		if(isError === false){
			this.saveCustomerPersonalDetails();
		}
	}
	checkCustomerPhoneNumber = (val) => {
		const {alternateCustomerPhoneNumber} = this.state
		let isError = false
		if(alternateCustomerPhoneNumber && alternateCustomerPhoneNumber.length !== 10){
			isError = true
			this.setState({showErrorMessage:true, alternateNumberError:i18n.t('invalid_number')})
		}
		else {
			this.setState({showErrorMessage:false, alternateNumberError:''})
		}
		return isError
	}
	onChangeAlternateMobileNumber = (val)=>{
		const {alternateCustomerPhoneNumber, alternativeMobileNumbersList, maximumAlterNativePhoneNumber} = this.state
		const isValidValue = isValidPhoneNumber(val)
		this.scrollView.scrollTo({x:0,y:205,animated:true})
		if( isValidValue === true && alternativeMobileNumbersList.length < maximumAlterNativePhoneNumber ){ // To check isValid && maximum 5 Phonenumbers
			if(val.length === 10){
				if(alternativeMobileNumbersList.indexOf(val) === -1 ){//Check duplicate phonenumber
					alternativeMobileNumbersList.push(val)//add phoneNumber in list
					this.setState({alternateCustomerPhoneNumber:'', alternativeMobileNumbersList:alternativeMobileNumbersList}, ()=>{
						if ( alternativeMobileNumbersList.length === maximumAlterNativePhoneNumber) { //To show hint of maximum numbers
							this.setState({alternateNumberError:i18n.t('maximumAlterNativePhoneNumber',{maximumAlterNativePhoneNumber:maximumAlterNativePhoneNumber})})
						}
					})
				}
				else { this.setState({alternateCustomerPhoneNumber:''})}
			}
			else { this.setState({alternateCustomerPhoneNumber:val}) }
		}
	}
	onDeleteAlternativeNumber = (indexItem)=> {
		this.state.alternativeMobileNumbersList.splice(indexItem, 1)//remove 1 element(passed index)
		this.setState({alternativeMobileNumbersList:this.state.alternativeMobileNumbersList}, ()=>{
			if(this.textInput){
				this.textInput.focus()
			}
		})// because reRender after setState function call
	}
	handleAddressOnPress=(details)=>{
		this.setState({customerAddress:details.formatted_address, showPlaceList:false})
	}
	handleAddressChangeText=(value,showPlaceList)=>{
		if(showPlaceList){
			this.setState({customerAddress:value, showPlaceList:showPlaceList})
		}
		else{
			this.setState({customerAddress:value})
		}
	}
	handleAddressSubmitEdit=()=>{
		this.setState({showPlaceList:false})
	}

	render() {
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<View style={{flex:1, backgroundColor:'#ffffff'}}>
					<Header
						statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						placement={"left"}
						leftComponent={
							<TouchableOpacity
								style={styles.headerLeftComponentStyle}
								activeOpacity={0.5}
								onPress={()=>this.props.navigation.openDrawer()}>
								<Ionicons name='md-menu' color='#38c6f4' size={34} />
								<Text style={styles.headerTitleTextStyle}>{i18n.t('profile')}</Text>
							</TouchableOpacity>
						}
						centerContainerStyle={{justifyContent:'center'}}
						leftContainerStyle={{justifyContent:'center'}}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
					/>
					<ScrollView
						ref={ref => this.scrollView = ref}
						showsVerticalScrollIndicator={true}
						keyboardShouldPersistTaps='always'
					>
						<View style={styles.updateCustomerInfoViewStyle}>
							<View style={styles.phoneNumberFieldViewStyle}>
								<View style={styles.phoneNumberRequiredLabelViewStyle}>
									<Text style={styles.labelTextStyle}>{i18n.t('mobileNumber')}</Text>
									<Text style={styles.requiredLabelTextStyle}>{' *'}</Text>
								</View>
								<View style={styles.phoneNumberInnerViewStyle}>
									<TouchableOpacity
										style={styles.changeButtonStyle}
										disabled={false}
										onPress={()=> this.props.navigation.navigate('ResetCustomerPhoneNumber',{
											loginCustomerData:this.state.loginCustomerData
											})
										}
										activeOpacity={0.5}>
										<Text style={styles.changeNumberTextStyle}>{i18n.t('change')}</Text>
									</TouchableOpacity>
								</View>
								<View style={styles.phoneDetailViewStyle}>
									<Ionicons name="ios-phone-portrait" size={24} color="#38c6f4" />
									<Text style={styles.phoneNumberValueTextStyle}>{this.state.customerPhoneNumber}</Text>
									<MaterialCommunityIcons name="check-decagram" size={28} color="#38c6f4" />
								</View>
							</View>
							<TouchableOpacity onPress={()=>this.nameInput?.focus()} activeOpacity={1} style={this.state.showErrorMessage === true ?styles.errorFieldViewStyle :styles.fieldViewStyle}>
								<View style={styles.labelTextViewStyle}>
									<Text style={styles.labelTextStyle}>{i18n.t('fullName')}</Text>
								</View>
								<View style={styles.fieldInnerViewStyle}>
									<Ionicons name="md-person" size={24} color="#38c6f4" />
									<TextInput
										ref={ref=>{this.nameInput=ref}}
										style={styles.input}
										autoCapitalize="none"
										maxLength = {40}
										autoCompleteType="off"
										value={this.state.customerDefineName}
										onChangeText={(val)=> this.setState({customerDefineName:val})}
									/>
								</View>
							</TouchableOpacity>
							<TouchableOpacity onPress={()=>this.businessNameInput?.focus()} activeOpacity={1} style={this.state.showErrorMessage === true ?styles.errorFieldViewStyle :styles.fieldViewStyle}>
								<View style={styles.labelTextViewStyle}>
									<Text style={styles.labelTextStyle}>{i18n.t('businessName')}</Text>
								</View>
								<View style={styles.fieldInnerViewStyle}>
									<Ionicons name="ios-business" size={24} color="#38c6f4" />
									<TextInput
										ref={ref=>{this.businessNameInput=ref}}
										style={styles.input}
										autoCapitalize="none"
										autoCompleteType="off"
										value={this.state.customerSelfBusinessName}
										onChangeText={(val)=> this.setState({customerSelfBusinessName:val})}
									/>
								</View>
							</TouchableOpacity>
							{this.state.isCustomerAddressExist
								?<View style={styles.addressReadOnlyViewStyle}>
									<View style={styles.labelTextViewStyle}>
										<Text style={styles.labelTextStyle}>{i18n.t('address')}</Text>
									</View>
									<View style={styles.fieldInnerViewStyle}>
										<MaterialCommunityIcons name="map-marker-radius" size={24} color="#38c6f4" />
										<Text style={styles.addressInputBox}>{this.state.customerAddress}</Text>
									</View>
								</View>
								:<TouchableOpacity onPress={()=>this.address?.focus()} activeOpacity={1} style={this.state.showErrorMessage === true ?styles.errorFieldViewStyle :styles.addressFieldViewStyle}>
									<View style={styles.labelTextViewStyle}>
										<Text style={styles.labelTextStyle}>{i18n.t('address')}</Text>
									</View>
									<View style={[styles.fieldInnerViewStyle,{alignItems:'flex-start'}]}>
										<MaterialCommunityIcons name="map-marker-radius" size={24} color="#38c6f4" />										
										<GooglePlacesAutocomplete
											value={this.state.customerAddress}
											inputStyle={[styles.addressInputBox,{marginBottom:10,marginLeft:0,paddingTop:0}]}
											containerStyle={{width:wp('80%'),marginLeft:5}}
											listStyle={{paddingBottom:10}}
											onChangeText={t=>this.setState({customerAddress:t})}
											onSelect={result=>this.setState({customerAddress:result.title})}
											value={this.state.customerAddress} />
									</View>
								</TouchableOpacity>
							}
							<TouchableOpacity activeOpacity={1} onPress={()=>this.textInput?.focus()} style={this.state.showErrorMessage ?styles.alternativeMobleNumberError :styles.alternativeMobleNumber}>
								<View style={styles.labelTextViewStyle}>
									<Text style={styles.labelTextStyle}>{i18n.t('alternateMobileNumber')}</Text>
								</View>
								<View style={styles.fieldInnerViewStyle}>
									<Ionicons name="ios-phone-portrait" size={24} color="#38c6f4" />
									<TextInput
										ref={ref => this.textInput = ref}
										style={styles.input}
										autoCapitalize="none"
										autoCompleteType="off"
										keyboardType='number-pad'
										maxLength={10}
										onChangeText={(val)=> this.onChangeAlternateMobileNumber(val)}
										onSubmitEditing={val=> this.checkCustomerPhoneNumber(val)}
										value={this.state.alternateCustomerPhoneNumber}
										editable={this.state.alternativeMobileNumbersList.length < this.state.maximumAlterNativePhoneNumber}
									/>
								</View>
								{this.state.alternativeMobileNumbersList.length > 0 && // if number will exist then it will show otherwise no
									<View
										style={styles.validPhoneNumberMainContainer}
									>
										{ this.state.alternativeMobileNumbersList.map((item, indexItem)=>{
											return(
												<View
													key={ indexItem }
													style={styles.validPhoneNumberInnerContainer}
												>
													<Text
														style={styles.alterNativePhoneNumberText}
													>{ item }</Text>
													<TouchableOpacity
														onPress={ () => this.onDeleteAlternativeNumber( indexItem )}
													>
														<Entypo name="circle-with-cross" size={22} color="#b3b3b3" />
													</TouchableOpacity>
												</View>
											)
										})}
									</View>
								}
							</TouchableOpacity>
							{this.state.alternateNumberError
								? <Text style={styles.errorText}>{this.state.alternateNumberError}</Text>
								: null
							}
							<TouchableOpacity onPress={()=>this.emailInput?.focus()} activeOpacity={1} style={this.state.showErrorMessage === true ?styles.errorFieldViewStyle :styles.fieldViewStyle}>
								<View style={styles.labelTextViewStyle}>
									<Text style={styles.labelTextStyle}>{i18n.t('email')}</Text>
								</View>
								<View style={styles.fieldInnerViewStyle}>
									<FontAwesome name="envelope" size={24} color="#38c6f4" />
									<TextInput
										ref={ref=>{this.emailInput=ref}}
										style={styles.input}
										autoCapitalize="none"
										autoCompleteType="off"
										keyboardType='email-address'
										value={this.state.customerEmail}
										onChangeText={(val)=> this.setState({customerEmail:val})}
									/>
								</View>
							</TouchableOpacity>
							{this.state.emailErrorMessage
								? <Text style={styles.errorText}>{this.state.emailErrorMessage}</Text>
								: null
							}
							<TouchableOpacity onPress={()=>this.setState({showDateOfBirth:true})} activeOpacity={1} style={this.state.showErrorMessage === true ?styles.errorFieldViewStyle :styles.fieldViewStyle}>
								<View style={styles.labelTextViewStyle}>
									<Text style={styles.labelTextStyle}>{i18n.t('dateOfBirth')}</Text>
								</View>
								<TouchableOpacity 
									style={styles.fieldInnerViewStyle}
									onPress={()=> this.setState({showDateOfBirth:true})}>
									<Image 
										source={require('../assets/images/birthday-date.png')} 
										style={styles.calendarIconStyle}
									/>
									<Text style={[styles.input,{ width : Platform.OS==='web' ? wp('30%') : wp('85%')}]}>{ this.state.dateOfBirthDate ?format(this.state.dateOfBirthDate, 'DD/MMM/YYYY') :''}</Text>

									{this.state.showDateOfBirth &&
										<CommonDatePicker
											value={this.state.dateOfBirthDate ? new Date(this.state.dateOfBirthDate) : new Date()}
											maximumDate={new Date()}
											mode="date"
											onChange={(event, selectedDate)=> {
												event.type==='set'
												? this.setState({dateOfBirthDate:new Date(selectedDate), showDateOfBirth:false}) 
												: this.setState({showDateOfBirth:false})}
										}
										/>
									}
								</TouchableOpacity>
							</TouchableOpacity>
							<View style={styles.checkboxFieldViewStyle}>
								<CheckBox
									title={i18n.t('maritalStatus')}
									iconLeft
									checkedColor="#38c6f4"
									uncheckedColor='#b3b3b3'
									size={24}
									textStyle={styles.maritalStatusTextStyle}
									containerStyle={styles.maritalStatusContainerStyle}
									checked={this.state.maritalStatus}
									onPress={() => this.setState({maritalStatus: !this.state.maritalStatus})}
								/>
							</View>
							{this.state.maritalStatus === true &&
								<TouchableOpacity activeOpacity={1} onPress={()=>this.setState({showMarriageDate:true})} style={this.state.showErrorMessage === true ?styles.errorFieldViewStyle :styles.fieldViewStyle}>
									<View style={styles.labelTextViewStyle}>
										<Text style={styles.labelTextStyle}>{i18n.t('dateOfMarriageAnniversary')}</Text>
									</View>
									<TouchableOpacity 
										style={styles.fieldInnerViewStyle}
										onPress={()=> this.setState({showMarriageDate:true})}>
										<Image 
											source={require('../assets/images/marriage_date.png')} 
											style={styles.calendarIconStyle}
										/>
										<Text style={[styles.input,{ width : Platform.OS==='web' ? wp('30%') : wp('85%')}]}>{ this.state.dateOfMarriage ?format(this.state.dateOfMarriage, 'DD/MMM/YYYY') :''}</Text>										
										{this.state.showMarriageDate &&
											<CommonDatePicker
												value={this.state.dateOfMarriage ?new Date(this.state.dateOfMarriage) :new Date()}
												maximumDate={new Date()}
												onChange={(event, selectedDate)=> {
													event.type==='set' 
														? this.setState({dateOfMarriage:new Date(selectedDate), showMarriageDate:false}) 
														: this.setState({showMarriageDate:false})}
											}
											/>
										}
									</TouchableOpacity>
								</TouchableOpacity>
							}
							<Button
								ViewComponent={LinearGradient}
								linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0},end:{x: 1, y: 0},}}
								title={i18n.t('submit')}
								containerStyle={styles.submitButtonContainerStyle}
								buttonStyle={styles.submitButtonStyle}
								titleStyle={styles.titleStyle}
								onPress={this.onSubmitPersonalInformation}
							/>
						</View>
					</ScrollView>
				</View>
			)
		}
	}
}
const styles = StyleSheet.create({
	maritalStatusContainerStyle:{
		backgroundColor:null,
		borderWidth:null,
		padding:0,
		margin:0,
		marginLeft:0,
		marginRight:0
	},
	maritalStatusTextStyle:{
		color:'#b3b3b3',
		fontSize:16,
		textAlign:'left',
		fontWeight:'bold',
		marginLeft:15
	},
	calendarIconStyle:{
		width:24,
		height:24,
		resizeMode:'contain'
	},
	phoneDetailViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		paddingLeft:10,
	},
	requiredLabelTextStyle:{
		fontSize: 14,
		color: "red",
	},
	changeButtonStyle:{
		flexDirection:'row',
		backgroundColor:'#175491',
		borderRadius:3,
		paddingTop:5,paddingLeft:10,
		paddingRight:10,
		paddingBottom:5,
		marginRight:15
	},
	updateCustomerInfoViewStyle:{
		padding:10,
	},
	headerLeftComponentStyle:{
		alignItems:'center',
		flexDirection:'row'
	},
	headerTitleTextStyle:{
		color: '#fff',
		fontSize:16,
		fontWeight:'bold',
		marginLeft:5
	},
	containerStyle:{
		borderBottomWidth:null,
		backgroundColor:null,
		alignItems:'center',
		elevation:5
	},
	loadingViewStyle:{
		backgroundColor:'#ffffff',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	errorText:{
	  	fontSize:12,
		textAlign:'center',
		color:'red',
		width:wp('85%'),
		marginBottom:5,
		alignSelf:'center'
	},
	input: {
		width :wp('85%'),
		fontSize: 14,
		marginLeft:10,
		color:'#808080',
		fontWeight:'bold'
	},
	addressInputBox:{
		width: wp('80%'),
		marginLeft:10,
		fontSize: 14,
		color:'#808080',
		fontWeight:'bold'
	},
	requiredLabelViewStyle:{
		flexDirection:'row',
		backgroundColor:'#ffffff',
		marginTop:-10,
		marginLeft:35,
		marginBottom:5,
		paddingLeft:5,
		paddingRight:5
	},
	phoneNumberRequiredLabelViewStyle:{
		flexDirection:'row',
		backgroundColor:'#ffffff',
		paddingLeft:10,
	},
	fieldViewStyle:{
		borderWidth:1,
		borderColor:'#38c6f4',
		borderRadius:5,
		width:wp('95%'),
		height:50,
		alignItems:'flex-start',
		marginTop:15,
		marginBottom:15
	},
	addressFieldViewStyle:{
		borderWidth:1,
		borderColor:'#38c6f4',
		borderRadius:5,
		width:wp('95%'),
		paddingVertical:8,
		alignItems:'flex-start',
		marginTop:15,
		marginBottom:15
	},
	addressReadOnlyViewStyle:{
		borderWidth:1,
		borderColor:'#38c6f4',
		borderRadius:5,
		width:wp('95%'),
		alignItems:'flex-start',
		marginTop:15,
		marginBottom:15,
		paddingBottom:10
	},
	checkboxFieldViewStyle:{
		width:wp('95%'),
		justifyContent:'flex-start',
		marginTop:15,
		marginBottom:15
	},
	errorFieldViewStyle:{
		borderWidth:0.5,
		borderColor:'red',
		borderRadius:5,
		width:wp('95%'),
		height:50,
		alignItems:'flex-start',
		marginTop:15,
		marginBottom:15
	},
	addressFieldViewStyle:{
		borderWidth:1,
		borderColor:'#38c6f4',
		borderRadius:5,
		width:wp('95%'),
		alignItems:'flex-start',
		marginTop:15,
		marginBottom:15
	},
	addressErrorFieldViewStyle:{
		borderWidth:0.5,
		borderColor:'red',
		borderRadius:5,
		width:wp('95%'),
		alignItems:'flex-start',
		marginTop:15,
		marginBottom:15
	},
	labelTextViewStyle:{
		backgroundColor:'#ffffff',
		marginTop:-10,
		marginLeft:35,
		marginBottom:5,
		paddingLeft:5,
		paddingRight:5
	},
	labelTextStyle:{
		color:'#b3b3b3',
		fontSize:12
	},
	fieldInnerViewStyle:{
		alignItems:'center',
		flexDirection:'row',
		paddingLeft:10
	},
	changeNumberTextStyle:{
		color:'#ffffff',
		fontSize:14,
		fontWeight:'bold'
	},
	phoneNumberInnerViewStyle:{
		justifyContent:'flex-end',
		flexDirection:'row',
	},
	phoneNumberFieldViewStyle:{
		borderWidth:1,
		borderColor:'#38c6f4',
		borderRadius:5,
		width:wp('95%'),
		marginTop:15,
		marginBottom:15,
		paddingTop:10,
		paddingBottom:10,
	},
	phoneNumberValueTextStyle:{
		color:'#808080',
		fontSize:16,
		fontWeight:'bold',
		paddingLeft:10,
		paddingRight:5,
	},
	submitButtonStyle:{
		width:wp('75%'),
		padding:15,
		borderRadius:5
	},
	titleStyle:{
		fontWeight:'bold',
		color:'#ffffff',
		fontSize:20,
		textAlign:'center'
	},
	submitButtonContainerStyle:{
		alignItems:'center',
		marginTop:20,
		marginBottom:20
	},
	alterNativePhoneNumberText:{
		fontSize: 14,
		color:'#5154B5',
		fontWeight:'bold',
		paddingRight:5
	},
	alternativeMobleNumberError:{
		borderWidth:0.5,
		borderColor:'red',
		borderRadius:5,
		width:wp('95%'),
		height:'auto',
		alignItems:'flex-start',
		marginTop:15,
		marginBottom:15,
		paddingBottom:10,
		justifyContent:'center'
	},
	alternativeMobleNumber:{
		borderWidth:1,
		borderColor:'#38c6f4',
		borderRadius:5,
		width:wp('95%'),
		height:'auto',
		alignItems:'flex-start',
		marginTop:15,
		marginBottom:15,
		paddingBottom:10,
		justifyContent:'center'
	},
	validPhoneNumberMainContainer:{
		flexDirection:'row',
		flexWrap:'wrap',
		borderTopWidth:0.5,
		borderColor:'#b3b3b3',
		marginLeft:40,
		width:wp('84%')
	},
	validPhoneNumberInnerContainer:{
		flexDirection:'row',
		marginTop:10,
		marginHorizontal:20,
		backgroundColor:'#f2f5f7',
		borderWidth:1,
		borderColor:'#f2f5f7',
		borderRadius:5,
		alignItems:'center'
	},
	addressListStyle:{
		marginLeft:-20,
		marginTop:-15,
		width: wp('80%'),
		backgroundColor:'white',
		paddingBottom:10
	},
	listItemStyle: {	
		paddingVertical:5,
		fontSize:15,
		fontWeight: 'bold',
		backgroundColor:'white',		
	}
})