import { Alert, Platform } from 'react-native'

const webAlert = ( title, description, options=[], extra ) => {
	
	const result = window.confirm([title,description].filter(Boolean).join('\n'))

	if(result){
		const confirmOption = options.find(({style}) => style!=='cancel')
		confirmOption && confirmOption.onPress && confirmOption.onPress()
	}
	else {
		const cancelOption = options.find(({style}) => style==='cancel')
		cancelOption && cancelOption.onPress && cancelOption.onPress()
	}
}

const commonAlert = Platform.OS==='web' ? webAlert : Alert.alert

export default commonAlert