import React, { Component } from 'react';
import { Text, View, StyleSheet,ActivityIndicator, TouchableOpacity, AsyncStorage,Image, Platform} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Header} from "react-native-elements";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { Ionicons, FontAwesome,MaterialCommunityIcons } from '@expo/vector-icons';
import i18n from 'i18n-js';
import translationValues from '../components/translation';
import client from '../client';
import {format} from 'date-fns';
import logout from '../components/logout';
import alert from '../utils/commonAlert'
/*
	usedFor:-this screen is show when user press on Jar Delivery Frequency,to show the delivery frequency of user
*/
export default class JarDeliveryFrequency extends Component{
	constructor(props){
		super(props)
		this.state={
			isLoading:true,
			frequency:null,
			deliveryDayIncludes:null,
			alernateFrequencyStartDate:null
		}
		this.willFocusSubscription = ''
		
	}
	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			Platform.OS==='web' && window.history.replaceState(null,null,"/JarDeliveryFrequency")
			this.fetchDeliveryFrequency();
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
		})
	}

	fetchDeliveryFrequency = async () => {
		const { navigation, route, route: { params } } = this.props;
		const loginCustomerData = params.loginCustomerData;
		let urlParams = {request_for:'delivery_frequency'}
		const responseData = await client.fetchCustomerDetail(loginCustomerData.business_id, loginCustomerData.customer_business_id, urlParams)
		if (responseData.detail) {
			alert(
				'Customer',
				'You do not have permission to perform this action.',
				[
					{text: 'OK', onPress:()=> logout(this.props.navigation)},
				],
				{ cancelable: false }
			)
		}
		else{
			this.setState({frequency:responseData.frequency, deliveryDayIncludes:responseData.delivery_day_includes,alernateFrequencyStartDate:responseData.alternate_frequency_start_date,isLoading:false})
		}

	}
	componentWillUnmount(){
		this.willFocusSubscription()
	}
	render() {
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<View style={{flex:1, backgroundColor:'#ffffff'}}>
					<Header
						statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						placement={"left"}
						leftComponent={
							<TouchableOpacity
								style={styles.headerLeftComponentStyle}
								activeOpacity={0.5}
								onPress={()=>this.props.navigation.openDrawer()}>
								<Ionicons name='md-menu' color='#38c6f4' size={34} />
								<Text style={styles.headerTitleTextStyle}>{i18n.t('jarDeliveryFrequency')}</Text>
							</TouchableOpacity>
						}
						leftContainerStyle={{justifyContent:'center'}}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
					/>
					<Text style={styles.jarDeliveryFrequencyHeadingTextStyle}>{i18n.t('jarDeliveryFrequencyHeading')}</Text>
					<View style={styles.deliveryFrequencyViewStyle}>
						<TouchableOpacity
						style={this.state.frequency !== 'alternate' && Array.isArray(this.state.frequency) === false ?styles.selectedDeliveryFrequencyTouchableStyle :styles.deliveryFrequencyTouchableStyle}
						disabled={true}>
							<Text style={this.state.frequency !== 'alternate' && Array.isArray(this.state.frequency) === false ?styles.selectedDeliveryFrequencyTextStyle :styles.deliveryFrequencyTextStyle}>{i18n.t('daily')}</Text>
						</TouchableOpacity>
						<TouchableOpacity
						style={this.state.frequency === 'alternate' ?styles.selectedDeliveryFrequencyTouchableStyle :styles.deliveryFrequencyTouchableStyle}
						disabled={true}>
							<Text style={this.state.frequency === 'alternate' ?styles.selectedDeliveryFrequencyTextStyle :styles.deliveryFrequencyTextStyle}>{i18n.t('alternate')}</Text>
						</TouchableOpacity>
						<TouchableOpacity
						style={Array.isArray(this.state.frequency) === true ?styles.selectedDeliveryFrequencyTouchableStyle :styles.deliveryFrequencyTouchableStyle}
						disabled={true}>
							<Text style={Array.isArray(this.state.frequency) === true ?styles.selectedDeliveryFrequencyTextStyle : styles.deliveryFrequencyTextStyle}>{i18n.t('selectWeekDays')}</Text>
						</TouchableOpacity>
					</View>
					{Array.isArray(this.state.frequency) === true
					?<View style={styles.frequencyDetailsViewStyle}>
						<Text style={styles.frequencyDetailTextStyle}>{i18n.t('selectedDays')}</Text>
						<View style={styles.showFrequencyValueViewStyle}>
							<Text style={styles.frequencyTextStyle}>{this.state.frequency.map(item =>i18n.t(item['value'])).join(', ')}</Text>
						</View>
					</View>
					: this.state.frequency === 'alternate'
						? 	<View style={styles.frequencyDetailsViewStyle}>
								<Text style={styles.frequencyDetailTextStyle}>{i18n.t('alternateDayHeading')}</Text>
								<View style={styles.showFrequencyValueViewStyle}>
									<MaterialCommunityIcons name={'calendar-range'} color={'#38c6f4'} size={32}/>
									<Text style={styles.alternateFrequencyTextStyle}>{format(this.state.alernateFrequencyStartDate, 'DD ')}{i18n.t(format(this.state.alernateFrequencyStartDate, 'MMMM'))}{format(this.state.alernateFrequencyStartDate, ' YYYY')}</Text>
								</View>
								<View style={styles.showDeliveryHappenViewStyle}>
									<Text style={styles.deliveryHappenTextStyle}>{i18n.t('alternateDayIncludeWeekend')}{' : '}</Text>
									<Text style={styles.deliveryHappenValueTextStyle}>{this.state.deliveryDayIncludes.map(item =>i18n.t(item)).join(', ')}</Text>
								</View>
							</View>
						: 	null

					}
				</View>
			)
		}
	}

}
const styles = StyleSheet.create({
	headerIconStyle:{
		height: hp('12%'),
		width: wp('12%'),
		resizeMode:'contain',
	},
	headerLeftComponentStyle:{
		flexDirection:'row',
		alignItems:'center'
	},
	headerTitleTextStyle:{
		color: '#fff',
		fontSize:16,
		fontWeight:'bold',
		marginLeft:5
	},
	containerStyle:{
		borderBottomWidth:null,
		backgroundColor:null,
		alignItems:'center',
		elevation:5
	},
	deliveryFrequencyViewStyle:{
		marginTop:20,
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		padding:10,
		paddingBottom:20,
		borderBottomWidth:10,
		borderBottomColor:'#f2f5f7'
	},
	deliveryFrequencyTouchableStyle:{
		borderWidth:0.5,
		borderColor:'#6fdde2',
		backgroundColor:'#ffffff',
		width:wp('30%'),
		height:45,
		alignItems:'center',
		justifyContent:'center',
	},
	selectedDeliveryFrequencyTouchableStyle:{
		borderWidth:1,
		borderColor:'#38c6f4',
		backgroundColor:'#38c6f4',
		width:wp('30%'),
		height:45,
		alignItems:'center',
		justifyContent:'center',
	},
	deliveryFrequencyTextStyle:{
		color:'#b3b3b3',
		textAlign:'center',
		fontSize:14,
	},
	selectedDeliveryFrequencyTextStyle:{
		color:'#ffffff',
		fontWeight:'bold',
		fontSize:14,
		textAlign:'center',
	},
	jarDeliveryFrequencyHeadingTextStyle:{
		marginTop:10,
		padding:10,
		color:'#175491',
		fontWeight:'bold',
		fontSize:18
	},
	frequencyDetailsViewStyle:{
		marginTop:10,
		padding:10,
	},
	frequencyDetailTextStyle:{
		marginBottom:15,
		color:'#175491',
		fontWeight:'bold',
		fontSize:18
	},
	frequencyTextStyle:{
		color:'#000000',
		fontSize:18,
		lineHeight:30,
	},
	loadingViewStyle:{
		backgroundColor:'#ffffff',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	alternateFrequencyTextStyle:{
		color:'#000000',
		fontSize:18,
		paddingLeft:5
	},
	deliveryHappenTextStyle:{
		color:'#38c6f4',
		fontSize:16,
		width:wp('60%')
	},
	deliveryHappenValueTextStyle:{
		color:'#000000',
		fontSize:16,
		width:wp('40%')
	},
	showFrequencyValueViewStyle:{
		flexDirection:'row',
		alignItems:'center'
	},
	showDeliveryHappenViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		paddingTop:20,
	}
})