import React from "react";
import { View, Text, StyleSheet} from "react-native";
import i18n from 'i18n-js';
import {FontAwesome } from '@expo/vector-icons';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
// This component is used to Show common  balance and deposit amount in Bill and Ledger Screen 

export default class BalanceAndDepositView extends React.PureComponent{
	constructor(props){
		super(props);
	}
	render(){
		return(
			<View>
				{this.props.showTotalInvoiceAmount === true &&
					<View style={[styles.totalbalanceContaner, {backgroundColor:'#F5FCFC'}]}>
						<Text>
							<Text style={styles.invoiceTextStyle}>{`${i18n.t('totalInvoiceAmount')} (A)`}</Text>
							<Text style={{fontSize:12,color:'#175491',maxWidth:wp('60%'),}}>{`\n(${i18n.t('includesDiscountExtraText')})`}</Text>
						</Text>
						<Text style={styles.amountTextNeg}>{'₹ '}{this.props.billAmount}</Text>
					</View>
				}
				<View style={[styles.totalbalanceContaner,{backgroundColor:'#E6FFDE'}]} >
					{this.props.showReceivedAmount 
						? <Text style={styles.invoiceTextStyle}>{`${i18n.t('totalGivenAmount')} (B)`}</Text>
						: <Text style={styles.invoiceTextStyle}>{`${i18n.t('depositAmount')}`}</Text>
					}
					<Text style={styles.amountGreen}>{'₹ '}{this.props.depositAmount}</Text>
				</View>
				<View style={styles.totalbalanceView}>
					<Text style={styles.invoiceTextStyle}>{`${i18n.t('balanceAmount')} ${this.props.showReceivedAmount ? '(A-B)':''}`}</Text>
					<Text style={this.props.balanceAmount < 0 ? styles.creditTextStyle : styles.amountTextNeg}>{'₹ '}{this.props.balanceAmount < 0 ? this.props.balanceAmount*-1 : this.props.balanceAmount}
						{this.props.balanceAmount < 0
							? <Text style={[styles.creditTextStyle, {fontWeight:'300', fontSize:14}]}>{` (${i18n.t('creditText')})`}</Text>
							: null
						}
					</Text>
				</View>
			</View>
		)
	}
}
const styles = StyleSheet.create({
	amountGreen:{
		fontSize:16, 
		fontWeight:'bold',
		color:'#299B2E',
		paddingLeft:5
	},
	amountRed:{
		fontSize:16, 
		fontWeight:'bold',
		color:'#ED2424',
		paddingLeft:5
	},
	amountText:{
		fontSize:16, 
		fontWeight:'bold',
		paddingLeft:5,
		color:'#175491',
	},
	amountHeadingText:{
		color:'#175491',
		fontSize:16,
		fontWeight:'bold'
	},
	totalbalanceContaner:{
		alignItems:'center',
		flexDirection:'row', 
		justifyContent:'space-between', 
		padding:10, 
		marginBottom:3
	},
	totalbalanceView:{
		alignItems:'center',
		flexDirection:'row', 
		justifyContent:'space-between', 
		padding:10, 
		marginBottom:3,
		backgroundColor:'#FFF3F3',
		borderTopWidth:1,
		borderBottomWidth:1,
		borderColor:'#e5e5e5'
	},
	textIconContainer:{
		flexDirection:'row', 
		alignItems:'center'
	},
	rupeeFontAdv:{
		color: '#299B2E',
		paddingLeft:10,
		marginTop:3
	},
	rupeeFontDue:{
		color: '#ED2424',
		paddingLeft:25,
		marginTop:3
	},
	rupeeFontStyle:{
		color: '#175491', 
		paddingLeft:25,
		marginTop:3
	},
	rupeeIcon:{
		marginTop:4
	},
	amountTextNeg:{
		color:'#CE3838', 
		paddingLeft:5, 
		fontWeight:'bold', 
		fontSize:16
	},
	invoiceTextStyle:{
		fontSize:16, 
		color:'#175491', 
		fontWeight:'bold',
		maxWidth:wp('60%'),
	},
	creditTextStyle:{
		color:'#26890E',
		fontWeight:'bold',
		marginLeft:5,
		fontSize:16,
	 },
})
BalanceAndDepositView.defaultProps = {
	showTotalInvoiceAmount : false,
	showReceivedAmount: true
}