import AsyncStorage from '@react-native-async-storage/async-storage'
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

export const getDeviceId = async () => {
  let deviceId = await AsyncStorage.getItem('secure_deviceid');
  if (!deviceId) {
    let uuid = uuidv4();
    deviceId = uuid
    if(deviceId){
      await AsyncStorage.setItem('secure_deviceid', deviceId);
    }
    else{
      null
    }
  }
  return deviceId;
}