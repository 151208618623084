import React from "react";
import { View, Text, StyleSheet, TouchableOpacity} from "react-native";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import i18n from 'i18n-js';
import {format} from 'date-fns';
import {FontAwesome } from '@expo/vector-icons';

export default class CustomerBillInfo extends React.PureComponent{
	constructor(props){
		super(props);
	}

	render(){
		const item = this.props.item;
		return(
			<View style={styles.mainView}>
				{item.invoice_amount > 0
					?<View>
						<View style={styles.GSTViewStyle}>
							<View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-start',width:wp('70%'),}}>
								<Text style={{fontSize:14}}>{i18n.t('invoice')}{' '}{i18n.t('amount')}</Text>
							</View>
							<View style={{width:wp('30%'), flexDirection:'row',justifyContent:'flex-start', alignItems:'center'}}>
								<FontAwesome name="rupee" size={14} style={{paddingLeft:25 }} />
								<Text style={styles.amountText}>{item.invoice_amount}</Text>
							</View>
						</View>
						<View style={styles.mainViewStyle}>
							<View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-start',width:wp('70%'),}}>
								<Text style={{fontSize:14}}>{'GST '}{i18n.t('amount')}</Text>
							</View>
							<View style={{width:wp('30%'), flexDirection:'row',justifyContent:'flex-start', alignItems:'center'}}>
								<FontAwesome name="rupee" size={14} style={{ paddingLeft:25}} />
								<Text style={styles.amountText}>{item.total_gst_amount}</Text>
							</View>
						</View>
					</View>
					:null
				}
				<View style={styles.mainView}>
					<View style={styles.mainViewStyle}>
						<View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-start',width:wp('70%'),}}>
							<Text style={{fontSize:14}}>{i18n.t('month_amount')}</Text>
						</View>
						<View style={{width:wp('30%'), flexDirection:'row',justifyContent:'flex-start', alignItems:'center'}}>
							<FontAwesome name="rupee" size={14} style={{ paddingLeft:25 }} />
							<Text style={styles.amountText}>{item.month_amount}</Text>
						</View>
					</View>
					<View style={styles.smallWidthBorder}>
						<View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-start',width:wp('70%'),}}>
							<Text style={{fontSize:14}}>{item.past_month_bill_amount <0 ? i18n.t('last_outstanding_amount')+' ( '+i18n.t('advanceText')+' ) ' : i18n.t('last_outstanding_amount')}</Text>
						</View>
						<View style={{width:wp('30%'), flexDirection:'row',justifyContent:'flex-start', alignItems:'center'}}>
							<FontAwesome name="rupee" size={14} style={item.past_month_bill_amount <0 ? {color:'#299B2E' ,paddingLeft:10 } : item.past_month_bill_amount > 0 ? { color: '#ED2424',paddingLeft:25 } :{paddingLeft:25 } } />
							<Text style={item.past_month_bill_amount <0 ? styles.amountGreen : item.past_month_bill_amount > 0 ?styles.amountRed : styles.amountText}>{item.past_month_bill_amount <0 ?item.past_month_bill_amount*(-1)+' Adv.': item.past_month_bill_amount}</Text>
						</View>
					</View>
					<View style={styles.smallWidthBorder}>
						<View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-start',width:wp('70%'),}}>
							<Text style={{fontSize: 14}}>{i18n.t(format(item.billing_period, 'MMMM'))}{' '}{format(item.billing_period, 'YYYY')} {i18n.t('paid_payment')}</Text>
						</View>
						<View style={{width:wp('30%'), flexDirection:'row',justifyContent:'flex-start', alignItems:'center'}}>
							<FontAwesome name="rupee" size={14} style={{paddingLeft:25 }} />
							<Text style={styles.amountText}>{item.month_total_paid_payment}</Text>
						</View>
					</View>
					{item.discount_amount>0 &&
						<View style={styles.smallWidthBorder}>
							<View style={{width:wp('70%'),flexDirection:'column'}}>
								<Text style={{fontSize:14}}>{i18n.t('showing_discount_value')}</Text>
								{item.discount_amount_reason
									? <Text style={{fontSize:12}}>({item.discount_amount_reason})</Text>
									: 	null
								}
							</View>
							<View style={{width:wp('30%'), flexDirection:'row',justifyContent:'flex-start', alignItems:'center'}}>
								
								<FontAwesome name="rupee" size={14} style={{ paddingLeft:25 }} />
								<Text style={styles.amountText}>-{item.discount_amount}</Text>
							</View>
						</View>
					}
					{item.extra_charges_amount>0 &&
						<View style={styles.smallWidthBorder}>
							<View style={{width:wp('70%'),flexDirection:'column'}}>
								<Text style={{fontSize:14}}>{i18n.t('showing_extra_charges_value')}</Text>
								{item.extra_charges_amount_reason
									?<Text style={{fontSize:12}}>({item.extra_charges_amount_reason})</Text>
									:null
								}
							</View>
							<View style={{width:wp('30%'), flexDirection:'row',justifyContent:'flex-start', alignItems:'center'}}>
								<FontAwesome name="rupee" size={14} style={{ paddingLeft:25}} />
								<Text style={styles.amountText}>{item.extra_charges_amount}</Text>
							</View>
						</View>
					}
					<View style={styles.mainViewStyle}>
						<View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-start',width:wp('70%'),}}>
							<Text style={{fontSize:14}}>{i18n.t('at_starting_amount')}</Text>
						</View>
						<View style={{width:wp('30%'), flexDirection:'row',justifyContent:'flex-start', alignItems:'center'}}>
							<FontAwesome name="rupee" size={14} style={{ paddingLeft:25 }} />
							<Text style={styles.amountText}>{item.at_start_payment}</Text>
						</View>
					</View>
					<View style={styles.mainViewStyle}>
						<View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-start',width:wp('70%'),}}>
							<Text style={{fontSize:14}}>{item.total_amount <0 ?i18n.t('total_amount')+' ( '+i18n.t('advanceText')+' ) ' :i18n.t('total_amount')}</Text>
						</View>
						<View style={{width:wp('30%'), flexDirection:'row',justifyContent:'flex-start', alignItems:'center'}}>
							<FontAwesome name="rupee" size={14} style={item.total_amount < 0 ? { color: '#299B2E',paddingLeft:10 } : item.total_amount > 0 ?{ color: '#ED2424',paddingLeft:25 } :{ paddingLeft:25 }} />
							<Text style={item.total_amount < 0 ? styles.amountGreen :item.total_amount > 0 ? styles.amountRed :styles.amountText }>{item.total_amount < 0 ?item.total_amount*(-1)+' Adv.' :item.total_amount}</Text>
						</View>
					</View>
				</View>
			</View>	
		)
	}
}

const styles = StyleSheet.create({
	mainView:{
		flexDirection:'column',
	},
	mainViewStyle:{
		borderBottomWidth:3, 
		borderBottomColor:'#afdbe2',
		flexDirection:'row',
		justifyContent:'space-between',
		alignItems:'center',
		paddingTop:10,
		paddingBottom:10,
	
	},smallWidthBorder:{
		borderBottomWidth:1, 
		borderBottomColor:'#afdbe2',
		justifyContent:'space-between',
		alignItems:'center',
		flexDirection:'row',
		paddingTop:10,
		paddingBottom:10 
	},amountText:{
		fontSize:14, 
		fontWeight:'bold',
		paddingLeft:15
	},
	GSTViewStyle:{
		flexDirection:'row',
		justifyContent:'space-between',
		alignItems:'center',
		paddingTop:10,
		paddingBottom:10,
	},
	amountGreen:{
		fontSize:14, 
		fontWeight:'bold',
		color:'#299B2E',
		paddingLeft:10
	},
	amountRed:{
		fontSize:14, 
		fontWeight:'bold',
		color:'#ED2424',
		paddingLeft:10
	},
});