import React,{ PureComponent } from 'react';
import { TouchableOpacity,Text, StyleSheet, View, Platform } from 'react-native';
import i18n from 'i18n-js';
import { Ionicons, Feather } from '@expo/vector-icons';
/*
	this component is used to show the details on header in right side

*/
export default class ShareOptionComponent extends PureComponent {
	render() {
		const {isForSharing, isForDownload, isForPrint} = this.props
		return (
			<View style={{padding:10}}>
				<View style={styles.optionViewStyle}>
				{
					Platform.OS!=='web'
					?	<TouchableOpacity style={styles.optionTouchableStyle}
							onPress={()=> this.props.shareDownloadPDF(isForSharing)}>
							<Ionicons name='md-share' size={32} color='#175491' />
							<Text style={styles.optionLabelText}>{i18n.t('share')}</Text>
						</TouchableOpacity>
					: null
				}
					<TouchableOpacity style={styles.optionTouchableStyle}
						onPress={()=> this.props.shareDownloadPDF(isForDownload)}>
						<Feather name="download" size={32} color="#175491"/>
						<Text style={styles.optionLabelText}>{i18n.t('download')}</Text>
					</TouchableOpacity>
					<TouchableOpacity style={styles.optionTouchableStyle}
						onPress={()=> Platform.OS!=='web' ? this.props.shareDownloadPDF(isForPrint) : this.props.shareDownloadPDF(isForDownload)}>
						<Ionicons name='md-print' size={32} color='#175491'/>
						<Text style={styles.optionLabelText}>{i18n.t('print')}</Text>
					</TouchableOpacity>
				</View>
			</View>
		);
	}
}


const styles = StyleSheet.create({
	optionLabelText:{
		color: "#808080",
		fontWeight:'bold',
		fontSize:12,
		textAlign: 'center',
	},
	optionTouchableStyle:{
		flexDirection:'column',
		alignItems:'center',
		paddingHorizontal:8
	},
	optionViewStyle:{
		alignItems:'center',
		flexDirection:'row',
		justifyContent:'space-between',
		marginTop:5,
		marginBottom:5
	},
})