import { View, Image, Text, StyleSheet, TouchableOpacity, ActivityIndicator, ScrollView, TextInput} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import {Header, SearchBar} from "react-native-elements";
import DateTimePicker from '../components/commonDatePicker';
import {FontAwesome, MaterialCommunityIcons, MaterialIcons} from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import {format} from 'date-fns';
import i18n from 'i18n-js';

import {constantValues} from '../utils/helpers';

const RenderPurchaseOrderSummaryUI = (props)=>{
	return (
		<View style={styles.screenMainContainer}>
			<Header
				statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
				leftContainerStyle={{justifyContent:'center'}}
				leftComponent={<TouchableOpacity
					style={styles.leftHeaderContainer}
					onPress={() => props.onPressBack()}
					activeOpacity={0.5}>
					<FontAwesome name='chevron-left' color='#38c6f4' size={28} />
				</TouchableOpacity>}
				centerComponent={<Image resizeMode='cover' style={styles.headerIconStyle}  source={require('../assets/images/small_logo.png')}/>}
				centerContainerStyle={{justifyContent:'center'}}
				leftContainerStyle={{justifyContent:'center'}}
				ViewComponent={LinearGradient}
				linearGradientProps={{colors:constantValues.gradientCommonColorsArray, start:{x: 0, y: 0}, end:{x: 1, y: 0} }}
			/>
			<ScrollView keyboardShouldPersistTaps='always'>
				<View>
					<Text style={{paddingVertical:15, paddingLeft:10, fontSize:18, fontWeight:'bold', color:'#175491', borderBottomWidth:3, borderBottomColor:'#D5F7F0'}}>{i18n.t('orderSummery')}</Text>
					<View style={styles.tableColumnHeadingContainer}>
						<Text style={styles.tableColumnHeading}>{i18n.t('productName')}</Text>
						<Text style={styles.tableColumnHeading}>{i18n.t('quantity')}</Text>
					</View>
				</View>
				<View>
					{props.businessAllItemsArray.map((item, index)=>{
						return(<View style={styles.productDetailContainer} key={index}>
							<View style={styles.prductNameAndIconContainer}>
								<View style={styles.productIconContainer}>
									<Image source={item.product_image_url ?{uri:item.product_image_url} :require('../assets/jar_icon/default-item-icon.png')} style={styles.productIconStyle}/>
								</View>
								<Text style={styles.productNameStyle}>{i18n.t('itemPerDelivery',{jarType:item.user_facing_name ? item.user_facing_name : item.product_name})}</Text>
							</View>
							<Text style={styles.productQuantity}>{item.product_quantity} </Text>
						</View>)
					})}
				</View>
				<View style={styles.totalProductsRow}>
					<Text style={styles.totalProductsStyle}>{i18n.t('totalProduct')}</Text>
					<Text style={styles.totalProductsStyle}>{props.totalProducts}</Text>
				</View>
				<Text style={styles.dateTimePickerTitle}>{i18n.t('selectedDeliveryDateAndTime')}</Text>
				<View style={styles.datePickerViewStyle}>
					<TouchableOpacity
						onPress={()=>props.onOpenDateSelection()}
						style={styles.dateTimePickerTouchableStyle}
					>
						<Text style={styles.dateTimePickerFieldLabel}>{i18n.t('deliveryDate')}</Text>
						<View style={{flexDirection:'row', paddingBottom:2}}>
							<MaterialCommunityIcons name={'calendar-range'} color={'#38c6f4'} size={22} style={styles.datetimeStyle}/>
							<Text style={styles.purchaseOrderDateTimeText}>{props.purchaseOrderDate ?format(props.purchaseOrderDate, 'DD MMM YYYY'): ''}</Text>
						</View>
						{props.showPurchaseOrderSelectionDate === true
							?<DateTimePicker
								value={props.purchaseOrderDate ?new Date(props.purchaseOrderDate) :new Date()}
								minimumDate={new Date()}
								mode="date"
								onChange={(event, val)=> props.onChangeDate(event, val)}
							/>
							:null
						}
					</TouchableOpacity>
					<TouchableOpacity
						onPress={()=>props.onOpenTimeSelection()}
						style={styles.dateTimePickerTouchableStyle}
					>
						<Text style={styles.dateTimePickerFieldLabel}>{i18n.t('deliveryTime')}</Text>
						<View style={{flexDirection:'row', paddingTop:2}}>
							<MaterialIcons name="watch-later" size={20} color="#38c6f4" style={styles.datetimeStyle}/>
							<Text style={styles.purchaseOrderDateTimeText}>{props.purchaseOrderTime ?format(props.purchaseOrderTime, 'hh:mm A') : ''}</Text>
						</View>
						{props.showPurchaseOrderSelectionTime === true
							?<DateTimePicker
								value={props.purchaseOrderTime ?new Date(props.purchaseOrderTime) : new Date()}
								mode="time"
								is24Hour={true}
								onChange={(event, val)=> props.onChangeTime(event, val)}
							/>
							:null
						}
					</TouchableOpacity>
				</View>
				<View style={styles.fieldViewStyle}>
					<Text style={styles.fieldLabelStyle}>{i18n.t('messagesOptional')+' )'}</Text>
					<TextInput
						style={styles.input}
						autoCorrect={false}
						autoCapitalize="none"
						onChangeText={val => props.onChangeOptionalMessage(val)}
						value={props.optionalMessage}
					/>
				</View>
				<View style={{alignItems:'center', justifyContent:'center', borderTopColor:'#D5F7F0', borderTopWidth:3, paddingVertical:15}}>
					<TouchableOpacity
						style={{backgroundColor:'#175491', padding:15, borderRadius:5, alignSelf:'center', width:wp('75%'), justifyContent:'center', alignItems:'center', marginBottom:15}}
						onPress={()=> props.onSubmitOrder()}
					>
						<Text style={{color:'#ffffff', fontWeight:'bold', fontSize:20}}>{i18n.t('submitOrder')}</Text>
					</TouchableOpacity>
				</View>
			</ScrollView>
		</View>
	)
};

export default RenderPurchaseOrderSummaryUI;

const styles = StyleSheet.create({
	screenMainContainer:{
		flex:1,
		backgroundColor:'#ffffff'	
	},
	leftHeaderContainer:{
		paddingLeft:5,
		paddingVertical:5,
		justifyContent:'flex-start',
		width:50,
	},
	headerIconStyle:{
		height: 45,
		width: 45,
		resizeMode:'contain',
	},
	containerStyle:{
		borderBottomWidth:null,
		backgroundColor:null,
		alignItems:'center',
		elevation:5
	},
	tableColumnHeadingContainer:{
		paddingVertical:15,
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		borderBottomWidth:0.5,
		borderBottomColor:'#D5F7F0',
		paddingRight:10,
		paddingLeft:5,
	},
	productDetailContainer:{
		paddingTop:15,
		paddingBottom:15,
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		borderBottomWidth:0.8,
		borderBottomColor:'#D5F7F0',
		paddingRight:10,
		paddingLeft:5,
	},
	prductNameAndIconContainer:{
		flexDirection:'row',
		alignItems:'center'
	},
	perDeliveryJarIconStyle:{
		width:30,
		height:30,
		resizeMode:'contain'
	},
	productIconStyle:{
		width:20,
		height:20,
		resizeMode:'contain',
	},
	productIconContainer:{
		borderRadius:15,
		padding:5,
		borderWidth:1,
		borderColor:"#38c6f4",
	},
	tableColumnHeading:{
		fontSize:18,
		fontWeight:'bold',
		color:'#38C6F4',
		paddingLeft:5,
	},
	productNameStyle:{
		fontSize:16,
		fontWeight:'bold',
		color:'#175491',
		width:wp('40%'),
		marginLeft:5,
	},
	productQuantity:{
		fontSize:16,
		fontWeight:'bold',
		color:'#175491',
		width:wp('10%'),
		textAlign:'right'
	},
	totalProductsStyle:{
		marginLeft:10,
		paddingVertical:8,
		color:'#175491',
		fontWeight:'bold',
		fontSize:18,
	},
	totalProductsRow:{
		flexDirection:'row',
		paddingRight:10,
		paddingLeft:3,
		justifyContent:'space-between',
		paddingVertical:10,
		backgroundColor:'#F2FCFC',
		borderBottomWidth:3,
		borderBottomColor:'#D5F7F0',
	},
	dateTimePickerTitle:{
		marginLeft:10,
		paddingVertical:8,
		color:'#175491',
		fontWeight:'bold',
		fontSize:16
	},
	dateTimePickerFieldLabel:{
		fontSize: 14,
		color: "#b3b3b3",
		marginTop:-30,
		backgroundColor:"#ffffff",
		paddingHorizontal:1,
		paddingVertical:5,
		width:100
	},
	datePickerViewStyle:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		paddingHorizontal:10,
		marginBottom:10,
		marginTop:5,
		paddingBottom:10,
		borderBottomWidth:3,
		borderBottomColor:'#D5F7F0',
	},
	datetimeStyle:{
		marginTop:3,
	},
	dateTimePickerTouchableStyle:{
		justifyContent:'flex-start',
		paddingLeft:10,
		paddingRight:5,
		paddingBottom:10,
		paddingTop:15,
		minWidth:150,
		borderRadius:5,
		borderWidth:1,
		borderColor:'#38c6f4',
	},
	purchaseOrderDateTimeText:{
		fontSize: 18,
		fontWeight:'bold',
		paddingBottom:5,
		color: "#808080",
		marginLeft:5,
	},
	fieldLabelStyle:{
		fontSize: 15,
		color: "#b3b3b3",
		marginTop:-10,
		marginLeft:10,
		backgroundColor:"#ffffff",
		paddingHorizontal:5,
	},
	fieldViewStyle:{
		borderWidth:1,
		borderColor:'#38c6f4',
		borderRadius:5,
		width:wp('95%'),
		alignItems:'flex-start',
		marginTop:10,
		marginBottom:18,
		alignSelf:'center',
	},
	input:{
		width:wp('92%'),
		fontSize: 18,
		marginLeft:10,
		marginTop:-20,
		color:'#808080',
		fontWeight:'bold',
		paddingTop:25,
		paddingBottom:12,
	},
});