import { useState } from 'react'
import { StyleSheet, View, Pressable, TextInput } from 'react-native'
import { AntDesign } from '@expo/vector-icons'; 
import PropTypes from 'prop-types'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
export default function NumericInput(props){
	//Destructuring Props
	const { iconColor, addButtonStyle, onLimitReached, substractButtonStyle, iconSize,  value, inputStyle, plusRippleColor, minusRippleColor, onChange, editable, minValue, maxValue, containerStyle, textInputProps, substractButtonProps, addButtonProps, reachMinIncButtonStyle, reachMinDecButtonStyle ,reachMaxIncButtonStyle, reachMaxDecButtonStyle, step, initValue, type, separatorWidth, separatorColor } = props
	//State in case value , onchange are not provided
	const [counter,setCounter] = useState(initValue)
	//Value to display on Input and make calculations
	const currentValue = value || value===0 ? value.toString() : counter.toString()
	//Function to handle Add Button Press
	const handleAdd = () => {
			//Checking if changed value is greater than max value
			if(maxValue && parseInt(currentValue)+step > maxValue){
				onLimitReached(false,"Max_Limit_Reached")
			}
			else {
				onChange(parseInt(currentValue)+step)
				setCounter(parseInt(currentValue)+step)
			}
	}
	//Function to handle Substract Button Press
	const handleSubstract = () => {
			//Checking if changed value is less than min value
			if((minValue || minValue===0) && parseInt(currentValue)-step < minValue){
				onLimitReached(true,"Min_Limit_Reached")
			}
			else {
				onChange(parseInt(currentValue)-step)
				setCounter(parseInt(currentValue)-step)
			}
	}
	//Function to handle direct Input Change
	const handleInputChange = val => {
		if(val > maxValue){
			onChange(maxValue)
			setCounter(maxValue)
			onLimitReached(false,"Max_Limit_Reached")
		}
		else if(val < minValue){ 
			onChange(minValue)
			setCounter(minValue)
			onLimitReached(true,"Min_Limit_Reached")
		} 
		else if(val===''){
			onChange(0)
			setCounter(0)
		}
		else {
			onChange(parseInt(val))
			setCounter(parseInt(val))
		}
	}
	if(type==='up-down') return (
		<View style={[styles.updownContainer,containerStyle]}>
			<TextInput editable={editable} onChangeText={handleInputChange} selectTextOnFocus={true} value={currentValue} keyboardType='numeric' style={[styles.inputUpDown,inputStyle,{borderRightColor:separatorColor, borderRightWidth:separatorWidth}]} {...textInputProps}  />
			<View style={{flex:1}}>
				<Pressable onPress={handleAdd} android_ripple={{color:plusRippleColor, borderless:false}} style={[styles.buttonUp, addButtonStyle, value===minValue ? reachMinIncButtonStyle : value===maxValue ? reachMaxIncButtonStyle : {} ]} {...addButtonProps} >
					<AntDesign name="arrowup" size={iconSize} color={iconColor} />
				</Pressable>
				<Pressable onPress={handleSubstract} android_ripple={{color:minusRippleColor, borderless:false}} style={[styles.buttonDown, substractButtonStyle, value===minValue ? reachMinDecButtonStyle : value===maxValue ? reachMaxDecButtonStyle : {} ]} {...substractButtonProps} >
					<AntDesign name="arrowdown" size={iconSize} color={iconColor} />
				</Pressable>
			</View>
		</View>
	)
	else return (
		<View style={[styles.container,containerStyle]}>
			<Pressable onPress={handleSubstract} android_ripple={{color:minusRippleColor}} style={[styles.button, substractButtonStyle, value===minValue ? reachMinDecButtonStyle : value===maxValue ? reachMaxDecButtonStyle : {} ]} {...substractButtonProps} >
				<AntDesign name="minus" size={iconSize} color={iconColor} />
			</Pressable>
			<TextInput editable={editable} onChangeText={handleInputChange} selectTextOnFocus={true} value={currentValue} keyboardType='numeric' style={[styles.input,inputStyle,{ borderRightColor:separatorColor, borderLeftColor:separatorColor, borderLeftWidth:separatorWidth, borderRightWidth:separatorWidth }]} {...textInputProps} />
			<Pressable onPress={handleAdd} android_ripple={{color:plusRippleColor}} style={[styles.button, addButtonStyle, value===minValue ? reachMinIncButtonStyle : value===maxValue ? reachMaxIncButtonStyle : {} ]} {...addButtonProps} >
				<AntDesign name="plus" size={iconSize} color={iconColor} />
			</Pressable>
		</View>
	)
}
const styles = StyleSheet.create({
	container : {
		flexDirection : 'row',
		justifyContent:'space-between',
		overflow : 'hidden',
		borderWidth : 0.5,
		width : wp('35%'),
		height : 40,
		borderRadius : 5,
		borderColor : 'gray'
	},
	input : {
		textAlign : 'center',
		fontSize : 15,
		backgroundColor : '#ffffff',
		color : 'black',
		width : '40%'
	},
	button : {
		alignItems : 'center',
		justifyContent : 'center',
		backgroundColor : '#38C6F4',
		width : '30%',
	},
	updownContainer : {
		width : 100,
		height : 75,
		borderColor : '#808080',
		borderWidth : 1,
		borderRadius : 10,
		flexDirection : 'row'
	},
	inputUpDown : {
		width : '66%',
		fontSize : 30,
		textAlign : 'center',
		color : 'black'
	},
	buttonUp : {
		flex : 1,
		alignItems : 'center',
		justifyContent : 'center'
	},
	buttonDown : {
		flex : 1,
		alignItems : 'center',
		justifyContent : 'center'
	}
})
NumericInput.propTypes = {
	iconColor : PropTypes.string,
	iconSize : PropTypes.number,
	minusRippleColor : PropTypes.string,
	plusRippleColor : PropTypes.string,
	onChange : PropTypes.func,
	editable : PropTypes.bool,
	addButtonStyle : PropTypes.object,
	substractButtonStyle : PropTypes.object,
	value : PropTypes.oneOfType([PropTypes.number,PropTypes.string]),
	onLimitReached : PropTypes.func,
	minValue : PropTypes.number,
	maxValue : PropTypes.number,
	inputStyle : PropTypes.object,
	containerStyle : PropTypes.object,
	textInputProps : PropTypes.object, 
	substractButtonProps : PropTypes.object, 
	addButtonProps : PropTypes.object,
	reachMinIncButtonStyle : PropTypes.object,
	reachMinDecButtonStyle : PropTypes.object,
	reachMaxIncButtonStyle : PropTypes.object,
	reachMaxDecButtonStyle : PropTypes.object,
	step : PropTypes.number,
	initValue : PropTypes.number,
	type : PropTypes.oneOf(['plus-minus','up-down']),
	separatorWidth : PropTypes.number,
	separatorColor : PropTypes.string
}
NumericInput.defaultProps = {
	iconColor : '#ffffff',
	iconSize : 18,
	minusRippleColor : '#ffffff',
	plusRippleColor : '#ffffff',
	onChange : ()=>{},
	editable : false,
	rightButtonStyle : {},
	leftButtonStyle : {},
	value : null,
	onLimitReached : ()=>{},
	inputStyle : {},
	containerStyle : {},
	textInputProps : {}, 
	substractButtonProps : {}, 
	addButtonProps : {},
	reachMinIncButtonStyle : {},
	reachMinDecButtonStyle : {},
	reachMaxIncButtonStyle : {},
	reachMaxDecButtonStyle : {},
	step : 1,
	initValue : 0,
	type : 'plus-minus',
	separatorWidth : 1,
	separatorColor : '#808080'
}