import axios from 'axios';
import { AsyncStorage, Platform } from 'react-native';
import getEnvVars from './environment';
const { businessApiUrl } = getEnvVars();

const webHeaders = {
	'Accept' : '*/*'
}

const mobileHeaders = {
	'Access-Control-Allow-Origin': '*',
	'Accept': '*/*',
};

const v1 = axios.create({
	baseURL: businessApiUrl,
	headers : Platform.OS==='web' ? webHeaders : mobileHeaders
});

v1.interceptors.request.use((req) => {
	if (global.hasOwnProperty('userTokenData') && global.userTokenData) {
		const authToken = global.userTokenData.token;
		req.headers['Authorization'] = 'Token ' +authToken;
	}
	// Important: request interceptors **must** return the request.
	return req;
	},
	(error) => {
		return Promise.reject(error);
	}
);

v1.interceptors.response.use(res => {
	// Important: response interceptors **must** return the response.
	return res;
	},
	(error) => {
		if (error.response && (error.response.status === 401 || error.response.status === 403)) {
			return error.response;//if error.response exist
		}
		else {
			return {
				data:{
					statusCode:400,
					responseDetail:'badRequest'
				}
			}//To create error data
		}
	}
);

export const clientBusinessApi = {
	v1,
	fetchProducts:(businessId, params) => {
		return v1.get(`products/${businessId}/`, {params:params}).then((res) => {
			return res;
		});
	},
	savePlacedOrder:(customerBusinessId, requestBody) => {
		return v1.post(`product-orders/customer-order/${customerBusinessId}/`, requestBody).then((res) => {
			return res;
		});
	},
	getAllCreatedOrders:(businessId, params)=>{
		return v1.get(`product-orders/all-orders/${businessId}/`, {params:params}).then((res) =>{
			return res;
		});
	},
};