import React, { Component } from 'react';
import getEnvVars from '../environment';
const { apiUrl } = getEnvVars();
import { Text, View, StyleSheet, ScrollView, TextInput,ActivityIndicator, Image} from 'react-native';
import {Button} from 'react-native-elements';
import { LinearGradient } from 'expo-linear-gradient';
import { FontAwesome, MaterialCommunityIcons} from '@expo/vector-icons';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
const axios = require('axios');
import AutoCompleteAddress from '../components/GooglePlacesAutocomplete';
import translationValues from '../components/translation';
import i18n from 'i18n-js';
import {format} from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage'
import alert from '../utils/commonAlert'

export default class CustomerRequestInfo extends Component{

	constructor(props){
		super(props)
		this.state = {
			isLoading:true,
			fullName: '',
			location:'',
			zipcode:'',
			showPlaceList:false,
			chilledJar:'',
			capsuleJar:'',
			locationType:'',
			deliveryTime:new Date(),
			eventFromDate:new Date(),
			eventToDate:new Date(),
			eventComment:'came from referral',
		}
		this.willFocusSubscription = ''
	}

	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
		});
		this.setState({isLoading:false})
	}

	componentWillUnmount(){
		this.willFocusSubscription();
	}

	onChangeText = (key, val) =>{
		this.setState({[key]: val})
	}

	onChange = (key,val) => {
		const re = /^[0-9\b]+$/;
		if (val === '' || re.test(val)) {
			this.setState({[key]: val})
		}
	}

	submitCustomerRequest = async () => {
		const{fullName, location, zipcode} = this.state;
		this.props.navigation.navigate('AdditionalCustomerRequestInfo',{
			fullName:fullName,
			location:location,
			zipcode:zipcode
		});
	};
	submitRequest = async () => {
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		const JsonLoginCustomerData = JSON.parse(loginCustomerData)
		const { navigation, route, route: { params } } = this.props;
		const{fullName,location,zipcode, locationType, eventComment, eventFromDate, eventToDate, deliveryTime, chilledJar, capsuleJar} = this.state;
		this.setState({isLoading:true})
		const response = await axios.post(apiUrl+'customer-request-info/'+JsonLoginCustomerData.customer_otp_verify_id+'/',{
			full_name:fullName,
			location:location,
			location_zip_code:zipcode,
			location_type:locationType,
			delivery_time:format(deliveryTime, 'HH:mm'),
			event_from_date:format(eventFromDate,'DD-MM-YYYY'),
			event_to_date:format(eventToDate, 'DD-MM-YYYY'),
			event_comment:eventComment,
			number_of_chilled_jar:chilledJar,
			number_of_capsule_jar:capsuleJar,
			is_request_more:false
			},
			{
				headers:{
					Authorization:'Token '+JsonLoginCustomerData.token
				}
			}
		);
		if(response.data && response.data.success){
			this.setState({
				fullName: '',
				location:'',
				zipcode:'',
				chilledJar:'',
				capsuleJar:'',
				locationType:'',
				deliveryTime:'',
				eventFromDate:'',
				eventToDate:'',
				eventComment:'',
				showTime:false,
				showTimePicker:false,
				showEventDetails:false,
				isLoading:false
			});
			AsyncStorage.getItem('loginCustomerData')
				.then( data => {
				// transform it back to an object
				data = JSON.parse( data );
				data.fullName = fullName
				data.location = location
				data.is_not_serve_area = response.data.is_not_serve_area
				data.is_already_submitted_info = true
				//save the value to AsyncStorage again
				AsyncStorage.setItem('loginCustomerData', JSON.stringify(data));
				global.userTokenData = data
				this.props.navigation.replace('LeadChatScreen',{
					screen:'ShowAllBusiness',
					params:{loginCustomerData:data,is_not_serve_area:response.data.is_not_serve_area,},
				});
			})
		}
		else{
				alert(
				i18n.t('customer'),
				i18n.t('badRequest'),
				[
					{text: i18n.t('ok'), },
				],
				{ cancelable: false }
			)
		}
	};

	handleAddressOnPress=(details)=>{
		let zipcode = '';
		if (details.address_components.length>=1) {
			for (let i=0; i<details.address_components.length; i++){
				if (details.address_components[i]['types'][0]=='postal_code'){
					zipcode = details.address_components[i]['long_name'];
				}	
			}
		}
		let selected_location = details.title;
		this.setState({location:selected_location,zipcode:zipcode,showPlaceList:false, showErrorMessage:false, errorMessage:''})
		this.address && this.address.setNativeProps({ selection: { start: 0, end: 0 } })


	}
	handleAddressChangeText=(value,showPlaceList)=>{
		if(showPlaceList){
			this.setState({location:value,showPlaceList:showPlaceList,showErrorMessage:false, errorMessage:'',zipcode:''})
			this.address && this.address.setNativeProps({ selection: null })
		}
		else{
			this.setState({location:value,showErrorMessage:false, errorMessage:'',zipcode:''})
		}
	}
	handleAddressSubmitEdit=()=>{
		this.setState({showPlaceList:false,showErrorMessage:false, errorMessage:''})
	}
	
	render(){
		const { navigation, route, route: { params } } = this.props;
		if(this.state.isLoading){
			return(
				<View style={{position: 'absolute',left: 0,right: 0,top: 0,bottom: 0,alignItems: 'center',justifyContent: 'center',backgroundColor:'#ffffff'}}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<View style={{flex:1, backgroundColor:'#ffffff'}}>
					<ScrollView keyboardShouldPersistTaps={'always'}>
						<LinearGradient
							colors={['#175491','#175491','#5154b5','#5154b5']}
							start={{x: 0, y: 0}}
							end={{x: 1, y: 0}}>
							<View style={{height:hp('28%'),alignItems:'center',justifyContent:'center'}}>
								<Image 
									source={require('../assets/images/small_logo.png')} 
									style={{height: hp('17%'),width: wp('20%'),resizeMode : 'contain'}}
								/>
							</View>	
						</LinearGradient>
						<View style={{alignItems:'center'}}>
							<View style={styles.innerView}>
								<View style={styles.innerViewStyle}>
									<View style={{height:hp('8%')}}>
										<Text style={{fontWeight:'bold', fontSize:25,textAlign:'center',marginTop:10,color:'#175491'}}>{i18n.t('getJarDelivered')}</Text>
									</View>
									<View style={styles.AddressView}>
										<View style={styles.inputTextBoxView}>
											<FontAwesome name='user-circle-o' size={24} color='#175491'
											/>
											<TextInput
												style={styles.input}
												placeholder={i18n.t('enterName')}
												autoCapitalize="none"
												placeholderTextColor='#808080'
												onChangeText={val => this.onChangeText('fullName', val)}
												value={this.state.fullName}
											/>
										</View>
										
										<View style={styles.inputTextBoxView}>
											<MaterialCommunityIcons name='map-marker-radius' size={24} color='#175491' style={{marginTop:5}}/>
											<AutoCompleteAddress 
												value={this.state.location}
												onSelect={this.handleAddressOnPress}
												onChangeText={this.handleAddressChangeText}
												textInputProps={{placeholder:i18n.t('address')+" *"}}
												inputStyle={styles.input}
												containerStyle={{width:wp('65%')}}
											/>
										</View>
										<View style={styles.inputTextBoxView}>
											<Image 
												source={require('../assets/images/pin-code.png')} 
												style={{height: 25,width: 25,resizeMode : 'contain'}}
											/>
											<TextInput
												style={styles.input}
												placeholder={i18n.t('enterPinCode')}
												autoCapitalize="none"
												keyboardType='number-pad'
												placeholderTextColor='#808080'
												onChangeText={val => this.onChange('zipcode', val)}
												maxLength={6}
												value={this.state.zipcode}
											/>
										</View>
									</View>
								</View>
								<View style={{flexDirection:'row', justifyContent:'center',alignItems:'center',marginTop:-35}}>
								{params.isCameFromReferral === true 
									? <Button
										ViewComponent={LinearGradient}
										linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0},end:{x: 1, y: 0},}}
										title={i18n.t('submit')}
										buttonStyle={{width:wp('70%'),padding:20,borderRadius:5}}
										containerStyle={{alignItems:"center"}}
										titleStyle={{fontWeight:'bold',color:'#ffffff',fontSize:20,textAlign:'center'}}
										onPress={this.submitRequest}
										disabled={this.state.fullName==='' || this.state.location==='' ||this.state.zipcode==='' || this.state.zipcode.length < 6 ?true:false}
										disabledTitleStyle={{color:'hsl(208, 8%, 63%)'}}
									/>
									: <Button
										ViewComponent={LinearGradient}
										linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0},end:{x: 1, y: 0},}}
										title={i18n.t('next')}
										buttonStyle={{width:wp('70%'),padding:20,borderRadius:5}}
										containerStyle={{alignItems:"center"}}
										titleStyle={{fontWeight:'bold',color:'#ffffff',fontSize:20,textAlign:'center'}}
										onPress={this.submitCustomerRequest}
										disabled={this.state.fullName==='' || this.state.location==='' ||this.state.zipcode==='' || this.state.zipcode.length < 6 ?true:false}
										disabledTitleStyle={{color:'hsl(208, 8%, 63%)'}}
									/>
								}
								</View>
							</View>
						</View>
					</ScrollView>
				</View>
			);
		}
	}
}

const styles = StyleSheet.create({
  input: {
	width: wp('65%'),
	color:'#808080',
	fontSize: 20,
	marginLeft:10,
  },
  innerView:{
	alignItems: 'center',
	width:wp('95%'),
	backgroundColor: '#fff',
	padding:10,
	marginTop:-50,
	borderRadius:10,
  },
  innerViewStyle:{
	width:wp('90%'),
	backgroundColor: '#fff',
	borderRadius:5,
	borderWidth:1,
	borderColor:'#175491'
  },
  inputTextBoxViewError:{
  	flexDirection: 'row', 
  	borderBottomWidth:1, 
  	borderBottomColor:'red',
  	margin:10,
  	marginBottom:10
  },
  inputTextBoxView:{
  	flexDirection: 'row',
  	borderBottomWidth:1,
  	borderBottomColor:'#6fdde2',
  	margin:10,
  	marginTop:40
  },
  AddressView:{
  	top:0,
  	zIndex:1,
  	marginBottom:60,
  	paddingLeft:10,
  	paddingRight:10
  }
})