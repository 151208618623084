import React, { Component } from 'react';
import getEnvVars from '../environment';
const { apiUrl } = getEnvVars();
import { Text, View, ActivityIndicator, TouchableOpacity,FlatList, Image, StyleSheet} from 'react-native';
const axios = require('axios');
import { LinearGradient } from 'expo-linear-gradient';
import { ListItem, SearchBar} from "@rneui/themed";
import { Ionicons } from '@expo/vector-icons';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import AsyncStorage from '@react-native-async-storage/async-storage'

export default class AdminDashboard extends Component{
	
	constructor(props){
		super(props);
		this.state={
			isLoading: true,
			allBusinessCustomerList:[],
			showLoading:false,
			searchValue:'',
		}
	}
	componentDidMount(){
		this.fetchAllBusinessVerifiedCustomers();
	}
	async fetchAllBusinessVerifiedCustomers(){
		const { navigation, route, route: { params } } = this.props;
		let loginCustomerData = params.loginCustomerData;
		const response = await fetch(
			apiUrl+'verified-business-customers/?search='+encodeURIComponent(this.state.searchValue),{
				headers:new Headers({
					Authorization:'Token '+loginCustomerData.token
				})
			}
		);
		const responseJsonData = await response.json();
		if (response.status === 401) {
			this.props.navigation.replace('LoginStackScreen',{
				screen:'CustomerLoginScreen',
				params:{}
			});
		}
		else{
			if(responseJsonData.success){
				this.setState({ allBusinessCustomerList: responseJsonData.all_customer_business_otp_verify_list,isLoading:false, showLoading:false});
			}
			else{
				this.setState({allBusinessCustomerList:[],isLoading:false, showLoading:false});
			}
		}
	}
	skipSuperAdmin = async() =>{
		const { navigation, route, route: { params } } = this.props;
		let loginCustomerData = params.loginCustomerData;
		this.OnselectValue(loginCustomerData.customer_otp_verify_id)
	}
	OnselectValue = async (customer_otp_verify_id) => {
		const { navigation, route, route: { params } } = this.props;
		let loginCustomerData = params.loginCustomerData;
		this.setState({isLoading:true})
		try{
			const response = await fetch(
				apiUrl+'verified-business-customers/',{
				method:'POST',
				body: JSON.stringify({
				    customer_otp_verify_id: customer_otp_verify_id
				 }),
				headers:{
					Authorization:'Token '+loginCustomerData.token,
					"Accept": "application/json",
					"Content-Type": "application/json",
				}
			})
			const responseData = await response.json();
			if (response.status === 401) {
				this.props.navigation.replace('LoginStackScreen',{
					screen:'CustomerLoginScreen',
					params:{}
				});
				
			}
			else{
				if(responseData.success){
					await AsyncStorage.setItem('loginCustomerData', JSON.stringify(responseData.user_loggedin_data));
					global.userTokenData = responseData.user_loggedin_data
					if(responseData.user_loggedin_data.list_of_business_customers.length>1){
						this.props.navigation.replace('ShowCustomerBusiness',{
							loginCustomerData:responseData.user_loggedin_data,
						})
					}
					else{
						AsyncStorage.getItem('loginCustomerData')
						.then( data => {
						// transform it back to an object
							data = JSON.parse( data );
							data.business_id = responseData.user_loggedin_data.list_of_business_customers[0].business_id
							data.customer_business_id = responseData.user_loggedin_data.list_of_business_customers[0].customer_business_id,
							data.business_name = responseData.user_loggedin_data.list_of_business_customers[0].business_name,
							data.business_address = responseData.user_loggedin_data.list_of_business_customers[0].business_address,
							data.phone_number = responseData.user_loggedin_data.list_of_business_customers[0].phone_number,
							data.customer_name = responseData.user_loggedin_data.list_of_business_customers[0].customer_name
							//save the value to AsyncStorage again
							AsyncStorage.setItem('loginCustomerData', JSON.stringify(data));
							this.props.navigation.replace('CustomerStackScreen',{
								screen:'CustomerScreen',
								params:{
									screen: 'Home', 
									params:{loginCustomerData:data},
								}
							});
						})
					}
				}
			}
		}
		catch(err){
			console.log(err);
		}

	}
	resetCustomerData = () => {
		this.setState({allBusinessCustomerList:[], showLoading:true},()=> this.fetchAllBusinessVerifiedCustomers());
	}
	searchFilterFunction = () => {
		this.setState({allBusinessCustomerList:[], showLoading:true},()=>this.fetchAllBusinessVerifiedCustomers());
    	
	}
	handleSearch = (searchText) => {    
    	this.setState({searchValue:searchText});
    	if(searchText === ''){
    		this.searchFilterFunction()
    	}
  	}
	renderHeader = () => {  
		return (
			<View style={{padding:10}}>
				<Text style={{fontWeight:'bold', fontSize:16,textAlign:'left',color:'#175491',}}>All Verified Business Customers On GoPaani</Text>
				<SearchBar
					platform="android"
					placeholder="Search"
					onChangeText={this.handleSearch}
					value={this.state.searchValue}
					loadingProps={{size:'large',color:'skyblue'}}
					showLoading={this.state.showLoading}
					searchIcon={<Ionicons name="search" size={24} color="#808080"/>}
					clearIcon={null}
					cancelIcon={<Ionicons name="arrow-back" size={24} color="#808080"/>}
					containerStyle={styles.searchbarContainer}
					inputContainerStyle={styles.inputContainer}
					onClear={this.resetCustomerData}
					returnKeyType='search'
					onSubmitEditing={(event)=>this.searchFilterFunction(event.nativeEvent.text)}
					onCancel={this.resetCustomerData}
					
				/>
			</View>
		);
	}
	renderSeparator = () => {
		return (
			<View
				style={{
					height: 1,
					width: '100%',
					backgroundColor: '#CED0CE',
				}}
			/>
		);
	}
	renderNotFoundData = () => {
		return (
			<View style={{padding:10,marginTop:10}}>
				<Text style={{fontWeight:'bold', fontSize:14,textAlign:'center',color:'#175491',}}>No Business Customer Found</Text>
			</View>
		);
	}
	render() {
		if(this.state.isLoading){
			return(
				<View style={{position: 'absolute',left: 0,right: 0,top: 0,bottom: 0,alignItems: 'center',justifyContent: 'center', backgroundColor:'#ffffff'}}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return (
				<View style={{flex:1, backgroundColor:'#ffffff'}}>
					<LinearGradient
						start={{x:0,y:0}}
						end={{x:1,y:0}}
						colors={['#175491','#175491','#5154b5','#5154b5']}
						style={styles.headerContainer}
					>
						<View style={styles.headerSection}/>
						<View style={styles.headerSection}>
							<Image resizeMode='contain' style={styles.mainIcon}  source={require('../assets/images/small_logo.png')}/>
						</View>
						<TouchableOpacity onPress={this.skipSuperAdmin} style={styles.skipButton}>
							<Text style={styles.skipText}>Skip</Text>
						</TouchableOpacity>
					</LinearGradient>
					<FlatList 
						data={this.state.allBusinessCustomerList} 
						renderItem={({item}) => {
							return(
								<ListItem
									onPress={()=>this.OnselectValue(item.customer_otp_verify_id)}
								>
									<ListItem.Content>
										<ListItem.Title style={styles.listTitle}>{item.customer_name}</ListItem.Title>
										<ListItem.Subtitle style={styles.listSubtitle}>{item.business_name}</ListItem.Subtitle>
									</ListItem.Content>
								</ListItem>
							)}
						}
						ItemSeparatorComponent={this.renderSeparator}
						ListHeaderComponent={this.renderHeader}
						ListEmptyComponent={!this.state.showLoading&& this.renderNotFoundData}
						keyExtractor={(item, index) => index.toString()}
						extraData={this.state}
					/>
				</View>	
			);
		}
	};
}

const styles = StyleSheet.create({
	headerContainer:{
		flexDirection:'row',
		alignItems:'center',
		justifyContent:'space-between',
		padding:10
	},
	headerSection:{
		flex:1,
		alignItems:'center',
		justifyContent:'center',
	},
	listTitle:{
		color:'#175491',
		fontSize:16,
		fontWeight:'bold',
	},
	listSubtitle:{
		color:'#808080',
		fontSize:14,
		fontWeight:'bold',
	},
	skipText:{
		color:'#FFFFFF',
		fontSize:18,
		fontWeight:'bold',
	},
	mainIcon:{
		width:45,
		height:45,
	},
	skipButton:{
		flex:1,
		justifyContent:'center',
		alignItems:'flex-end',
	},
	inputContainer:{
		backgroundColor:'#FFFFFF',
	},
	searchbarContainer:{
		marginTop:0,
		backgroundColor:'#FFFFFF',
		borderBottomWidth:1,
		borderBottomColor:'#175491',
	}
})