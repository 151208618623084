import AsyncStorage from '@react-native-async-storage/async-storage'
import { Platform } from 'react-native'
import client from '../client'

const navigateToCustomerScreen = (route,params,navigation) => {
	navigation.navigate('CustomerStackScreen',{
					screen:'CustomerScreen',
					params:{
						screen: route, 
						params: params,
					}
				});
}

const navigateToBillsLedgerScreen = (route,params,navigation) => {
	navigation.navigate('CustomerStackScreen',{
					screen:'BillAndLedgerScreen',
					params:{
						screen:route,
						params
					}
				});

}

export const titleFormatter = (options, route) => {
	const defaultTitle = options?.title ?? route?.name;
	if(Platform.OS==='web'){
		switch(defaultTitle){
			case "LoginStackScreen" :
				return "Login"
			case "CustomerProfile" :
				return "Your Profile"
			case "ChatConversation" :
				return "Chat With Supplier"
			case "ChooseLanguage" :
				return "Choose Language"
			case "JarDeliveryFrequency" :
				return "Product Delivery Frequency"
			case "SwitchAccount" :
				return "Switch Account"
			case "CustomerStackScreen" :
				return "Home"
			case "ResetCustomerPhoneNumber":
				return "Reset Phone Number"
			case "PlaceOrderScreen":
				return "Place Order"
			case "AllPurchaseOrderScreen":
				return "All Orders"
			case "BillAndLedgerScreen":
				return "Bills"
			case "CustomerBills":
				return "Bills"
			case "ShowPaymentBillLedger":
				return "Ledger"
			case "LeadStackScreen":
				return "Welcome"
			case "HowItWork":
				return "How It Works"
			case "CustomerRequestInfo":
				return "Submit Request"
			case "AdditionalCustomerRequestInfo":
				return "Submit Additional Info"
			case "Lead Chat Screen":
				return "Chat With Supplier"
			case "ResetPhoneNumberOtpView":
				return "Reset Phone OTP"
			case "CustomerJarEntry":
				return "Product Entry"
			case "WelcomeGoPaaniScreen":
				return "Welcome"
			case "OtpVerifyView" :
				return "Verify OTP"
			case "LeadChatScreen":
				return "Home"
			case "ShowAllBusiness" :
				return "Home"
			case "AuthLoadingScreen" :
				return "Loading"
			default :
				return defaultTitle
		}
	}
	else return defaultTitle
}

export const handleNavigation = async (route,navigation) => {
	const userTokenData = await AsyncStorage.getItem('loginCustomerData');
	const JsonUserTokenData = JSON.parse(userTokenData)
	switch(route){
			case 'Home' :
				navigateToCustomerScreen('Home',{loginCustomerData:JsonUserTokenData},navigation)
				break;

			case 'CustomerStackScreen' :
				navigateToCustomerScreen('Home',{loginCustomerData:JsonUserTokenData},navigation)
				break;

			case 'CustomerScreen' :
				navigateToCustomerScreen('Home',{loginCustomerData:JsonUserTokenData},navigation)
				break;

			case 'ChooseLanguage' :
				navigateToCustomerScreen('ChooseLanguage',{loginCustomerData:JsonUserTokenData},navigation)
				break;

			case 'SwitchAccount' :
				navigateToCustomerScreen('SwitchAccount',{loginCustomerData:JsonUserTokenData,showMenuicon:true},navigation)
				break;

			case 'JarDeliveryFrequency' :
				navigateToCustomerScreen('JarDeliveryFrequency',{loginCustomerData:JsonUserTokenData},navigation)
				break;

			case 'CustomerProfile' :
				navigateToCustomerScreen('CustomerProfile',{loginCustomerData:JsonUserTokenData},navigation)
				break;

			case 'ChatConversation' :
				navigateToCustomerScreen('ChatConversation',{loginCustomerData:JsonUserTokenData},navigation)
				break;

			case 'PlacePurchaseOrder' :
				navigation.navigate('CustomerStackScreen',{
					screen:'PlaceOrderScreen',
					params:{loginCustomerData:JsonUserTokenData}
				})
				break;

			case 'AllPurchaseOrderScreen' :
				navigation.navigate('CustomerStackScreen',{
					screen:'AllPurchaseOrderScreen',
					params:{loginCustomerData:JsonUserTokenData}
				})
				break;

			case 'CustomerBills' :
				navigateToBillsLedgerScreen('CustomerBills',{
													loginCustomerData:JsonUserTokenData,
													customer_business_id:JsonUserTokenData.customer_business_id,
													business_id:JsonUserTokenData.business_id,
													showHeader:false},navigation)
				break;

			case 'ShowPaymentBillLedger' :
				navigateToBillsLedgerScreen('ShowPaymentBillLedger',{
													loginCustomerData:JsonUserTokenData,
													customer_business_id:JsonUserTokenData.customer_business_id,
													business_id:JsonUserTokenData.business_id,
													showHeader:false},navigation)
				break;

			case 'CustomerJarEntry' :
				let urlParams = {request_for:'jar_customer_detail', new_generic_version_request:true}
				const responseData = await client.fetchCustomerDetail(JsonUserTokenData.business_id, JsonUserTokenData.customer_business_id, urlParams)
				navigation.navigate('CustomerStackScreen',{
					screen:'CustomerJarEntry',
					params:{
						loginCustomerData:JsonUserTokenData,
						deliveredJarTypelist:responseData.list_of_delivery_jar_type
					}
				})
				break;

			default :
				route!=='' && alert("ROUTE_NOT_FOUND")
				window.history.replaceState(null,null,'/')
		}
}