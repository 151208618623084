import {View,Text,TouchableHighlight,StyleSheet,Dimensions} from 'react-native'
import PropTypes from 'prop-types'
import { Feather } from '@expo/vector-icons'; 

const phoneWidth = Dimensions.get('window').width

export default function ListItem(props){
	const {containerStyle, onPress, leftAvatar, leftAvatarContainerStyle, centerContainerStyle, centerElement,
				titleContainerStyle, titleElement, rightContainerStyle, rightElement, subtitleElement, rightTitleElement, disabled, checkmark, checkmarkElement, disabledStyle } = props
	return (
		<TouchableHighlight disabled={disabled} style={[styles.containerStyle,containerStyle,disabled ? disabledStyle : {}]} underlayColor={'#D1D1D1'} onPress={onPress}>
			<View style={styles.innerContainer}>
				
				{ //Left Avatar Container
					leftAvatar
						?
						<View style={[styles.leftAvatarContainerStyle,leftAvatarContainerStyle]}>
							{leftAvatar}
						</View>
						: null
				}

				{ //Title and Subtitle Container
					titleElement || subtitleElement
					?
					<View style={[styles.titleContainerStyle,titleContainerStyle]}>
						{ titleElement ? titleElement : null }
						{ subtitleElement ? subtitleElement : null }
					</View>
					: null
				}

				{ //Center Element
					centerElement
					?
					<View style={[styles.centerContainerStyle,centerContainerStyle]}>
						{ centerElement }
					</View>
					:null
				}

				
				<View style={[styles.rightContainerStyle,rightContainerStyle]}>
					{ rightTitleElement ? rightTitleElement : null }
					{ checkmark && checkmarkElement ? checkmarkElement : null }
					{ rightElement ? rightElement : null }
				</View>

			</View>
		</TouchableHighlight>
	)
}

const styles = StyleSheet.create({
	innerContainer : {
		flexDirection : 'row',
		width : '100%',
		minHeight : 50,
		alignItems : 'center'
	},
	containerStyle : {
		width : '100%',
		padding : 10,
	},
	leftAvatarContainerStyle : {
		flex : 0.5,
		alignItems : 'center',
		justifyContent : 'center'
	},
	titleContainerStyle : {
		flex : 2.75,
		alignItems : 'flex-start',
		justifyContent : 'center',
		paddingLeft : 8
	},
	rightContainerStyle : {
		flex:0.75,
		alignItems : 'flex-end',
		justifyContent : 'center',
		marginLeft : 'auto'
	},
	centerContainerStyle : {
		flex : 0.5,
		alignItems : 'center',
		justifyContent : 'center'
	},
})

ListItem.defaultProps = {
	containerStyle : {},
	leftAvatarContainerStyle : {},
	titleContainerStyle : {},
	rightContainerStyle : {},
	centerContainerStyle : {},
	onPress : () => {}, //To Avoid Error like onPress is not a function
	disabled : false,
	checkmark : false,
	checkmarkElement : <Feather name="check" size={24} color="#38C6F4" />,
	disabledStyle : {}
}

ListItem.propTypes = {
	containerStyle : PropTypes.object,
	leftAvatarContainerStyle : PropTypes.object,
	titleContainerStyle : PropTypes.object,
	titleStyle : PropTypes.object,
	subtitleStyle : PropTypes.object,
	onPress : PropTypes.func,
	rightContainerStyle : PropTypes.object,
	centerContainerStyle : PropTypes.object,
	secondTitle : PropTypes.string,
	secondTitleStyle : PropTypes.object,
	disabled : PropTypes.bool,
	checkmark : PropTypes.bool,
	checkmarkElement : PropTypes.element,
	disabledStyle : PropTypes.object
}