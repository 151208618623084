import Toast from 'react-native-root-toast';
import {constantValues} from './helpers';
import { Platform } from 'react-native'

export const showCrossPlatformToast = (message, showForDuration = constantValues.crossPlatformToastDuration, styles = constantValues.crossPlatformToastStyle, crossPlatformToastSomeValue = constantValues.crossPlatformToastSomeValue) => {
	/*
		this function is used to show the toast message in android and ios platform both
		showForDuration--> will be time in micro second
	*/

	if(Platform.OS==='web'){
		alert(message)
	}

	else{
		let toast = Toast.show(message, {
			duration: showForDuration,
			position: crossPlatformToastSomeValue.position,
			shadow: crossPlatformToastSomeValue.shadow,
			animation: crossPlatformToastSomeValue.animation,
			hideOnPress: crossPlatformToastSomeValue.hideOnPress,
			delay: crossPlatformToastSomeValue.delay,
			opacity:crossPlatformToastSomeValue.opacity,
			backgroundColor:styles.backgroundColor,
			textColor:styles.textColor,
		});		
	}
};