import React, { Component } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Image, Alert} from 'react-native';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { Ionicons, FontAwesome, FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import i18n from 'i18n-js';
import translationValues from './components/translation';
import { LinearGradient } from 'expo-linear-gradient';
import { Header } from 'react-native-elements';
import getEnvVars from './environment';
const { apiUrl,businessApiUrl } = getEnvVars();
import logout from './components/logout';
import AsyncStorage from '@react-native-async-storage/async-storage'
/*
	usedFor:- this component is used to show top tap bar on business expense section
*/
export default class BillAndLedgerBarComponent extends Component {    
	constructor(props){
		super(props);
		this.willFocusSubscription ='';
	}
	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
		});
	}
	componentWillUnmount() {
		this.willFocusSubscription();
	}
	navigationToScreen = async () => {
		const loginCustomerData = await AsyncStorage.getItem('loginCustomerData');
		let loginCustomerDataJson = JSON.parse(loginCustomerData);
		this.props.navigation.replace('CustomerStackScreen',{
			screen:'CustomerScreen',
			params:{
				screen: 'Home', 
				params:{loginCustomerData:loginCustomerDataJson},
			}
		});
	}
	render() {
		const {state, descriptors, navigation } = this.props;
		const activeTabIndex = state.index;
		return (
			<View style={{backgroundColor:'#ffffff'}}>
				<Header
					centerComponent={<Image resizeMode='cover' style={{height: 45,width: 45,resizeMode:'contain'}}  source={require('./assets/images/small_logo.png')}/>}
					statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
					centerContainerStyle={{justifyContent:'center'}}
					leftContainerStyle={{justifyContent:'center'}}
					leftComponent={<TouchableOpacity
						style={styles.headerLeftComponentStyle}
						activeOpacity={0.5}
						onPress={()=>this.navigationToScreen()}>
						<FontAwesome name='chevron-left' color='#38c6f4' size={24} />
						<Text style={styles.headerTitleTextStyle}>{i18n.t('home')}</Text>
						</TouchableOpacity>
					}
					containerStyle={{borderBottomWidth:null,backgroundColor:null,alignItems:'center',elevation:5}}
					ViewComponent={LinearGradient}
					linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
				/>
				<View style={styles.tabContainer}>
					{state.routes.map((route, index) => {
						const isRouteActive = index === activeTabIndex;
						const tintColor = '#175491';
						return (
							<TouchableOpacity
								style={isRouteActive ?styles.activeTabStyle :styles.inactiveTabStyle}
								key={index}
								onPress={() => navigation.navigate(route.name,{
									showHeader:false
								})}>
								<Image source={route.name === 'CustomerBills' ?require('./assets/images/billing_icon.png') :require('./assets/images/ledger-tab-bar.png')} 
									style={route.name === 'CustomerBills' ?styles.billImageIconStyle :styles.ImageIconStyle}  />
								<Text style={styles.tabBarTextStyle}>
									{route.name === 'CustomerBills' ?i18n.t('bill') :i18n.t('ledger')}
								</Text>
							</TouchableOpacity>
						);
					})}
				</View>
			</View>
		)
	}
}
const styles = StyleSheet.create({
	headerStyle:{
		marginLeft:10,
		marginTop:-20, 
		flexDirection:'row'
	},
	headerCenter:{
		width:31.05,
		height:35.85,
		resizeMode:'contain',
		marginTop:-25
	},
	tabContainer: {
		width: wp('100%'),
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		height: 70,
		elevation:2,
		borderWidth:1,
		borderColor:'transparent'
	},
	headerContainerStyle:{
		height: 55,
		borderBottomWidth:null,
		backgroundColor:null,
		elevation:5
	},
	activeTabStyle:{
		width:wp('50%'),
		height:70,
		elevation:2,
		borderWidth:1,
		borderColor:'transparent',
		alignItems:'center',
		justifyContent:'center',
		borderBottomColor: '#175491',
		borderBottomWidth: 3,
		flexDirection:'row',
		zIndex: 1
	},
	inactiveTabStyle:{
		width:wp('50%'),
		height:70,
		alignItems:'center',
		justifyContent:'center',
		flexDirection:'row'
	},
	headerIconStyle:{
		height: hp('12%'),
		width: wp('12%'),
		resizeMode:'contain',
		marginTop:-25
	},
	headerLeftComponentStyle:{
		marginTop:10,
		flexDirection:'row',
		alignItems:'center'
	},
	headerTitleTextStyle:{
		color: '#fff',
		fontSize:16,
		fontWeight:'bold',
		marginLeft:5
	},
	ImageIconStyle:{
		width:40,
		height:40,
		resizeMode:'contain'
	},
	billImageIconStyle:{
		tintColor:'#38c6f4',
		width:35,
		height:35,
		resizeMode:'contain'
	},
	tabBarTextStyle:{
		fontSize: 16,
		textAlign:'center',
		color:'#175491',
		fontWeight: "bold",
		marginLeft:10
	},
});