const translationValues = {
	en:{
		customer:'Customer',
		payment:'Payment',
		customer_bills:'Customer Bills',
		billing_period:'Billing Period',
		billing_date:'Billing Date',
		total_cool_jar:'Total Chilled jar',
		total_pet_jar:'Total Capsule Jar',
		balance_jar:'Balance Jar',
		cool_jar_pricing:'Per Chilled Jar Price',
		pet_jar_pricing:'Per Capsule Jar Price',
		month_amount:'Month Amount',
		last_outstanding_amount:'Last Outstanding Amount',
		credit_amount:'Credit Amount',
		creditText:'Credit',
		at_starting_amount:'Payment Due before GoRecordz',
		total_amount:'Total Amount',
		not_available:'Not Available',
		ok:'OK',
		customer_info:'Customer Info',
		January:'January',
		February:'February',
		March:'March',
		April:'April',
		May:'May',
		June:'June',
		July:'July',
		August:'August',
		September:'September',
		October:'October',
		November:'November',
		December:'December',
		success_message:'Thanks for updating details',
		edit_bill:'Edit Bill',
		change_only_once:'You can only enter discount or extra charges once',
		reason_discount:'Enter reason for discount',
		discount_value:'Enter Discount Amount',
		extra_charges:'Enter Extra charges',
		reason_extra_charges:'Enter reason for extra charges',
		cancel:'Cancel',
		paid_payment:'Payments',
		extra_cool_jar:'Chilled Jar',
		extra_pet_jar:'Capsule Jar',
		fixed_amount:'Fixed Amount',
		total_extra_jar_amount:'Total Jar Amount',
		a4_size:'A4 Size',
		a5_size:'A5 Size',
		roll_size:'Roll Size',
		size_option_message:'Select Bill Size to generate Bill',
		a4_size:'A4 Size',
		a5_size:'A5 Size',
		roll_size:'Roll Size',
		size_option_message:'Select Bill Size to generate Bill',
		fixed_chilled_jar:'Fixed Chilled Jar',
		fixed_capsule_jar:'Fixed Capsule Jar',
		total_delivered_chilled_jar:'Total Delivered Chilled Jar',
		total_delivered_capsule_jar:'Total Delivered Capsule Jar',
		choose_language_to_generate_bill:'Choose Language To Generate Bill',
		back:'Customer Info',
		cancelOption:'Cancel',
		share:'Share',
		download:'Download',
		print:'Print',
		select_option:'Select Option',
		jarEntry: 'Jar Entry' ,
		gopaani:'GoPaani',
		cancel:'NO',
		logout:'Logout',
		logout_message:'Are you sure you want to logout?',
		chilled_jar:'Chilled Jar',
		capsule_jar:'Capsule Jar',
		balance_chilled_jar:'Balance Chilled Jar',
		balance_capsule_jar:'Balance Capsule Jar',
		no_data:'No Jar Entry found for this month',
		date:'Date',
		in:'In',
		out:'Out',
		copy_to_clipboard:'Copy to Clipboard',
		copy:'Copy',
		have_an_existing_water_supplier:'Have an existing supplier?',
		message_content:'I am using GoPaani to make sure I can see daily jar entry and bills in the app - please use GoPaani to run your business,for more information call  - 98269-66287 90094-67264',
		notify_message:'Thanks! We will let you know when we launch customer app upgrade',
		send_them_a_message:'Send them a message to create account on GoPaani',
		verify_text:'Verify Your Mobile Number',
		sub_heading:'We sent you a four digit OTP to your registered mobile number. Enter the OTP below to confirm your mobile number.',
		correct_otp_number:'OTP is incorrect or has been expired',
		verify_title:'Verify',
		again_sent_otp_message:'Your OTP has been sent again to your mobile number. Please enter the OTP below',
		try_again_later_message:'Please, try again later',
		resend_otp:'Resend OTP',
		time_remaining:'Time Remaining Till requesting OTP again',
		otp:'OTP',
		permission_to_perform:'You do not have permission to perform this action.',
		customer_login_heading:'Enter your phone number',
		registered_mobile_number:'Enter Mobile Number',
		incorrect_phone_number:'Phone number is incorrect',
		submit:'Submit',
		messages: 'Messages',
		home: 'Home',
		choose_language: 'Choose Language',
		language_changed_succesfully: 'Language Changed Succesfully',
		download_bills:'Download Bills',
		jar_enteries:'Jar Enteries',
		write_a_message:'Write a message',
		switch_account:'Switch Account',
		yes:'YES',
		ledger:'Ledger',
		productEntry:'Product Entry',
		bill:'Bills',
		price:'Price',
		total:'Total',
		monthlyBillingMessage:'Monthly Billing for Products will be\navailable soon!',
		paymentBillLedger:'Payment and Bill Ledger',
		totalBillAmount:'Total Bill Amount',
		productLedgerStartBalance:'Starting Balance',
		perticulars:'Particulars',
		billAmount:'Bill Amount',
		noProductDeliveredMessage:'No Product Delivered for this month',
		showing_extra_charges_value:'Extra Charge',
		showing_discount_value:'Discount',
		no_past_payment:"No Past Payment details",
		jarAmount:'Jar Amount',
		welcome:'Welcome',
		welcomeContent:'Pure drinking water is the most essential thing in life and GoPaani brings it for you',
		next:'Next',
		howItWork:'How It Works',
		submitDetails:'Submit your details',
		step2Content:'You get contacted by no more than three water suppliers',
		step3content:'Send confirmation to your selected service provider to start',
		enterName:'Name *',
		enterLocation:'Location *',
		getCurrentLocation:'Get Current Location',
		enterPinCode:'Pincode *',
		getJarDelivered:'Get Jar Delivered',
		shop:'Shop',
		office:'Office',
		residence:'Residence',
		event:'Event',
		numberOfJar:'Number of Jar',
		selectTime:'Select TIme',
		selectDateTime:'Select Date and Time',
		enterComment:'Enter comment',
		selectLocationType:'Select Location Type',
		whatSupplierHeading:'Suppliers for you - ',
		notserveAreaMessage:'Looks like we do not serve the location that you just entered. We are expanding and will reach out to you when we are available in your area.',
		sendRequestToWaterSupplierMessage:'We have sent your request to suppliers, awaiting their reply',
		inviteWaterSupllierMessage:'Invite Suppliers you know to GoPaani',
		requestMore:'Request more',
		approveButton:'Approve',
		inviteSupllierHeading:'Do Something Good Today\nShare GoPaani',
		inviteContent:'I am using GoPaani to make sure I get pure water and I can get jar entry and get bills in the app - please get verified by GoPaani - 9009467264',
		sendRequestMoreSuccessMessage:'Thanks! We will soon add more suppliers, you will receive a message when we do so',
		becomeBusinessCustomerSuccessMessage:'Thanks! You are now become the customer on Gopaani of a business.',
		approveBusinessMessage:'%{businessName} wants to add you as a customer, approve?',
		mon:'Monday',
		tue:'Tuesday',
		wed:'Wednesday',
		thu:'Thursday',
		fri:'Friday',
		sat:'Saturday',
		sun:'Sunday',
		todayDelivery:"Today's Delivery",
		pastJarEntries:'Past Enteries',
		jarsYouHave:'Jars You Have',
		rateTodayDelivery:'Rate Today’s Delivery',
		notDeliveryDay:'Not a delivery day',
		lastMonthDueAmount:'Last Month Due Amount',
		todayDueAmount:"Today's Due Amount",
		paymentDetails:'Payment Details',
		payNow:'Pay Now',
		headingPaymentDetailsScreen:'Use the Following Payment Information Provided by Your Water Supplier to make the payment',
		bankAccount:'Bank Account',
		accountName:'Account Name',
		accountNo:'Account No',
		bankName:'Bank Name',
		branch:'Branch',
		ifscCode:'IFSC Code',
		changesInDelivery:'Changes in Delivery?',
		setOffDay:'Set Off day',
		billsandLedger:'Bills & Ledger',
		WaterSupplierHeading:'Your Supplier',
		jarDeliveryFrequency:'Delivery Frequency',
		jarDeliveryFrequencyHeading:'Your Product Delivery Frequency',
		daily:"Daily",
		alternate:"Alternate Day",
		selectWeekDays:'Select\nWeekDays',
		selectedDays:'Selected Days',
		alternateDayHeading:'Started Date of Alternate Delivery',
		alternateDayIncludeWeekend:'Weekend Included in the alternate delivery',
		profile:'Profile',
		mobileNumber:'Mobile Number',
		change:'CHANGE',
		changedMobileNumberSccessMessage:'Mobile Number Changed Successfully!',
		updateMobileNumber:'Update Your Mobile Number',
		waterSupplierWillBeNotifiedAbouttheChange:'Product Supplier Will be Notified About the Change',
		continue:'Continue',
		fullName:'Full Name',
		businessName:'Business Name',
		address:'Address',
		changeAddress:'CHANGE ADDRESS',
		alternateMobileNumber:'Alternate Mobile Number',
		email:'Email',
		dateOfBirth:'Date of Birth',
		maritalStatus:'Marital Status',
		dateOfMarriageAnniversary:'Date of Marriage Anniversary',
		setOffDays:'Set Off days',
		selectDateOnWhichYouwillNotBeNeedingjars:'Select Date on which you will not be needing products',
		leaveComment:'Leave comment ',
		fromToday:'From\nToday',
		fromTomorrow:'From\nTomorrow',
		fromDayAfterTomorrow:'From Day\nafter Tomorrow',
		itemPerDelivery:'%{jarType} Per Delivery',
		youCanleaveCommentForTodayDelivery:'You can leave comment for today’s delivery',
		thanksWeSentyourRequestToWaterSupplier:'Thanks, we sent your request to supplier',
		thanksForRating:'Thanks for rating...!!',
		doYouWantToMakeAnyChangeInTheJarsYouGetDelivered:'Do you want to make any change in the items you get delivered?',
		waterSupplierWillBeNotifiedOfYourRequestWillCallOrMessageYouToConfirm:'Supplier will be notified of your request they will call or message you to confirm',
		YourRequestSubmittedPleaseWaitWaterSupplierEeviewIt:'Your request to set off day from %{fromDatetime} - %{toDatetime} has been submitted, please wait for Water Supplier to review it.',
		selectDate:'select date',
		thanksForupdatingDetails:'Thanks for updating details',
		pleaseEnterValidMobileNumbers:'Please enter valid mobile number',
		setChangeJarDeliveryMessage:'Your request has been sent to supplier. You have made a request for %{requestedItemString} to be delivered from %{fromeRquestedDatetime}',
		youCanPaViaPaymentModeOurPhoneNumberMobile:'You can pay via %{paymentMode}\nOur Phone Number %{paymentMethodNumber}',
		noPaymentInfoFound:'No Payment Info Provided, Please Contact Your Supplier',
		help:'Help',
		watchVideo:'Watch Video',
		headingWaterDeliveryCustomers:'This is the phone number that your product supplier has',
		buttonHeadingWatchVideo:'To Learn About Customer App',
		transactionSuccessMessage:'Payment SuccessFul!',
		transactionInfoText:'Amount transfer to %{businessName} was SuccessFul.',
		transactionDetails:'Transaction Number -',
		fixedJarText:'Fixed %{jarType}',
		jarPerDelivery:'%{jarType} Per Delivery',
		deliveredJar:'Delivered %{jarType}',
		totalJarLoaded:'Total Loaded %{jarType}',
		totalReturnFilledJar:'Return Filled %{jarType}',
		totalCollectedJar:'Collected %{jarType}',
		balanceJarText:'Balance %{jarType}',
		totalJarInventory:'Total %{jarType} Inventory',
		defaultJarPrice:'Default %{jarType} Price',
		jarEntryText:'%{jarType} Entry',
		totalJarText:'Total %{jarType}',
		jarPricingText:"Per %{jarType} Price",
		extraJarText:'Extra %{jarType}',
		totalDeliveredJarText:'Total Delivered %{jarType}',
		customAmountText:'Custom Amount',
		advanceAmountText:'Enter Amount to Pay',
		advanceAmountSubheadingText:'This will be considered  advance payment',
		enterAmountText:'Enter Amount',
		billAmount:'Bill Amount',
		convenienceFee:'Convenience fee (%{chargesInPercentage} %)',
		refundableText:'(Refundable for UPI payments)',
		totalPayabeText:'Total Payable',
		noteText:'Note',
		detailNoteContentHeading1:'Free - ',
		detailNoteContentHeading2:'Convenience Fee (%{chargesInPercentage} %) - ',
		detailNoteContent1:'UPI - You will refunded  %{refundAmount} Rupees after Payment',
		detailNoteContent2:'Net Banking, Paytm Wallet, Credit & Debit Card',
		reviewPaymentText:'Review Payment',
		updateRequiredHeading:'Update Required',
		updateRequiredContent:'Please update to continue using GoPaani Customer App',
		update:'Update',
		advanceText:'Advance',
		maximumAlterNativePhoneNumber:'you have reached maximum %{maximumAlterNativePhoneNumber} alternative phone number',
		invalid_number:'Invalid Phone Number',
		invalidEmailError:'Enter a valid email address.',
		alreadyExistEmailError:'Email address is already exists',
		discount:'Discount',
		if_discount:'Have discount code? Enter below',
		enter_discount_code:'Enter Discount Code',
		apply:'Apply',
		errorDiscountAlreadyAppliedMessage:'Sorry, coupon is already used',
		errorDiscountCoupanInvalid:'Sorry, coupon is invalid',
		couponAppliedText:'Coupon Applied',
		gopaaniSpecialDiscountMessage:'Thanks! You will get up to %{discountValue}%* discount in your next bill',
		couponAppliedMessage:'Discount code has already been added',
		invoice:'Invoice',
		amount: 'Amount (Rs)',
		resend_otp_via_call:'Resend OTP via Call',
		again_sent_otp_message_via_call:'You will soon receive a call with OTP',
		sub_heading_verify:'We sent you a four digit OTP to your registered mobile number. Enter the OTP below to confirm your mobile number - ',
		paymentNotAllowedAlert:'Please directly pay product supplier, we do not support high amount payments yet',
		delivery_bill:'Delivery Bill',
		onlinePayment:'Online Payment',
		balanceYouHave:'Balance you have',
		noBalanceItem:'No balance product you have',
		addSupportContactText:'Please press “Next” to add GoPaani support phone numbers to your contact book.',
		badRequest:'Something went wrong please try again later',
		extra:'Extra',
		balanceAmount:'Balance Amount',
		depositAmount:'Deposit Amount',
		orderProducts:'Order Products',
		yourOrders:'Your Orders',
		addPlaceOrderProducts:'Add products to place the order',
		totalProduct:'Total Products',
		orderSummery:'Order Summary',
		messagesOptional:'Message (Optional)',
		submitOrder:'Submit Order',
		selectedDeliveryDateAndTime:'Select Delivery Date & Time',
		deliveryDate:'Delivery Date',
		deliveryTime:'Delivery Time',
		allOrder:'All Orders',
		showMore:'Show More',
		productName:'Product Name',
		quantity:'Quantity',
		defaultTechnicalError:'Sorry, something went wrong, please try again or contact us if issue persists',
		invalid_token_message:'Please login again',
		createdOrderSuccess:'Order has been created successfully',
		addAtleastQuantity:'Please add atleast 1 product quantity',
		noOrderFound:'No Order Found',
		noMtchingProductFound:'No matching products found',
		commentText:'Comment',
		advancePayment:'Advance Payment',
		invoiceAmount:'Invoice Amount',
		paymentGivenHeading:'Payment Given',
		runningBalance:'Running Balance',
		runningBalanceDate:'From %{date}',
		appliedOn:'Applied On %{date}',
		deliveryInvoiceText:'Delivery Invoice',
		totalInvoiceAmount:'Total Invoice Amount',
		debit:'Debit',
		includesDiscountExtraText:'Includes Discount & Extra Charges',
		totalGivenAmount:'Total Given Amount',
	},	
	hi:{
		customer:'ग्राहक',
		payment:'पेमेंट ',
		customer_bills:'ग्राहक बिल',
		billing_period:'बिलिंग अवधि',
		billing_date:'बिलिंग तारीख',
		total_cool_jar:'टोटल  चिल्ड जार',
		total_pet_jar:'टोटल कैप्सूल जार',
		balance_jar:'बाकी जार',
		cool_jar_pricing:'प्रति चिल्ड जार राशि',
		pet_jar_pricing:'प्रति कैप्सूल जार राशि',
		month_amount:'मासिक अमाउंट',
		last_outstanding_amount:'पिछले महीने का बकाया',
		credit_amount:'जमा राशि',
		creditText:'जमा',
		at_starting_amount:'GoPaani के पहले का बकाया',
		total_amount:'टोटल अमाउंट',
		not_available:'उपलब्ध नहीं है',
		ok:'ठीक है',
		customer_info:'ग्राहक सूची',
		January:'जनवरी',
		February:'फ़रवरी',
		March:'मार्च',
		April:'अप्रैल',
		May:'मई',
		June:'जून',
		July:'जुलाई',
		August:'अगस्त',
		September:'सितंबर',
		October:'अक्टूबर',
		November:'नवंबर',
		December:'दिसंबर',
		success_message:'जानकार्री अपडेट करने के लिए धन्यवाद',
		edit_bill:'बिल में बदलाव करें',
		change_only_once:'डिस्काउंट अमाउंट और अतिरिक्त शुल्क एक बार डालने के बाद बदला नहीं जा सकता हैं',
		reason_discount:'डिस्काउंट का कारण',
		discount_value:'डिस्काउंट अमाउंट',
		extra_charges:'अतिरिक्त शुल्क',
		reason_extra_charges:'अतिरिक्त शुल्क का कारण',
		showing_discount_value:'डिस्काउंट',
		showing_extra_charges_value:'अतिरिक्त शुल्क',
		cancel:'नही',
		paid_payment:' में भुगतान हुए',
		extra_cool_jar:'चिल्ड जार',
		extra_pet_jar:'कैप्सूल जार',
		fixed_amount:'निश्चित अमाउंट',
		total_extra_jar_amount:'टोटल जार अमाउंट',
		a4_size:'A4 साइज',
		a5_size:'A5 साइज',
		roll_size:'रोल साइज',
		size_option_message:'बिल जनरेट करने के लिए बिल साइज का चयन करें',
		fixed_chilled_jar:'निश्चित चिल्ड जार',
		fixed_capsule_jar:'निश्चित कैप्सूल जार',
		fixed_capsule_jar:'निश्चित कैप्सूल जार',
		total_delivered_chilled_jar:'टोटल चिल्ड जार दिए',
		total_delivered_capsule_jar:'टोटल कैप्सूल जार दिए',
		choose_language_to_generate_bill:'बिल जनरेट करने के लिए भाषा का चयन करे',
		back:'ग्राहक सूची',
		cancelOption:'हटाए',
		share:'शेयर करें',
		download:'डाउनलोड करें',
		print:'प्रिन्ट करें',
		select_option:'विकल्प चुनें',
		jarEntry: 'जार एंट्री',
		gopaani:'GoPaani',
		cancel:'नहीं',
		logout:'बाहर जाये',
		logout_message:'क्या आप इस अकाउन्ट से बाहर निकलना चहाते हे',
		capsule_jar:'कैप्सूल जार',
		chilled_jar:'चिल्ड जार',
		balance_chilled_jar:'बैलेंस चिल्ड जार',
		balance_capsule_jar:'बैलेंस कैप्सूल जार',
		no_data:'इस महीने के लिए कोई जार एंट्री नहीं हैं |',
		date:'दिनांक',
		in:'लिए',
		out:'दिए',
		copy_to_clipboard:'कॉपी करे क्लिपबोर्ड पर ',
		copy:'कॉपी',
		have_an_existing_water_supplier:'आप पहले से ही किसी सप्लायर से प्रोडक्ट मंगा रहे हैं ?',
		message_content:'मैं GoPaani का इस्तेमाल कर रहा हु ताकि मुझे रोज की जार एंट्री और हर महीने का बिल ऐप मैं ही दिख जाये,विनती है की आप अपना व्यवसाय GoPaani से चलाये - 98269-66287 90094-67264',
		notify_message:'धन्यवाद! हम जल्द ही अपडेट लॉन्च करने वाले है जिससे की आप पानी वितरण व्यवसायों से सीधे संपर्क मैं आ सकते हैं',
		send_them_a_message:'उन्हें मैसेज भेजे जिससे की आप GoPaani की सेवाओं का लाभ उठा उठा सके',
		verify_text:'अपना मोबाइल नंबर वेरीफाई करें',
		sub_heading:'हमने आपको आपके मोबाइल नंबर पर चार अंकों का OTP भेजा है। अपने मोबाइल नंबर की पुष्टि करने के लिए नीचे OTP दर्ज करें',
		correct_otp_number:'OTP गलत है या समाप्त हो चुका है',
		verify_title:'वेरीफाई करें',
		again_sent_otp_message:'आपका OTP फिर से आपके मोबाइल नंबर पर भेज दिया गया है। कृपया नीचे OTP दर्ज करें',
		try_again_later_message:'कृपया, कुछ देर बाद फिर से कोशिश करें',
		resend_otp:'OTP फिर से भेजे',
		time_remaining:'नया OTP प्राप्त करने के लिए रुके',
		otp:'OTP',
		permission_to_perform:'आपको यह क्रिया करने की अनुमति नहीं है।',
		customer_login_heading:'आपका मोबाइल नंबर दर्ज़ करें',
		registered_mobile_number:'आपका मोबाइल नंबर',
		incorrect_phone_number:'फ़ोन नंबर गलत है',
		submit:'जानकारी भेजें',
		messages: 'संदेश',
		home: 'मुख पृष्ठ',
		choose_language: 'भाषा चुनें',
		language_changed_succesfully: 'भाषा सफलतापूर्वक बदल दी गयी',
		download_bills:'बिल डाउनलोड करें',
		jar_enteries:'जार एंट्रीज',
		write_a_message:'मैसेज लिखिए',
		switch_account:'खाता बदलिये',
		yes:'हाँ',
		ledger:'खाता',
		productEntry:'प्रोडक्ट एंट्री',
		bill:'बिल',
		price:'मूल्य',
		total:'टोटल',
		monthlyBillingMessage:'प्रोडक्ट्स के लिए मासिक बिलिंग जल्द ही\nउपलब्ध होगी',
		paymentBillLedger:'भुगतान और बिल खाता',
		totalBillAmount:'कुल बिल अमाउंट',
		productLedgerStartBalance:'शुरुआत में बैलेंस',
		perticulars:'ब्यौरा',
		billAmount:'बिल अमाउंट',
		noProductDeliveredMessage:'इस महीने के लिए कोई प्रोडक्ट्स नहीं दिए गए',
		no_past_payment:"कोई पिछला पेमेंट जानकारी नहीं हे",
		jarAmount:'जार अमाउंट',
		welcome:'नमस्कार',
		welcomeContent:'शुद्ध पेयजल जीवन की सबसे आवश्यक चीज है और GoPaani आपके लिए इसे लाती है',
		next:'आगे',
		howItWork:'यह कैसे काम करता है?',
		submitDetails:'अपनी जानकारी जोड़े',
		step2Content:'अधिक से अधिक तीन व्यापारी आपसे संपर्क करेंगे',
		step3content:'चुने हुए पानी व्यापारी को पानी की डिलीवरी शुरू करने के लिए पुष्टि भेजे',
		enterName:'नाम दर्ज करें *',
		enterLocation:'पता दर्ज करें *',
		getCurrentLocation:'मौजूदा स्थान प्राप्त करें',
		enterPinCode:'पिन कोड दर्ज करें *',
		getJarDelivered:'जार डिलीवरी प्राप्त करें',
		shop:'दुकान',
		office:'दफ्तर',
		residence:'निवास',
		event:'इवेंट',
		numberOfJar:'जार की संख्या',
		selectTime:'समय का चयन करें',
		selectDateTime:'दिनांक और समय का चयन करें',
		enterComment:'टिप्पणी दर्ज करें',
		selectLocationType:'स्थान प्रकार का चयन करें',
		whatSupplierHeading:'व्यापारी जो आपके क्षेत्र मैं सप्लाई करते है - ',
		notserveAreaMessage:'अभी आपके क्षेत्र मैं यह सेवा उपलब्ध नहीं हैं, भविष्य मैं सेवा शुरू होने पर हम आपको सम्पर्क करेंगे',
		sendRequestToWaterSupplierMessage:'हमने आपकी जानकारी सप्लायर्स को भेज दी हैं, उनके जवाब के लिए प्रतीक्षा करे',
		inviteWaterSupllierMessage:'आपकी पहचान के व्यापारियों को GoPaani पर आमंत्रित करे',
		requestMore:'और पानी व्यवसाय को जानकारी भेजे',
		approveButton:'सहमति दे',
		inviteSupllierHeading:'आज कुछ अच्छा कार्य करे\nGoPaani को शेयर करे',
		inviteContent:'मैं GoPaani का इस्तेमाल कर रहा हूँ, जिससे की मुझे मोबाइल ऐप के माध्यम से जार की संख्या एवं बिल दिख सके, आप भी GoPaani से अपना पानी व्यापार जोड़े - 9009467264',
		sendRequestMoreSuccessMessage:'धन्यवाद ! व्यापार जुड़ते ही आपको एक मैसेज प्राप्त होगा',
		becomeBusinessCustomerSuccessMessage:' धन्यवाद ! अब आप GoPaani पर आपके द्वारा चुने हुए व्यापार के ग्राहक बन चुके हैं',
		approveBusinessMessage:'%{businessName} आपको ग्राहक बनाना चाहते हैं, सहमति हैं ?',
		mon:'सोमवार',
		tue:'मंगलवार',
		wed:'बुधवार',
		thu:'गुरूवार',
		fri:'शुक्रवार',
		sat:'शनिवार',
		sun:'रविवार',
		todayDelivery:"आज की डिलीवरी",
		pastJarEntries:'पूरानी डिलीवरी',
		jarsYouHave:"आपके पास जार हैं",
		rateTodayDelivery:"आज सर्विस कैसी रही ?",
		notDeliveryDay:"डिलीवरी का दिन नहीं",
		lastMonthDueAmount:"पिछले महीने का बकाया अमाउंट",
		todayDueAmount:"आज का बकाया अमाउंट",
		paymentDetails:"पेमेंट जानकारी",
		payNow:'पेमेंट करें',
		headingPaymentDetailsScreen:"आप निचे दी गयी जानकारी पर भुगतान कर सकते हैं",
		bankAccount:"बैंक अकाउंट",
		bankName:'बैंक नाम',
		accountName:'अकाउंट नाम',
		accountNo:'अकाउंट नंबर',
		branch:'ब्रांच',
		ifscCode:'IFSC कोड',
		changesInDelivery:"प्रोडक्ट संख्या में कोई बदलाव?",
		setOffDay:"छुट्टी का दिन सेट करें",
		billsandLedger:'बिल एवम खाता',
		WaterSupplierHeading:'व्यापार का नाम',
		jarDeliveryFrequency:'डिलीवरी के दिन',
		jarDeliveryFrequencyHeading:'आप कितने दिनों में प्रोडक्ट मंगाते हैं - रोज या एक दिन छोड़ के या हफ्ते के फिक्स दिन',
		daily:"रोज",
		alternate:"एक दिन छोढ़कर",
		selectWeekDays:'सप्ताह के दिन',
		selectedDays:'चुने हुए दिन',
		alternateDayHeading:'दिनांक से एक दिन छोड़ के डिलीवरी शुरू हुई',
		alternateDayIncludeWeekend:'शनिवार/रविवार में से किसी दिन प्रोडक्ट मंगाते हैं',
		profile:'प्रोफाइल',
		mobileNumber:'मोबाइल नंबर',
		change:'बदले',
		changedMobileNumberSccessMessage:'मोबाइल नंबर सफलतापूर्वक बदला गया.',
		updateMobileNumber:'मोबाइल नंबर बदले',
		waterSupplierWillBeNotifiedAbouttheChange:"सप्लायर को एक मैसेज भेजा जायेगा",
		continue:'आगे',
		fullName:"पूरा नाम",
		businessName:"व्यापार का नाम",
		address:'पता',
		alternateMobileNumber:"दूसरे मोबाइल नंबर",
		email:'मेल',
		dateOfBirth:'जन्मदिन',
		maritalStatus:'वैवाहित है ?',
		dateOfMarriageAnniversary:'सालगिरह दिनांक',
		saveDetails:'Save Details',
		setOffDays:'छुट्टी का दिन सेट करें',
		selectDateOnWhichYouwillNotBeNeedingjars:'जिन दिनों प्रोडक्ट की जरुरत नहीं हैं वह चिन्हित करें',
		leaveComment:'कमेंट करें',
		fromToday:"आज से",
		fromTomorrow:"कल से",
		fromDayAfterTomorrow:"परसो से",
		itemPerDelivery:"रोज %{jarType} आते हैं",
		youCanleaveCommentForTodayDelivery:'आज की डिलीवर के बारे मैं टिप्पड़ी करें',
		thanksWeSentyourRequestToWaterSupplier:"धन्यवाद! आपका निवेदन भेज दिया गया हैं",
		thanksForRating:'धन्यवाद !',
		doYouWantToMakeAnyChangeInTheJarsYouGetDelivered:'प्रोडक्ट संख्या मैं कोई बदलाव?',
		waterSupplierWillBeNotifiedOfYourRequestWillCallOrMessageYouToConfirm:'सप्लायर को एक मैसेज भेजा जायेगा',
		YourRequestSubmittedPleaseWaitWaterSupplierEeviewIt:'आपने %{fromDatetime} - %{toDatetime} तक जार डिलीवरी नहीं करने का निवेदन किया हैं, कृपया इसकी पुष्टि के लिए इंतज़ार करें |',
		selectDate:"दिनांक चुने",
		pleaseEnterValidMobileNumbers:'कृपया सही मोबाइल नंबर दर्ज करें',
		setChangeJarDeliveryMessage:'आपका अनुरोध आपके सप्लायर को भेज दिया गया है । आपने %{requestedItemString} के लिए %{fromeRquestedDatetime} को अनुरोध किया है',
		youCanPaViaPaymentModeOurPhoneNumberMobile:'%{paymentMode} के माध्यम से भुगतान कर सकते हैं\n हमारा नंबर हैं %{paymentMethodNumber}',
		noPaymentInfoFound:'कोई पेमेंट जानकारी नहीं मिल पायी हैं, पेमेंट कैसे करना हैं उसके लिए अपने सप्लायर से संपर्क करें',
		help:'हेल्प',
		watchVideo:'वीडियो देखे',
		headingWaterDeliveryCustomers:'सप्लायर के पास आपका जो फ़ोन नंबर हैं वह दर्ज करें',
		buttonHeadingWatchVideo:'देखे GoPaani कस्टमर ऐप का इस्तमाल कैसे करें',
		transactionSuccessMessage:'पेमेंट सफल हुआ !',
		transactionInfoText:'%{businessName} को आपके द्वारा किया पेमेंट प्राप्त हुआ',
		transactionDetails:'ट्रांजैक्शन नंबर -',
		fixedJarText:"निश्चित %{jarType}",
		jarPerDelivery:'हर डिलीवरी मैं %{jarType} कितने जाते है',
		deliveredJar:'%{jarType} दिए',
		totalJarLoaded:'टोटल %{jarType} लोड हुए',
		totalReturnFilledJar:'टोटल भरे %{jarType} वापिस आये',
		totalCollectedJar:'टोटल खाली %{jarType} ग्राहक ने दिए',
		balanceJarText:'बैलेंस %{jarType}',
		totalJarInventory:'टोटल %{jarType} का स्टॉक',
		defaultJarPrice:'%{jarType} किस मूल्य पर आमतौर पर देते हैं',
		jarEntryText:'%{jarType} एंट्री जोड़े',
		totalJarText:'टोटल %{jarType}',
		jarPricingText:"प्रति %{jarType} राशि",
		extraJarText:'अतिरिक्त %{jarType}',
		totalDeliveredJarText:'टोटल %{jarType} दिए',
		customAmountText:'कस्टम अमाउंट',
		advanceAmountText:'निचे अमाउंट दर्ज करे',
		advanceAmountSubheadingText:'इस अमाउंट को एडवांस माना जायेगा',
		enterAmountText:'राशि दर्ज करे',
		billAmount:'बिल अमाउंट',
		convenienceFee:'ऑनलाइन सुविधा शुल्क (%{chargesInPercentage} %)',
		refundableText:'रिफंड किया जाएगा UPI पेमेंट के लिए',
		totalPayabeText:'कुल देय',
		noteText:'ध्यान दें:',
		detailNoteContentHeading1:'नि:शुल्क - ',
		detailNoteContentHeading2:'ऑनलाइन सुविधा शुल्क (%{chargesInPercentage} %) - ',
		detailNoteContent1:'UPI - पेमेंट करते ही आपको %{refundAmount} रुपए वापस किये जायेंगे',
		detailNoteContent2:'नेट बैंकिंग, पेमेंट वॉलेट, क्रेडिट & डेबिट कार्ड',
		reviewPaymentText:'पेमेंट डिटेल्स',
		updateRequiredHeading:'अपडेट की आवश्यकता',
		updateRequiredContent:'कृपया GoPaani ग्राहक ऍप का उपयोग जारी रखने के लिए अपडेट करें।',
		update:'अपडेट',
		advanceText:'एडवांस',
		maximumAlterNativePhoneNumber:'आपके द्वारा अधिकतम 5 फ़ोन नंबर जोड़े जा चुके है',
		invalid_number:'ग़लत फ़ोन नंबर',
		invalidEmailError:'ईमेल सही नहीं है',
		alreadyExistEmailError:'ईमेल पहले से रजिस्टर्ड है',
		discount:'डिस्काउंट',
		if_discount:'डिस्काउंट कोड है? नीचे दर्ज करें',
		enter_discount_code:'डिस्काउंट कोड दर्ज करें',
		apply:'दर्ज करें',
		errorDiscountAlreadyAppliedMessage:'क्षमा करें, कूपॉन पहले ही उपयोग किया जा चुका है',
		errorDiscountCoupanInvalid:'क्षमा करें, कूपॉन अमान्य है',
		couponAppliedText:'कूपॉन जोड़ा गया',
		gopaaniSpecialDiscountMessage:'धन्यवाद! आपको अपने अगले बिल में %{discountValue}%* तक की छूट मिलेगी',
		couponAppliedMessage:'डिस्काउंट कोड पहले ही जोड़ा जा चुका है',
		amount: 'अमाउंट ( ₹ )',
		invoice:'इनवॉइस',
		resend_otp_via_call:'कॉल के द्वारा OTP दोबारा भेजें',
		again_sent_otp_message_via_call:'आपको जल्द ही OTP के साथ एक कॉल प्राप्त होगी',
		sub_heading_verify:'हमने आपको आपके मोबाइल नंबर पर चार अंकों का OTP भेजा है। अपने मोबाइल नंबर की पुष्टि करने के लिए नीचे OTP दर्ज करें - ',
		paymentNotAllowedAlert:'कृपया सीधे आपके सप्लायर को भुगतान करें, हम अभी ज्यादा राशि के भुगतान नहीं स्वीकार कर पा रहे हैं',
		delivery_bill:'डिलीवरी बिल',
		onlinePayment:'ऑनलाइन पेमेंट ',
		balanceYouHave:'आपके पास प्रोडक्ट',
		noBalanceItem:'आपके पास कोई प्रोडक्ट नहीं है ',
		addSupportContactText:'आपकी फ़ोनबुक में GoPaani के फोन नंबर जोड़ने के लिए कृपया निचे दिया बटन दबाएं',
		badRequest:'तकनिकी परेशांनी के कारण नहीं हो पाया हैं, कृपया दोबारा प्रयास करें',
		extra:'अतिरिक्त',
		balanceAmount:'शेष राशि',
		depositAmount:'जमा राशि',
		orderProducts:'प्रोडक्ट ऑर्डर करें',
		yourOrders:'पहले के आर्डर',
		addPlaceOrderProducts:'ऑर्डर करने के लिए प्रोडक्ट जोड़ें',
		totalProduct:'कुल प्रोडक्ट',
		orderSummery:'ऑर्डर विवरण',
		messagesOptional:'मेसेज (वैकल्पिक)',
		submitOrder:'ऑर्डर भेजें',
		selectedDeliveryDateAndTime:'डिलीवरी दिनांक और समय चुनें',
		deliveryDate:'डिलीवरी की तारीख',
		deliveryTime:'डिलीवरी का समय',
		allOrder:'सभी ऑर्डर',
		showMore:'और दिखाएँ',
		defaultTechnicalError:'क्षमा करें, तकनीकी परेशानी हुई हैं,  कृपया पुनः प्रयास करें या हमसे संपर्क करें',
		invalid_token_message:'कृपया फिर से लॉग इन करें',
		createOrderSuccess: 'आर्डर दर्ज किया गया',
		addAtleastQuantity: 'कृपया कम से कम एक प्रोडक्ट जोड़ें',
		noOrderFound:'कोई ऑर्डर नहीं मिल पाया हैं',
		commentText:'टिप्पणी',
		advancePayment:'एडवांस पेमेंट',
		invoiceAmount:'इनवॉइस अमाउंट',
		paymentGivenHeading:'दिया गया पेमेंट',
		runningBalance:'चला आ रहा बैलेंस',
		runningBalanceDate:'%{date} से',
		appliedOn:'%{date} को लागू',
		deliveryInvoiceText:'डिलीवरी इनवॉइस',
		totalInvoiceAmount:'कुल इनवॉइस अमाउंट',
		debit:'डेबिट',
		includesDiscountExtraText:'डिस्काउंट और अतिरिक्त शुल्क शामिल हैं',
		totalGivenAmount:'कुल दी गई राशि',
	},
	gu:{
		'customer':'ગ્રાહક',
		'payment':'ચુકવણી',
		'customer_bills':'ગ્રાહક બિલ',
		'billing_period':'બિલિંગ અવધિ',
		'billing_date':'બિલિંગ તારીખ',
		'total_cool_jar':'કુલ ઠંડા જાર',
		'total_pet_jar':'કુલ પેટ જાર',
		'balance_jar':'બાકીના જાર',
		'cool_jar_pricing':'દીઠ ઠંડા જાર રાશિ',
		'pet_jar_pricing':'દીઠ કેપ્સ્યુલ જાર રાશિ',
		'month_amount':'માસિક રકમ',
		'last_outstanding_amount':'છેલ્લી બાકી રકમ',
		'credit_amount':'જમા રકમ',
		creditText:'જમા',
		'at_starting_amount':'ગોપણીની પૂર્વ ચુકવણી',
		'total_amount':'કુલ રકમ',
		'not_available':'ઉપલબ્ધ નથી',
		'ok':'ઠીક છે',
		'customer_info':'ग्राहक सूची',
		'January':'જાન્યુઆરી',
		'February':'ફેબ્રુઆરી',
		'March':'માર્ચ',
		'April':'એપ્રિલ',
		'May':'મે',
		'June':'જૂન',
		'July':'જુલાઈ',
		'August':'ઑગસ્ટ',
		'September':'સપ્ટેમ્બર',
		'October':'ઑક્ટોબર',
		'November':'નવેમ્બર',
		'December':'ડિસેમ્બર',
		'success_message':'માહિતી અપડેટ કરવા બદલ આભાર',
		'edit_bill':'બિલ સુધારો',
		'change_only_once':'તમે ફક્ત એક જ વાર ડિસ્કાઉન્ટ અથવા વધારાના શુલ્ક દાખલ કરી શકો છો',
		'reason_discount':'ડિસ્કાઉન્ટ માટેનું કારણ દાખલ કરો',
		'discount_value':'ડિસ્કાઉન્ટ રકમ દાખલ કરો',
		'extra_charges':'વિશેષ શુલ્ક દાખલ કરો',
		'reason_extra_charges':'વધારાના શુલ્ક માટેનું કારણ દાખલ કરો',
		'cancel':'ના',
		'paid_payment':'ચુકવણીઓ',
		'extra_cool_jar':'ઠંડા જાર',
		'extra_pet_jar':'કેપ્સ્યુલ જાર',
		'fixed_amount':'નિયત રકમ',
		'total_extra_jar_amount':'કુલ જાર રકમ',
		'a4_size':'A4 કદ',
		'a5_size':'A5 કદ',
		'roll_size':'રોલનું કદ',
		'size_option_message':'બિલ બનાવવા માટે બિલ સાઇઝ પસંદ કરો',
		'fixed_chilled_jar':'સ્થિર મરચી જાર',
		'fixed_capsule_jar':'સ્થિર કેપ્સ્યુલ જાર',
		'total_delivered_chilled_jar':'કુલ વિતરિત મરચી જાર',
		'total_delivered_capsule_jar':'કુલ વિતરિત કેપ્સ્યુલ જાર',
		'choose_language_to_generate_bill':'બિલ બનાવવા માટે ભાષા પસંદ કરો',
		'back':'ગ્રાહક માહિતી',
		'cancelOption':'રદ કરો',
		'share':'રદ કરો',
		'download':'ડાઉનલોડ કરો',
		'print':'છાપો',
		'select_option':'વિકલ્પ પસંદ કરો',
		'jarEntry':'જાર પ્રવેશ',
		'gopaani':'GoPaani',
		'logout':'બહાર જાઓ',
		'logout_message':'શું તમે આ ખાતામાંથી બહાર નીકળવા માંગો છો?',
		'chilled_jar':'મરચી જાર',
		'capsule_jar':'કેપ્સ્યુલ જાર',
		'balance_chilled_jar':'સંતુલન ઠંડા જાર',
		'balance_capsule_jar':'બેલેન્સ કેપ્સ્યુલ જાર',
		'no_data':'આ મહિના માટે પ્રવેશો પ્રાપ્ત થયા નથી',
		'date':'તારીખ',
		'in':'દીવા',
		'out':'માટે',
		'copy_to_clipboard':'ક્લિપબોર્ડ પર ક Copyપિ કરો',
		'copy':'નકલ',
		'have_an_existing_water_supplier':'તમે પહેલેથી જ સપ્લાયર પાસેથી ઉત્પાદનનો ઓર્ડર આપી રહ્યાં છો?',
		'message_content':'હું એપ્લિકેશનમાં દૈનિક જાર પ્રવેશ અને બિલ જોઈ શકું છું તેની ખાતરી કરવા માટે હું ગોપાનીનો ઉપયોગ કરી રહ્યો છું - કૃપા કરીને વધુ માહિતી ક callલ માટે તમારો વ્યવસાય ચલાવવા માટે ગોપાનીનો ઉપયોગ કરો - 98269-66287 90094-67264',
		'notify_message':'આભાર! જ્યારે અમે ગ્રાહક એપ્લિકેશન અપગ્રેડ શરૂ કરીએ છીએ ત્યારે અમે તમને જણાવી',
		'send_them_a_message':'તેમને સંદેશ મોકલો જેથી તમે GoPaani ની સેવાઓનો લાભ લઈ શકો',
		'verify_text':'તમારો મોબાઇલ નંબર તપાસો',
		'sub_heading':'અમે તમને તમારા મોબાઇલ નંબર પર ચાર અંકનો ઓટીપી મોકલ્યો છે. તમારા મોબાઇલ નંબરની પુષ્ટિ કરવા માટે નીચે ઓટીપી દાખલ કરો',
		'correct_otp_number':'OTP ખોટો અથવા સમાપ્ત થઈ ગયો છે',
		'verify_title':'તપાસવા',
		'again_sent_otp_message':'તમારું ઓટીપી તમારા મોબાઇલ નંબર પર પાછા મોકલવામાં આવ્યું છે. કૃપા કરીને નીચે ઓટીપી દાખલ કરો',
		'try_again_later_message':'કૃપા કરીને પછીથી ફરી પ્રયાસ કરો',
		'resend_otp':'OTP ફરીથી મોકલો',
		'time_remaining':'ફરીથી OTP ને વિનંતી કરવામાં સમય બાકી છે',
		'otp':'OTP',
		'permission_to_perform':'તમારી પાસે આ ક્રિયા કરવાની પરવાનગી નથી.',
		'customer_login_heading':'તમારો ફોન નંબર દાખલ કરો',
		'registered_mobile_number':'મોબાઇલ નંબર દાખલ કરો',
		'incorrect_phone_number':'મોબાઇલ નંબર ખોટો છે',
		'submit':'મોકલો',
		'messages':'સંદેશાઓ',
		'home':'હોમ',
		'choose_language':'ભાષા પસંદ કરો',
		'language_changed_succesfully':'ભાષા સફળતાપૂર્વક બદલાઈ',
		'download_bills':'બીલ ડાઉનલોડ કરો',
		'jar_enteries':'જાર પ્રવેશો',
		write_a_message:'સંદેશ લખો',
		switch_account:'એકાઉન્ટ સ્વિચ કરો',
		'yes':'હા',
		ledger:'ખાતું',
		productEntry:'ઉત્પાદન પ્રવેશ',
		bill:'બિલ',
		price:'મૂલ્ય',
		total:'ટોટલ',
		monthlyBillingMessage:'ઉત્પાદનો માટે માસિક બિલિંગ ટૂંક સમયમાં\nઉપલબ્ધ થશે',
		paymentBillLedger:'ચુકવણી અને બિલ લેજર',
		totalBillAmount:'કુલ બિલ રકમ',
		productLedgerStartBalance:'શરૂઆતમાં બેલેન્સ',
		perticulars:'કાટમાળ',
		billAmount:'બિલ રકમ',
		noProductDeliveredMessage:'આ મહિના માટે કોઈ ઉત્પાદનો પ્રદાન કરવામાં આવ્યાં નથી.',
		showing_extra_charges_value:'વધારાની કિમત',
		showing_discount_value:'ડિસ્કાઉન્ટ',
		no_past_payment:'કોઈ જૂના વ્યવહારો નથી',
		jarAmount:'જાર રકમ',
		welcome: 'સ્વાગત છે',
		welcomeContent:'શુદ્ધ પીવાનું પાણી જીવનની સૌથી આવશ્યક વસ્તુ છે અને GoPaani તમારા માટે તે લાવે છે',
		next: 'આગળ',
		howItWork:'તે કેવી રીતે કામ કરે છે?',
		submitDetails:'તમારી માહિતી ઉમેરો',
		step2Content:'ત્રણથી વધુ વેપારીઓ તમારો સંપર્ક કરશે',
		step3content:'પાણીની ડિલિવરી શરૂ કરવા માટે પાણીના વેપારીઓને પસંદ કરવા પુષ્ટિ મોકલો',
		enterName:'નામ દાખલ કરો *',
		enterLocation:'સરનામું દાખલ કરો *',
		getCurrentLocation:'વર્તમાન સ્થાન મેળવો',
		enterPinCode:'પીન કોડ દાખલ કરો *',
		getJarDelivered:'જાર ડિલિવરી મેળવો',
		shop:'દુકાન',
		office:'કાર્યસ્થળ',
		residence:'નિવાસસ્થાન',
		event:'ઇવન્ટ',
		numberOfJar:'જારની સંખ્યા',
		selectTime:'સમય પસંદ કરો',
		selectDateTime:'તારીખ અને સમય પસંદ કરો',
		enterComment:'ટિપ્પણી દાખલ કરો',
		selectLocationType:'સ્થાન પ્રકાર પસંદ કરો',
		whatSupplierHeading:'તમારા વિસ્તારમાં સપ્લાય કરનારા વેપારીઓ - ',
		notserveAreaMessage:'હાલમાં આ સેવા તમારા વિસ્તારમાં ઉપલબ્ધ નથી, જ્યારે ભાવિ સેવા શરૂ થશે ત્યારે અમે તમારો સંપર્ક કરીશું.',
		sendRequestToWaterSupplierMessage:'અમે તમારી માહિતી સપ્લાયરોને મોકલી છે, તેમના જવાબની રાહ જોઈ રહ્યા છીએ',
		inviteWaterSupllierMessage:'તમે જાણતા હોય તેવા વેપારીઓને GoPaani પર આમંત્રિત કરો',
		requestMore:'અને જળ વ્યવસાયને માહિતી મોકલો',
		approveButton:'સંમત થાઓ',
		inviteSupllierHeading:'આજે કેટલાક સારા કામ કરો\GoPaani શેર કરો',
		inviteContent:'હું GoPaani નો ઉપયોગ કરી રહ્યો છું, જેથી હું મોબાઇલ એપ્લિકેશન દ્વારા જારનો નંબર અને બિલ જોઈ શકું, તમે તમારા જળ વ્યવસાયને GoPaani સાથે પણ જોડી શકો છો. - 9009467264',
		sendRequestMoreSuccessMessage:'આભાર ! વ્યવસાય ઉમેરતાની સાથે જ તમને એક સંદેશ મળશે',
		becomeBusinessCustomerSuccessMessage:'આભાર ! તમે હવે GoPaani પર તમારા પસંદ કરેલા વ્યવસાયના સબ્સ્ક્રાઇબર છો',
		approveBusinessMessage:'%{businessName} તમે સબ્સ્ક્રાઇબ કરવા માંગો છો, સંમત છો?',
		mon:'સોમવાર',
		tue:'મંગળવારે',
		wed:'બુધવાર',
		thu:'ગુરુવાર',
		fri:'શુક્રવાર',
		sat:'શનિવાર',
		sun:'રવિવાર',
		todayDelivery:"આજની  વિતરણ",
		pastJarEntries:'પુરાણી વિતરણ',
		jarsYouHave:"તમારી પાસે બરણીઓ છે",
		rateTodayDelivery:"આજે કેવી સેવા હતી?",
		notDeliveryDay:"ડિલિવરીનો દિવસ નથી",
		lastMonthDueAmount:"ગયા મહિનાની બાકી રકમ",
		todayDueAmount:"આજની બાકી રકમ",
		paymentDetails:"ચુકવણીની માહિતી",
		payNow:'पेमेंट करें',
		headingPaymentDetailsScreen:"તમે નીચે આપેલી માહિતી પર ચુકવણી કરી શકો છો",
		bankAccount:"બેંક એકાઉન્ટ",
		bankName:'બેંક નામ',
		accountName:'ખાતાનું નામ',
		accountNo:'ખાતા નંબર',
		branch:'શાખા',
		ifscCode:'આઈએફએસસી કોડ',
		changesInDelivery:"પ્રોડક્ટ નંબરમાં કોઈ ફેરફાર?",
		setOffDay:"એક દિવસની રજા સેટ કરો",
		billsandLedger:'બિલ અને એકાઉન્ટ',
		WaterSupplierHeading:'વેપાર નામ',
		jarDeliveryFrequency:'ડિલિવરી ડે',
		jarDeliveryFrequencyHeading:'તમે પ્રોડક્ટ માટે કેટલા દિવસ પૂછો છો - દરરોજ અથવા એક દિવસ અવગણો અથવા અઠવાડિયાના દિવસો નક્કી કરો',
		daily:"દૈનિક",
		alternate:"એક દિવસ સિવાય",
		selectWeekDays:'અઠવાડિયાના દિવસો',
		selectedDays:'પસંદ કરેલો દિવસ',
		alternateDayHeading:'ડિલિવરી એક દિવસ જૂનો થઈ ગઈ',
		alternateDayIncludeWeekend:'શનિવાર / રવિવારના રોજ પ્રોડક્ટ માટે પૂછો',
		profile:'પ્રોફાઇલ',
		mobileNumber:'મોબાઇલ નંબર',
		change:'બદલો',
		changedMobileNumberSccessMessage:'મોબાઇલ નંબર સફળતાપૂર્વક બદલ્યો',
		updateMobileNumber:'મોબાઇલ નંબર બદલો',
		waterSupplierWillBeNotifiedAbouttheChange:"સપ્લાયરને એક સંદેશ મોકલવામાં આવશે",
		continue:'આગળ',
		fullName:"પૂરું નામ",
		businessName:"વ્યવસાયનું નામ",
		address:'જાણો',
		alternateMobileNumber:"અન્ય મોબાઇલ નંબર્સ",
		email:'સમાનતા',
		dateOfBirth:'બર્થ ડે',
		maritalStatus:'લગ્ન કર્યાં?',
		dateOfMarriageAnniversary:'એનિવર્સરી ડેટ',
		saveDetails:'વિગતો સાચવો',
		setOffDays:'એક દિવસની રજા સેટ કરો',
		selectDateOnWhichYouwillNotBeNeedingjars:'તે દિવસોને માર્ક કરો જ્યારે પ્રોડક્ટ જરૂરી નથી',
		leaveComment:'ટિપ્પણી',
		fromToday:"આજથી",
		fromTomorrow:"આવતીકાલથી",
		fromDayAfterTomorrow:"પહેલા દિવસથી",
		itemPerDelivery:"દૈનિક %{jarType} આવે છે",
		youCanleaveCommentForTodayDelivery:'આજની ​​ડિલિવરી વિશે ટિપ્પણી',
		thanksWeSentyourRequestToWaterSupplier:"આભાર! તમારી વિનંતી મોકલવામાં આવી છે",
		thanksForRating:'આભાર !',
		doYouWantToMakeAnyChangeInTheJarsYouGetDelivered:'પ્રોડક્ટ નંબરમાં કોઈ ફેરફાર?',
		waterSupplierWillBeNotifiedOfYourRequestWillCallOrMessageYouToConfirm:'સપ્લાયરને એક સંદેશ મોકલવામાં આવશે',
		YourRequestSubmittedPleaseWaitWaterSupplierEeviewIt:'તમે %{fromDatetime} - %{toDatetime} સુધી બરણી નહીં પહોંચાડવા વિનંતી કરી છે, કૃપા કરીને પુષ્ટિ માટે રાહ જુઓ.',
		selectDate:"તારીખ પસંદ કરો",
		pleaseEnterValidMobileNumbers:'કૃપા કરી સાચો મોબાઇલ નંબર દાખલ કરો',
		setChangeJarDeliveryMessage:'તમારી વિનંતી તમારા સપ્લાયરને મોકલવામાં આવી છે. તમે %{requestedItemString} માટે %{requestedDatetime} ની વિનંતી કરી',
		youCanPaViaPaymentModeOurPhoneNumberMobile:'તમે %{paymentMode} દ્વારા ચુકવણી કરી શકો છો \nઅમારી સંખ્યા %{paymentMethodNumber} છે',
		noPaymentInfoFound:'કોઈ ચુકવણી માહિતી મળી નથી, ચુકવણી વિગતો માટે તમારા સપ્લાયરનો સંપર્ક કરો',
		help:'હેલ્પ',
		watchVideo:'વિડિઓ જુઓ',
		headingWaterDeliveryCustomers:'તમારી પાસે સપ્લાયર પાસેનો ફોન નંબર દાખલ કરો',
		buttonHeadingWatchVideo:'GoPaani ગ્રાહક એપ્લિકેશનનો ઉપયોગ કેવી રીતે કરવો તે જુઓ',
		transactionSuccessMessage:'ચુકવણી સફળ !',
		transactionInfoText:'%{businessName} એ તમે કરેલી ચુકવણી પ્રાપ્ત થઈ છે',
		transactionDetails:'ટ્રાન્સેકશન નંબર -',
		fixedJarText:"નિશ્ચિત %{jarType}",
		jarPerDelivery:'%{jarType} ડિલિવરી દીઠ',
		deliveredJar:'%{jarType} પહોંચાડ્યો',
		totalJarLoaded:'કુલ લોડેડ %{jarType}',
		totalReturnFilledJar:'રીટર્ન ફીલ %{jarType}',
		totalCollectedJar:'સંગ્રહિત %{jarType}',
		balanceJarText:'બેલેન્સ %{jarType}',
		totalJarInventory:'કુલ %{jarType} સ્ટોક',
		defaultJarPrice:'સામાન્ય રીતે %{jarType} કિંમત શું છે?',
		jarEntryText:'%{jarType} પ્રવેશ ઉમેરો',
		totalJarText:'કુલ %{jarType}',
		jarPricingText:"દીઠ %{jarType} મૂલ્ય",
		extraJarText:'વધારાની %{jarType}',
		totalDeliveredJarText:'કુલ વિતરિત %{jarType}',
		customAmountText:'કસ્ટમ અમોઉન્ટ',
		advanceAmountText:'નીચે અમોઉન્ટ દાખલ કરો',
		advanceAmountSubheadingText:'આ અમોઉન્ટ અગાઉથી માનવામાં આવશે',
		enterAmountText:'અમોઉન્ટ દાખલ કરો',
		billAmount:'બિલની રકમ',
		convenienceFee:'ઓનલાઇન સુવિધા ફી (%{chargesInPercentage} %)',
		refundableText:'UPI ચુકવણી માટે પરત કરવામાં આવશે',
		totalPayabeText:'કુલ ચૂકવવાપાત્ર',
		noteText:'ધ્યાન આપો:',
		detailNoteContentHeading1:'મફત -',
		detailNoteContentHeading2:'ઓનલાઇન સુવિધા ફી (%{chargesInPercentage} %) -',
		detailNoteContent1:'UPI - %{refundAmount} તમે ચુકવણી કરો કે તરત તમને પરત કરવામાં આવશે',
		detailNoteContent2:'નેટ બેન્કિંગ, પેમેન્ટ વોલેટ, ક્રેડિટ અને ડેબિટ કાર્ડ',
		reviewPaymentText:'પેમેન્ટ ડેટાઇલ્સ',
		updateRequiredHeading:'અપડેટ જરૂરી',
		updateRequiredContent:'GoPaani ગ્રાહક એપ્લિકેશનનો ઉપયોગ ચાલુ રાખવા માટે કૃપા કરીને અપડેટ કરો.',
		update:'અપડેટ',
		advanceText:'એડ્વાન્સ',
		maximumAlterNativePhoneNumber:'તમે 5 જેટલા ફોન નંબર ઉમેર્યા છે',
		invalid_number: 'અમાન્ય ફોન નંબર',
		invalidEmailError:'યોગ્ય ઇ - મેલ એડ્રેસ આપો',
		alreadyExistEmailError:'ઈમેલ પહેલેથી જ અસ્તિત્વમાં છે',
		discount:'ડિસ્કાઉન્ટ',
		if_discount:'ડિસ્કાઉન્ટ કોડ છે? નીચે દાખલ કરો',
		enter_discount_code:'ડિસ્કાઉન્ટ કોડ દાખલ કરો',
		apply:'દાખલ કરો',
		errorDiscountAlreadyAppliedMessage:'માફ કરશો, કૂપૉન નો ઉપયોગ થઈ ચૂક્યો છે',
		errorDiscountCoupanInvalid:'માફ કરશો, કૂપૉન અમાન્ય છે',
		couponAppliedText:'કૂપૉન ઉમેર્યું',
		gopaaniSpecialDiscountMessage:'આભાર! તમને તમારા આગલા બિલ પર %{discountValue}%* સુધીનું ડિસ્કાઉન્ટ મળશે',
		couponAppliedMessage:'ડિસ્કાઉન્ટ કોડ પહેલેથી જ ઉમેરાયેલ છે',
		amount:"રકમ  (₹)",
		invoice:"ઇન્વોઇસ",
		resend_otp_via_call:'કૉલ દ્વારા OTP ફરીથી મોકલો',
		again_sent_otp_message_via_call:'તમને ટૂંક સમયમાં OTP સાથે કૉલ પ્રાપ્ત થશે',
		sub_heading_verify:'અમે તમને તમારા મોબાઇલ નંબર પર ચાર અંકનો ઓટીપી મોકલ્યો છે. તમારા મોબાઇલ નંબરની પુષ્ટિ કરવા માટે નીચે ઓટીપી દાખલ કરો - ',
		paymentNotAllowedAlert:'કૃપા કરીને તમારા સપ્લાયરને સીધું ચૂકવણી કરો, અમે આ સમયે મોટી રકમની ચૂકવણી સ્વીકારી શકતા નથી',
		delivery_bill:'ડિલિવરી બિલ',
		onlinePayment:'ઑનલાઇન ચુકવણી',
		balanceYouHave:'પ્રોડક્ટ તમારી પાસે છે',
		noBalanceItem:'તમારી પાસે પ્રોડક્ટ નથી',
		in:"અંદર",
		out:"બહાર",
		addSupportContactText:'તમારી કોન્ટેક્ટ બુકમાં GoPaani સપોર્ટ ફોન નંબર ઉમેરવા માટે કૃપા કરીને "આગલું" દબાવો.',
		badRequest:'તકનીકી સમસ્યાને કારણે થઈ શક્યું નથી, કૃપા કરીને ફરી પ્રયાસ કરો',
		extra:'વધારાની',
		balanceAmount:'બેલેન્સ રકમ',
		depositAmount:'જમા રકમ',
		orderProducts:'પ્રોડકટ આદેશો',
		yourOrders:'પૂર્વ ઓર્ડર',
		addPlaceOrderProducts:'ઓર્ડર માટે પ્રોડકટ ઉમેરો',
		totalProduct:'કુલ પ્રોડકટ',
		orderSummery:'ઓર્ડર વિગત',
		messagesOptional:'સંદેશ (વૈકલ્પિક)',
		submitOrder:'ઓર્ડર મોકલો',
		selectedDeliveryDateAndTime:'ડિલિવરી તારીખ અને સમય પસંદ કરો',
		deliveryDate:'ડિલિવરીની તારીખ',
		deliveryTime:'ડિલિવરી સમય',
		allOrder:'બધા ઓર્ડર',
		showMore:'વધારે બતાવ',
		defaultTechnicalError:'માફ કરશો, તકનીકી મુશ્કેલીઓ આવી છે, કૃપા કરીને ફરી પ્રયાસ કરો અથવા અમારો સંપર્ક કરો',
		invalid_token_message:"કૃપા કરીને ફરી લોગિન કરો",
		createOrderSuccess: 'ઓર્ડર દાખલ કર્યો',
		addAtleastQuantity: 'કૃપા કરીને ઓછામાં ઓછું એક ઉત્પાદન ઉમેરો',
		noOrderFound:'કોઈ ઓર્ડર મળ્યા નથી',
		commentText:"ટિપ્પણી",
		advancePayment:'અગાઉથી ચુકવણી',
		invoiceAmount:'ઇનવોઇસ એકાઉન્ટ',
		paymentGivenHeading:'ચૂકવણી આપી',
		runningBalance:'રનિંગ બેલેન્સ',
		runningBalanceDate:'%{date} થી',
		appliedOn:'%{date} ના રોજ લાગુ',
		deliveryInvoiceText:'ડિલિવરી ઇન્વૉઇસ',
		totalInvoiceAmount:'કુલ ઇનવોઇસ રકમ',
		debit:'ડેબિટ',
		includesDiscountExtraText:'ડિસ્કાઉન્ટ અને વધારાના શુલ્કનો સમાવેશ થાય છે',
		totalGivenAmount:'કુલ આપેલ રકમ',
	},
	mr:{
		'customer':'ग्राहक',
		'payment':'देय',
		'customer_bills':'ग्राहक बिले',
		'billing_period':'बिलिंगचा कालावधी',
		'billing_date':'बिलिंग तारीख',
		'total_cool_jar':'एकूण थंड जार',
		'total_pet_jar':'एकूण कैप्सूल जार',
		'balance_jar':'शिल्लक जार',
		'cool_jar_pricing':'प्रति थंड जार किंमत',
		'pet_jar_pricing':'प्रति कैप्सूल जार किंमत',
		'month_amount':'महिन्याची रक्कम',
		'last_outstanding_amount':'शेवटची थकीत रक्कम',
		'credit_amount':'पत रक्कम',
		creditText:'जमा',
		'at_starting_amount':'GoPaani आधी देय देय',
		'total_amount':'एकूण रक्कम',
		'not_available':'उपलब्ध नाही',
		'ok':'ठीक आहे',
		'customer_info':'ગ્રાહક માહિતી',
		'January':' जानेवारी',
		'February':'फेब्रूवारी',
		'March':'मार्च',
		'April':' एप्रिल',
		'May':'मे',
		'June':'जून',
		'July':' जुलै',
		'August':'ऑगस्ट',
		'September':'सप्टेंबर',
		'October':'ऑक्टोबर',
		'November':'नोव्हेंंबर',
		'December':'डिसेंबर',
		'success_message':'तपशील अद्यतनित केल्याबद्दल धन्यवाद',
		'edit_bill':'बिल संपादित करा',
		'change_only_once':'आपण फक्त एकदाच सूट किंवा अतिरिक्त शुल्क प्रविष्ट करू शकता',
		'reason_discount':'सूट देण्याचे कारण द्या',
		'discount_value':'सवलत रक्कम प्रविष्ट करा',
		'extra_charges':'अतिरिक्त शुल्क प्रविष्ट करा',
		'reason_extra_charges':'अतिरिक्त शुल्कासाठी कारण प्रविष्ट करा',
		'cancel':'नाही',
		'paid_payment':'देयके',
		'extra_cool_jar':'थंडगार बरणी',
		'extra_pet_jar':'कॅप्सूल किलकिले',
		'fixed_amount':'निश्चित रक्कम',
		'total_extra_jar_amount':'एकूण जार रक्कम',
		'a4_size':'A4 आकार',
		'a5_size':'A5 आकार',
		'roll_size':'रोल आकार',
		'size_option_message':'बिल तयार करण्यासाठी बिल आकार निवडा',
		'fixed_chilled_jar':'फिक्स्ड चिल्ड जार',
		'fixed_capsule_jar':'फिक्स्ड कॅप्सूल जार',
		'total_delivered_chilled_jar':'एकूण वितरित थंडगार जार',
		'total_delivered_capsule_jar':'एकूण वितरीत कॅप्सूल जार',
		'choose_language_to_generate_bill':'बिल तयार करण्यासाठी भाषा निवडा',
		'back':'ग्राहक माहिती',
		'cancelOption':'रद्द करा',
		'share':'सामायिक करा',
		'download':'डाउनलोड करा',
		'print':'मुद्रित करा',
		'select_option':'पर्याय निवडा',
		'jarEntry':'जार एंट्री',
		'gopaani':'GoPaani',
		'logout':'बाहेर पडणे',
		'logout_message':'आपली खात्री आहे की आपण लॉगआउट करू इच्छिता?',
		'chilled_jar':'थंडगार बरणी',
		'capsule_jar':'कॅप्सूल किलकिले',
		'balance_chilled_jar':'शिल्लक थंडगार जार',
		'balance_capsule_jar':'बॅलन्स कॅप्सूल जार',
		'no_data':'या महिन्यासाठी कोणतीही जार एंट्री आढळली नाही',
		'date':'तारीख',
		'in':'दिवे',
		'out':'च्या साठी',
		'copy_to_clipboard':'क्लिपबोर्डवर कॉपी करा',
		'copy':'प्रत',
		'have_an_existing_water_supplier':'तुम्ही आधीच पुरवठादाराकडून उत्पादन ऑर्डर करत आहात?',
		'message_content':'मी अॅपमध्ये दररोजची जार एंट्री आणि बिले पाहू शकतो हे सुनिश्चित करण्यासाठी मी गोपाणी वापरत आहे - कृपया आपला व्यवसाय चालविण्यासाठी GoPaani वापरा,अधिक माहिती कॉलसाठी - 98269-66287 90094-67264',
		'notify_message':'धन्यवाद! आम्ही ग्राहक अॅप अपग्रेड सुरू करतो तेव्हा आम्ही आपल्याला कळवू',
		'send_them_a_message':'त्यांना GoPaani वर खाते तयार करण्यासाठी एक संदेश पाठवा',
		'verify_text':'आपला मोबाइल नंबर सत्यापित करा',
		'sub_heading':'आम्ही आपल्या मोबाइल नंबरवर चार अंकी OTP पाठविला आहे. आपल्या मोबाइल नंबरची पुष्टी करण्यासाठी खाली OTP दर्ज करा',
		'correct_otp_number':'ओटीपी चुकीचे किंवा एक्सपायर्ड झाले आहे',
		'verify_title':'सत्यापित करा',
		'again_sent_otp_message':'आपला OTP पुन्हा आपल्या मोबाइल नंबरवर पाठविला आहे. कृपया खाली OTP दर्ज करा',
		'try_again_later_message':'कृपया, काही वेळाने पुन्हा प्रयत्न करा',
		'resend_otp':'ओटीपी पुन्हा पाठवा',
		'time_remaining':'नवीन OTP मिळविण्यासाठी थांबा',
		'otp':'OTP',
		'permission_to_perform':'आपणास ही क्रिया करण्याची परवानगी नाही.',
		'customer_login_heading':'आपला फोन नंबर प्रविष्ट करा',
		'registered_mobile_number':'मोबाइल नंबर प्रविष्ट करा',
		'incorrect_phone_number':'फोन नंबर चुकीचा आहे',
		'submit':'प्रस्तुत करणे',
		'messages':'संदेश',
		'home':'मुख्यपृष्ठ',
		'choose_language':'भाषा निवडा',
		'language_changed_succesfully':'भाषा यशस्वीरित्या बदलली',
		'download_bills':'बिले डाउनलोड करा',
		'jar_enteries':'जार नोंदी',
		write_a_message:'एक संदेश लिहा',
		switch_account:'खाते बदल',
		'yes':'होय',
		ledger:'खाते',
		productEntry:'प्रोडक्ट एंट्री',
		bill:'बिल',
		price:'मूल्य',
		total:'एकूण',
		monthlyBillingMessage:'प्रोडक्ट्स साठी मासिक बिलिंग लवकरच उपलब्ध होईल!',
		paymentBillLedger:'पेमेंट आणि बिल लेजर',
		totalBillAmount:'एकूण बिलाची रक्कम',
		productLedgerStartBalance:'प्रारंभ करीत आहे बॅलन्स',
		perticulars:'कुणाद्वारे',
		billAmount:'बिल रक्कम',
		noProductDeliveredMessage:'या महिन्यासाठी कोणतीही उत्पादने दिली गेली नाहीत',
		showing_extra_charges_value:'अतिरिक्त शुल्क',
		showing_discount_value:'सवलत',
		no_past_payment:'मागील देय तपशील नाहीत',
		jarAmount:'जार रक्कम',
		welcome: 'स्वागत आहे',
		welcomeContent:'शुद्ध पिण्याचे पाणी ही जीवनातील सर्वात आवश्यक गोष्ट आहे आणि GoPaani आपल्यासाठी ती आणते',
		next: 'पुढे',
		howItWork:'हे कस काम करत?',
		submitDetails:'आपली माहिती जोडा',
		step2Content:'तीनहून अधिक व्यापारी आपल्याशी संपर्क साधतील',
		step3content:'पाण्याचे वितरण सुरू करण्यासाठी जल व्यापारी निवडण्यासाठी पुष्टीकरण पाठवा',
		enterName:'नाव प्रविष्ट करा *',
		enterLocation:'पत्ता प्रविष्ट करा *',
		getCurrentLocation:'सध्याचे स्थान मिळवा',
		enterPinCode:'पिन कोड रविष्ट करा *',
		getJarDelivered:'किलची वितरण मिळवा',
		shop:'दुकान',
		office:'कामाची जागा',
		residence:'निवासस्थान',
		event:'इवेंट',
		numberOfJar:'किलकिलेची संख्या',
		selectTime:'वेळ निवडा',
		selectDateTime:'तारीख आणि वेळ निवडा',
		enterComment:'टिप्पणी प्रविष्ट करा',
		selectLocationType:'स्थान प्रकार निवडा',
		whatSupplierHeading:'तुमच्या परिसरात पुरवठा करणारे व्यापारी - ',
		notserveAreaMessage:'सध्या ही सेवा आपल्या क्षेत्रात उपलब्ध नाही, भविष्यात सेवा सुरू होईल तेव्हा आम्ही आपल्याशी संपर्क साधू.',
		sendRequestToWaterSupplierMessage:'आम्ही तुमची माहिती पुरवठादारांना पाठवली आहे, त्यांच्या उत्तराची वाट पाहत आहोत',
		inviteWaterSupllierMessage:'तुमच्या ओळखीच्या व्यापाऱ्यांना GoPaani वर आमंत्रित करा',
		requestMore:'आणि पाण्याच्या व्यवसायासाठी माहिती पाठवा',
		approveButton:'संमती द्या',
		inviteSupllierHeading:'आज कुछ अच्छा कार्य करे\GoPaani सामायिक करा',
		inviteContent:'मी GoPaani वापरत आहे, जेणेकरुन मला मोबाईल अ‍ॅपद्वारे जारची संख्या आणि बील दिसू शकतील, आपण आपला पाण्याचा व्यवसाय GoPaani शी देखील जोडू शकता. - 9009467264',
		sendRequestMoreSuccessMessage:'धन्यवाद ! व्यवसाय जोडताच तुम्हाला एक संदेश मिळेल',
		becomeBusinessCustomerSuccessMessage:'धन्यवाद ! आपण आता GoPaani वर आपल्या निवडलेल्या व्यवसायाचे ग्राहक आहात',
		approveBusinessMessage:'%{businessName} आपली सदस्यता घेऊ इच्छिता, सहमत आहात?',
		mon:'सोमवार',
		tue:'मंगळवार',
		wed:'बुधवार',
		thu:'गुरुवार',
		fri:'शुक्रवार',
		sat:'शनिवार',
		sun:'रविवारी',
		todayDelivery:'आजची डिलीवरी',
		pastJarEntries:'पुरानी  वितरण',
		jarsYouHave:'आपल्याकडे असलेले जार',
		rateTodayDelivery:'आज सेवा कशी होती?',
		notDeliveryDay:'डिलीवरी दिवस नाही',
		todayDueAmount:'आजची थकित रक्कम',
		lastMonthDueAmount:'मागील महिन्याची थकबाकी',
		paymentDetails:'पेमेंट माहिती',
		payNow:'पेमेंट करें',
		headingPaymentDetailsScreen:'आप निचे दी गयी जानकारी पर भुगतान कर सकते हैं',
		bankAccount:'बैंक अकाउंट',
		bankName:'बैंक नाव',
		accountName: 'बैंक खात्याचे नाव',
		accountNo:' अकाउंट नंबर ब्रांच',
		branch:'ब्रांच',
		ifscCode:'IFSC कोड',
		changesInDelivery:'प्रॉडक्ट नंबरमध्ये काही बदल?',
		setOffDay:'सुट्टी च्या दिवस ची नोंद करा ',
		billsandLedger:'बिल आणि खाते',
		WaterSupplierHeading:'व्यापाराचे नाव',
		jarDeliveryFrequency:'डिलीवरी चे दिवस',
		jarDeliveryFrequencyHeading:'आपण प्रॉडक्ट किती दिवस विचारता - दररोज किंवा एक दिवस वगळा किंवा आठवड्याचे काही दिवस निश्चित करा',
		daily:"दैनिक",
		alternate:"एक दिवस वगळता",
		selectWeekDays:'सप्ताह के दिन',
		selectedDays:'आठवड्याचे दिवस',
		alternateDayHeading:'एक दिवस कालबाह्य झाला',
		alternateDayIncludeWeekend:'शनिवार / रविवारी प्रॉडक्ट मागा',
		profile:'प्रोफाइल ',
		mobileNumber:'मोबाइल नंबर',
		change:'बदला',
		changedMobileNumberSccessMessage:'मोबाइल नंबर यशस्वीरित्या बदलला',
		updateMobileNumber:'मोबाइल नंबर बदला',
		waterSupplierWillBeNotifiedAbouttheChange:'पुरवठादाराला संदेश पाठवला जाईल',
		continue:'पुढे',
		fullName:'पूर्ण नाव',
		businessName :'व्यापार नाव',
		address:'माहित आहे',
		changeAddress:'पत्ता बदला',
		alternateMobileNumber:'इतर मोबाइल नंबर',
		email:'ई-मेल',
		dateOfBirth:'वाढदिवस',
		maritalStatus:'विवाहित आहे',
		dateOfMarriageAnniversary:'वर्धापन दिन',
		saveDetails:'पाठवा',
		setOffDays:'दिवसाची सुट्टी द्या',
		selectDateOnWhichYouwillNotBeNeedingjars:'प्रॉडक्ट आवश्यक नसलेले दिवस चिन्हांकित करा',
		leaveComment:'कमेंट करें',
		fromToday:'आज पासून',
		fromTomorrow:'उद्या पासून',
		fromDayAfterTomorrow:'परवा पासून',
		itemPerDelivery:'दररोज %{jarType} येतात',
		youCanleaveCommentForTodayDelivery:'आजच्या डिलीवर बद्दल टिप्पणी',
		thanksWeSentyourRequestToWaterSupplier:'धन्यवाद! आपली विनंती पाठविली गेली आहे',
		thanksForRating:'धन्यवाद!',
		doYouWantToMakeAnyChangeInTheJarsYouGetDelivered:'आपल्याला दररोज येणाऱ्या प्रोडक्ट संख्येत बदल हवा आहे का?',
		waterSupplierWillBeNotifiedOfYourRequestWillCallOrMessageYouToConfirm:'आपण प्रदान केलेली माहिती पाठविली गेली आहे, सप्लायर आपल्याला संदेश पाठवेल किंवा कॉल करेल',
		selectDate:'तारीख निवडा',
		thanksForupdatingDetails:'धन्यवाद.!',
		pleaseEnterValidMobileNumbers:'कृपया योग्य मोबाइल नंबर प्रविष्ट करा',
		YourRequestSubmittedPleaseWaitWaterSupplierEeviewIt:'आपण %{fromDatetime} - %{toDatetime} पर्यंत किलकिले न वितरित करण्याची विनंती केली आहे, कृपया पुष्टीकरणाची प्रतीक्षा करा.',
		setChangeJarDeliveryMessage:'आपली विनंती पाठविली गेली आहे. आपण %{fromeRquestedDatetime} रोजी %{requestedItemString} साठी विनंती केली आहे',
		youCanPaViaPaymentModeOurPhoneNumberMobile:'आपण %{paymentMode} द्वारे पैसे देऊ शकता\nआमची नंबर %{paymentMethodNumber} आहे.',
		noPaymentInfoFound:'कोणतीही देय माहिती आढळली नाही, देयक तपशीलांसाठी आपल्या पुरवठादाराशी संपर्क साधा',
		help:'हेल्प',
		watchVideo:'व्हिडिओ पहा',
		headingWaterDeliveryCustomers:'पुरवठादाराकडे असलेला फोन नंबर प्रविष्ट करा',
		buttonHeadingWatchVideo:'GoPaani कस्टमर अॅप कसे वापरावे ते पहा',
		transactionSuccessMessage:'पेयमेंट यशस्वी झाले!',
		transactionInfoText:'%{businessName} आपले देयक प्राप्त झाले',
		transactionDetails:'ट्रांजैक्शन क्रमांक -',
		fixedJarText:"निश्चित %{jarType}",
		jarPerDelivery:'%{jarType} प्रति वितरण',
		deliveredJar:'डिलिव्हर्ड %{jarType}',
		totalJarLoaded:'एकूण भारित %{jarType}',
		totalReturnFilledJar:'रिटर्न फिल्ड %{jarType}',
		totalCollectedJar:'एकत्रित %{jarType}',
		balanceJarText:'शिल्लक %{jarType}',
		totalJarInventory:'टोटल %{jarType} स्टॉक',
		defaultJarPrice:'%{jarType} सहसा कोणती किंमत देतात?',
		jarEntryText:'%{jarType} एंट्री',
		totalJarText:'एकूण %{jarType}',
		jarPricingText:"प्रति %{jarType} किंमत",
		extraJarText:'अतिरिक्त %{jarType}',
		totalDeliveredJarText:'एकूण वितरित %{jarType}',
		customAmountText:'कस्टम रक्कम',
		advanceAmountText:'खाली रक्कम प्रविष्ट करा',
		advanceAmountSubheadingText:'ही रक्कम एडवांस समजली जाईल',
		enterAmountText:'रक्कम प्रविष्ट करा',
		billAmount:'बिल अमाउंट',
		convenienceFee:'ऑनलाइन सुविधा शुल्क (%{chargesInPercentage} %)',
		refundableText:'UPI पेमेंट साठी रिफंड केले जाईल',
		totalPayabeText:'एकूण देय',
		noteText:'टीप:',
		detailNoteContentHeading1:'फ्री - ',
		detailNoteContentHeading2:'ऑनलाइन सुविधा शुल्क (%{chargesInPercentage} %) - ',
		detailNoteContent1:'UPI - तुम्ही पेमेंट करताच तुम्हाला %{refundAmount} परत केले जाईल',
		detailNoteContent2:'नेट बँकिंग, पेमेंट वॉलेट, क्रेडिट आणि डेबिट कार्ड',
		reviewPaymentText:'पेमेंट डिटेल्स',
		updateRequiredHeading:'अपडेट आवश्यक',
		updateRequiredContent:'कृपया GoPaani ग्राहक अॅप वापरणे सुरू ठेवण्यासाठी अपडेट करा.',
		update:'अपडेट',
		advanceText:'एडवांस',
		maximumAlterNativePhoneNumber:'तुम्ही 5 पर्यंत फोन नंबर जोडले आहेत',
		invalid_number: 'अवैध फोन नंबर',
		invalidEmailError:'वैध ईमेल पत्ता दर्ज करा',
		alreadyExistEmailError:'ईमेल आधीच रजिस्टर्ड आहे',
		discount:'डिस्काउंट',
		if_discount:'डिस्काउंट कोड आहे का? खाली प्रविष्ट करा',
		enter_discount_code:'डिस्काउंट कोड प्रविष्ट करा',
		apply:'प्रविष्ट करा',
		errorDiscountAlreadyAppliedMessage:'क्षमा करा, कूपॉन आधीच वापरले गेले आहे',
		errorDiscountCoupanInvalid:'क्षमा करा, कूपॉन अवैध आहे',
		couponAppliedText:'कूपॉन जोडले',
		gopaaniSpecialDiscountMessage:'धन्यवाद! तुमच्या पुढील बिलावर तुम्हाला %{discountValue}%* पर्यंत सूट मिळेल',
		couponAppliedMessage:'डिस्काउंट कोड आधीच जोडला आहे',
		'amount': 'रक्कम  (₹)',
		invoice:'इनवॉइस',
		resend_otp_via_call:'कॉलद्वारे OTP पुन्हा पाठवा',
		again_sent_otp_message_via_call:'तुम्हाला लवकरच OTP सह कॉल प्राप्त होईल',
		sub_heading_verify:'आम्ही आपल्या मोबाइल नंबरवर चार अंकी OTP पाठविला आहे. आपल्या मोबाइल नंबरची पुष्टी करण्यासाठी खाली OTP दर्ज करा - ',
		paymentNotAllowedAlert:'कृपया तुमच्या पुरवठादाराला थेट पैसे द्या, आम्ही यावेळी मोठ्या रकमेची देयके स्वीकारण्यास सक्षम नाही',
		delivery_bill:'डिलीवरी बिल',
		onlinePayment:'ऑनलाइन पेमेंट',
		balanceYouHave:'तुमच्याकडे असलेले उत्पादन',
		noBalanceItem:'तुमच्याकडे उत्पादन नाही',
		in:'दिवे',
		out:'च्या साठी',
		addSupportContactText:'कृपया आपल्या फोनबुकमध्ये GoPaani चे फोन नंबर जोडण्यासाठी खालील बटण दाबा',
		badRequest:'तकनिकी परेशांनी के कारण नहीं हो पाया हे, कृपया दोबारा प्रयास करें',
		extra:'अतिरिक्त',
		balanceAmount:'शिल्लक रक्कम',
		depositAmount:'ठेवीची रक्कम',
		orderProducts:'प्रोडक्ट ऑर्डर करा',
		yourOrders:'पहिलेची ऑर्डर',
		addPlaceOrderProducts:'ऑर्डरमध्ये प्रोडक्ट जोडा',
		totalProduct:'एकूण प्रोडक्ट',
		orderSummery:'ऑर्डर तपशील',
		messagesOptional:'संदेश (पर्यायी)',
		submitOrder:'ऑर्डर पाठवा',
		selectedDeliveryDateAndTime:'डिलीवरी तारीख आणि वेळ निवडा',
		deliveryDate:'डिलीवरीची तारीख',
		deliveryTime:'डिलीवरीची वेळ',
		allOrder:'सर्व  ऑर्डर',
		showMore:'अजून दाखवा',
		defaultTechnicalError:'क्षमस्व, तांत्रिक अडचणी आल्या, कृपया पुन्हा प्रयत्न करा किंवा आमच्याशी संपर्क साधा',
		invalid_token_message:'कृपया पुन्हा लॉग इन करा',
		createOrderSuccess: 'ऑर्डर दाखल केली',
		addAtleastQuantity: 'कृपया किमान एक प्रोडक्ट जोडा',
		noOrderFound:'ऑर्डर आढळल्या नाहीत',
		commentText:'टिप्पणी',
		advancePayment:'एडवांस पेमेंट',
		invoiceAmount:'इन्व्हॉइस खाते',
		paymentGivenHeading:'पेमेंट दिले',
		runningBalance:'रनिंग बॅलन्स',
		runningBalanceDate:'%{date} पासून',
		appliedOn:'%{date} रोजी लागू',
		deliveryInvoiceText:'डिलिव्हरी इन्व्हॉइस',
		totalInvoiceAmount:'एकूण इनव्हॉइस रक्कम',
		debit:'डेबिट',
		includesDiscountExtraText:'सवलत आणि अतिरिक्त शुल्क समाविष्ट आहे',
		totalGivenAmount:'एकूण दिलेली रक्कम',
	}
}
export default translationValues;