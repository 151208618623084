import React, { Component } from 'react';
import { Text, View, StyleSheet, ScrollView, TextInput, TouchableOpacity, ActivityIndicator, Image} from 'react-native';
import {Button, Header} from '@rneui/themed';
import { LinearGradient } from 'expo-linear-gradient';
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import { FontAwesome } from '@expo/vector-icons';
import i18n from 'i18n-js';
import client from '../client';
import translationValues from '../components/translation';
import logout from '../components/logout';
import {isValidPhoneNumber} from '../commonFunctions';
import alert from '../utils/commonAlert'
/*
	usedFor:- this component is used when customer want to change the phone number that he/she loggedin with
*/
export default class ResetCustomerPhoneNumber extends Component {
	constructor(props){
		super(props);
		this.state = {
			customerPhoneNumber: '',
			isLoading:false,
			errorMessage:'',
			showErrorMessage:false
		}
	}

	componentDidMount(){
		this.willFocusSubscription = this.props.navigation.addListener('focus', ()=>{
			i18n.fallbacks = true;
			i18n.translations = translationValues;
			i18n.locale = global.isLanguage;
		})
	}
	componentWillUnmount(){
		this.willFocusSubscription()
	}

	onChangeText = (val) => {
		const isValidValue = isValidPhoneNumber(val)//Check is only digit
		if(isValidValue === true){
			this.setState({ customerPhoneNumber: val })
		}
		else{
			// Do Nothing
		}
	}

	onResetCustomerPhoneNumberSubmit = async () => {
		this.setState({isLoading:true})
		const {customerPhoneNumber} = this.state;
		const { navigation, route, route: { params } } = this.props;
		let loginCustomerData = params.loginCustomerData;
		let requestBody = {'customer_phone_number':customerPhoneNumber}
		const responseData = await client.resetCustomerPhoneNumber(loginCustomerData.customer_otp_verify_id, requestBody)
		if (responseData.detail) {
			alert(
				'Customer',
				'You do not have permission to perform this action.',
				[
					{text: 'OK', onPress: () => logout(this.props.navigation)},
				],
				{ cancelable: false }
			)
		}else{
			if(responseData.success === true){
				this.setState({isLoading:false})
				this.props.navigation.navigate('ResetPhoneNumberOtpView',{
					loginCustomerData:loginCustomerData,
					customerResetPhoneNumber:responseData.customer_phone_number,
				})

			}else{
				let errorMessage = ''
				if(responseData.errors.hasOwnProperty('customer_phone_number')){
					errorMessage = responseData.errors['customer_phone_number'][0]
				}
				this.setState({isLoading:false, showErrorMessage:true, errorMessage:errorMessage})
			}
		}
	}
	render(){
		if(this.state.isLoading){
			return(
				<View style={styles.loadingViewStyle}>
					<ActivityIndicator size="large" color="skyblue" />
				</View>
			)
		}
		else{
			return(
				<View style={styles.mainViewStyle}>
					<Header
						statusBarProps={{ barStyle: 'light-content', "backgroundColor": "#173988", }}
						placement={"left"}
						leftComponent={
							<TouchableOpacity
								style={styles.headerLeftComponentStyle}
								activeOpacity={0.5}
								onPress={()=>this.props.navigation.goBack()}>
								<FontAwesome name='chevron-left' color='#38c6f4' size={28} />
							</TouchableOpacity>
						}
						leftContainerStyle={{justifyContent:'center'}}
						ViewComponent={LinearGradient}
						linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0}, end:{x: 1, y: 0}}}
					/>
					<ScrollView keyboardShouldPersistTaps={'always'}>
						<View style={styles.headingViewStyle}>
							<Text style={styles.headingTextStyle}>{i18n.t('updateMobileNumber')}</Text>
							<Text style={styles.headingContentTextStyle}>{i18n.t('waterSupplierWillBeNotifiedAbouttheChange')}</Text>
						</View>
						<View style={styles.contentContainerStyle}>
							<View style={this.state.showErrorMessage === true ?styles.errorFieldViewStyle :styles.fieldViewStyle}>
								<View style={styles.requiredLabelViewStyle}>
									<Text style={styles.labelTextStyle}>{i18n.t('mobileNumber')}</Text>
									<Text style={styles.requiredLabelTextStyle}>{' *'}</Text>
								</View>
								<View style={styles.fieldInnerViewStyle}>
									<TextInput
										style={styles.input}
										autoCapitalize="none"
										autoCompleteType="off"
										maxLength={10}
										keyboardType='number-pad'
										onChangeText={this.onChangeText}
										value={this.state.customerPhoneNumber}
									/>
								</View>
							</View>
						</View>
						{this.state.showErrorMessage === true &&
							<Text style={styles.errorText}>{this.state.errorMessage}</Text>
						}
						<Button
							ViewComponent={LinearGradient}
							linearGradientProps={{colors:['#175491','#175491','#5154b5','#5154b5'],start:{x: 0, y: 0},end:{x: 1, y: 0},}}
							title={i18n.t('continue')}
							buttonStyle={styles.buttonStyle}
							containerStyle={styles.containerStyle}
							titleStyle={styles.titleStyle}
							onPress={this.onResetCustomerPhoneNumberSubmit}
							disabled={this.state.customerPhoneNumber.length<10 ?true:false}
							disabledTitleStyle={{color:'hsl(208, 8%, 63%)'}}
						/>
					</ScrollView>
				</View>
			)
		}
	}
}


const styles = StyleSheet.create({
	errorText:{
		fontSize:12,
		textAlign:'center',
		color:'red',
		width:wp('85%'),
		marginBottom:5,
	},
	contentContainerStyle:{
		justifyContent:'center',
		alignItems:'center',
	},
	mainViewStyle:{
		flex:1,
		backgroundColor:'#ffffff'
	},
	headingTextStyle:{
		fontWeight:'bold',
		fontSize:20,
		color:'#175491',
		marginTop:10,
		marginBottom:10,
	},
	headingViewStyle:{
		flexDirection:'column',
		padding:10
	},
	headingContentTextStyle:{
		fontWeight:'bold',
		fontSize:16,
		marginTop:10,
		marginBottom:10,
		color:'#b3b3b3'
	},
	loadingViewStyle:{
		backgroundColor:'#ffffff',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	errorFieldViewStyle:{
		borderWidth:0.5,
		borderColor:'red',
		borderRadius:5,
		width:wp('95%'),
		height:50,
		alignItems:'flex-start',
		marginTop:20,
		marginBottom:20
	},
	fieldViewStyle:{
		borderWidth:1,
		borderColor:'#38c6f4',
		borderRadius:5,
		width:wp('95%'),
		height:50,
		alignItems:'flex-start',
		marginTop:20,
		marginBottom:20
	},
	requiredLabelViewStyle:{
		flexDirection:'row',
		backgroundColor:'#ffffff',
		marginTop:-10,
		marginLeft:15,
		marginBottom:5,
		paddingLeft:5,
		paddingRight:5
	},
	fieldInnerViewStyle:{
		alignItems:'center',
		flexDirection:'row',
		paddingLeft:10
	},
	input: {
		width: wp('85%'),
		fontSize: 14,
		marginLeft:10,
		color:'#808080',
		fontWeight:'bold'
	},
	requiredLabelTextStyle:{
		fontSize: 14,
		color: "red",
	},
	labelTextStyle:{
		color:'#b3b3b3',
		fontSize:12
	},
	titleStyle:{
		fontWeight:'bold',
		color:'#ffffff',
		fontSize:20,
		textAlign:'center'
	},
	containerStyle:{
		flexDirection:'row',
		justifyContent:'center',
		alignItems:'center',
		marginTop:20
	},
	buttonStyle:{
		width:wp('75%'),
		padding:15,
		borderRadius:5
	},
	headerLeftComponentStyle:{
		flexDirection:'row',
		alignItems:'center'
	},
	headerContainerStyle:{
		borderBottomWidth:null,
		backgroundColor:null,
		alignItems:'center',
		elevation:5
	},
})